import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import userReducer from './user'
import messagesCountReducer from './messagesCount'
import notificationsCountReducer from './notificationsCount'
import isHeaderExpandedReducer from './isHeaderExpanded'
import adsReducer from './ads'

const store = configureStore({
    reducer: {
        user: userReducer,
        messagesCount: messagesCountReducer,
        notificationsCount: notificationsCountReducer,
        isHeaderExpanded: isHeaderExpandedReducer,
        ads: adsReducer,
    },
})

setupListeners(store.dispatch)

export default store
