import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import _ from 'lodash'

import { getMessagesCount, getAdminMessagesCount } from '../services'
import { toastError } from '../utils/toast'
import { getErrorResponse } from '../utils/responseText'

const initialCount = 0

export const fetchMessagesCount = createAsyncThunk(
    'fetchMessagesCount',
    async user => {
        const variables = {
            type_ne: user?.role?.name === 'EMPLOYEE' ? undefined : 'FEEDBACK_EMPLOYEE',
        }

        if (user?.role?.name === 'EMPLOYEE') {
            variables.employeeId = user.id
        } else {
            variables.employerId = user.id
        }

        const getMessagesCountRes = await getMessagesCount(variables)
        const getAdminMessagesCountRes = await getAdminMessagesCount({
            userId: !['ADMIN', 'STAFF'].includes(user?.role?.name) ? user.id : undefined,
        })

        const filtered = _.concat(
            getMessagesCountRes.data.rooms,
            getAdminMessagesCountRes.data.adminRooms
        ).filter((item) => item.messages[0])
            .filter(
                room => {
                    if (user?.role?.name === "EMPLOYEE" && !room.messages[0]?.isReadEmployee) return true
                    if (['EMPLOYER', 'COMPANY'].includes(user?.role?.name) && !room.messages[0]?.isReadEmployer) return true
                    if (['ADMIN', 'STAFF'].includes(user?.role?.name) && !room.messages[0]?.isReadAdmin) return true
                    return false
                }
            )
        return filtered.length
    }
)

const messagesCountSlice = createSlice({
    name: 'messagesCount',
    initialState: initialCount,
    extraReducers(builder) {
        builder
            .addCase(fetchMessagesCount.fulfilled, (state, action) => {
                return action.payload
            })
            .addCase(fetchMessagesCount.rejected, (state, action) => {
                toastError(getErrorResponse('DEFAULT', 500))
            })
    },
})

export default messagesCountSlice.reducer
