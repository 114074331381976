import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
    Box,
    Text,
    Stack,
    HStack,
    VStack,
    Pressable,
    IconButton,
} from 'native-base'

import {
    FacebookLogo,
    // TwitterLogo,
    LineLogo,
    // MessengerLogo,
    InstagramLogo,
    TiktokLogo,
    CModalConfirm,
} from '../index'

import TermModal from '../TermAndPolicy/TermModal'
import PolicyModal from '../TermAndPolicy/PolicyModal'
import GeneralModal from '../TermAndPolicy/GeneralModal'

const ContactUsModal = ({ isOpen, onCancel }) => {
    return (
        <CModalConfirm
            showModal={isOpen}
            maxWidth='380px'
            children={
                <VStack space={3}>
                    <Text fontSize='lg' fontWeight={500} color='heavyBlack'>
                        ติดต่อเรา
                    </Text>
                    <Text fontSize='md' color='heavyBlack'>
                        บริษัท เมดวิลล์ จำกัด
                    </Text>
                    <Text fontSize='md' color='heavyBlack'>
                        เลขที่ 9/26 หมู่ที่ 2 แขวงทวีวัฒนา เขตทวีวัฒนา
                        กรุงเทพมหานคร 10170
                    </Text>
                    <HStack space={2} alignItems='center'>
                        <img
                            src='/images/phone.svg'
                            width='16px'
                            alt='phone_icon'
                        />
                        <a
                            href='tel:0814994266'
                            rel='noreferrer'
                            style={{ textDecoration: 'none' }}
                        >
                            <Text fontSize='md' color='heavyBlack'>
                                081-499-4266
                            </Text>
                        </a>
                    </HStack>
                    <HStack space={2} alignItems='center'>
                        <img
                            src='/images/sms.svg'
                            width='16px'
                            alt='sms_icon'
                        />
                        <a
                            href='mailto:info@medimatch.app'
                            rel='noreferrer'
                            style={{ textDecoration: 'none' }}
                        >
                            <Text fontSize='md' color='heavyBlack'>
                                info@medimatch.app
                            </Text>
                        </a>
                    </HStack>
                    <HStack space={2} alignItems='center'>
                        <LineLogo width='16px' height='17px' />
                        <a
                            href='https://lin.ee/7Ezx3Lr'
                            rel='noreferrer'
                            style={{ textDecoration: 'none' }}
                        >
                            <Text fontSize='md' color='heavyBlack'>
                                @medimatch
                            </Text>
                        </a>
                    </HStack>
                </VStack>
            }
            textCancel='ปิด'
            onCancel={onCancel}
        />
    )
}

export const PublicFooterCard = ({ bg = 'gray13' }) => {
    const [searchParams] = useSearchParams()

    /* ---------- Contact Us ---------- */
    const [isOpenContactUs, setIsOpenContactUs] = useState(false)

    /* ---------- General Term and Con ---------- */
    const [isOpenGeneral, setIsOpenGeneral] = useState(false)

    /* ---------- Term and Con ---------- */
    const [isOpenTerm, setIsOpenTerm] = useState(false)

    /* ---------- Policy ---------- */
    const [isOpenPolicy, setIsOpenPolicy] = useState(false)

    useEffect(() => {
        const policy = searchParams.get('policy')

        if (policy) {
            setIsOpenPolicy(true)
            return
        }

        const term = searchParams.get('term')
        if (term) {
            setIsOpenTerm(true)
            return
        }
    }, [])

    return (
        <Box
            bg={bg}
            maxWidth='1312px'
            width='100%'
            borderRadius={48}
            margin={5}
            px={{ base: 8, md: 8 }}
            py={{ base: 4, md: 8 }}
            alignItems='center'
        >
            <ContactUsModal
                onCancel={() => setIsOpenContactUs(false)}
                isOpen={isOpenContactUs}
            />
            <GeneralModal
                header='ข้อกำหนดและเงื่อนไขทั่วไป'
                textCancel='ปิด'
                onCancel={() => setIsOpenGeneral(false)}
                isOpen={isOpenGeneral}
            />
            <TermModal
                header='ข้อปฏิบัติและกฎหมาย'
                textCancel='ปิด'
                onCancel={() => setIsOpenTerm(false)}
                isOpen={isOpenTerm}
            />
            <PolicyModal
                header='นโยบายความเป็นส่วนตัว'
                textCancel='ปิด'
                onCancel={() => setIsOpenPolicy(false)}
                isOpen={isOpenPolicy}
            />

            <Stack
                space={3}
                direction={{ base: 'column', xl: 'row' }}
                justifyContent='space-between'
                alignItems='center'
                width='100%'
            >
                <Text color='coolGray.400' fontSize='md'>
                    © 2023 - Medimatch. All rights reserved.
                </Text>
                <Stack
                    direction={{ base: 'column', md: 'row' }}
                    space={{ base: 0, md: 3 }}
                    alignItems={{ base: 'flex-start', md: 'center' }}
                >
                    <Pressable onPress={() => setIsOpenContactUs(true)}>
                        <Text color='coolGray.400' fontSize='md'>
                            ติดต่อเรา
                        </Text>
                    </Pressable>
                    <Pressable onPress={() => setIsOpenGeneral(true)}>
                        <Text color='coolGray.400' fontSize='md'>
                            ข้อกำหนดและเงื่อนไขทั่วไป
                        </Text>
                    </Pressable>
                    <Pressable onPress={() => setIsOpenTerm(true)}>
                        <Text color='coolGray.400' fontSize='md'>
                            ข้อปฏิบัติและกฎหมาย
                        </Text>
                    </Pressable>
                    <Pressable onPress={() => setIsOpenPolicy(true)}>
                        <Text color='coolGray.400' fontSize='md'>
                            นโยบายความเป็นส่วนตัว
                        </Text>
                    </Pressable>
                </Stack>

                <HStack space={3} justifyContent='center'>
                    <a
                        href='https://www.facebook.com/profile.php?id=100090149922767'
                        target='_blank'
                        rel='noreferrer'
                    >
                        <IconButton icon={<FacebookLogo />} />
                    </a>
                    {/* <IconButton icon={<TwitterLogo />} /> */}
                    <a
                        href='https://lin.ee/7Ezx3Lr'
                        target='_blank'
                        rel='noreferrer'
                    >
                        <IconButton icon={<LineLogo />} />
                    </a>
                    {/* <IconButton icon={<MessengerLogo />} /> */}

                    <a
                        href='https://www.instagram.com/medimatch.app/'
                        target='_blank'
                        rel='noreferrer'
                    >
                        <IconButton icon={<InstagramLogo />} />
                    </a>
                    <a
                        href='https://www.tiktok.com/@medimatch.app'
                        target='_blank'
                        rel='noreferrer'
                    >
                        <IconButton icon={<TiktokLogo />} />
                    </a>
                </HStack>
            </Stack>
        </Box>
    )
}

export const PublicFooter = () => {
    /* ---------- General Term and Con ---------- */
    const [isOpenGeneral, setIsOpenGeneral] = useState(false)

    /* ---------- Term and Con ---------- */
    const [isOpenTerm, setIsOpenTerm] = useState(false)

    /* ---------- Policy ---------- */
    const [isOpenPolicy, setIsOpenPolicy] = useState(false)

    return (
        <Box
            alignItems='center'
            py='6'
            position='absolute'
            bottom='0'
            left='0'
            right='0'
        >
            <GeneralModal
                header='ข้อกำหนดและเงื่อนไขทั่วไป'
                textCancel='ปิด'
                onCancel={() => setIsOpenGeneral(false)}
                isOpen={isOpenGeneral}
            />
            <TermModal
                header='ข้อปฏิบัติและกฎหมาย'
                textCancel='ปิด'
                onCancel={() => setIsOpenTerm(false)}
                isOpen={isOpenTerm}
            />
            <PolicyModal
                header='นโยบายความเป็นส่วนตัว'
                textCancel='ปิด'
                onCancel={() => setIsOpenPolicy(false)}
                isOpen={isOpenPolicy}
            />
            <Stack
                direction={{ base: 'column', lg: 'row' }}
                space={3}
                justifyContent='space-between'
                alignItems='center'
                maxWidth={{ base: '95%', lg: '90%' }}
                width='100%'
            >
                <Box></Box>
                <Stack
                    direction={{
                        base: 'column',
                        sm: 'row',
                    }}
                    alignItems={{
                        base: 'center',
                        sm: 'start',
                    }}
                    space={{ base: 0, md: 20 }}
                >
                    <Pressable onPress={() => setIsOpenGeneral(true)}>
                        <Text color='coolGray.400' fontSize='xs'>
                            ข้อกำหนดและเงื่อนไขทั่วไป
                        </Text>
                    </Pressable>
                    <Pressable onPress={() => setIsOpenTerm(true)}>
                        <Text color='coolGray.400' fontSize='xs'>
                            ข้อปฏิบัติและกฎหมาย
                        </Text>
                    </Pressable>
                    <Pressable onPress={() => setIsOpenPolicy(true)}>
                        <Text color='coolGray.400' fontSize='xs'>
                            นโยบายความเป็นส่วนตัว
                        </Text>
                    </Pressable>
                </Stack>
            </Stack>
        </Box>
    )
}

export const PrivateFooter = () => {
    /* ---------- General Term and Con ---------- */
    const [isOpenGeneral, setIsOpenGeneral] = useState(false)

    /* ---------- Term and con ---------- */
    const [isOpenTerm, setIsOpenTerm] = useState(false)

    /* ---------- Policy ---------- */
    const [isOpenPolicy, setIsOpenPolicy] = useState(false)

    return (
        <Box alignItems='center' pt='20' pb='10'>
            <GeneralModal
                header='ข้อกำหนดและเงื่อนไขทั่วไป'
                textCancel='ปิด'
                onCancel={() => setIsOpenGeneral(false)}
                isOpen={isOpenGeneral}
            />
            <TermModal
                header='ข้อปฏิบัติและกฎหมาย'
                textCancel='ปิด'
                onCancel={() => setIsOpenTerm(false)}
                isOpen={isOpenTerm}
            />
            <PolicyModal
                header='นโยบายความเป็นส่วนตัว'
                textCancel='ปิด'
                onCancel={() => setIsOpenPolicy(false)}
                isOpen={isOpenPolicy}
            />
            <HStack
                space={3}
                justifyContent='space-between'
                alignItems='center'
                maxWidth='90%'
                width='100%'
            >
                <Text color='coolGray.400' fontSize='xs'>
                    © 2023 - Medimatch. All rights reserved.
                </Text>
                <HStack space={20}>
                    <Pressable onPress={() => setIsOpenGeneral(true)}>
                        <Text color='coolGray.400' fontSize='xs'>
                            ข้อกำหนดและเงื่อนไขทั่วไป
                        </Text>
                    </Pressable>
                    <Pressable onPress={() => setIsOpenTerm(true)}>
                        <Text color='coolGray.400' fontSize='xs'>
                            ข้อปฏิบัติและกฎหมาย
                        </Text>
                    </Pressable>
                    <Pressable onPress={() => setIsOpenPolicy(true)}>
                        <Text color='coolGray.400' fontSize='xs'>
                            นโยบายความเป็นส่วนตัว
                        </Text>
                    </Pressable>
                </HStack>
            </HStack>
        </Box>
    )
}
