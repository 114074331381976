import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import jsCookies from 'js-cookie'

/* ---------- UI component ---------- */
import { NativeBaseProvider } from 'native-base'

/* ---------- Route ---------- */
import { BrowserRouter } from 'react-router-dom'

import reportWebVitals from './reportWebVitals'

import App from './App'

/* ---------- Redux ---------- */
import { Provider } from 'react-redux'
import store from './store'

/* ---------- Apollo Graphql ---------- */
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    from,
    split,
} from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import createUploadLink from 'apollo-upload-client/public/createUploadLink.js'

import {
    getCMSAPIUrl,
    getPaymentAPIUrl,
    getWsPaymentAPIUrl,
} from './utils/urlFactory'
import theme from './Theme'

/* ---------- Toast ---------- */
import 'react-toastify/dist/ReactToastify.css'
import ToastContainer from './components/Toast'

/* ---------- Apollo Client ---------- */
const httpLink = createUploadLink({
    uri: `${getCMSAPIUrl()}/graphql`,
})

const httpPurchaseLink = createUploadLink({
    uri: `${getPaymentAPIUrl()}/graphql`,
})

const wsPurchaseLink = new WebSocketLink({
    uri: `${getWsPaymentAPIUrl()}/graphql`,
    options: {
        connectionParams: {
            authorization: `Bearer ${jsCookies.get('medi_to')}`,
        },
    },
})

const authLink = setContext(async (_, { headers }) => {
    const token = jsCookies.get('medi_to')

    const obj = {
        ...headers,
    }

    if (token) {
        obj.authorization = `Bearer ${token}`
    }

    return {
        headers: obj,
    }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) => {
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )

            if (message === 'Invalid token.') {
                jsCookies.remove('medi_to')
                jsCookies.remove('medi_id')
                window.location.replace('/')
            }
        })
    if (networkError) console.log(`[Network error]: ${networkError}`)
})

export const apolloClient = new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache(),
})

const purchaseLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsPurchaseLink,
    httpPurchaseLink,
);

export const apolloPurchaseClient = new ApolloClient({
    link: from([errorLink, authLink, purchaseLink]),
    cache: new InMemoryCache(),
})

createRoot(document.getElementById('root')).render(
    <Provider store={store}>
        <ApolloProvider client={apolloClient}>
            <NativeBaseProvider theme={theme}>
                <ToastContainer />
                <BrowserRouter>
                    <Suspense fallback={<div></div>}>
                        <App />
                    </Suspense>
                </BrowserRouter>
            </NativeBaseProvider>
        </ApolloProvider>
    </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
