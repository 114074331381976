import React from 'react'
import { Select } from '../Input'
export const RowsPerPage = ({ callback, value, isDisabled }) => {
  const options = [
    { value: 10, label: '10' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ]
  return (
    <div className="rows-per-page-table">
      <div className="rows-per-page-title">แสดงผล</div>
      <div className="rows-per-page-selected">
        <Select
          name='rows-per-page'
          onChange={callback}
          options={options}
          value={value}
          isDisabled={isDisabled || false}
        />
      </div>
    </div>
  )
}