import { Box, Text } from 'native-base'
export const CustomTooltip = ({ active, payload, label, zero }) => {
    if (
        zero?.length > 0 &&
        payload?.length > 0 &&
        payload?.[0].name === 'NO_DATA'
    ) {
        return (
            <Box borderColor='#cccccc' borderWidth={1} p='10px' bgColor='white'>
                {zero.map((item, ind) => {
                    return (
                        <Box key={ind}>
                            <Text>{`${item.name} : ${item.value}`}</Text>
                        </Box>
                    )
                })}
            </Box>
        )
    }
    if (active)
        return (
            <Box borderColor='#cccccc' borderWidth={1} p='10px' bgColor='white'>
                {label && <Text>{label}</Text>}
                {payload?.length > 0 &&
                    payload.map((item, ind) => {
                        return (
                            <Box key={ind}>
                                <Text
                                    color={item.stroke}
                                >{`${item.name} : ${item.value}`}</Text>
                            </Box>
                        )
                    })}
            </Box>
        )

    return null
}
