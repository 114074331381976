import { useMemo } from 'react'
import { isPast, parseISO } from 'date-fns'

import _ from 'lodash'

import {
    Box,
    Text,
    Button,
    useTheme,
    HStack,
    VStack,
    Divider,
    Flex,
    Pressable,
    Stack,
} from 'native-base'

import {
    Card,
    LinearGradientText,
    FilledButton,
    MessageVerifyIcon,
    FileCardTemplate,
    CImageViewer,
    ToolText,
    DropDownOption2,
} from '../index'

import {
    formatJobDateTimeTH,
    formatDateTimeConfirmCard,
    formatMessageData,
} from '../../utils/index'

import { ImageFilesType } from '../../constant'
import { transformName } from '../../utils/tranformValue'

const MessageContainer = ({
    isReminder,
    isMy,
    children,
    createdAt,
    width,
    maxWidth = '343px',
}) => {
    return (
        <Flex
            width='100%'
            alignItems={
                isReminder ? 'center' : isMy ? 'flex-end' : 'flex-start'
            }
        >
            <VStack
                width={width}
                maxWidth={maxWidth}
                space='1'
                alignItems={isMy ? 'flex-end' : 'flex-start'}
            >
                {children}
            </VStack>
            <Text fontSize='xs' color='textDetail'>
                {formatMessageData(createdAt)}
            </Text>
        </Flex>
    )
}

const Bubble = ({ isMy, role, children }) => {
    const { colors } = useTheme()

    return (
        <Box
            py='4'
            pl={isMy ? 4 : 5}
            pr={isMy ? 5 : 4}
            borderTopLeftRadius='24px'
            borderTopRightRadius='24px'
            borderBottomLeftRadius={isMy ? '24px' : 0}
            borderBottomRightRadius={isMy ? 0 : '24px'}
            style={{
                background: isMy
                    ? role === 'EMPLOYEE'
                        ? colors.verticalGreenBlueLinear
                        : colors.primaryBlue
                    : colors.gray22,
            }}
            width='100%'
        >
            {children}
        </Box>
    )
}

export const MessageText = ({ isMy, role, data, handleSelectMessage }) => {
    return (
        <MessageContainer
            isMy={isMy}
            createdAt={data.createdAt}
            maxWidth={{ base: '220px', md: '300px', xl: '450px' }}
        >
            {isMy && (
                <DropDownOption2
                    borderRadius={'5px'}
                    buttonParent={
                        <Box>
                            <img
                                src='/images/meatball_gray.svg'
                                alt='meatball_icon'
                                style={{ transform: 'rotate(90deg)' }}
                                width='15px'
                                className='meat-ball'
                            />
                        </Box>
                    }
                >
                    <Box py={2} px={3} minW='20px'>
                        <Pressable onPress={() => handleSelectMessage(data)}>
                            <Text>ยกเลิกการส่งข้อความ</Text>
                        </Pressable>
                    </Box>
                </DropDownOption2>
            )}
            <Bubble isMy={isMy} role={role}>
                <Text fontSize='md' color={isMy ? 'white' : 'heavyBlack'}>
                    {data?.text}
                </Text>
            </Bubble>
        </MessageContainer>
    )
}

const ReportText = ({ isMy, role, data }) => {
    return (
        <MessageContainer
            isMy={isMy}
            createdAt={data.createdAt}
            maxWidth={{ base: '100%', sm: '90%' }}
        >
            <Bubble isMy={isMy} role={role}>
                <VStack space={3}>
                    <HStack space={3}>
                        <Text
                            fontSize='md'
                            color={isMy ? 'white' : 'heavyBlack'}
                        >
                            ผู้ถูกรายงาน:
                        </Text>
                        <Text
                            fontSize='md'
                            color={isMy ? 'white' : 'heavyBlack'}
                        >
                            {transformName(data?.target)}
                        </Text>
                    </HStack>
                    <CImageViewer
                        imagesUrl={[data?.file?.url]}
                        width='100%'
                        height='auto'
                        alt={`img-${data?.id}`}
                        styleImg={{
                            objectFit: 'contain',
                            maxWidth: '250px',
                        }}
                    />
                    <Text
                        fontSize='md'
                        color={isMy ? 'white' : 'heavyBlack'}
                        maxWidth={{ base: '220px', sm: '400px' }}
                    >
                        {`รายละเอียด: ${data?.text}`}
                    </Text>
                </VStack>
            </Bubble>
        </MessageContainer>
    )
}

export const WorkConfirmCard = ({
    data,
    isMy,
    isEmployee,
    handleAcceptJob,
}) => {
    return (
        <MessageContainer isMy={isMy} createdAt={data.createdAt}>
            <Card
                bg='white'
                borderRadius='8'
                borderColor='gray1'
                borderWidth='1'
                padding='0'
            >
                <VStack space='1'>
                    <HStack
                        alignItems='center'
                        justifyContent='space-between'
                        py='2'
                        px='4'
                    >
                        <LinearGradientText
                            fontSize='xl'
                            color='greenBlueLinear'
                        >
                            ใบรับงาน
                        </LinearGradientText>
                        <HStack alignItems='center'>
                            <LinearGradientText>
                                <ToolText
                                    fontSize='lg'
                                    color='greenBlueLinear'
                                    text={data?.application?.job?.wage}
                                    fontFamily='Kanit'
                                    maxWidth='100px'
                                    showTooltip
                                />
                            </LinearGradientText>
                        </HStack>
                    </HStack>
                    <Divider
                        bg='divider'
                        thickness='1'
                        orientation='horizontal'
                        width='100%'
                    />
                    <VStack pt='2' pb='4' px='4' space='4'>
                        <VStack>
                            <Text fontSize='md' color='heavyBlack'>
                                {data?.application?.job?.topic}
                            </Text>
                            <Text
                                fontSize='sm'
                                color='textDetail'
                                fontWeight='300'
                            >
                                {`${data?.application?.job?.company} • ${data?.application?.job?.district?.name_th} • ${data?.application?.job?.province?.name_th}`}
                            </Text>
                        </VStack>
                        <Text fontSize='md' color='heavyBlack'>
                            {data?.application?.job?.jobType === 'PART_TIME' &&
                                `${formatDateTimeConfirmCard(
                                    data?.application?.job?.startDate
                                )} - ${formatDateTimeConfirmCard(
                                    data?.application?.job?.endDate
                                )}`}
                        </Text>
                        {isEmployee && (
                            <FilledButton
                                borderRadius='9'
                                mt='2'
                                height='12'
                                onPress={() =>
                                    handleAcceptJob(data?.application)
                                }
                                isDisabled={
                                    data?.application?.job.status ===
                                        'CANCELLED' ||
                                    data?.application?.job?.number ===
                                        data?.application?.job?.taken ||
                                    data?.application?.status !== 'ACCEPTED' ||
                                    isPast(
                                        parseISO(
                                            data?.application?.job?.expiredAt
                                        )
                                    )
                                }
                            >
                                <Text fontSize='md' color='white'>
                                    {data?.application?.status !== 'ACCEPTED'
                                        ? 'คุณยืนยันเรียบร้อยแล้ว'
                                        : data?.application?.job.status ===
                                              'CANCELLED' ||
                                          data?.application?.job?.number ===
                                              data?.application?.job?.taken ||
                                          isPast(
                                              parseISO(
                                                  data?.application?.job
                                                      ?.expiredAt
                                              )
                                          )
                                        ? 'ปิดรับสมัคร'
                                        : 'ยืนยันรับงาน'}
                                </Text>
                            </FilledButton>
                        )}
                    </VStack>
                </VStack>
            </Card>
        </MessageContainer>
    )
}

export const JobCard = ({
    isEmployee,
    data,
    handleSendConfirmation,
    handleDetail,
    handleGoToProfile,
}) => {
    return (
        <Box flexGrow='1' width='100%'>
            <Card bg='inputFilled' borderRadius='8'>
                <VStack space='1'>
                    <HStack
                        alignItems='flex-start'
                        justifyContent='space-between'
                    >
                        <VStack>
                            <LinearGradientText
                                onPress={() =>
                                    handleDetail(data?.application?.job.id)
                                }
                            >
                                <Text fontSize='lg' fontWeight='500'>
                                    {data?.application?.job?.topic}
                                </Text>
                            </LinearGradientText>
                            <Pressable
                                onPress={() =>
                                    handleGoToProfile(
                                        data?.application?.job?.createdBy?.id
                                    )
                                }
                                width='fit-content'
                            >
                                <Text fontSize='sm' width='fit-content'>
                                    {data?.application?.job?.company}
                                </Text>
                            </Pressable>
                            {data?.application?.job?.address && (
                                <ToolText
                                    fontSize='sm'
                                    text={`${data?.application?.job?.address} •`}
                                    showTooltip
                                    maxWidth='220px'
                                />
                            )}
                            <Text fontSize='sm'>
                                {`${data?.application?.job?.district?.name_th} • ${data?.application?.job?.province?.name_th}`}
                            </Text>
                        </VStack>
                        <HStack
                            bg='blue1'
                            borderRadius={4}
                            py={1}
                            px={4}
                            minWidth='152px'
                            alignItems='center'
                            justifyContent='space-between'
                            space='3'
                        >
                            <LinearGradientText color='greenBlueLinear'>
                                <ToolText
                                    text={data?.application?.job?.wage}
                                    fontSize='lg'
                                    fontFamily='Kanit'
                                    maxWidth='220px'
                                    showTooltip
                                />
                            </LinearGradientText>
                        </HStack>
                    </HStack>
                    <HStack alignItems='center' justifyContent='space-between'>
                        {data?.application?.job?.jobType === 'PART_TIME' && (
                            <Stack
                                direction={{ base: 'column', xl: 'row' }}
                                space={1}
                            >
                                <Text fontSize='md'>
                                    {formatJobDateTimeTH(
                                        data?.application?.job?.startDate,
                                        data?.application?.job?.startTime
                                    )}{' '}
                                    -{' '}
                                </Text>
                                <Text fontSize='md'>
                                    {formatJobDateTimeTH(
                                        data?.application?.job?.endDate,
                                        data?.application?.job?.endTime
                                    )}
                                </Text>
                            </Stack>
                        )}
                        <Button
                            variant='ghost'
                            isDisabled={!data?.application}
                            onPress={() =>
                                handleDetail(data?.application?.job.id)
                            }
                        >
                            <Text
                                fontSize='xs'
                                color='textDetail'
                            >{`ไปที่หน้าหลัก >`}</Text>
                        </Button>
                    </HStack>
                    {!isEmployee && (
                        <HStack justifyContent='flex-end'>
                            <FilledButton
                                borderRadius='9'
                                width='230px'
                                onPress={() =>
                                    handleSendConfirmation(data?.application)
                                }
                                isDisabled={
                                    data?.application?.job.status ===
                                        'CANCELLED' ||
                                    data?.application?.job?.number ===
                                        data?.application?.job?.taken ||
                                    !['APPLIED'].includes(
                                        data?.application?.status
                                    ) ||
                                    isPast(
                                        parseISO(
                                            data?.application?.job?.expiredAt
                                        )
                                    )
                                }
                            >
                                <Text fontSize='md' color='white'>
                                    {data?.application?.job.status ===
                                        'CANCELLED' ||
                                    isPast(
                                        parseISO(
                                            data?.application?.job?.expiredAt
                                        )
                                    )
                                        ? 'ปิดรับสมัคร'
                                        : data?.application?.job?.number ===
                                          data?.application?.job?.taken
                                        ? 'ผู้สมัครเต็มจำนวน'
                                        : 'ส่งใบรับงาน'}
                                </Text>
                            </FilledButton>
                        </HStack>
                    )}
                </VStack>
            </Card>
        </Box>
    )
}

const ReminderCard = ({ data }) => {
    const { colors } = useTheme()

    return (
        <MessageContainer
            isReminder={true}
            createdAt={data.createdAt}
            width='100%'
            maxWidth='100%'
        >
            <Card
                bg='heavyBlack'
                alignSelf='center'
                style={{ background: colors.greenBlueLinear }}
            >
                <HStack space={5} alignItems='center'>
                    <HStack alignItems='center'>
                        <MessageVerifyIcon size={26} />
                    </HStack>
                    <Text color='white' fontSize='sm'>
                        {data?.text}
                    </Text>
                </HStack>
            </Card>
        </MessageContainer>
    )
}

const FileCard = ({ isMy, role, data, handleSelectMessage }) => {
    return (
        <MessageContainer isMy={isMy} createdAt={data.createdAt}>
            {isMy && (
                <Box py={'5px'}>
                    <DropDownOption2
                        borderRadius={'5px'}
                        buttonParent={
                            <Box>
                                <img
                                    src='/images/meatball_gray.svg'
                                    alt='meatball_icon'
                                    style={{ transform: 'rotate(90deg)' }}
                                    width='15px'
                                    className='meat-ball'
                                />
                            </Box>
                        }
                    >
                        <Box py={2} px={3} minW='20px'>
                            <Pressable
                                onPress={() => handleSelectMessage(data)}
                            >
                                <Text>ยกเลิกการส่งข้อความ</Text>
                            </Pressable>
                        </Box>
                    </DropDownOption2>
                </Box>
            )}
            {ImageFilesType.includes(data.file?.mime) ? (
                data.file?.url ? (
                    <CImageViewer
                        imagesUrl={[data.file?.url]}
                        width='100%'
                        height='auto'
                        alt={`img-${data?.id}`}
                        styleImg={{
                            objectFit: 'contain',
                            maxWidth: '250px',
                            borderRadius: '8px',
                        }}
                    />
                ) : (
                    <Bubble isMy={isMy} role={role}>
                        <Text
                            fontSize='md'
                            color={isMy ? 'white' : 'heavyBlack'}
                        >
                            File is unavailable
                        </Text>
                    </Bubble>
                )
            ) : (
                <Bubble isMy={isMy} role={role}>
                    <Text fontSize='md' color={isMy ? 'white' : 'heavyBlack'}>
                        {data.file?.url ? (
                            <FileCardTemplate file={data?.file} donwloadCV />
                        ) : (
                            'File is unavailable'
                        )}
                    </Text>
                </Bubble>
            )}
        </MessageContainer>
    )
}

const CVCARD = ({ isMy, role, data }) => {
    return (
        <MessageContainer
            isMy={isMy}
            createdAt={data.createdAt}
            maxWidth='400px'
        >
            <Bubble isMy={isMy} role={role}>
                <VStack space={3}>
                    <FileCardTemplate file={data?.file} donwloadCV />
                    <VStack>
                        <Text
                            fontSize='md'
                            color={isMy ? 'white' : 'heavyBlack'}
                        >
                            อีเมล:
                        </Text>
                        <Text
                            fontSize='md'
                            color={isMy ? 'white' : 'heavyBlack'}
                        >
                            {data?.text?.email}
                        </Text>
                    </VStack>
                    <VStack>
                        <Text
                            fontSize='md'
                            color={isMy ? 'white' : 'heavyBlack'}
                        >
                            เบอร์โทรศัพท์:
                        </Text>
                        <Text
                            fontSize='md'
                            color={isMy ? 'white' : 'heavyBlack'}
                        >
                            {data?.text?.mobileNumber}
                        </Text>
                    </VStack>
                    <VStack>
                        <Text
                            fontSize='md'
                            color={isMy ? 'white' : 'heavyBlack'}
                        >
                            แนะนำตัว:
                        </Text>
                        <Text
                            fontSize='md'
                            color={isMy ? 'white' : 'heavyBlack'}
                        >
                            {data?.text?.info}
                        </Text>
                    </VStack>
                </VStack>
            </Bubble>
        </MessageContainer>
    )
}

const isMyMessage = (data, pairedUser) => {
    return data?.sender?.id !== pairedUser.id
}

export const Message = ({
    data,
    user,
    pairedUser,
    handleSendConfirmation,
    handleDetail,
    handleAcceptJob,
    handleSelectMessage,
    handleGoToProfile,
}) => {
    if (data.type === 'JOB')
        return (
            <JobCard
                isEmployee={user?.role?.name === 'EMPLOYEE'}
                data={data}
                handleSendConfirmation={handleSendConfirmation}
                handleDetail={handleDetail}
                handleGoToProfile={handleGoToProfile}
            />
        )

    if (data.type === 'CONFIRM')
        return (
            <WorkConfirmCard
                isEmployee={user?.role?.name === 'EMPLOYEE'}
                data={data}
                isMy={isMyMessage(data, pairedUser)}
                handleAcceptJob={handleAcceptJob}
            />
        )

    if (['TEXT', 'AUTO'].includes(data.type))
        return (
            <MessageText
                data={data}
                isMy={isMyMessage(data, pairedUser)}
                handleSelectMessage={handleSelectMessage}
            />
        )

    if (data.type === 'REMINDER') return <ReminderCard data={data} />

    if (data.type === 'FEEDBACK_EMPLOYEE') return <ReminderCard data={data} />

    if (data.type === 'FILE')
        return (
            <FileCard
                data={data}
                isMy={isMyMessage(data, pairedUser)}
                handleSelectMessage={handleSelectMessage}
            />
        )

    if (data.type === 'CV')
        return <CVCARD data={data} isMy={isMyMessage(data, pairedUser)} />

    if (data.type === 'REPORT')
        return <ReportText data={data} isMy={isMyMessage(data, pairedUser)} />
}

export const StatusCard = ({ status, userType }) => {
    const property = useMemo(() => {
        if (status === 'CONFIRMED' && userType === 'EMPLOYEE') {
            return {
                label: 'ยืนยันรับงาน',
                labelColor: 'green1',
                borderColor: 'green1',
                bg: 'white',
            }
        }

        if (status === 'CONFIRMED' && userType === 'EMPLOYER') {
            return {
                label: 'ยืนยันรับงาน',
                labelColor: 'primaryYellow',
                borderColor: 'primaryYellow',
                bg: 'white',
            }
        }

        if (status === 'WORKING') {
            return {
                label: 'ทำงาน',
                labelColor: 'green1',
                borderColor: 'green1',
                bg: 'white',
            }
        }

        if (status === 'COMPLETED') {
            return {
                label: 'งานสำเร็จ',
                labelColor: 'white',
                borderColor: 'green1',
                bg: 'green1',
            }
        }

        if (status === 'CANCELLED') {
            return {
                label: 'ยกเลิก',
                labelColor: 'textDetail',
                borderColor: 'textDetail',
                bg: 'white',
            }
        }

        return {}
    }, [status])

    return (
        !_.isEmpty(property) && (
            <Box
                alignItems='center'
                justifyContent='center'
                bg={property.bg}
                width='107px'
                height='27px'
                borderRadius='9px'
                borderColor={property.borderColor}
                borderWidth='1'
            >
                <Text
                    fontSize='sm'
                    fontWeight='500'
                    color={property.labelColor}
                >
                    {property.label}
                </Text>
            </Box>
        )
    )
}
