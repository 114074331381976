import React from 'react'
import { Box } from 'native-base'
import SideMenu from '.'

const Admin = () => {
    return (
        <Box
            borderRightColor={'gray28'}
            borderRightWidth={1}
            minH={'100vh'}
            height='100%'
            display={{ base: 'none', lg: 'block' }}
        >
            <SideMenu />
        </Box>
    )
}

export default Admin
