import { useState, Fragment, useMemo, useEffect, useRef } from 'react'

import { useNavigate, useLocation, useMatch } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Overlay from 'react-overlays/Overlay'
import _ from 'lodash'
import { useQuery, useMutation } from '@apollo/client'

import {
    Text,
    Pressable,
    Box,
    HStack,
    VStack,
    Button,
    Divider,
    IconButton,
    PresenceTransition,
    useTheme,
    useMediaQuery,
} from 'native-base'

import {
    FilledInput,
    LinearSearchIcon,
    LinearGradientOutlineButton,
    WithIconButton,
    BagIcon,
    LinearBriefcaseIcon,
    HeartIcon,
    MessagesIcon,
    NotificationIcon,
    ProfileIcon,
    Tabs,
    FilledButton,
    TextButton,
    VolumeIcon,
    Card,
    Avatar,
    EyeIcon,
    UserIcon,
    // AlarmIcon,
    ArrowDownIcon,
    BroomIcon,
    BriefcaseIcon3,
    AdsIcon,
    GearIcon,
    SelectSearch,
    NewTimePicker,
} from '../index'

import PackageBox from '../Package/PackageBox'

import { logout } from '../../store/user'
import { fetchMessagesCount } from '../../store/messagesCount'
import { fetchNotificationsCount } from '../../store/notificationsCount'
import { setIsHeaderExpanded } from '../../store/isHeaderExpanded'
import { SORT_ORDER, JOB_TYPE } from '../../constant'

import { toastError } from '../../utils/toast'
import { getErrorResponse } from '../../utils/responseText'
import {
    getGraphqlError,
    sortProfessionsOne,
    sortProfessionsTwo,
} from '../../utils'
import socket from '../../utils/socket'
import {
    GET_PROVINCES,
    GET_DISTRICT,
    GET_SUB_DISTRICT,
    GET_PROFESSION_ONE,
    GET_PROFESSION_TWO,
    GET_PROFESSION_THREES,
    GET_NOTIFICATIONS,
    READ_NOTIFICATION,
} from '../../services'

const medimatchLogo = '/images/logo.png'

export const PublicHeader = ({
    isShowLogo,
    tab,
    handleSelectTab,
    tabsOptions,
}) => {
    const navigate = useNavigate()

    const handleToHome = () => {
        navigate('/')
    }
    const handleToLogin = () => {
        navigate('/login')
    }

    const handleToRegisterEmployee = () => {
        navigate('/register/employee', { state: { redirect: '/job' } })
    }
    const handleToRegisterEmployer = redirect => {
        navigate('/register/employer', { state: { redirect } })
    }

    return (
        <Box
            width='100%'
            position='sticky'
            top='0'
            zIndex={20}
            display={{ base: 'none', lg: 'block' }}
        >
            <Box
                borderRadius={14}
                width='100%'
                maxWidth={{ base: '95%', lg: '90%' }}
                mx='auto'
                bg='white'
                style={{
                    boxShadow:
                        '8.66007px 3.60836px 72.1672px rgba(0, 0, 0, 0.18)',
                }}
            >
                <HStack
                    height='72px'
                    alignItems='center'
                    justifyContent='space-between'
                    px={{ base: '20px', lg: '30px' }}
                >
                    {isShowLogo && (
                        <Pressable onPress={handleToHome}>
                            <img
                                src={medimatchLogo}
                                alt='medimatch-logo'
                                style={{ width: '50px' }}
                            />
                        </Pressable>
                    )}
                    <HStack justifyContent='center' alignSelf='flex-end'>
                        {!_.isUndefined(
                            tab || tabsOptions || handleSelectTab
                        ) && (
                            <Box>
                                <Tabs
                                    options={tabsOptions}
                                    selected={tab}
                                    setSelected={handleSelectTab}
                                    containerStyle={{ columnGap: '20px' }}
                                    textOptionStyle={{
                                        fontSize: '16px',
                                        fontWeight: '400',
                                        marginBottom: '20px',
                                    }}
                                />
                            </Box>
                        )}
                    </HStack>
                    <HStack
                        alignItems='center'
                        justifyContent='flex-end'
                        space={2}
                    >
                        <TextButton
                            containerStyle={{ marginRight: '5px' }}
                            onPress={handleToLogin}
                        >
                            <Text fontSize='md' fontWeight='400'>
                                เข้าสู่ระบบ / ลงทะเบียน
                            </Text>
                        </TextButton>
                        <LinearGradientOutlineButton
                            minWidth='130px'
                            height='43px'
                            textStyle={{ fontSize: '16px', fontWeight: '500' }}
                            onPress={() =>
                                handleToRegisterEmployer('/messages?room=admin')
                            }
                        >
                            ติดต่อลงโฆษณา
                        </LinearGradientOutlineButton>
                        <LinearGradientOutlineButton
                            minWidth='165px'
                            height='43px'
                            textStyle={{ fontSize: '16px', fontWeight: '500' }}
                            onPress={() => handleToRegisterEmployer('/post')}
                        >
                            ลงประกาศหางาน
                        </LinearGradientOutlineButton>
                        <FilledButton
                            minWidth='130px'
                            height='43px'
                            borderRadius='9'
                            onPress={handleToRegisterEmployee}
                        >
                            <Text color='white' fontSize='md' fontWeight='500'>
                                หางาน
                            </Text>
                        </FilledButton>
                    </HStack>
                </HStack>
            </Box>
        </Box>
    )
}

export const PrivateHeader = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()

    const isJobDetail = useMatch('/job/:id')
    const isPreview = useMatch('/preview')
    const isDraftPreview = useMatch('/preview/:id')

    const user = useSelector(state => state.user)
    const messagesCount = useSelector(state => state.messagesCount)
    const notificationsCount = useSelector(state => state.notificationsCount)
    const isHeaderExpanded = useSelector(state => state.isHeaderExpanded)

    const [isLargerThan1200] = useMediaQuery({
        minWidth: 1200,
    })

    useEffect(() => {
        if (!_.isEmpty(user)) {
            dispatch(fetchMessagesCount(user))
            dispatch(fetchNotificationsCount(user))
        }
    }, [user])

    useEffect(() => {
        socket.off('messageNotification')
        socket.on('messageNotification', () => {
            dispatch(fetchMessagesCount(user))
        })
    }, [])

    useEffect(() => {
        socket.on('newNotificaton', () => {
            dispatch(fetchNotificationsCount(user))
        })
    }, [])

    const [isProfileOpen, setIsProfileOpen] = useState(false)
    const [isNotificationOpen, setIsNotificationOpen] = useState(false)

    useEffect(() => {
        if (isProfileOpen) {
            setIsNotificationOpen(false)
        }
    }, [isProfileOpen])
    useEffect(() => {
        if (isNotificationOpen) {
            setIsProfileOpen(false)
        }
    }, [isNotificationOpen])

    useEffect(() => {
        if (isProfileOpen) {
            setIsNotificationOpen(false)
        }
    }, [isProfileOpen])
    useEffect(() => {
        if (isNotificationOpen) {
            setIsProfileOpen(false)
        }
    }, [isNotificationOpen])

    const [inputValue, setInputValue] = useState({
        keyword: '',
        jobType: JOB_TYPE[0],
        sort: SORT_ORDER[0],
        tag: null,
        startTime: null,
        endTime: null,
        subDistrict: null,
        district: null,
        province: null,
        professionOne: null,
        professionTwo: null,
        professionThree: null,
    })

    const handleClearSearch = () => {
        setInputValue({
            keyword: '',
            jobType: JOB_TYPE[0],
            sort: SORT_ORDER[0],
            tag: null,
            startTime: null,
            endTime: null,
            subDistrict: null,
            district: null,
            province: null,
            professionOne: null,
            professionTwo: null,
            professionThree: null,
        })
    }

    const handleChange = (name, value) => {
        const newInputValue = { ...inputValue }

        newInputValue[name] = value

        if (name === 'province') {
            newInputValue['district'] = null
            newInputValue['subDistrict'] = null
        } else if (name === 'district') {
            newInputValue['subDistrict'] = null
        } else if (name === 'professionOne') {
            newInputValue['professionTwo'] = null
            newInputValue['professionThree'] = null
        } else if (name === 'professionTwo') {
            newInputValue['professionThree'] = null
        } else if (name === 'jobType') {
            newInputValue['startTime'] = null
            newInputValue['endTime'] = null
        }

        setInputValue(newInputValue)
    }

    const handleSearch = () => {
        navigate(`/job`, {
            state: { ...inputValue, jobType: inputValue.jobType.value },
        })
    }

    /* ---------- get Province ---------- */
    const [provincesData, setProvincesData] = useState([])
    const {
        data: getProvicesData,
        error: getProvicesError,
        loading: getProvicesLoading,
    } = useQuery(GET_PROVINCES)

    useEffect(() => {
        if (getProvicesError) {
            console.log(`Cannot get Provinces: ${getProvicesError.message}`)
            toastError(getErrorResponse('DEFAULT', 500))
        }

        if (getProvicesData) {
            setProvincesData(
                getProvicesData.provinces.map(province => ({
                    label: province.name_th,
                    value: province.province_id,
                    id: province.id,
                }))
            )
        }
    }, [getProvicesData, getProvicesError])

    /* ---------- get District ---------- */
    const [districtsData, setDistrictsData] = useState([])
    const {
        data: getDistrictsData,
        error: getDistrictsError,
        loading: getDistrictsLoading,
    } = useQuery(GET_DISTRICT, {
        variables: { province_id: inputValue.province?.value },
        skip: !inputValue.province?.value,
    })

    useEffect(() => {
        if (getDistrictsError) {
            console.log(`Cannot get Districts: ${getDistrictsError.message}`)
            toastError(getErrorResponse('DEFAULT', 500))
        }

        if (getDistrictsData) {
            setDistrictsData(
                getDistrictsData.districts.map(district => ({
                    label: district.name_th,
                    value: district.district_id,
                    id: district.id,
                }))
            )
        }
    }, [getDistrictsData, getDistrictsError])

    /* ---------- get Sub District ---------- */
    const [subDistrictsData, setSubDistrictsData] = useState([])
    const {
        data: getSubDistrictsData,
        error: getSubDistrictsError,
        loading: getSubDistrictsLoading,
    } = useQuery(GET_SUB_DISTRICT, {
        variables: { district_id: inputValue.district?.value },
        skip: !inputValue.district?.value,
    })

    useEffect(() => {
        if (getSubDistrictsError) {
            console.log(
                `Cannot get Sub Districts: ${getSubDistrictsError.message}`
            )
            toastError(getErrorResponse('DEFAULT', 500))
        }

        if (getSubDistrictsData) {
            setSubDistrictsData(
                getSubDistrictsData.subDistricts.map(subDistrict => ({
                    label: subDistrict.name_th,
                    value: subDistrict.sub_district_id,
                    id: subDistrict.id,
                }))
            )
        }
    }, [getSubDistrictsData, getSubDistrictsError])

    /* ---------- Get Profession One ---------- */
    const [professionOneData, setProfessionOneData] = useState([])

    const {
        loading: getProfessionOneLoading,
        data: getProfessionOneData,
        error: getProfessionOneError,
    } = useQuery(GET_PROFESSION_ONE, { variables: { isTemp: false } })

    useEffect(() => {
        if (getProfessionOneError) {
            console.log(
                `Cannot get Profession One: ${getProfessionOneError.message}`
            )
            toastError(getErrorResponse('DEFAULT', 500))
        }

        if (getProfessionOneData) {
            if (_.isEmpty(getProfessionOneData.professionOnes)) {
                return setProfessionOneData([])
            }

            const newProfessionOneData = sortProfessionsOne(
                getProfessionOneData.professionOnes
            ).map(item => ({
                value: item.id,
                label: item.title,
            }))

            setProfessionOneData(newProfessionOneData)
        }
    }, [getProfessionOneError, getProfessionOneData])

    /* ---------- Get Profession Two ---------- */
    const [professionTwoData, setProfessionTwoData] = useState([])

    const {
        loading: getProfessionTwoLoading,
        data: getProfessionTwoData,
        error: getProfessionTwoError,
    } = useQuery(GET_PROFESSION_TWO, {
        variables: {
            profession_one: inputValue.professionOne?.value,
            isTemp: false,
        },
        skip:
            inputValue.professionOne === null ||
            inputValue.professionOne?.value === 'OTHER',
    })

    useEffect(() => {
        if (getProfessionTwoError) {
            console.log(
                `Cannot get Profession Two: ${getProfessionTwoError.message}`
            )
            toastError(getErrorResponse('DEFAULT', 500))
        }

        if (inputValue.professionOne?.value === 'OTHER') {
            return setProfessionTwoData([{ value: 'OTHER', label: 'อื่นๆ' }])
        }

        if (inputValue.professionOne === null) {
            return setProfessionTwoData([])
        }

        if (getProfessionTwoData) {
            if (_.isEmpty(getProfessionTwoData?.professionTwos)) {
                return setProfessionTwoData([])
            }

            const newProfessionTwoData = sortProfessionsTwo(
                getProfessionTwoData?.professionTwos
            ).map(item => ({
                value: item.id,
                label: item.title,
            }))

            setProfessionTwoData(newProfessionTwoData)
        }
    }, [getProfessionTwoError, getProfessionTwoData, inputValue.professionOne])

    /* ---------- Get Profession Three ---------- */
    const [professionThreeData, setProfessionThreeData] = useState([])

    const {
        loading: getProfessionThreeLoading,
        data: getProfessionThreeData,
        error: getProfessionThreeError,
    } = useQuery(GET_PROFESSION_THREES, {
        variables: {
            profession_two: inputValue.professionTwo?.value,
            isTemp: false,
        },
        skip:
            inputValue.professionTwo === null ||
            inputValue.professionTwo?.value === 'OTHER',
    })

    useEffect(() => {
        if (getProfessionThreeError) {
            console.log(
                `Cannot get Profession Three: ${getProfessionThreeError.message}`
            )
            toastError(getErrorResponse('DEFAULT', 500))
        }

        if (inputValue.professionTwo?.value === 'OTHER') {
            return setProfessionThreeData([{ value: 'OTHER', label: 'อื่นๆ' }])
        }

        if (inputValue.professionTwo === null) {
            return setProfessionThreeData([])
        }

        if (getProfessionThreeData) {
            if (_.isEmpty(getProfessionThreeData.professionThrees)) {
                return setProfessionThreeData([])
            }

            const newProfessionThreeData =
                getProfessionThreeData.professionThrees.map(item => ({
                    value: item.id,
                    label: item.title,
                }))

            setProfessionThreeData(newProfessionThreeData)
        }
    }, [
        getProfessionThreeError,
        getProfessionThreeData,
        inputValue.professionTwo,
    ])

    const handleRedirect = path => {
        setIsProfileOpen(false)
        setIsNotificationOpen(false)
        navigate(path)
        handleClearSearch()
    }

    /*---------- Get Notifications ----------*/
    const [notificationsData, setNotificationsData] = useState([])

    const {
        data: getNotificationsData,
        error: getNotificationsError,
        refetch: getNotificationsRefetch,
    } = useQuery(GET_NOTIFICATIONS, {
        variables: { id: user.id, limit: 10 },
        skip: _.isEmpty(user),
    })

    useEffect(() => {
        if (getNotificationsError) {
            console.log(
                `Cannot get Notifications: ${getNotificationsError.message}`
            )
            toastError(getErrorResponse('DEFAULT', 500))
        }
        if (!_.isEmpty(getNotificationsData?.notifications)) {
            setNotificationsData(getNotificationsData.notifications)
        }
    }, [getNotificationsError, getNotificationsData])

    useEffect(() => {
        socket.on('newNotificaton', () => {
            getNotificationsRefetch()
        })
    }, [])

    /* ---------- Read Notification ---------- */
    const [readNotification] = useMutation(READ_NOTIFICATION, {
        onCompleted: data => {
            dispatch(fetchNotificationsCount(user))
        },
        onError: err => {
            const error = getGraphqlError(err)
            if (error) {
                toastError(getErrorResponse('DEFAULT', 500))
            }
        },
    })

    const handleClickNotification = item => {
        let redirectPath

        switch (item.type) {
            case 'JOB_INTEREST':
                redirectPath = `/job/${item?.job?.id}`
                break
            case 'RATING':
                redirectPath = `/manage-profile?reviews=true`
                break
            case 'VERIFY':
            case 'CONFIRM':
            case 'CONFIRMED':
                redirectPath = `/manage-profile`
                break
            default:
        }

        if (redirectPath) {
            handleRedirect(redirectPath)
        }

        if (item.isRead) return
        readNotification({ variables: { id: item.id } })
    }

    const currentPath = useMemo(() => {
        return location.pathname
    }, [location])

    return (
        <Box
            position='fixed'
            top='0'
            left='0'
            right='0'
            width='100%'
            background='white'
            zIndex={999}
            style={{
                boxShadow: '8.66007px 3.60836px 12px rgba(0, 0, 0, 0.08)',
            }}
            display={{ base: 'none', lg: 'block' }}
        >
            <HStack alignItems='center' pt={2} px={2}>
                <HStack flexGrow={1} justifyContent='space-around'>
                    <WithIconButton
                        onPress={() => handleRedirect('/')}
                        Icon={<BagIcon />}
                        text='หน้าหลัก'
                    />
                    <HStack space={2}>
                        <FilledInput
                            placeholder='ต้องการหา...'
                            name='keyword'
                            value={inputValue.keyword}
                            onChange={handleChange}
                            InputLeftElement={<LinearSearchIcon />}
                            height='38px'
                            containerStyle={{ maxWidth: '233px' }}
                            onSubmitEditing={handleSearch}
                            maxLength={256}
                        />
                        <LinearGradientOutlineButton
                            onPress={handleSearch}
                            width='102px'
                            height='38px'
                        >
                            ค้นหา
                        </LinearGradientOutlineButton>
                    </HStack>
                </HStack>
                <HStack
                    space={
                        user?.role?.name === 'EMPLOYEE' || isLargerThan1200
                            ? 8
                            : 5
                    }
                    flexGrow={1}
                    justifyContent='center'
                >
                    {user?.role?.name === 'EMPLOYEE' ? (
                        <Fragment>
                            <WithIconButton
                                onPress={() => handleRedirect('/myjob')}
                                Icon={<BagIcon />}
                                text='งานของฉัน'
                                selected={currentPath === '/myjob'}
                            />
                            <WithIconButton
                                onPress={() => handleRedirect('/job')}
                                Icon={<LinearBriefcaseIcon />}
                                text='ตามหางาน'
                                selected={currentPath === '/job'}
                            />
                            <WithIconButton
                                onPress={() => handleRedirect('/favoritejob')}
                                Icon={<HeartIcon height='28' />}
                                text='งานโปรด'
                                selected={currentPath === '/favoritejob'}
                            />
                        </Fragment>
                    ) : (
                        <WithIconButton
                            onPress={() => handleRedirect('/myjob')}
                            Icon={<VolumeIcon />}
                            text='งานประกาศ'
                            selected={currentPath === '/myjob'}
                        />
                    )}
                    <WithIconButton
                        onPress={() => handleRedirect('/messages')}
                        Icon={<MessagesIcon />}
                        text='แชท'
                        badgeContent={messagesCount}
                        selected={currentPath === '/messages'}
                    />
                    <NotificationCard
                        isOpen={isNotificationOpen}
                        setIsOpen={setIsNotificationOpen}
                        handleRedirect={handleRedirect}
                        handleClickNotification={handleClickNotification}
                        currentPath={currentPath}
                        data={notificationsData}
                        badgeContent={notificationsCount}
                    />
                    <Divider
                        bg='divider'
                        thickness='1'
                        mx='2'
                        orientation='vertical'
                        height='auto'
                    />
                    <ProfileCard
                        user={user}
                        handleRedirect={handleRedirect}
                        isOpen={isProfileOpen}
                        setIsOpen={setIsProfileOpen}
                    />
                    {user?.role?.name !== 'EMPLOYEE' && (
                        <HStack space={2}>
                            <LinearGradientOutlineButton
                                width='110px'
                                height='43px'
                                borderRadius={9}
                                onPress={() =>
                                    handleRedirect('/messages?room=admin')
                                }
                            >
                                ติดต่อลงโฆษณา
                            </LinearGradientOutlineButton>
                            <LinearGradientOutlineButton
                                onPress={() => handleRedirect('/post')}
                                width='120px'
                                height='43px'
                                borderRadius={9}
                            >
                                ลงประกาศหางาน
                            </LinearGradientOutlineButton>
                        </HStack>
                    )}
                </HStack>
            </HStack>
            <Divider
                bg='divider'
                thickness='1'
                orientation='horizontal'
                width='100%'
            />
            {(isJobDetail || isPreview || isDraftPreview) && (
                <Fragment>
                    <HStack
                        pt={2}
                        pr={2}
                        pl='132px'
                        pb={2}
                        alignItems='center'
                        space='3'
                        mt='1'
                    >
                        <SelectSearch
                            placeholder='ประเภทการจ้างงาน'
                            name='jobType'
                            onChange={handleChange}
                            options={JOB_TYPE}
                            value={inputValue.jobType}
                            width='167px'
                            containerStyle={{ flexGrow: 0 }}
                        />

                        <SelectSearch
                            placeholder='งานล่าสุด'
                            name='sort'
                            value={inputValue.sort}
                            options={SORT_ORDER}
                            onChange={handleChange}
                            width='150px'
                            containerStyle={{ flexGrow: 0 }}
                            isSearchable={false}
                        />

                        <SelectSearch
                            placeholder='อาชีพ'
                            name='professionOne'
                            value={inputValue.professionOne}
                            options={professionOneData}
                            onChange={handleChange}
                            isLoading={getProfessionOneLoading}
                            width='180px'
                            containerStyle={{ flexGrow: 0 }}
                        />

                        <SelectSearch
                            placeholder='สาขา'
                            name='professionTwo'
                            options={professionTwoData}
                            value={inputValue.professionTwo}
                            isDisabled={_.isEmpty(professionTwoData)}
                            onChange={handleChange}
                            isLoading={getProfessionTwoLoading}
                            isClearable
                            width='180px'
                            containerStyle={{ flexGrow: 0 }}
                        />

                        <SelectSearch
                            placeholder='สาขาย่อย'
                            name='professionThree'
                            options={professionThreeData}
                            value={inputValue.professionThree}
                            isDisabled={_.isEmpty(professionThreeData)}
                            onChange={handleChange}
                            isLoading={getProfessionThreeLoading}
                            isClearable
                            width='180px'
                            containerStyle={{ flexGrow: 0 }}
                        />

                        <Divider
                            bg='divider'
                            thickness='1'
                            mx='2'
                            orientation='vertical'
                            height='48px'
                        />
                        <Button
                            variant='outline'
                            borderColor='heavyBlack'
                            _hover={{
                                bg: 'light.100',
                            }}
                            _pressed={{
                                bg: 'light.200',
                            }}
                            borderRadius='9px'
                            borderWidth='1px'
                            width='190px'
                            onPress={() => {
                                dispatch(setIsHeaderExpanded(!isHeaderExpanded))
                            }}
                        >
                            <HStack alignItems='center' space='2'>
                                <Text fontSize='md' color='heavyBlack'>
                                    {isHeaderExpanded
                                        ? 'ย่อเลือกทั้งหมด'
                                        : 'แสดงตัวเลือกทั้งหมด'}
                                </Text>
                                <Box
                                    style={{
                                        transform: `rotate(${
                                            isHeaderExpanded ? 180 : 0
                                        }deg)`,
                                    }}
                                >
                                    <ArrowDownIcon />
                                </Box>
                            </HStack>
                        </Button>
                        <Button
                            variant='outline'
                            borderColor='gray19'
                            _hover={{
                                bg: 'light.100',
                            }}
                            _pressed={{
                                bg: 'light.200',
                            }}
                            borderRadius='9px'
                            borderWidth='1px'
                            width='116px'
                            onPress={handleClearSearch}
                        >
                            <HStack alignItems='center' space='2'>
                                <Text fontSize='md' color='gray19'>
                                    ล้างข้อมูล
                                </Text>
                                <BroomIcon />
                            </HStack>
                        </Button>
                    </HStack>
                    <PresenceTransition
                        visible={isHeaderExpanded}
                        initial={{
                            opacity: 0,
                            height: 0,
                        }}
                        animate={{
                            opacity: 1,
                            height: 68,
                            transition: {
                                duration: 250,
                            },
                        }}
                    >
                        <HStack
                            pt={2}
                            pr={2}
                            pl='132px'
                            pb={3}
                            alignItems='center'
                            space='3'
                        >
                            <SelectSearch
                                placeholder='จังหวัด'
                                name='province'
                                onChange={handleChange}
                                options={provincesData}
                                value={inputValue.province}
                                isLoading={getProvicesLoading}
                                width='170px'
                                containerStyle={{ flexGrow: 0 }}
                                isClearable
                            />

                            <SelectSearch
                                placeholder='เขต/อำเภอ'
                                name='district'
                                onChange={handleChange}
                                options={districtsData}
                                value={inputValue.district}
                                isDisabled={_.isNull(inputValue.province)}
                                isLoading={getDistrictsLoading}
                                width='170px'
                                containerStyle={{ flexGrow: 0 }}
                                isClearable
                            />

                            <SelectSearch
                                placeholder='แขวง/ตำบล'
                                name='subDistrict'
                                onChange={handleChange}
                                options={subDistrictsData}
                                value={inputValue.subDistrict}
                                isDisabled={_.isNull(inputValue.district)}
                                isLoading={getSubDistrictsLoading}
                                width='187px'
                                containerStyle={{ flexGrow: 0 }}
                                isClearable
                            />

                            <NewTimePicker
                                placeholder='เวลาเริ่มงาน'
                                name='startTime'
                                onChange={handleChange}
                                value={inputValue.startTime}
                                width='200px'
                                isDisabled={
                                    inputValue.jobType?.value === 'FULL_TIME'
                                }
                                containerStyle={{ flexGrow: 0 }}
                                minuteStep={15}
                            />
                            <NewTimePicker
                                placeholder='เวลาเลิกงาน'
                                name='endTime'
                                onChange={handleChange}
                                value={inputValue.endTime}
                                width='200px'
                                isDisabled={
                                    inputValue.jobType?.value === 'FULL_TIME'
                                }
                                containerStyle={{ flexGrow: 0 }}
                                minuteStep={15}
                            />
                        </HStack>
                    </PresenceTransition>
                </Fragment>
            )}
        </Box>
    )
}

const NotificationCard = ({
    isOpen,
    setIsOpen,
    handleRedirect,
    handleClickNotification,
    data,
    badgeContent,
}) => {
    const { colors } = useTheme()

    const triggerRef = useRef(null)
    const containerRef = useRef(null)

    // hide picker if click somewhere else
    useEffect(() => {
        document.addEventListener('click', handleClickOutside, false)
        return () => {
            document.removeEventListener('click', handleClickOutside, false)
        }
    }, [])

    const handleClickOutside = event => {
        if (
            containerRef.current &&
            !containerRef.current.contains(event.target)
        ) {
            setIsOpen(false)
        }
    }

    return (
        <Box ref={containerRef}>
            <WithIconButton
                ref={triggerRef}
                onPress={() => setIsOpen(!isOpen)}
                Icon={<NotificationIcon />}
                text='แจ้งเตือน'
                isDropdown
                badgeContent={badgeContent}
            />

            <Overlay
                show={isOpen}
                rootClose
                offset={[0, 10]}
                onHide={() => setIsOpen(false)}
                placement={'bottom-end'}
                container={containerRef}
                target={triggerRef}
            >
                {({ props, placement }) => (
                    <Box {...props} placement={placement} zIndex={1}>
                        <Card shadow>
                            <VStack space='1' width='420px'>
                                <Text
                                    fontSize='md'
                                    fontWeight='500'
                                    color='heavyBlack'
                                >
                                    การแจ้งเตือน
                                </Text>
                                <VStack
                                    maxHeight='500px'
                                    overflowY='auto'
                                    mt='1'
                                >
                                    {data.map(item => (
                                        <Notification
                                            key={item.id}
                                            onPress={() =>
                                                handleClickNotification(item)
                                            }
                                            data={item}
                                        />
                                    ))}
                                </VStack>
                                <IconButton
                                    width='100%'
                                    justifyContent='center'
                                    icon={
                                        <HStack>
                                            <span
                                                style={{
                                                    fontSize: '14px',
                                                    fontWeight: '500',
                                                    color: colors.heavyBlack,
                                                }}
                                            >
                                                ดูทั้งหมด
                                            </span>
                                        </HStack>
                                    }
                                    onPress={() =>
                                        handleRedirect('/notifications')
                                    }
                                />
                            </VStack>
                        </Card>
                    </Box>
                )}
            </Overlay>
        </Box>
    )
}

const Notification = ({ data, onPress }) => {
    return (
        <Pressable onPress={onPress}>
            {({ isHovered, isPressed }) => {
                return (
                    <Box
                        px='1'
                        pt='2'
                        bg={
                            isPressed
                                ? 'light.200'
                                : isHovered
                                ? 'light.100'
                                : 'white'
                        }
                    >
                        <HStack
                            alignItems='flex-start'
                            justifyContent='flex-start'
                            space='2'
                        >
                            <Box
                                bg={
                                    data.isRead
                                        ? isPressed
                                            ? 'light.200'
                                            : isHovered
                                            ? 'light.100'
                                            : 'white'
                                        : 'primaryBlue'
                                }
                                width='8px'
                                height='8px'
                                borderRadius='full'
                                mt='2'
                            ></Box>
                            <Text
                                fontSize='sm'
                                fontWeight={data.isRead ? '300' : '500'}
                                color={
                                    data.isRead ? 'textDetail' : 'heavyBlack'
                                }
                            >
                                {data?.title}
                            </Text>
                        </HStack>
                        <Divider
                            bg='divider'
                            thickness='1'
                            orientation='horizontal'
                            width='100%'
                            mt='2'
                        />
                    </Box>
                )
            }}
        </Pressable>
    )
}

const ProfileCard = ({ user, handleRedirect, isOpen, setIsOpen }) => {
    const { colors } = useTheme()
    const dispatch = useDispatch()

    const handleLogout = () => {
        dispatch(logout())
    }

    const triggerRef = useRef(null)
    const containerRef = useRef(null)

    // hide picker if click somewhere else
    useEffect(() => {
        let elements = document.querySelectorAll("[role='button']")
        elements.forEach(item => {
            item.addEventListener('click', handleClickOutside, false)
        })

        document.addEventListener('click', handleClickOutside, false)
        return () => {
            document.removeEventListener('click', handleClickOutside, false)
            elements.forEach(item => {
                item.removeEventListener('click', handleClickOutside, false)
            })
        }
    }, [])

    const handleClickOutside = event => {
        if (
            containerRef.current &&
            !containerRef.current.contains(event.target)
        ) {
            setIsOpen(false)
        }
    }

    return (
        <Box ref={containerRef}>
            <WithIconButton
                ref={triggerRef}
                onPress={() => setIsOpen(!isOpen)}
                Icon={<ProfileIcon />}
                text='โปรไฟล์'
                isDropdown
            />

            <Overlay
                show={isOpen}
                rootClose
                offset={[0, 10]}
                onHide={() => setIsOpen(false)}
                placement={'bottom-end'}
                container={containerRef}
                target={triggerRef}
            >
                {({ props, placement }) => (
                    <Box {...props} placement={placement} zIndex='1'>
                        <Card
                            maxWidth='300px'
                            minWidth='274px'
                            padding='4'
                            shadow
                            maxHeight='80vh'
                            overflow='auto'
                        >
                            <VStack space='3'>
                                <Text fontSize='xs' color='gray42'>
                                    บัญชีของฉัน
                                </Text>
                                <VStack space='2'>
                                    <Box
                                        borderWidth='1'
                                        borderColor='gray1'
                                        borderRadius='12'
                                        py='2'
                                        px='3'
                                    >
                                        <HStack space='2'>
                                            <Avatar
                                                url={user?.avatar?.url}
                                                alt={`${user?.firstName}-${user?.lastName}`}
                                                size='40px'
                                                isVerify={user?.isVerify}
                                            />
                                            <VStack>
                                                <Text
                                                    fontSize='md'
                                                    color='heavyBlack'
                                                    fontWeight='500'
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        width: '165px',
                                                        overflow: 'hidden',
                                                        textOverflow:
                                                            'ellipsis',
                                                    }}
                                                >
                                                    {user?.role?.name ===
                                                    'COMPANY'
                                                        ? user.firstName
                                                        : `${user.firstName} ${user.lastName}`}
                                                </Text>
                                                <Text
                                                    fontSize='xs'
                                                    color='primaryBlue'
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        width: '165px',
                                                        overflow: 'hidden',
                                                        textOverflow:
                                                            'ellipsis',
                                                    }}
                                                >
                                                    {user.email}
                                                </Text>
                                            </VStack>
                                        </HStack>
                                    </Box>
                                    {/* {user?.role?.name !== 'EMPLOYEE' && (
                                        <PackageBox
                                            handleRedirect={handleRedirect}
                                        />
                                    )} */}
                                </VStack>

                                <VStack mt='2'>
                                    <Text fontSize='xs' color='gray42' mb='1'>
                                        จัดการบัญชีของฉัน
                                    </Text>
                                    <IconButton
                                        width='100%'
                                        justifyContent='flex-start'
                                        icon={
                                            <HStack
                                                space={2}
                                                alignItems='center'
                                            >
                                                <EyeIcon fill='textDetail' />
                                                <span
                                                    style={{
                                                        color: colors.heavyBlack,
                                                    }}
                                                >
                                                    โปรไฟล์สาธารณะของฉัน
                                                </span>
                                            </HStack>
                                        }
                                        onPress={() =>
                                            handleRedirect('/profile')
                                        }
                                    />
                                    <IconButton
                                        width='100%'
                                        justifyContent='flex-start'
                                        icon={
                                            <HStack
                                                space={2}
                                                alignItems='center'
                                            >
                                                <UserIcon />
                                                <span
                                                    style={{
                                                        color: colors.heavyBlack,
                                                    }}
                                                >
                                                    จัดการโปรไฟล์ของฉัน
                                                </span>
                                            </HStack>
                                        }
                                        onPress={() =>
                                            handleRedirect('/manage-profile')
                                        }
                                    />
                                    {/* {['EMPLOYER', 'COMPANY'].includes(
                                        user?.role?.name
                                    ) && (
                                        <IconButton
                                            width='100%'
                                            justifyContent='flex-start'
                                            icon={
                                                <HStack
                                                    space={2}
                                                    alignItems='center'
                                                >
                                                    <BriefcaseIcon3
                                                        fill='textDetail'
                                                        size='20'
                                                    />
                                                    <span
                                                        style={{
                                                            color: colors.heavyBlack,
                                                        }}
                                                    >
                                                        ประวัติการซื้อ
                                                    </span>
                                                </HStack>
                                            }
                                            onPress={() =>
                                                handleRedirect('/history')
                                            }
                                        />
                                    )} */}
                                    {/* <IconButton
                                        width='100%'
                                        justifyContent='flex-start'
                                        icon={
                                            <HStack
                                                space={2}
                                                alignItems='center'
                                            >
                                                <AlarmIcon />
                                                <span
                                                    style={{
                                                        color: colors.heavyBlack,
                                                    }}
                                                >
                                                    ศูนย์ความช่วยเหลือ
                                                </span>
                                            </HStack>
                                        }
                                        onPress={() => handleRedirect('/help')}
                                    /> */}
                                    {['EMPLOYER', 'COMPANY'].includes(
                                        user?.role?.name
                                    ) && (
                                        <IconButton
                                            width='100%'
                                            justifyContent='flex-start'
                                            icon={
                                                <HStack
                                                    space={2}
                                                    alignItems='center'
                                                >
                                                    <AdsIcon
                                                        fill='textDetail'
                                                        size='20'
                                                    />
                                                    <span
                                                        style={{
                                                            color: colors.heavyBlack,
                                                        }}
                                                    >
                                                        การเข้าถึงโฆษณา
                                                    </span>
                                                </HStack>
                                            }
                                            onPress={() =>
                                                handleRedirect('/dashboard')
                                            }
                                        />
                                    )}

                                    <IconButton
                                        width='100%'
                                        justifyContent='flex-start'
                                        icon={
                                            <HStack
                                                space={2}
                                                alignItems='center'
                                            >
                                                <GearIcon
                                                    fill='textDetail'
                                                    size='20'
                                                />
                                                <span
                                                    style={{
                                                        color: colors.heavyBlack,
                                                    }}
                                                >
                                                    การตั้งค่า
                                                </span>
                                            </HStack>
                                        }
                                        onPress={() =>
                                            handleRedirect('/settings')
                                        }
                                    />
                                </VStack>
                                <Divider
                                    bg='divider'
                                    thickness='1'
                                    orientation='horizontal'
                                    width='100%'
                                />
                                <IconButton
                                    width='100%'
                                    justifyContent='flex-start'
                                    icon={
                                        <HStack alignItems='center'>
                                            <span
                                                style={{
                                                    color: colors.textDetail,
                                                }}
                                            >
                                                ออกจากระบบ
                                            </span>
                                        </HStack>
                                    }
                                    onPress={handleLogout}
                                />
                            </VStack>
                        </Card>
                    </Box>
                )}
            </Overlay>
        </Box>
    )
}
