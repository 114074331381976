export const commonColors = {
    primaryBlue: '#0278D6',
    primaryBlueLignt: '#CEFFFF',
    primaryBlueLignten: '#E7FFFF',
    primaryYellow: '#DDA14A',
    primaryYellowLight: '#FFFFC9',
    primaryYellowLighten: '#FFFFE3',
    textDetail: '#9CA3AF',
    topicBlack: '#40506A',
    heavyBlack: '#3B4759',
    blackLight: '#5F6073',
    negroClaro: '#5F6073',
    gray1: '#E9E9E9',
    gray2: '#7C848F',
    gray3: '#9CA3AF',
    gray4: '#C0C6CF',
    gray5: '#EFEFEF',
    gray6: '#F4F4F4',
    gray7: '#66708080',
    gray8: '#F1F1F1',
    gray9: '#96A0AF',
    gray10: '#667080',
    gray11: '#A5B1C5',
    gray12: '#A3A9B3',
    gray13: '#F8F8F8',
    gray14: '#82878E',
    gray15: '#F5F5F5',
    gray16: '#B5BAC4',
    gray17: '#ECECEC',
    gray18: '#3b47590f',
    gray19: '#CBD5E3',
    gray20: '#AFAFAF',
    gray21: '#7C8797',
    gray22: '#F9F7F7',
    gray24: '#6B7077',
    gray23: '#E9E9E9',
    gray25: '#FCFCFC',
    gray26: '#939393',
    gray27: '#FBFBFC',
    gray28: '#E9EAEC',
    gray29: '#F7F7F7',
    gray30: '#FBFBFC',
    gray31: '#D9D9D9',
    gray32: '#EEF1F2',
    gray33: '#707070',
    gray34: '#9C9C9C',
    gray35: '#EBECEF',
    gray36: '#626D7D',
    gray37: '#3AAB820A',
    gray38: '#F3F3F3',
    gray39: '#292D32',
    gray40: '#FAFAFA',
    gray41: '#D0D0D0',
    gray42: '#B5BAC3',
    gray43: '#333333',
    blue1: '#EDF5FF',
    blue2: '#EBF1FE',
    blue3: '#b9ccf6',
    blue4: '#F0F2F5',
    blue5: '#0278d61a',
    blue6: '#0278D60A',
    themeBlue: '#4983c1',
    periodBlue: '#456DFF',
    packageBlack: '#0B0914',
    packageBlue: '#5076FF',
    packageBlue2: '#4F75FF',
    packageButton: '#466eff0a',
    packagePinnedButton: '#5DE1B1',
    white: '#FFFFFF',
    crimson: '#C6605C',
    inputFilled: '#F9F9F9',
    inputFilledDisabled: '#F0F0F0',
    purpleLight: '#EDEEFF',
    divider: 'rgba(59, 71, 89, 0.1)',
    badge: '#DDA14A',
    red1: '#FF0D1B',
    red2: '#E74242',
    red3: '#FF0000',
    red4: '#E73C37',
    green1: '#3AAB82',
    green2: '#22A85B',
    green3: '#02857F',
    green4: '#67B22C',
    greenBlueLinear: 'linear-gradient(90deg, #02857F 0%, #0278D6 108.42%)',
    greenBlueLinearDisabled:
        'linear-gradient(90deg, rgba(2, 133, 127, 0.24) 20.39%, rgba(2, 120, 214, 0.24) 81.94%)',
    verticalGreenBlueLinear:
        'linear-gradient(180deg, #02857F 43.9%, #0278D6 175%)',
    greenYellowBlueLinear:
        'linear-gradient(90deg, #02857F 20.51%, #F1B359 58.81%, #0278D6 96.07%)',
    nativeBaseBorderBlue: '#0891b2',
    nativeBassInputError: '#dc2626',
    periodLinear: 'linear-gradient(138.2deg, #3D67FF 14.21%, #5B7FFF 76.17%)',
    pinnedPackageLiner:
        'linear-gradient(342.42deg, #5B7FFF 22.97%, #3D66FF 96.92%)',
}
