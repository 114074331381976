import { gql } from '@apollo/client'
import axios from './index'

export const createJob = async formData => {
    // deprecated!!! please use graphql 'postJob' instead
    const res = await axios.post(`/jobs/create`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })

    return res
}

export const editJob = async (id, formData) => {
    // deprecated!!! please use graphql 'editJob' instead
    const res = await axios.put(`/jobs/edit/${id}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })

    return res
}

export const sendConfirmJob = async data => {
    // deprecated!!! please use graphql 'sendConfirmation' instead
    const res = await axios.post(`/jobs/send-accept`, data)

    return res
}
export const employerRateJob = async data => {
    const res = await axios.post(`/applications/rate/employee`, data)

    return res
}

export const TODAY_PUBLISH_JOBS_COUNT = gql`
    query TODAY_PUBLISH_JOBS_COUNT($id: ID!, $startOfToday: DateTime!, $packageHistoryId: ID!){
        jobsConnection(
            where: { createdBy: $id, publishedAt_gte: $startOfToday, package_history: $packageHistoryId }
        ) {
            aggregate {
                count
            }
        } 
    }
`

export const COUNT_PUBLISH_JOBS = gql`
    query COUNT_PUBLISH_JOBS($id: ID!) {
        publishJob: jobsConnection(
            where: {
                createdBy: $id
                status_in: ["PUBLISH", "WORKING", "COMPLETED", "CANCELLED"]
            }
        ) {
            aggregate {
                count
            }
        }
        completeJob: jobsConnection(
            where: { createdBy: $id, status_in: ["COMPLETED"] }
        ) {
            aggregate {
                count
            }
        }
    }
`
export const GET_MY_APPLICATIONS_REVIEWS_BY_EMPLOYEE = gql`
query GET_MY_APPLICATIONS_REVIEW(
    $id: ID!
    $start: Int!
    $limit: Int
    $status: [ENUM_APPLICATION_STATUS]
    $sort: String
  ) {
    applications(
      where: { status: $status, ratingByEmployee_gt: 0, job: { createdBy: $id } }
      limit: $limit
      start: $start
      sort: $sort
    ) {
      showEmployerName
      status
      applicant {
        firstName
        lastName
        avatar {
          url
        }
        isVerify
        isDeleted
      }
      ratingByEmployee
      ratingByEmployeeDateTime
      showEmployeeName
    }
  }
  
`

export const COUNT_MY_APPLICATIONS_REVIEWS_BY_EMPLOYEE = gql`
    query COUNT_MY_APPLICATIONS($id: ID!, $status: [ENUM_APPLICATION_STATUS]) {
        applicationsConnection( where: { status: $status, ratingByEmployee_gt: 0, job: { createdBy: $id } }) {
            aggregate {
                count
            }
        }
    }
`

export const UPDATE_JOB_STATUS = gql`
    mutation UPDATE_JOB_STATUS($id: ID!, $status: ENUM_JOB_STATUS!) {
        updateJob(input: { where: { id: $id }, data: { status: $status } }) {
            job {
                id
                topic
                status
            }
        }
    }
`

export const GET_CREATE_JOBS = gql`
    query GET_CREATE_JOBS(
        $id: ID!
        $jobType: String
        $status: [ENUM_JOB_STATUS!]!
        $start: Int!
    ) {
        jobs(
            where: { status_in: $status, jobType: $jobType, createdBy: $id }
            limit: 9
            start: $start
            sort: "updatedAt:desc"
        ) {
            id
            jobType
            status
            createdAt
            updatedAt
            publishedAt
            expiredAt
            topic
            wage
            startDate
            startTime
            endDate
            endTime
            company
            address
            province {
                name_th
            }
            district {
                name_th
            }
            sub_district {
                name_th
            }
            createdBy {
                id
                firstName
                lastName
                mobileNumber
                avatar {
                    url
                }
            }
            number
            taken
            favoredBy{
                id
            }
            isEdited
        }
    }
`

export const CREATE_JOBS_COUNT = gql`
    query CREATE_JOBS_COUNT($id: ID!, $jobType: String, $status: [ENUM_JOB_STATUS!]!) {
        jobsConnection(
            where: { status_in: $status, jobType: $jobType, createdBy: $id }
        ) {
            aggregate {
                count
            }
        }
    }
`

export const GET_CREATE_JOB = gql`
    query GET_CREATE_JOB($id: ID!) {
        job(id: $id) {
            id
            createdAt
            publishedAt
            expiredAt
            createdBy {
                id
                isVerify
                firstName
                lastName
                occupation
                avatar {
                    url
                }
            }
            profession_one {
                id
                title
            }
            profession_two {
                id
                title
            }
            profession_three {
                id
                title
            }
            company
            province {
                name_th
                province_id
            }
            district {
                name_th
                district_id
            }
            sub_district {
                name_th
                sub_district_id
            }
            address
            jobType
            startDate
            startTime
            endDate
            endTime
            topic
            content
            geoUrl
            wage
            contactType
            contact
            contactInfo
            number
            taken
            status
            isEdited
            tags {
                id
                title
            }
            image0 {
                id
                url
            }
            image1 {
                id
                url
            }
            image2 {
                id
                url
            }
            image3 {
                id
                url
            }
            image4 {
                id
                url
            }
        }
    }
`

export const APPLICATIONS_COUNT = gql`
    query APPLICATIONS_COUNT($id: ID!){
        jobsConnection(where: { createdBy: $id, contactType_ne: "EMAIL" }) {
            aggregate {
                count
            }
        }
    }
`

export const GET_APPLICATIONS = gql`
query GET_APPLICATIONS(
    $id: ID!
    $start: Int!
    $status: [ENUM_APPLICATION_STATUS!]!
  ) {
    jobs(
      limit: 20
      start: $start
      sort: "updatedAt:desc"
      where: { createdBy: $id, contactType_ne: "EMAIL" }
    ) {
      id
      jobType
      status
      topic
      wage
      publishedAt
      startDate
      startTime
      endDate
      endTime
      company
      company
      address
      district {
        name_th
      }
      province {
        name_th
      }
      createdBy {
        id
        userType
        role {
          id
          name
        }
        firstName
        lastName
        occupation
        avatar {
          url
        }
      }
      applications(where: { status_in: $status }) {
        id
        status
        ratingByEmployer
        showEmployerName
  
        applicant {
          firstName
          lastName
          isDeleted
        }
      }
    }
  }
`

export const UPDATE_APPLICATION_STATUS = gql`
    mutation UPDATE_APPLICATION_STATUS(
  $id: ID!
  $status: ALLOW_APPLICATION_STATUS!
) {
    updateApplicationStatus(id: $id, status: $status) {
    id
    status
    job {
      id
      createdBy {
        id
      }
    }
  }
}

`

export const GET_PACKAGE_HISTORIES = gql`
    query GET_PACKAGE_HISTORIES($id: ID!, $sort: String! $today: DateTime, $limit: Int, $start: Int) {
        packageHistories(limit: $limit,start :$start, sort: $sort, 
            where: { user: $id,  _or: [{ expiredAt_gt: $today }, { expiredAt_null: true }] }) {
            id
            createdAt
            expiredAt
            partTimeRemaining
            fullTimeRemaining
            package {
                id
                name
                description
            }
            transaction {
                price
                total
            }
            packageDetail
        }
    }

`

export const COUNT_PACKAGE_HISTORY = gql`
query COUNT_PACKAGE_HISTORY($id: ID!, $today: DateTime){
    packageHistoriesConnection(where: { user: $id, expiredAt_gt: $today }){
      aggregate{
        count
      }
    }
  }`

export const COUNT_EMPLOYER_ADS = gql`
    query COUNT_EMPLOYER_ADS($id: ID!, $startDate: DateTime, $endDate: DateTime){
        advertisementsConnection(
        where: { owner: $id, createdAt_gte: $startDate, createdAt_lte: $endDate }
    ) {
        aggregate {
            count
        }
    }
    }
`

export const GET_EMPLOYER_ADS_ID = gql`
    query GET_EMPLOYER_ADS_ID($id: ID!, $startDate: DateTime, $endDate: DateTime, $start: Int) {
        advertisements(where: { owner: $id, createdAt_gte: $startDate, createdAt_lte: $endDate}, sort: "createdAt:desc", start: $start) {
            id
            name
            viewCount
            clickCount
        }
    }
`

export const GET_EMPLOYER_ADS_BY_ID = gql`
    query GET_EMPLOYER_ADS_BY_ID($ads_id: [ID!]!) {
        advertisements(where: { id_in: $ads_id }) {
            id
            name
            description
            createdAt
            dueDate
        }
    }
`

export const GET_PACKAGE = gql`
    query GET_PACKAGE($id: ID!) {
        package(id: $id) {
            id
            name
            price
            packageDuration
            packageDurationUnit
            partTimeNumber
            fullTimeNumber
            companyOnly
            sale
            isPublished
        }
    }
`

export const VERIFY_VOUCHER = gql`
    query VERIFY_VOUCHER($code: String!){
        verifyVoucher(code: $code) {
            id
            percentage
            amount
            number
            taken
            startDate
            endDate
        }
    }
`

export const CREATE_ORDER = gql`
    mutation CREATE_ORDER($id: ID!, $paymentMethod: ENUM_TRANSACTION_PAYMENTMETHOD! $codeId: ID){
        createOrder(id: $id, paymentMethod: $paymentMethod, codeId: $codeId){
            id
            status
            paymentMethod
        }
    }
`

export const ORDER_CALLBACK = gql`
    mutation ORDER_CALLBACK($id: ID!, $ref1: String!, $ref2: String!) {
        orderCallback(id: $id, ref1: $ref1, ref2: $ref2) {
            status
            message
            data
        }
    }
`

export const PURCHASE_DEFAULT_PACKAGE = gql`
    mutation PURCHASE_DEFAULT_PACKAGE($id: ID!){
        purchaseDefaultPackage(id: $id){
            status
            message
            data
        }
    }
`

export const CREATE_USER_EMPLOYER = gql`
  mutation CREATE_USER_EMPLOYER(
        $company: String!
        $email: String!
        $password: String!
  ) {
    createEmployer(
        company: $company
    email: $email
    password: $password){
        status
        message
        data
    }
    }
`
