import React from 'react'
import { SPagination } from './style'
import { HStack, useMediaQuery } from 'native-base'
import ReactPaginate from 'react-paginate'

export const Pagination = ({
    pageSize = 10,
    totalItem = 20,
    currentPage,
    onChangePage,
}) => {
    const [isSmallScreen] = useMediaQuery({
        maxWidth: 820,
    })

    const totalPage = Math.ceil(totalItem / pageSize)
    const handleClickPage = ({ selected }) => {
        onChangePage(selected + 1)
    }

    return (
        <HStack justifyContent='flex-end'>
            <SPagination>
                <ReactPaginate
                    breakLabel='...'
                    nextLabel={isSmallScreen ? ' >' : 'Next >'}
                    previousLabel={isSmallScreen ? '<' : '< Previous'}
                    pageCount={totalPage}
                    onPageChange={handleClickPage}
                    forcePage={currentPage - 1}
                />
            </SPagination>
        </HStack>
    )
}
