import React, { useRef, useEffect } from 'react'
import { VStack, Pressable, Text, HStack, Box } from 'native-base'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import { XIcon } from '../../components/Icon'
import { LinearGradientText } from '../../components'
import { employeeMenuItems } from './Employee/menuItems'
import { employerMenuItems } from './Employer/menuItems'
import { publishMenuItems } from './Publish/menuItems'
import { fetchMessagesCount } from '../../store/messagesCount'
import { fetchNotificationsCount } from '../../store/notificationsCount'
import socket from '../../utils/socket'
import SideMenu from '../SideMenu'
import CBadge from '../Badge'

export const HamburgerMenu = ({
    onMoving,
    onTriggerHamburger,
    hamburgerActive,
    activeMenu,
}) => {
    const containerRef = useRef(null)
    const user = useSelector(state => state.user)
    const messagesCount = useSelector(state => state.messagesCount)
    const notificationsCount = useSelector(state => state.notificationsCount)

    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()

    useEffect(() => {
        if (!_.isEmpty(user)) {
            dispatch(fetchMessagesCount(user))
        }
    }, [user])

    useEffect(() => {
        socket.off('messageNotification')
        socket.on('messageNotification', () => {
            dispatch(fetchMessagesCount(user))
        })
    }, [])

    useEffect(() => {
        socket.on('newNotificaton', () => {
            dispatch(fetchNotificationsCount(user))
        })
    }, [])

    const handleGoTo = (pathName, redirect) => {
        const currentHome = location.pathname === '/'
        if (pathName === '/about-us' && currentHome) {
            onTriggerHamburger(false)
            onMoving(1)
        } else if (pathName === '/' && currentHome) {
            onTriggerHamburger(false)
            onMoving(0)
        } else navigate(pathName, { state: { redirect } })
    }

    const handleCheckRoleMenu = () => {
        if (user?.role?.name === 'EMPLOYEE') return employeeMenuItems
        if (['EMPLOYER', 'COMPANY'].includes(user?.role?.name))
            return employerMenuItems

        return publishMenuItems
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, false)
        return () => {
            document.removeEventListener('click', handleClickOutside, false)
        }
    }, [])

    const handleClickOutside = event => {
        if (
            containerRef.current &&
            !containerRef.current.contains(event.target)
        ) {
            onTriggerHamburger(false)
        }
    }

    return (
        <Box
            ref={containerRef}
            display={hamburgerActive ? 'block' : 'none'}
            position='fixed'
            left={0}
            top={0}
            bg='white'
            width={
                !['ADMIN', 'STAFF'].includes(user?.role?.name) ? '75%' : '300px'
            }
            height='100%'
            maxWidth='330px'
            zIndex={100}
            style={{
                boxShadow: '8.66007px 3.60836px 72.1672px rgba(0, 0, 0, 0.06)',
            }}
            borderTopRadius={10}
            borderBottomRightRadius={10}
        >
            <Box position='absolute' right={3} top={3} zIndex={12}>
                <Pressable
                    onPress={() => onTriggerHamburger(false)}
                    _hover={{ opacity: 0.7 }}
                >
                    <XIcon fill={'gray24'} />
                </Pressable>
            </Box>
            {!['ADMIN', 'STAFF'].includes(user?.role?.name) && (
                <HStack justifyContent='center' mt={10}>
                    <LinearGradientText
                        fontSize='34px'
                        fontWeight={700}
                        fontFamily='Poppins'
                        color='greenBlueLinear'
                    >
                        Medimatch
                    </LinearGradientText>
                </HStack>
            )}
            {!['ADMIN', 'STAFF'].includes(user?.role?.name) ? (
                <Box p={5}>
                    <VStack px={5} space={2} mt={5}>
                        {handleCheckRoleMenu().map((item, ind) => {
                            if (
                                !activeMenu &&
                                location.pathname === item.pathName
                            )
                                return (
                                    <Pressable
                                        key={ind}
                                        onPress={() =>
                                            handleGoTo(
                                                item.pathName,
                                                item.redirect
                                            )
                                        }
                                        flex={1}
                                    >
                                        <HStack
                                            alignItems={'center'}
                                            height='100%'
                                            bg='blue5'
                                            borderRadius={5}
                                            p='3px'
                                        >
                                            <CBadge
                                                badgeContent={messagesCount}
                                                hidden={
                                                    item.pathName !==
                                                    '/messages'
                                                }
                                            >
                                                <item.icon />
                                            </CBadge>
                                            <Text
                                                color={'heavyBlack'}
                                                fontSize='sm'
                                                ml={3}
                                            >
                                                {item.name}
                                            </Text>
                                        </HStack>
                                    </Pressable>
                                )
                            else if (
                                activeMenu === 1 &&
                                '/about-us' === item.pathName
                            )
                                return (
                                    <Pressable
                                        key={ind}
                                        onPress={() =>
                                            handleGoTo(
                                                item.pathName,
                                                item.redirect
                                            )
                                        }
                                        flex={1}
                                    >
                                        <HStack
                                            alignItems={'center'}
                                            height='100%'
                                            bg='blue5'
                                            borderRadius={5}
                                            p='3px'
                                        >
                                            <item.icon />
                                            <Text
                                                color={'heavyBlack'}
                                                fontSize='sm'
                                                ml={3}
                                            >
                                                {item.name}
                                            </Text>
                                        </HStack>
                                    </Pressable>
                                )
                            return (
                                <Pressable
                                    key={ind}
                                    onPress={() =>
                                        handleGoTo(item.pathName, item.redirect)
                                    }
                                    flex={1}
                                >
                                    <HStack
                                        alignItems={'center'}
                                        height='100%'
                                        p='3px'
                                    >
                                        {[
                                            '/messages',
                                            '/notifications',
                                        ].includes(item.pathName) ? (
                                            <CBadge
                                                badgeContent={
                                                    item.pathName ===
                                                    '/messages'
                                                        ? messagesCount
                                                        : notificationsCount
                                                }
                                            >
                                                <item.icon fill='heavyBlack' />
                                            </CBadge>
                                        ) : (
                                            <item.icon fill='heavyBlack' />
                                        )}
                                        <Text
                                            color={'heavyBlack'}
                                            fontSize='sm'
                                            ml={3}
                                        >
                                            {item.name}
                                        </Text>
                                    </HStack>
                                </Pressable>
                            )
                        })}
                    </VStack>
                </Box>
            ) : (
                <SideMenu />
            )}
        </Box>
    )
}
