import { Box } from 'native-base'

const FallbackAvatar = '/images/professions/health.png'
const VerifyIcon = '/images/verify_icon.svg'

export const Avatar = ({
    url,
    size,
    alt,
    isVerify,
    imgStyle,
    containerStyle,
}) => {
    return (
        <Box
            position='relative'
            style={containerStyle}
            width={size}
            height={size}
            borderRadius='full'
        >
            <img
                src={url || FallbackAvatar}
                alt={alt}
                style={{
                    height: size,
                    width: size,
                    borderRadius: '100%',
                    overflow: 'hidden',
                    objectFit: 'cover',
                    backgroundColor: 'white',
                    ...imgStyle,
                }}
            />
            {isVerify && (
                <img
                    src={VerifyIcon}
                    alt={`verify-icon`}
                    style={{
                        width: '17px',
                        height: '17px',
                        position: 'absolute',
                        top: 0,
                        right: 0,
                    }}
                />
            )}
        </Box>
    )
}
