import { LineIcon } from 'react-share'
import { Box } from 'native-base'

export const LinkedLineOa = ({ isLink }) => {
    if (isLink)
        return (
            <Box>
                <LineIcon borderRadius={10} size={25} />
            </Box>
        )
    return (
        <Box style={{ filter: 'grayscale(90%)' }}>
            <LineIcon borderRadius={10} size={25} />
        </Box>
    )
}
