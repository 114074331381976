import axios from 'axios'
import { gql } from '@apollo/client'
import { getCMSAPIUrl } from '../utils/urlFactory'

const instance = axios.create({
    baseURL: getCMSAPIUrl(),
    timeout: 600000,
    headers: {
        'Content-Type': 'application/json',
    },
})

instance.interceptors.request.use(
    request => {
        return request
    },
    error => {
        return Promise.reject(error)
    }
)

instance.interceptors.response.use(
    response => {
        return response
    },
    error => {
        return Promise.reject(error)
    }
)

instance.interceptors.request.use(
    request => {
        return request
    },
    error => {
        return Promise.reject(error)
    }
)

instance.interceptors.response.use(
    response => {
        return response
    },
    error => {
        return Promise.reject(error)
    }
)

export default instance

export const registerEmployee = async data => {
    const res = await instance.post('/register/employee', data)

    return res
}

export const registerEmployer = async data => {
    const res = await instance.post('/register/employer', data)

    return res
}

export const registerCompany = async data => {
    const res = await instance.post('/register/company', data)

    return res
}

export const completeRegister = async data => {
    const res = await instance.post('/register/complete', data)

    return res
}

export const loginWithSocialMedial = async (provider, param) => {
    const res = await instance.get(`/auth/${provider}/callback${param}`)

    return res
}

export const loginWithEmail = async data => {
    const res = await instance.post('/auth/local', data)

    return res
}

export const tokenDecrypt = async token => {
    const res = await instance.post(`/token/decrypt`, {
        token: token,
    })

    return res.data
}

export const getMe = async id => {
    const res = await instance.post(`/graphql`, {
        query: GET_USER,
        variables: {
            id: id,
            sort: 'createdAt:asc',
            today: new Date().toISOString(),
        },
    })

    return res.data
}

export const getNotificationsCount = async variables => {
    const res = await instance.post(`/graphql`, {
        query: GET_NOTIFICATIONS_UNREAD_COUNT,
        variables: variables,
    })

    return res.data
}

export const getMessagesCount = async variables => {
    const res = await instance.post(`/graphql`, {
        query: GET_UNREAD_MESSAGES_COUNT,
        variables: variables,
    })

    return res.data
}

export const getAdminMessagesCount = async variables => {
    const res = await instance.post(`/graphql`, {
        query: GET_UNREAD_ADMIN_MESSAGES_COUNT,
        variables: variables,
    })

    return res.data
}

export const readMessage = async id => {
    // deprecated!!! please use graphql 'readMessage' instead
    const res = await instance.post(`/messages/read`, { id })

    return res
}

export const messageSendFiles = async form => {
    // deprecated!!! please use graphql 'sendFileMessage' instead
    const res = await instance.post(`/messages/files`, form)

    return res
}

export const messageReportUser = async form => {
    // deprecated!!! please use graphql 'reportUser' instead
    const res = await instance.post(`/messages/report`, form)

    return res
}

export const getAds = async () => {
    const res = await instance.post(`/graphql`, {
        query: GET_PUBLISHED_ADS,
    })

    return res.data
}

export const checkDuplicateSocialID = async data => {
    const res = await instance.post(`/social-check`, data)

    return res
}

export const uploadProfile = async formData => {
    const res = await instance.post('/profile/upload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })

    return res.data
}

export const uploadVerify = async formData => {
    const res = await instance.post('/profile/upload/verify', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })

    return res.data
}

export const GET_USER = `
query GET_USER(
    $id: ID!
    $today: DateTime
    $limit: Int
    $start: Int
    $sort: String
  ) {
    user(id: $id) {
      id
      email
      loginMethod
      lineID
      friendFlag
      confirmed
      userType
      role {
        id
        name
      }
      IDCard
      license
      firstName
      lastName
      occupation
      profession_one {
        id
        title
      }
      profession_two {
        id
        title
      }
      profession_three {
        id
        title
      }
      avatar {
        id
        url
      }
      company
      rating
      education
      mobileNumber
      gender
      isVerify
      verificationStatus
      birthday
      tags {
        id
        title
      }
      banner {
        id
        url
      }
      favorite_jobs {
        id
      }
      applications {
        id
        status
        job {
          id
        }
      }
      province {
        id
        name_th
        name_en
        province_id
      }
      district {
        id
        name_th
        name_en
        district_id
      }
      sub_district {
        id
        name_th
        name_en
        sub_district_id
      }
      address
      IDCardImg {
        url
      }
      isDeleted
    }
    packageHistories(
      limit: $limit
      start: $start
      sort: $sort
      where: { user: $id, _or: [{ expiredAt_gt: $today }, { expiredAt_null: true }] }
    ) {
      id
      createdAt
      expiredAt
      partTimeRemaining
      fullTimeRemaining
      package {
        id
        name
        description
      }
      transaction {
        price
        total
      }
      packageDetail
    }
  }  
`

export const GET_UNREAD_MESSAGES_COUNT = `
    query GET_UNREAD_MESSAGES_COUNT($employeeId: ID, $employerId: ID, $type_ne: ENUM_MESSAGES_TYPE) {
        rooms(where: { employee: $employeeId, employer: $employerId  }) {
            messages(limit: 1, sort: "createdAt:desc",where:{type_ne:$type_ne}) {
                id
                isRead
                sender {
                    id
                }
                isReadEmployee
                isReadEmployer
            }
        }
    }
`

export const GET_UNREAD_ADMIN_MESSAGES_COUNT = `
    query GET_UNREAD_ADMIN_MESSAGES_COUNT($userId: ID) {
        adminRooms(where: { user: $userId }) {
            messages(limit: 1, sort: "createdAt:desc") {
                id
                isRead
                sender {
                    id
                    firstName
                    lastName
                }
                isReadEmployee
                isReadEmployer
                isReadAdmin
            }
        }
    }
`

export const SOCIAL_MEDIA_LOGIN = gql`
    query SOCIAL_MEDIA_LOGIN($socialID: String!,$socialProvider: ALLOW_SOCIAL_MEDIA_PROVIDER!) {
        socialMediaLogin(socialID: $socialID, socialProvider: $socialProvider) {
            status
            data
            message
        }
    }
`

export const FORGOT_PASSWORD = gql`
    mutation FORGOT_PASSWORD($email: String!) {
        userForgotPassword(email: $email) {
            status
            message
        }
    }
`

export const RESET_PASSWORD = gql`
    mutation RESET_PASSWORD(
        $token: String!
        $password: String!
        $confirmPassword: String!
    ) {
        userResetPassword(
            token: $token
            password: $password
            confirmPassword: $confirmPassword
        ) {
            status
            message
        }
    }
`

export const CHANGE_PASSWORD = gql`
    mutation CHANGE_PASSWORD(
        $oldPassword: String!
        $password: String!
        $confirmPassword: String!
    ) {
        userChangePassword(
            oldPassword: $oldPassword
            password: $password
            confirmPassword: $confirmPassword
        ) {
            status
            message
        }
    }
`

export const SIMILAR_JOBS = gql`
    query SIMILAR_JOBS(
        $keyword: [String!]!
        $tags: [ID]!
        $endExpiredAt: String!
    ) {
        jobs(
            where: {
                status: "PUBLISH",
                expiredAt_gt: $endExpiredAt
            _or: [
                { topic_contains: $keyword }
                { content_contains: $keyword }
                { tags: { title: $keyword } }
                { tags_in: $tags }
            ]
            createdBy_null: false
            createdBy: { isDeleted: false }
            isDebug: false
            }
            limit: 20
        ) {
            id
            topic
            content
            company
            address
            province {
                name_th
            }
            district {
                name_th
            }
            createdBy {
                id
                firstName
                lastName
                occupation
                avatar {
                    url
                }
                isDeleted
                role {
                    name
                }
            }
            tags {
                id
                title
            }
            isDebug
        }
    }
`

export const COUNT_JOBS_TAGS = gql`
    query COUNT_JOBS_TAGS(
        $jobType: ENUM_JOB_JOBTYPE
        $tags: [ID!]!
        $endExpiredAt: String!
        $excludeID: [ID]
        $isDebug: Boolean
    ){
        jobsConnection(
            where: { 
                status: "PUBLISH"
                jobType: $jobType
                tags_in: $tags
                createdBy_null: false
                createdBy: { isDeleted: false }
                expiredAt_gt: $endExpiredAt
                id_nin: $excludeID
                isDebug: $isDebug
            }
        ) {
            aggregate {
                count
            }
        }
    }
`

export const GET_JOBS_TAGS = gql`
    query GET_JOBS_TAGS(
        $jobType: ENUM_JOB_JOBTYPE
        $tags: [ID!]!
        $limit: Int!
        $start: Int!
        $sort: String!
        $endExpiredAt: String!
        $excludeID: [ID]
        $isDebug: Boolean
    ){
        jobs(
            where: { 
                status: "PUBLISH"
                jobType: $jobType
                tags_in: $tags
                createdBy_null: false
                createdBy: { isDeleted: false }
                expiredAt_gt: $endExpiredAt
                id_nin: $excludeID
                isDebug: $isDebug
            },
            limit: $limit
            start: $start
            sort: $sort
        ) {
            id
            jobType
            status
            topic
            content
            company
            wage
            startDate
            startTime
            endDate
            endTime
            profession_one {
                id
            }
            profession_two {
                id
            }
            profession_three {
                id
            }
            address
            province {
                name_th
            }
            district {
                name_th
            }
            sub_district {
                name_th
            }
            tags {
                id
                title
            }
            number
            taken
            favoredBy {
                id
            }
            createdBy {
                id
                firstName
                lastName
                mobileNumber
                avatar {
                    url
                }
                isDeleted
            }
            publishedAt
            expiredAt
            isDebug
        }
    }
`

export const COUNT_JOBS_ADDRESS = gql`
    query COUNT_JOBS_ADDRESS(
    $jobType: ENUM_JOB_JOBTYPE
    $provinceId: ID!
    $endExpiredAt: String!
    $excludeID: [ID]
    $isDebug: Boolean
    ) {
        jobsConnection(
            where: {
            status: "PUBLISH"
            jobType: $jobType
            province: $provinceId
            createdBy_null: false
            createdBy: { isDeleted: false }
            expiredAt_gt: $endExpiredAt
            id_nin: $excludeID
            isDebug: $isDebug
            }
        ) {
            aggregate {
            count
            }
        }
    }
`

export const GET_JOBS_ADDRESS = gql`
    query GET_JOBS_ADDRESS(
    $jobType: ENUM_JOB_JOBTYPE
    $provinceId: ID!
    $limit: Int!
    $start: Int!
    $sort: String!
    $endExpiredAt: String!
    $excludeID: [ID]
    $isDebug: Boolean
    ) {
    jobs(
        where: {
        status: "PUBLISH"
        jobType: $jobType
        province: $provinceId
        createdBy_null: false
        createdBy: { isDeleted: false }
        expiredAt_gt: $endExpiredAt
        id_nin: $excludeID
        isDebug: $isDebug
        }
        limit: $limit
        start: $start
        sort: $sort
    ) {
        id
        jobType
        status
        topic
        content
        company
        wage
        startDate
        startTime
        endDate
        endTime
        profession_one {
            id
        }
        profession_two {
            id
        }
        profession_three {
            id
        }
        address
        province {
            id
            name_th
        }
        district {
            name_th
        }
        sub_district {
            name_th
        }
        tags {
            id
            title
        }
        number
        taken
        favoredBy {
            id
        }
        createdBy {
            id
            firstName
            lastName
            mobileNumber
            avatar {
                url
            }
            isDeleted
        }
        publishedAt
        expiredAt
        isDebug
    }
    }
`

export const COUNT_JOBS_PROFESSION = gql`
    query COUNT_JOBS_PROFESSION(
        $jobType: ENUM_JOB_JOBTYPE
        $profession_one: ID
        $profession_two: ID
        $profession_three: ID
        $endExpiredAt: String!
        $excludeID: [ID]
        $isDebug: Boolean
    ){
        jobsConnection(
            where: {
            status: "PUBLISH"
            jobType: $jobType
            profession_one: $profession_one
            profession_two: $profession_two
            profession_three: $profession_three
            createdBy_null: false
            createdBy: { isDeleted: false }
            expiredAt_gt: $endExpiredAt
            id_nin: $excludeID
            isDebug: $isDebug
            }
        ) {
            aggregate {
            count
            }
        }
    }
`

export const GET_JOBS_PROFESSION = gql`
    query GET_JOBS_PROFESSION(
        $jobType: ENUM_JOB_JOBTYPE
        $profession_one: ID
        $profession_two: ID
        $profession_three: ID
        $limit: Int!
        $start: Int!
        $sort: String!
        $endExpiredAt: String!
        $excludeID: [ID]
        $isDebug: Boolean
    ){
        jobs(
    where: {
      status: "PUBLISH"
      jobType: $jobType
      profession_one: $profession_one
      profession_two: $profession_two
      profession_three: $profession_three
      createdBy_null: false
      createdBy: { isDeleted: false }
      expiredAt_gt: $endExpiredAt
      id_nin: $excludeID
      isDebug: $isDebug
    }
        limit: $limit
        start: $start
        sort: $sort
  ){
    id
        jobType
        status
        topic
        content
        company
        wage
        startDate
        startTime
        endDate
        endTime
        profession_one {
            id
        }
        profession_two {
            id
        }
        profession_three {
            id
        }
        address
        province {
          name_th
        }
        district {
          name_th
        }
        sub_district {
           name_th
        }
        tags {
            id
            title
        }
        number
        taken
        favoredBy {
            id
        }
        createdBy {
            id
            firstName
            lastName
            mobileNumber
            avatar {
                url
            }
            isDeleted
        }
        publishedAt
        expiredAt
        isDebug
  }
    }
`

export const COUNT_JOBS_FILTER = gql`
    query COUNT_JOBS_FILTER(
        $jobType: ENUM_JOB_JOBTYPE
        $keyword: String
        $tag: ID
        $profession_one: ID
        $profession_two: ID
        $profession_three: ID
        $province: ID
        $district: ID
        $subDistrict: ID
        $endExpiredAt: String!
        $excludeID: [ID]
        $startTime: Time
        $endTime: Time
        $isDebug: Boolean
    ) {
        startTimeJobsConnection: jobsConnection(
            where: {
                status: "PUBLISH"
                jobType: $jobType
                profession_one: $profession_one
                profession_two: $profession_two
                profession_three: $profession_three
                province: $province
                district: $district
                sub_district: $subDistrict
                expiredAt_gt: $endExpiredAt
                tags: $tag
                _or: [
                    { topic_contains: $keyword }
                    { content_contains: $keyword }
                    { company_contains: $keyword }
                    { tags: { title_contains: $keyword } }
                ]
                createdBy_null: false
                createdBy: { isDeleted: false }
                id_nin: $excludeID
                isDebug: $isDebug
                startTime_gte: $startTime
                startTime_lte: $endTime
            }
        ) {
            aggregate {
                count
            }
        }
        endTimeJobsConnection: jobsConnection(
            where: {
                status: "PUBLISH"
                jobType: $jobType
                profession_one: $profession_one
                profession_two: $profession_two
                profession_three: $profession_three
                province: $province
                district: $district
                sub_district: $subDistrict
                expiredAt_gt: $endExpiredAt
                tags: $tag
                _or: [
                    { topic_contains: $keyword }
                    { content_contains: $keyword }
                    { company_contains: $keyword }
                    { tags: { title_contains: $keyword } }
                ]
                createdBy_null: false
                createdBy: { isDeleted: false }
                id_nin: $excludeID
                isDebug: $isDebug
                endTime_gte: $endTime
                endTime_lte: $startTime
            }
        ) {
            aggregate {
                count
            }
        }
    }
`

export const GET_JOBS_FILTER = gql`
    query GET_JOBS_FILTER(
        $jobType: ENUM_JOB_JOBTYPE
        $keyword: String
        $tag: ID
        $profession_one: ID
        $profession_two: ID
        $profession_three: ID
        $province: ID
        $district: ID
        $subDistrict: ID
        $limit: Int!
        $sort: String!
        $endExpiredAt: String!
        $excludeID: [ID]
        $isDebug: Boolean
        $startTimeStart: Int!
        $startTime: Time
        $endTimeStart: Int!
        $endTime: Time
    ) {
    startTimeJobs: jobs(
        where: {
            status: "PUBLISH"
            jobType: $jobType
            profession_one: $profession_one
            profession_two: $profession_two
            profession_three: $profession_three
            province: $province
            district: $district
            sub_district: $subDistrict
            expiredAt_gt: $endExpiredAt
            tags: $tag
            _or: [
                { topic_contains: $keyword }
                { content_contains: $keyword }
                { company_contains: $keyword }
                { tags: { title_contains: $keyword } }
            ]
            createdBy_null: false
            createdBy: { isDeleted: false }
            id_nin: $excludeID
            isDebug: $isDebug
            startTime_gte: $startTime
            startTime_lte: $endTime
        }
        limit: $limit
        start: $startTimeStart
        sort: $sort
    ) {
        id
        jobType
        status
        topic
        content
        company
        wage
        startDate
        startTime
        endDate
        endTime
        profession_one {
            id
        }
        profession_two {
            id
        }
        profession_three {
            id
        }
        address
        province {
          name_th
        }
        district {
          name_th
        }
        sub_district {
           name_th
        }
        tags {
            id
            title
        }
        number
        taken
        favoredBy {
            id
        }
        createdBy {
            id
            isDeleted
            firstName
            lastName
            mobileNumber
            avatar {
                url
            }
        }
        publishedAt
        expiredAt
        isDebug
    }
    endTimeJobs: jobs(
        where: {
            status: "PUBLISH"
            jobType: $jobType
            profession_one: $profession_one
            profession_two: $profession_two
            profession_three: $profession_three
            province: $province
            district: $district
            sub_district: $subDistrict
            expiredAt_gt: $endExpiredAt
            tags: $tag
            _or: [
                { topic_contains: $keyword }
                { content_contains: $keyword }
                { company_contains: $keyword }
                { tags: { title_contains: $keyword } }
            ]
            createdBy_null: false
            createdBy: { isDeleted: false }
            id_nin: $excludeID
            isDebug: $isDebug
            endTime_gte: $startTime
            endTime_lte: $endTime
        }
        limit: $limit
        start: $endTimeStart
        sort: $sort
    ) {
        id
        jobType
        status
        topic
        content
        company
        wage
        startDate
        startTime
        endDate
        endTime
        profession_one {
            id
        }
        profession_two {
            id
        }
        profession_three {
            id
        }
        address
        province {
          name_th
        }
        district {
          name_th
        }
        sub_district {
           name_th
        }
        tags {
            id
            title
        }
        number
        taken
        favoredBy {
            id
        }
        createdBy {
            id
            isDeleted
            firstName
            lastName
            mobileNumber
            avatar {
                url
            }
        }
        publishedAt
        expiredAt
        isDebug
    }
    }
`

export const GET_FILTER_JOBS = gql`
  query GET_FILTER_JOBS(
    $keyword: String
    $jobType: ENUM_JOB_JOBTYPE
    $startDate: Date
    $endDate: Date
    $startTime: Time
    $endTime: Time
    $province: ID
    $district: ID
    $profession_one: [ID]
    $profession_two: [ID]
    $profession_three: [ID]
    $tags: [ID]
    $limit: Int
    $page: Int
    $sort: FILTER_JOBS_SORT_CREATE_AT
  ) {
    getFilterJobs(
      input: {
        keyword: $keyword
        jobType: $jobType
        startDate: $startDate
        endDate: $endDate
        startTime: $startTime
        endTime: $endTime
        province: $province
        district: $district
        profession_one: $profession_one
        profession_two: $profession_two
        profession_three: $profession_three
        tags: $tags
        limit: $limit
        page: $page
        sort: $sort
      }
    ) {
      currentPage
      totalPages
      totalItems
      jobs {
        id
        createdAt
        jobType
        status
        topic
        content
        company
        wage
        startDate
        startTime
        endDate
        endTime
        profession_one {
          id
        }
        profession_two {
          id
        }
        profession_three {
          id
        }
        address
        province {
          name_th
        }
        district {
          name_th
        }
        sub_district {
          name_th
        }
        tags {
          id
          title
        }
        number
        taken
        favoredBy {
          id
        }
        createdBy {
          id
          isDeleted
          firstName
          lastName
          mobileNumber
          avatar {
            url
          }
        }
        publishedAt
        expiredAt
        isDebug
      }
    }
  }
`

export const GET_JOBS = gql`
    query GET_JOBS(
        $id: ID
        $jobType: String
        $topic: String
        $sort: String
        $province: ID
        $district: ID
        $subDistrict: ID
        $limit: Int!
        $start: Int
        $endExpiredAt: String
    ) {
        jobs(
            where: {
                createdBy: $id
                status: "PUBLISH"
                jobType: $jobType
                topic_contains: $topic
                province: $province
                district: $district
                sub_district: $subDistrict
                expiredAt_gt: $endExpiredAt
                isDebug: false
            }
            limit: $limit
            start: $start
            sort: $sort
        ) {
            id
            jobType
            status
            createdAt
            publishedAt
            expiredAt
            topic
            wage
            startDate
            startTime
            endDate
            endTime
            contactType
            company
            address
            province {
                name_th
            }
            district {
                name_th
            }
            sub_district {
                name_th
            }
            createdBy {
                id
                firstName
                lastName
                mobileNumber
                avatar {
                    url
                }
                isDeleted
            }
            isDebug
        }
    }
`

export const GET_JOB = gql`
    query GET_JOB($id: ID!) {
        job(id: $id) {
            id
            createdAt
            publishedAt
            expiredAt
            status
            createdBy {
                id
                isVerify
                firstName
                lastName
                occupation
                avatar {
                    url
                }
                isDeleted
                role {
                    name
                }
            }
            company
            province {
                name_th
            }
            district {
                name_th
            }
            sub_district {
                name_th
            }
            address
            jobType
            startDate
            startTime
            endDate
            endTime
            topic
            content
            contactInfo
            geoUrl
            geoLocation
            wage
            number
            taken
            image0 {
                id
                url
                name
            }
            image1 {
                id
                url
                name
            }
            image2 {
                id
                url
                name
            }
            image3 {
                id
                url
                name
            }
            image4 {
                id
                url
                name
            }
            tags{
                id
                title
            }
            isSponsor
            sponsoredStart
            sponsoredEnd
        }
    }
`

export const TAGS_COUNT = gql`
    query TAGS_COUNT($title: String) {
        tagsConnection(where: { title_contains: $title }) {
            aggregate {
                count
            }
    }
}
`

export const GET_TAGS = gql`
    query GET_TAGS($title: String, $start: Int) {
        tags(where: { title_contains: $title }, sort: "title:asc" , start: $start ) {
            id
            title
        }
    }
`

export const CREATE_TAG = gql`
    mutation CREATE_TAG($title: String!, $userId: ID!) {
        createTag(input: { data: { title: $title , createdBy: $userId } }) {
            tag {
                id
                title
                createdBy {
                    id
                }
            }
        }
    }
`

export const UPDATE_USER_TAGS = gql`
    mutation UPDATE_USER_TAGS($id: ID!, $tags: [ID]!) {
        updateUser(input: { where: { id: $id }, data: { tags: $tags } }) {
            user {
                id
                firstName
                tags {
                    id
                    title
                }
            }
        }
    }
`

export const GET_PROVINCES = gql`
    query GET_PROVINCES {
        provinces(sort: "name_th:asc") {
            id
            province_id
            name_th
        }
    }
`
export const GET_DISTRICT = gql`
    query GET_DISTRICT($province_id: Int!) {
        districts(where: { province_id: $province_id }, sort: "name_th:asc") {
            id
            name_th
            province_id
            district_id
        }
    }
`

export const GET_SUB_DISTRICT = gql`
    query GET_SUB_DISTRICT($district_id: Int!) {
        subDistricts(
            where: { district_id: $district_id }
            sort: "name_th:asc"
        ) {
            id
            name_th
            district_id
            sub_district_id
        }
    }
`

export const GET_ADMIN_ROOMS = gql`
    query GET_ADMIN_ROOMS($user: ID!) {
        adminRooms(where: { user: $user }) {
            id
            messages(limit: 1, sort: "createdAt:desc") {
                id
                createdAt
                isRead
                isReadEmployee
                isReadEmployer
                text
                type
                    sender {
                        id
                        userType
                        role {
                            id
                            name
                        }
                    }
            }
        }
    }
`

export const COUNT_ROOMS = gql`
    query COUNT_ROOMS($employeeId: ID, $employerId: ID) {
        roomsConnection(where: { employee: $employeeId, employer: $employerId }) {
            aggregate {
            count
            }
        }
    }
`

export const GET_ROOMS = gql`
    query GET_ROOMS($employeeId: ID, $employerId: ID, $isEmployee: Boolean!, $type_ne: ENUM_MESSAGES_TYPE, $start: Int) {
        rooms(where: { employee: $employeeId, employer: $employerId },sort: "createdAt:desc", start: $start) {
            id
            createdAt
            updatedAt
            applications(limit: -1, sort: "updatedAt:desc") {
                id
                createdAt
                updatedAt
                status
                job {
                    id
                    status
                    topic
                    content
                    createdBy {
                        id
                        firstName
                        isDeleted
                    }
                }
            }
            employee @skip(if: $isEmployee) {
                id
                userType
                role {
                    id
                    name
                }
                firstName
                lastName
                avatar {
                    url
                }
                isDeleted
            }
            employer @include(if: $isEmployee) {
                id
                userType
                role {
                    id
                    name
                }
                firstName
                lastName
                avatar {
                    url
                }
                isDeleted
            }
            messages(limit: 1, sort: "createdAt:desc",where:{type_ne:$type_ne}) {
                id
                createdAt
                isRead
                isReadEmployee
                isReadEmployer
                text
                type
                sender {
                    id
                    userType
                    role {
                        id
                        name
                    }
                }
            }
        }
    }
`

export const COUNT_MESSAGES = gql`
    query COUNT_MESSAGES($roomId: ID, $adminRoomId: ID, $type_ne: ENUM_MESSAGES_TYPE) {
        messagesConnection(where: { room: $roomId , admin_room: $adminRoomId , type_ne:$type_ne}) {
            aggregate {
                count
            }
        }
    }
`

export const GET_ADMIN_MESSAGES = gql`
    query GET_ADMIN_MESSAGES($adminRoomId: ID!, $limit: Int!, $start: Int) {
        adminRoom(id: $adminRoomId) {
            id
            user {
                id
                userType
                role {
                    id
                    name
                }
                firstName
                lastName
                isDeleted
                rating
                avatar {
                    url
                }
            }
            messages(limit: $limit, start: $start, sort: "createdAt:desc") {
                id
                createdAt
                type
                sender {
                    id
                }
                target {
                    id
                    role {
                        name
                    }
                    firstName
                    lastName
                }
                text
                isRead
                file {
                    url
                    name
                    mime
                }
            }
        }
    }
`

export const GET_MESSAGES = gql`
    query GET_MESSAGES(
        $roomId: ID!
        $isEmployee: Boolean!
        $limit: Int!
        $start: Int
        $type_ne: ENUM_MESSAGES_TYPE
    ) {
        room(id: $roomId) {
            id
            applications(limit: -1, sort: "createdAt:desc", where: { status_in: ["APPLIED","ACCEPTED","CONFIRMED","WORKING","COMPLETED"] }) {
                id
                status
                acceptedDate
                confirmedDate
                completeDate
                job {
                    id
                    status
                    jobType
                    topic
                    wage
                    startDate
                    startTime
                    endDate
                    endTime
                    company
                    address
                    district {
                        name_th
                    }
                    province {
                        name_th
                    }
                    expiredAt
                }
            }
            employee @skip(if: $isEmployee) {
                id
                userType
                role {
                    id
                    name
                }
                firstName
                lastName
                occupation
                rating
                avatar {
                    url
                }
                isDeleted
            }
            employer @include(if: $isEmployee) {
                id
                userType
                role {
                    id
                    name
                }
                firstName
                lastName
                occupation
                rating
                avatar {
                    url
                }
                isDeleted
            }
            messages(limit: $limit, start: $start, sort: "createdAt:desc", where:{type_ne:$type_ne}) {
                id
                createdAt
                type
                sender {
                    id
                }
                text
                isRead
                file {
                    url
                    name
                    mime
                }
                application {
                    id
                    status
                    job {
                        id
                        status
                        jobType
                        topic
                        wage
                        startDate
                        startTime
                        endDate
                        endTime
                        company
                        address
                        district {
                            name_th
                        }
                        province {
                            name_th
                        }
                        expiredAt
                        createdBy {
                            id
                            isDeleted
                            firstName
                            lastName
                        }
                        taken
                        number
                    }
                }
            }
        }
    }
`

export const UPDATE_PROFILE = gql`
    mutation UPDATE_PROFILE(
        $id: ID!
        $firstName: String
        $lastName: String
        $province: ID
        $district: ID
        $subDistrict: ID
        $email: String!
        $mobileNumber: String
        $occupation: String
        $education: JSON
        $birthday: Date
        $gender: ENUM_USERSPERMISSIONSUSER_GENDER
        $company: String
        $juristicId : String
        $address: String
        $selfDescription: String
        $verificationStatus: ENUM_USERSPERMISSIONSUSER_VERIFICATIONSTATUS
        $confirmed: Boolean
        $isVerify: Boolean
    ) {
        updateUser(
            input: {
                where: { id: $id }
                data: {
                    firstName: $firstName
                    lastName: $lastName
                    province: $province
                    district: $district
                    sub_district: $subDistrict
                    email: $email
                    mobileNumber: $mobileNumber
                    occupation: $occupation
                    education: $education
                    birthday: $birthday
                    gender: $gender
                    company: $company
                    juristicId: $juristicId
                    address: $address
                    selfDescription: $selfDescription
                    verificationStatus: $verificationStatus
                    confirmed: $confirmed
                    isVerify: $isVerify
                }
            }
        ) {
            user {
                id
                firstName
                lastName
                email
                mobileNumber
                occupation
                education
                birthday
                gender
                juristicId
                province {
                    name_th
                    name_en
                    province_id
                }
                district {
                    name_th
                    name_en
                    district_id
                }
                sub_district {
                    name_th
                    name_en
                    sub_district_id
                }
                address
                selfDescription
                verificationStatus
            }
        }
    }
`

export const UPDATE_EMPLOYEE_PROFILE = gql`
    mutation UPDATE_EMPLOYEE_PROFILE(
        $id: ID!
        $firstName: String
        $lastName: String
        $province: ID
        $district: ID
        $subDistrict: ID
        $email: String!
        $mobileNumber: String
        $education: JSON
        $birthday: Date
        $gender: ENUM_USERSPERMISSIONSUSER_GENDER!
        $professionOne: ID
        $professionOneOther: String
        $professionTwo: ID
        $professionTwoOther: String
        $professionThree: ID
        $professionThreeOther: String
        $license : String
        $licenseRequired: String
        $isVerify: Boolean
        $tags: [ID]
        $selfDescription: String

    ) {
        updateEmployeeProfile(
            id: $id
            firstName: $firstName
            lastName: $lastName
            province: $province
            district: $district
            subDistrict: $subDistrict
            email: $email
            mobileNumber: $mobileNumber
            education: $education
            birthday: $birthday
            gender: $gender
            professionOne: $professionOne
            professionOneOther: $professionOneOther
            professionTwo: $professionTwo
            professionTwoOther: $professionTwoOther
            professionThree: $professionThree
            professionThreeOther: $professionThreeOther
            license : $license
            licenseRequired: $licenseRequired
            isVerify: $isVerify
            tags: $tags
            selfDescription: $selfDescription
        ) {
            status
            message
            data
        }
    }
`

export const UPDATE_AVATAR_PROFILE = gql`
mutation UPDATE_AVATAR_PROFILE(
    $refId: ID!,
    $ref: String!
		$field: String!
		$source: String!
		$file: Upload!
		$IDCfield: String!
    ) {
        first:upload(refId:$refId,ref:$ref,field:$field,source:$source,file:$file) {
           id
           url
        }
        second:upload(refId:$refId,ref:$ref,field:$IDCfield,source:$source,file:$file) {
           id
           url
        }
    }`

export const GET_USER_BY_ID = gql`
query GET_USER($id: ID!) {
    user(id: $id) {
      id
      loginMethod
      confirmed
      username
      email
      selfDescription
      confirmed
      userType
      isVerify
      friendFlag
      role {
        id
        name
      }
      IDCard
      license
      firstName
      lastName
      isDeleted
      rating
      occupation
      profession_one {
        id
        title
        licenseRequired
      }
      profession_two {
        id
        title
        licenseRequired
      }
      profession_three {
        id
        title
        licenseRequired
      }
      license
      avatar {
        id
        url
      }
      company
      rating
      education
      mobileNumber
      gender
      birthday
      juristicId
      tags {
        id
        title
      }
      banner {
        id
        url
      }
      favorite_jobs {
        id
      }
      applications {
        id
        job {
          id
        }
      }
      province {
        name_th
        name_en
        province_id
      }
      district {
        name_th
        name_en
        district_id
      }
      sub_district {
        name_th
        name_en
        sub_district_id
      }
      address
      IDCardImg {
        url
      }
      educationCertificate {
        url
      }
      professionalLicense {
        url
      }
      companyCertificate {
        url
      }
      issuedCard {
        url
      }
      verificationStatus
      verificationDescription
      verificationSubmitDate
      blocked
      admin_room{
        id
      }
      isSponsor
      sponsoredStart
      sponsoredEnd
      isEmailEdit
    }
  }
  
`

export const GET_PUBLISHED_PACKAGES = gql`
    query GET_PUBLISHED_PACKAGES($companyOnly: Boolean) {
        packages(where: { isPublished: true, companyOnly: $companyOnly } , sort: "priority:asc") {
            id
            name
            fakePrice
            price
            type
            packageDuration
            packageDurationUnit
            partTimeNumber
            fullTimeNumber
            detail
            companyOnly
            priority
            canEdit
            sale
            isPublished
        }
        defaultPackage {
            package {
            id
            name
            fakePrice
            price
            type
            packageDuration
            packageDurationUnit
            partTimeNumber
            fullTimeNumber
            detail
            companyOnly
            priority
            canEdit
            sale
            isPublished
            }
        }
    }
`

export const GET_PUBLISHED_ADS = `
    query GET_PUBLISHED_ADS {
        getAds {
            extra {
                id
                name
                type
                isPriority
                priority
                link
                banner {
                    url
                }
            }
            large {
                id
                name
                type
                isPriority
                priority
                link
                banner {
                    url
                }
            }
            basic {
                id
                name
                type
                isPriority
                priority
                link
                banner {
                    url
                }
            }
        }
    }
`

export const GET_PROFESSION_ONE = gql`
    query GET_PROFESSION_ONE ($isTemp:Boolean) {
        professionOnes(locale: "th" , where: { isTemp: $isTemp }, sort:"title:asc") {
            id
            title
            licenseRequired
            isTemp
            tags {
                title
            }
        }
    }
`

export const GET_PROFESSION_TWO = gql`
    query GET_PROFESSION_TWO($profession_one: ID!,$isTemp:Boolean) {
        professionTwos(locale: "th", where: { profession_one: $profession_one, isTemp: $isTemp }, sort:"title:asc") {
            id
            title
            licenseRequired
            isTemp
            tags {
                title
            }
        }
    }
`

export const GET_PROFESSION_THREES = gql`
    query GET_PROFESSION_THREES($profession_two: ID!,$isTemp:Boolean) {
        professionThrees(locale: "th", where: { profession_two: $profession_two, isTemp: $isTemp }, sort:"title:asc") {
            id
            title
            licenseRequired
            isTemp
            tags {
                title
            }
        }
    }
`

export const GET_PROFESSION_TAGS = gql`
    query GET_PROFESSION_TAGS($id: ID!) {
        professionOne(id: $id) {
            id
            title
            tags {
                id
                title
            }
            profession_twos {
                id
                title
                tags {
                    id
                    title
                }
                profession_threes {
                    id
                    title
                    tags {
                        id
                        title
                    }
                }
            }
        }
    }
`

export const GET_NOTIFICATIONS_UNREAD_COUNT = `
    query GET_NOTIFICATIONS_UNREAD_COUNT($id: ID!) {
        notificationsConnection(where: { user: $id, isRead: false }) {
            aggregate {
                count
            }
        }
    }
`

export const GET_NOTIFICATIONS_COUNT = gql`
    query GET_NOTIFICATIONS_COUNT($id: ID!) {
        notificationsConnection(where: { user: $id }) {
            aggregate {
                count
            }
        }
    }
`

export const GET_NOTIFICATIONS = gql`
    query GET_NOTIFICATIONS($id: ID!, $limit: Int!, $start: Int) {
        notifications(where: { user: $id }, limit: $limit, start: $start, sort: "createdAt:desc") {
                id
                type
                title
                description
                isRead
                job {
                    id
                }
        }
    }
`

export const READ_NOTIFICATION = gql`
    mutation READ_NOTIFICATION($id: ID!) {
        updateNotification(input: { where: { id: $id }, data: { isRead: true } }) {
            notification {
                id
                isRead
            }
        }
    }
`

export const GET_MY_APPLICATION_BYID = gql`
query GET_MY_APPLICATION_BYID($id: ID!) {
    application(id:$id)
      {
        id
        completeDate
        status
        ratingByEmployee
        showEmployeeName
        ratingByEmployer
        showEmployerName
        applicant {
            firstName
            lastName
        }
        job {
            id
            jobType
            status
            topic
            wage
            startDate
            startTime
            endDate
            endTime
            company
            district {
                name_th
            }
            province {
                name_th
            }
            createdBy {
                id
                userType
                role {
                    id
                    name
                }
                firstName
                lastName
                occupation
                avatar {
                    url
                }
                isDeleted
            }
        }
    }
}
`

export const UPDATE_USER_LINE = gql`
    mutation UPDATE_USER_LINE($userID: ID!, $lineID: String, $friendFlag: Boolean) {
        updateUser(
            input: {
            where: { id: $userID }
            data: { lineID: $lineID, friendFlag: $friendFlag }
            }
        ) {
            user {
                id
                lineID
                friendFlag
            }
        }
    }
`

export const SET_LINE_NOTIFICATION = gql`
    mutation SET_LINE_NOTIFICATION(
        $userID: ID!
        $lineID: String
        $friendFlag: Boolean
    ) {
        setLineNotification(
            userID: $userID
            lineID: $lineID
            friendFlag: $friendFlag
        ) {
            status
            message
            data
        }
    }
`

export const RESEND_EMAIL_USER = gql`
    mutation RESEND_EMAIL_USER($email: String!) {
        userResendEmailConfirmRegister(email: $email) {
            status
            message
        }
    }
`

export const UNSEND_MESSAGE = gql`
    mutation UNSEND_MESSAGE($id: ID!) {
        unsendMessage(id: $id) {
            status
            message
        }
    }
`

export const VERIFY_TOKEN = gql`
    query VERIFY_TOKEN($token: String!) {
        verifyToken(token: $token) {
            status
            message
        }
    }
`

export const GET_EMPLOYER_ADS = gql`
    query GET_EMPLOYER_ADS($id: ID!, $startDate: DateTime, $endDate: DateTime) {
        advertisements(where: { owner: $id, createdAt_gte: $startDate, createdAt_lte: $endDate} , sort: "createdAt:desc") {
            id
            name
            description
            createdAt
            dueDate
            viewCount
            clickCount
        }
    }
`

export const GET_TAGS_OPTIONS = gql`
    query GET_TAGS_OPTIONS{
        getTagsOptions{
            status
            message
            data
        }
    }
`

export const GET_HOT_CAREERS = gql`
    query GET_HOT_CAREERS {
        hotCareers(where: { isPublished: true }) {
            id
            title
            available
            avatar {
                id
                url
            }
            isPublished
        }
    }
`

export const GET_RAMDON_JOBS = gql`
    query GET_RAMDON_JOBS {
        getRandomJobs(size: 20) {
            id
            topic
            status
            taken
            number
            wage
            startDate
            startTime
            endDate
            endTime
            createdBy {
                id
                isDeleted
                firstName
            }
        }
    }
`

export const RESEND_EMAIL_USER_NEWPASSWORD = gql`
    mutation RESEND_EMAIL_USER_NEWPASSWORD($userID: ID!, $email: String!) {
        userResendEmailNewPassword(userID: $userID, email: $email) {
            status
            message
        }
    }
`