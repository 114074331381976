import React from 'react'
import { Tooltip } from 'native-base'
import { useTheme } from 'native-base'

export const ToolText = ({
    text,
    textToolTip,
    showTooltip = false,
    color = '',
    fontSize = '14px',
    fontWeight,
    fontFamily = 'Kanit',
    maxWidth,
    width,
    cursor,
}) => {
    const { colors, fontSizes, breakpoints } = useTheme()
    const textVal = text || '-'
    const textToolTipVal = textToolTip

    const transformBreakpoint = objectWidth => {
        if (typeof objectWidth !== 'object') {
            return objectWidth
        }
        const screenWidth = screen.width
        const result = Object.keys(objectWidth)
            .map(key => {
                if (screenWidth >= parseInt(breakpoints[key])) {
                    return key
                }
                return undefined
            })
            .filter(item => item)
        return `${parseInt(objectWidth[result[result.length - 1]])}px`
    }

    if (showTooltip)
        return (
            <Tooltip
                label={textToolTipVal || textVal}
                placement='top left'
                _text={{ fontFamily: fontFamily }}
            >
                <div
                    style={{
                        cursor: cursor,
                        maxWidth: transformBreakpoint(maxWidth),
                        width: width,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    <span
                        style={{
                            color: colors[color],
                            fontSize: fontSizes[fontSize],
                            fontFamily: fontFamily,
                            fontWeight: fontWeight,
                            whiteSpace: 'nowrap',
                        }}
                        data-tag='allowRowEvents'
                    >
                        {textVal}
                    </span>
                </div>
            </Tooltip>
        )
    else
        return (
            <div>
                <span
                    style={{
                        color: colors[color],
                        fontSize: fontSizes[fontSize],
                        fontFamily: fontFamily,
                        fontWeight: fontWeight,
                    }}
                    data-tag='allowRowEvents'
                >
                    {textVal}
                </span>
            </div>
        )
}
