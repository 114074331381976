import { lazy, Fragment, useEffect } from 'react'
import { Routes, Route, Outlet, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Box } from 'native-base'

import AdminLayoutPart from '../components/AdminLayout'

const Reset = lazy(() => import('../containers/Reset'))

const Messages = lazy(() => import('../containers/Messages'))
const MessagesV2 = lazy(() => import('../containers/MessagesV2'))

const Dashboard = lazy(() => import('../containers/Admin/Dashboard'))
const LineOAAdmin = lazy(() => import('../containers/Admin/LineOA'))

const AdminManagement = lazy(() =>
    import('../containers/Admin/AdminManagement/PreviewData')
)
const UserRegistrationEmployer = lazy(() =>
    import('../containers/Admin/UserRegitration/Employer/Employer')
)
const UserRegistrationEmployee = lazy(() =>
    import('../containers/Admin/UserRegitration/Employee/Employee')
)

const RegistrationFormEmployer = lazy(() =>
    import('../containers/Admin/UserRegitration/Employer/RegistrationForm')
)
const RegistrationFormEmployee = lazy(() =>
    import('../containers/Admin/UserRegitration/Employee/RegistrationForm')
)

const UserManagementEmployer = lazy(() =>
    import('../containers/Admin/UserManagement/Employer/PreviewData')
)
const UserManagementEmployee = lazy(() =>
    import('../containers/Admin/UserManagement/Employee/PreviewData')
)

const UserManagementFormEmployee = lazy(() =>
    import('../containers/Admin/UserManagement/Employee/UserForm')
)
const UserManagementFormEmployer = lazy(() =>
    import('../containers/Admin/UserManagement/Employer/UserForm')
)
const TagManagement = lazy(() =>
    import('../containers/Admin/TagManagement/TagManagement')
)

const ProfessionOne = lazy(() =>
    import(
        '../containers/Admin/ProfessionManagement/ProfessionOne/ProfessionOne'
    )
)
const ProfessionTwo = lazy(() =>
    import(
        '../containers/Admin/ProfessionManagement/ProfessionTwo/ProfessionTwo'
    )
)
const ProfessionThree = lazy(() =>
    import(
        '../containers/Admin/ProfessionManagement/ProfessionThree/ProfessionThree'
    )
)

const JobDashboard = lazy(() =>
    import('../containers/Admin/JobManagement/JobDashboard')
)
const JobManagementParttime = lazy(() =>
    import('../containers/Admin/JobManagement/Parttime/PreviewData')
)
const JobManagementFulltime = lazy(() =>
    import('../containers/Admin/JobManagement/Fulltime/PreviewData')
)

const JobDetailPartime = lazy(() =>
    import('../containers/Admin/JobManagement/Parttime/JobDetail')
)
const JobDetailFulltime = lazy(() =>
    import('../containers/Admin/JobManagement/Fulltime/JobDetail')
)
const HotCareers = lazy(() =>
    import('../containers/Admin/JobManagement/HotCareers')
)

const PaymentHistory = lazy(() =>
    import('../containers/Admin/PaymentHistory/PreviewData')
)
const PackageManagement = lazy(() =>
    import('../containers/Admin/PackageManagement')
)
const AdsManagement = lazy(() => import('../containers/Admin/AdsManagement'))
const VoucherManagement = lazy(() =>
    import('../containers/Admin/VoucherManagement')
)

const GrayLaout = () => {
    return (
        <Box bg='gray15'>
            <Outlet />
        </Box>
    )
}

const AdminLayout = () => {
    return <AdminLayoutPart />
}

export const Redirect = () => {
    const navigate = useNavigate()

    useEffect(() => {
        navigate('/', true)
    }, [])

    return <Box></Box>
}

export const RedirectAdmin = () => {
    const navigate = useNavigate()

    useEffect(() => {
        navigate('/admin', true)
    }, [])

    return <Box></Box>
}

const PrivateRoute = () => {
    const user = useSelector(state => state.user)

    return (
        <Routes>
            <Route element={<GrayLaout />}>
                <Route path='/admin/reset' element={<Reset />} />
            </Route>
            {['STAFF', 'ADMIN'].includes(user?.role?.name) && (
                <Fragment>
                    <Route element={<AdminLayout />}>
                        <Route path='/admin' element={<Dashboard />} />
                        <Route
                            path='/admin/messages'
                            element={<MessagesV2 />}
                        />

                        <Route
                            path='/admin/line-oa'
                            element={<LineOAAdmin />}
                        />
                        <Route
                            path='/admin/admin-management'
                            element={<AdminManagement />}
                        />
                        <Route
                            path='/admin/user-registration/employer'
                            element={<UserRegistrationEmployer />}
                        />
                        <Route
                            path='/admin/user-registration/employee'
                            element={<UserRegistrationEmployee />}
                        />
                        <Route
                            path='/admin/user-registration/employer/:id'
                            element={<RegistrationFormEmployer />}
                        />
                        <Route
                            path='/admin/user-registration/employee/:id'
                            element={<RegistrationFormEmployee />}
                        />
                        <Route
                            path='/admin/user-management/employer'
                            element={<UserManagementEmployer />}
                        />
                        <Route
                            path='/admin/user-management/employee'
                            element={<UserManagementEmployee />}
                        />
                        <Route
                            path='/admin/user-management/employee/:id'
                            element={<UserManagementFormEmployee />}
                        />
                        <Route
                            path='/admin/user-management/employer/:id'
                            element={<UserManagementFormEmployer />}
                        />
                        <Route
                            path='/admin/user/tag-management'
                            element={<TagManagement />}
                        />
                        <Route
                            path='/admin/user/profession-management/profession-one'
                            element={<ProfessionOne />}
                        />
                        <Route
                            path='/admin/user/profession-management/profession-two'
                            element={<ProfessionTwo />}
                        />
                        <Route
                            path='/admin/user/profession-management/profession-three'
                            element={<ProfessionThree />}
                        />

                        <Route
                            path='/admin/job-management/job-dashboard'
                            element={<JobDashboard />}
                        />
                        <Route
                            path='/admin/job-management/part-time'
                            element={<JobManagementParttime />}
                        />
                        <Route
                            path='/admin/job-management/full-time'
                            element={<JobManagementFulltime />}
                        />
                        <Route
                            path='/admin/job-management/part-time/:id'
                            element={<JobDetailPartime />}
                        />
                        <Route
                            path='/admin/job-management/full-time/:id'
                            element={<JobDetailFulltime />}
                        />
                        <Route
                            path='/admin/job-management/hot-careers'
                            element={<HotCareers />}
                        />
                        <Route
                            path='/admin/payment-history'
                            element={<PaymentHistory />}
                        />
                        {/* <Route path='/admin/messages' element={<Messages />} /> */}
                        {user?.role?.name === 'ADMIN' && (
                            <Fragment>
                                <Route
                                    path='/admin/package/package-management'
                                    element={<PackageManagement />}
                                />
                                <Route
                                    path='/admin/package/ads-management'
                                    element={<AdsManagement />}
                                />
                                <Route
                                    path='/admin/vouncher-management'
                                    element={<VoucherManagement />}
                                />
                            </Fragment>
                        )}
                    </Route>
                    <Route path='*' element={<RedirectAdmin />} />
                </Fragment>
            )}
        </Routes>
    )
}

export default PrivateRoute
