import { useRef } from 'react'

import { Box, FormControl, IconButton, Center, Text } from 'native-base'

import { ErrorMessage, CImageViewer, EditIcon2, CrossIcon } from '../index'

const imagePlaceHolder = '/images/image-regular.svg'

export const UploadAds = ({
    onFileChange,
    file,
    width,
    height,
    error,
    preserveError,
    isDisabled,
    containerStyle,
    hideText,
    accept = '.jpeg,.jpg,.png',
    handleRemove,
}) => {
    const defaultInputRef = useRef(null)

    const handleClickUpload = () => {
        if (isDisabled) return
        defaultInputRef.current.click()
    }

    const onChange = event => {
        onFileChange(event)
        event.target.value = null
    }

    return (
        <FormControl
            isInvalid={error || preserveError}
            width='auto'
            flexGrow='1'
            style={{ ...containerStyle }}
        >
            <Box>
                <input
                    ref={defaultInputRef}
                    id='upload-file'
                    name='filefield'
                    type='file'
                    accept={accept}
                    onChange={onChange}
                    style={{ display: 'none' }}
                />
                <Box
                    position='relative'
                    borderRadius={8}
                    borderColor={error ? 'nativeBassInputError' : 'heavyBlack'}
                    borderStyle='solid'
                    borderWidth={error ? 2 : 1}
                    width={width}
                    height={height}
                    overflow='hidden'
                >
                    {file ? (
                        <CImageViewer
                            imagesUrl={[file]}
                            width={width}
                            height={height}
                            alt='ads'
                            isDisabled={!file || isDisabled}
                            styleImg={{
                                objectFit: 'contain',
                            }}
                        />
                    ) : (
                        <Center width='100%' height='100%'>
                            <img
                                src={imagePlaceHolder}
                                alt='image_place_holder'
                                width={70}
                            />
                            {!hideText && (
                                <Text fontSize='md' fontWeight='500'>
                                    Add Image
                                </Text>
                            )}
                        </Center>
                    )}

                    <IconButton
                        position='absolute'
                        top={0}
                        right={0}
                        icon={<EditIcon2 />}
                        onPress={handleClickUpload}
                        isDisabled={isDisabled}
                    />
                    {handleRemove && file && (
                        <IconButton
                            position='absolute'
                            top={0}
                            left={0}
                            icon={<CrossIcon fill='red1' />}
                            onPress={handleRemove}
                            isDisabled={isDisabled}
                        />
                    )}
                </Box>
            </Box>
            {(error || preserveError) && (
                <ErrorMessage preserveError={preserveError}>
                    {error}
                </ErrorMessage>
            )}
        </FormControl>
    )
}
