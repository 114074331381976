import React from 'react'

import { Box, Center } from 'native-base'

import socket from '../../utils/socket'

import { toastError } from '../../utils/toast'
import { getErrorResponse } from '../../utils/responseText'

import withRouter from '../../utils/withRouter'

import { ErrorIcon } from '../index'

const DefaultError = () => {
    return (
        <Center>
            <Box width='50px'>
                <ErrorIcon />
            </Box>
        </Center>
    )
}

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error) {
        // console.log('getDerivedStateFromError', error)
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    componentDidCatch(error, info) {
        if (process.env.NODE_ENV !== 'production') return

        toastError(getErrorResponse('DEFAULT', 501))

        const { location, navigate, user, name } = this.props

        if (location.key === 'default') {
            window.location.replace('/')
        } else {
            navigate(-1)
        }

        socket.emit('frontError', {
            user: user?.id,
            error: { message: error?.message },
            data: { info: info?.componentStack, name: name },
        })
    }

    render() {
        // if (this.state.hasError) {
        //     // You can render any custom fallback UI
        //     if (this.props.fallback) {
        //         return this.props.fallback
        //     } else {
        //         return <DefaultError />
        //     }
        // }

        return this.props.children
    }
}

export default withRouter(ErrorBoundary)
