import _ from 'lodash'

import {
    differenceInMinutes,
    differenceInHours,
    differenceInDays,
    differenceInMonths,
    differenceInYears,
    getDate,
    getMonth,
    getYear,
    format,
    isToday,
} from 'date-fns'
import { th } from 'date-fns/locale'

import { MONTHS_TH, MONTHS_TH_SHORT } from '../constant'

export const getRange = (start, stop) => {
    return Array.from({ length: stop - start + 1 }, (_, i) => start + i)
}

export const getTimePass = time => {
    const now = new Date()

    const yearsDiff = differenceInYears(now, new Date(time))
    if (yearsDiff > 0) return `${yearsDiff} ปีที่แล้ว`

    const monthsDiff = differenceInMonths(now, new Date(time))
    if (monthsDiff > 0) return `${monthsDiff} เดือนที่แล้ว`

    const daysDiff = differenceInDays(now, new Date(time))
    if (daysDiff > 0) return `${daysDiff} วันที่แล้ว`

    const hoursDiff = differenceInHours(now, new Date(time))
    if (hoursDiff > 0) return `${hoursDiff} ชั่วโมงที่แล้ว`

    const minutesDiff = differenceInMinutes(now, new Date(time))
    if (minutesDiff > 0) return `${minutesDiff} นาทีที่แล้ว`

    return 'ตอนนี้'
}

export const formatDateTimeTH = dateTime => {
    if (!dateTime) return ''

    const newDate = new Date(dateTime)

    return `${getDate(newDate)} ${MONTHS_TH[getMonth(newDate)]} ${
        getYear(newDate) + 543
    } • ${format(newDate, 'HH:mm')}`
}

export const formatJobDateTimeTH = (date, time) => {
    if (!date || !time) return ''

    const newDate = new Date(`${date}T${time}`)

    return `${getDate(newDate)} ${MONTHS_TH[getMonth(newDate)]} ${
        getYear(newDate) + 543
    } • ${format(newDate, 'HH:mm')}`
}

export const formatDateTH = date => {
    if (!date) return ''
    const newDate = new Date(date)
    return `${getDate(newDate)} ${MONTHS_TH[getMonth(newDate)]} ${getYear(
        newDate
    )}`
}

export const formatDateTHShort = dateTime => {
    if (!dateTime) return ''

    const newDate = new Date(dateTime)

    return `${getDate(newDate)} ${MONTHS_TH_SHORT[getMonth(newDate)]} ${format(
        newDate,
        'yy'
    )}`
}

export const formatDateTimeTHShort = (date, time) => {
    if (!date || !time) return ''

    const newDate = new Date(`${date}T${time}`)

    return `${getDate(newDate)} ${MONTHS_TH_SHORT[getMonth(newDate)]} ${format(
        newDate,
        'yy'
    )} • ${format(newDate, 'HH:mm')}`
}

export const formatDateTimeEN = dateTime => {
    if (!dateTime) return ''

    const newDate = new Date(dateTime)

    return `${format(newDate, 'dd LLL yy')} • ${format(newDate, 'HH:mm')}`
}

export const formatDateTime = dateTime => {
    if (!dateTime) return ''

    const newDate = new Date(dateTime)

    return format(newDate, 'dd/MM/yyyy HH:mm')
}

export const formatJobDateTimeEN = (date, time) => {
    if (!date || !time) return ''

    const newDate = new Date(`${date}T${time}`)

    return `${format(newDate, 'dd LLL yy')} • ${format(newDate, 'HH:mm')}`
}

export const formatDateTimeConfirmCard = dateTime => {
    if (!dateTime) return ''

    const newDate = new Date(dateTime)

    return format(newDate, 'd/L/y HH:mm', { locale: th })
}

export const formatSlashNumberThaiDate = dateTime => {
    if (!dateTime) return ''

    const newDate = new Date(dateTime)

    return `${String(getDate(newDate)).padStart(2, '0')}/${String(
        getMonth(newDate) + 1
    ).padStart(2, '0')}/${getYear(newDate) + 543}`
}

export const formatSlashNumberThaiDateShort = dateTime => {
    if (!dateTime) return ''

    const newDate = new Date(dateTime)

    return `${String(getDate(newDate)).padStart(2, '0')}/${String(
        getMonth(newDate) + 1
    ).padStart(2, '0')}/${String(getYear(newDate) + 543).slice(-2)}`
}

export const formatSlashThaiDate = dateTime => {
    if (!dateTime) return ''

    const newDate = new Date(dateTime)

    return `${getDate(newDate)}/${MONTHS_TH_SHORT[getMonth(newDate)]}/${
        getYear(newDate) + 543
    }`
}

export const formatSlashThaiDateShort = dateTime => {
    if (!dateTime) return ''

    const newDate = new Date(dateTime)

    return `${getDate(newDate)}/${MONTHS_TH_SHORT[getMonth(newDate)]}/${String(
        getYear(newDate) + 543
    ).slice(-2)}`
}

export const formatThaiDatePicker = dateTime => {
    if (!dateTime) return ''

    const newDate = new Date(dateTime)

    return `${getDate(newDate)} ${MONTHS_TH_SHORT[getMonth(newDate)]} ${
        getYear(newDate) + 543
    }`
}

export const formatThaiDateStandardYearPicker = dateTime => {
    if (!dateTime) return ''

    const newDate = new Date(dateTime)

    const getFullDate =
        Number(getDate(newDate)) < 10
            ? '0' + getDate(newDate).toString()
            : getDate(newDate)

    return `${getFullDate} ${MONTHS_TH_SHORT[getMonth(newDate)]} ${getYear(
        newDate
    )}`
}

export const formatThaiDateShort = dateTime => {
    if (!dateTime) return ''

    const newDate = new Date(dateTime)

    return `${format(newDate, 'dd-MM')}-${String(getYear(newDate) + 543).slice(
        -2
    )}`
}

export const formatMessageData = dateTime => {
    if (!dateTime) return ''

    const newDate = new Date(dateTime)

    return format(newDate, isToday(newDate) ? 'HH:mm' : 'd/M/yy HH:mm')
}

export const isShowFullName = (name, isShow) => {
    if (isShow) return name

    return `${name.charAt(0)}*****${
        name.slice(-1) === '์' ? name.slice(-2) : name.slice(-1)
    }`
}

export const getDateStandart = dateTime => {
    const transFromGetDate = newDate => {
        const newGetDate = getDate(newDate)
        const dateLength = newGetDate.toString().length
        return dateLength > 1 ? newGetDate : `0${newGetDate}`
    }
    const transFromGetMonth = newDate => {
        const newGetMonth = getMonth(newDate) + 1
        const dateLength = newGetMonth.toString().length
        return dateLength > 1 ? newGetMonth : `0${newGetMonth}`
    }
    const newDate = new Date(dateTime)

    return `${transFromGetDate(newDate)}-${transFromGetMonth(newDate)}-${String(
        getYear(newDate) + 543
    )}`
}

export const getDateTimeStandart = dateTime => {
    if (dateTime) {
        const newDate = new Date(dateTime)
        return `${getDateStandart(dateTime)} ${format(newDate, 'HH:mm')}`
    }
    return '-'
}

export const getDateYearThaiTimeStandart = dateTime => {
    const transFromGetDate = newDate => {
        const newGetDate = getDate(newDate)
        const dateLength = newGetDate.toString().length
        return dateLength > 1 ? newGetDate : `0${newGetDate}`
    }
    const transFromGetMonth = newDate => {
        const newGetMonth = getMonth(newDate) + 1
        const dateLength = newGetMonth.toString().length
        return dateLength > 1 ? newGetMonth : `0${newGetMonth}`
    }

    if (dateTime) {
        const newDate = new Date(dateTime)
        return `${transFromGetDate(newDate)}-${transFromGetMonth(newDate)}-${
            getYear(newDate) + 543 - 2500
        } ${format(newDate, 'HH:mm')}`
    }
    return '-'
}

export const getDateYearThaiStandart = dateTime => {
    const transFromGetDate = newDate => {
        const newGetDate = getDate(newDate)
        const dateLength = newGetDate.toString().length
        return dateLength > 1 ? newGetDate : `0${newGetDate}`
    }
    const transFromGetMonth = newDate => {
        const newGetMonth = getMonth(newDate) + 1
        const dateLength = newGetMonth.toString().length
        return dateLength > 1 ? newGetMonth : `0${newGetMonth}`
    }

    if (dateTime) {
        const newDate = new Date(dateTime)
        return `${transFromGetDate(newDate)}-${transFromGetMonth(newDate)}-${
            getYear(newDate) + 543 - 2500
        }`
    }
    return '-'
}

export const getTimeStandart = dateTime => {
    if (dateTime) {
        const newDate = new Date(dateTime)
        return `${format(newDate, 'HH:mm')}`
    }
    return '-'
}

export const getGraphqlError = error => {
    return error?.graphQLErrors[0]?.extensions?.exception?.output?.payload
}

export const getGraphqlErrorMessage = error => {
    return error?.graphQLErrors[0]?.message
}

export const handleCalculatePackageTotal = packages => {
    let partTimeRemaining = 0
    let fullTimeRemaining = 0
    packages?.forEach(item => {
        if (item.partTimeRemaining === -1) partTimeRemaining = -1
        if (item.fullTimeRemaining === -1) fullTimeRemaining = -1

        if (partTimeRemaining !== -1)
            partTimeRemaining = partTimeRemaining + item.partTimeRemaining

        if (fullTimeRemaining !== -1)
            fullTimeRemaining = fullTimeRemaining + item.fullTimeRemaining
    })
    const result = [{ partTimeRemaining, fullTimeRemaining }]

    return result || []
}

export const sortProfessionsOne = professions => {
    const newProfessionsOne = Array(6)

    professions.forEach(item => {
        switch (item.title) {
            case 'งานคลินิกความงาม':
                newProfessionsOne[0] = item
                break
            case 'แพทย์':
                newProfessionsOne[1] = item
                break
            case 'การพยาบาล':
                newProfessionsOne[2] = item
                break
            case 'เภสัชกรรม':
                newProfessionsOne[3] = item
                break
            case 'ทันตกรรม':
                newProfessionsOne[4] = item
                break
            case 'กลุ่มอาชีพสาธารณสุข':
                newProfessionsOne[5] = item
                break
            default:
                newProfessionsOne.push(item)
        }
    })
    return newProfessionsOne.filter(i => i)
}

export const sortProfessionsTwo = professions => {
    if (
        !professions.some(
            item =>
                item.title === 'แพทย์คลินิกความงาม' ||
                item.title === 'แพทย์เวชปฏิบัติทั่วไป'
        )
    )
        return professions

    const arr1 = Array(2)
    const arr2 = []

    professions.forEach(item => {
        switch (item.title) {
            case 'แพทย์คลินิกความงาม':
                arr1[0] = item
                break
            case 'แพทย์เวชปฏิบัติทั่วไป':
                arr1[1] = item
                break
            default:
                arr2.push(item)
        }
    })

    return _.concat(
        arr1.filter(i => i),
        arr2
    )
}

export const sortTags = (professionOne, tags) => {
    if (professionOne !== 'แพทย์') return tags

    const arr1 = Array(2)
    const arr2 = []

    tags.forEach(item => {
        switch (item.title) {
            case 'แพทย์คลินิกความงาม':
                arr1[0] = item
                break
            case 'GP':
                arr1[1] = item
                break
            default:
                arr2.push(item)
        }
    })

    return _.concat(
        arr1.filter(i => i),
        arr2
    )
}

export const mergeArrays = (array1, array2, key = 'id') => {
    const merged = {}

    JSON.parse(JSON.stringify(array1)).forEach(obj => {
        merged[obj[key]] = obj
    })

    JSON.parse(JSON.stringify(array2)).forEach(obj => {
        if (merged[obj[key]]) {
            Object.assign(merged[obj[key]], obj)
        } else {
            merged[obj[key]] = obj
        }
    })

    const mergedArray = Object.values(merged)

    return mergedArray
}

export const applyButtonText = (data, user) => {
    if (
        data?.isWorking ||
        data?.status !== 'PUBLISH' ||
        data?.createdBy?.isDeleted ||
        !data?.createdBy ||
        data.isExpired
    )
        return 'ปิดรับสมัคร'

    if (['EMPLOYER', 'COMPANY'].includes(user?.role?.name))
        return 'ผู้ว่าจ้างไม่สามารถสมัครงานได้'

    if (data?.taken >= data?.number) return 'ปิดรับสมัคร'

    if (data?.isApplied) return 'รอติดต่อกลับ'

    return 'สมัครงานนี้'
}
