import React from 'react'
import { Badge, Box } from "native-base"

const CBadge = ({ badgeContent, hidden, children }) => {
    return (
        <Box position='relative' >
            {badgeContent > 0 && (
                <Badge
                    position='absolute'
                    top='-1px'
                    right={
                        badgeContent > 99
                            ? '-10px'
                            : badgeContent > 9
                                ? '-6px'
                                : '-4px'
                    }
                    bg='badge'
                    rounded='full'
                    zIndex={1}
                    variant='solid'
                    minWidth='18px'
                    height='18px'
                    alignSelf='flex-end'
                    borderColor='white'
                    borderWidth='1px'
                    padding='2px'
                    _text={{
                        fontSize: 12,
                    }}
                    display={hidden ? 'none' : ''}
                >
                    {badgeContent > 99
                        ? '99+'
                        : badgeContent}
                </Badge>
            )}
            {children}
        </Box>

    )
}

export default CBadge