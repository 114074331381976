import styled from 'styled-components'

import { commonColors } from '../../constant'

export const SContainer = styled.div`

// select options display
.rdt_TableCol input {
    display: none;
}
.rdt_TableCell input{
    display: none;

}
.checkbox .rdt_TableCol input {
    display: block !important;
}
.checkbox .rdt_TableCell input {
    display: block !important;
}
width: 100%;

input[type="checkbox"]{
     width: 18px;
     height: 18px;
}



.rdt_TableCol,
.rdt_TableCell {
    padding-left: 5px;
    padding-right: 5px;
}

.rdt_TableHead {
    .rdt_TableHeadRow{
        min-height: 45px ;
        border-radius: 8px;
        border-bottom-color : unset;
        border-bottom-style :unset;
    }
    > .rdt_TableHeadRow{
        background-color: ${commonColors.gray29};
    }
    .rdt_TableCol:first-child > div > div {
        padding-left: 30px;
    }
    .rdt_TableCol_Sortable > div  {
        font-size: 14px;
        font-weight: 600;    
    }
  
}

.rdt_TableBody{
    margin-top: 10px;
    border: 1px solid #e9e9e9;
    border-radius: 8px;
    .rdt_TableRow{
        cursor: pointer;
        :hover{
        background-color: ${commonColors.gray27} !important;
        }
        border-bottom-style: unset;
        border-bottom-width: unset;
        border-bottom-color: unset;
        min-height: 53px;
        :first-child{
            border-top-left-radius:8px;
            border-top-right-radius:8px;
        }
        :last-child{
            border-bottom-left-radius:8px;
            border-bottom-right-radius:8px;
        }
        .rdt_TableCell:first-child > div {
        padding-left: 30px;
        }
        .rdt_TableCell input[type="checkbox"]
        {
         width: 18px;
         height: 18px;
        }
    }
       
}


.rdt_Pagination{
    display: none;
}

 .rdt_TableCell:first-child:has(input){
    -webkit-flex: 0 0 36px;
    flex  :0 0 36px; 
    min-width :unset;
    padding-left: unset;
}
.rdt_TableCol:first-child:has(input){
    -webkit-flex: 0 0 36px;
    flex  :0 0 36px; 
    min-width :unset;
    padding-left: unset;
}
.in-line {
    & > div:first-child {
        /* table-layout: fixed; */
    }
    .rdt_TableBody{
        .rdt_TableRow:not(:last-child){
        position: relative;
        ::after{
            content: "";
            position: absolute;
            left: 1%;
            bottom: 0;
            height: 15px;
            width: 98%;
            border-bottom: 1.5px solid ${commonColors.gray28};
            pointer-events: none;
           }
        }
}
}

${({ colorRow }) => `
.color-row{
    .rdt_TableBody{
        border: unset;
        .rdt_TableRow{
            margin-bottom: 10px;
            background-color: ${colorRow};
            border-radius: 6px;
        }
    }
}`}

.dashboard-out-line{
    .rdt_TableHead {
        > .rdt_TableHeadRow{
        background-color: unset;
    }
 
    .cmiNPJ{
        font-weight:100;
    }
    .sc-ivTmOn:first-child .cmiNPJ{
        font-weight:500;
    }
    }
    .rdt_TableBody{
    border:unset;
    }
}
`

export const SFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 24px;

    .rows-per-page-table {
        width: min-content;
        max-width: 100%;
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-tiems: center;
        justify-content: flex-end;

        .rows-per-page-title {
            width: max-content;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        .rows-per-page-selected {
            width: 65px;
        }
    }
`
