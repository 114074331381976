import React, { useEffect } from 'react'
import { HStack, Text, Button, Box } from 'native-base'
import {
    CModal,
    FilledButton,
    LinearGradientOutlineButton,
} from '../../components'

export const CModalConfirm = ({
    showModal,
    header,
    children,
    isLoading,
    textCancel = 'ยกเลิก',
    textOK = 'บันทึก',
    cancelButtonMinWidth = '133px',
    onOk,
    okButtonMinWidth = '133px',
    onCancel,
    onCancelLinearGradientOutlineButton,
    maxWidth = '450px',
    isDisabledOk,
    bodyStyle,
    padding,
    isAdmin,
    propsAlignOk,
    propsAlignCancel,
}) => {
    useEffect(() => {
        if (showModal) {
            document.documentElement.style.overflow = 'hidden'
            document.body.scroll = 'no'
        } else {
            document.documentElement.style.overflow = 'auto'
            document.body.scroll = 'yes'
        }
    }, [showModal])

    return (
        <CModal
            showModal={showModal}
            maxWidth={maxWidth}
            children={children}
            header={header}
            footer={
                <HStack space={3} justifyContent='center' width='100%' p='25px'>
                    <HStack {...propsAlignCancel}>
                        {onCancel &&
                            (onCancelLinearGradientOutlineButton ? (
                                <LinearGradientOutlineButton
                                    minWidth='165px'
                                    height='43px'
                                    textStyle={{
                                        fontSize: '16px',
                                        fontWeight: '500',
                                    }}
                                    onPress={onCancel}
                                >
                                    {textCancel}
                                </LinearGradientOutlineButton>
                            ) : (
                                <Button
                                    variant='outline'
                                    borderWidth={2}
                                    borderRadius={10}
                                    size='sm'
                                    height='43px'
                                    minWidth={cancelButtonMinWidth}
                                    onPress={onCancel}
                                    isDisabled={isLoading}
                                >
                                    <Text
                                        fontSize='md'
                                        fontWeight='500'
                                        color='gray3'
                                        fontFamily={
                                            isAdmin ? 'Poppins' : 'Kanit'
                                        }
                                    >
                                        {textCancel}
                                    </Text>
                                </Button>
                            ))}
                    </HStack>
                    <Box {...propsAlignOk}>
                        {onOk && (
                            <FilledButton
                                minWidth={okButtonMinWidth}
                                height='43px'
                                borderRadius='9'
                                onPress={onOk}
                                isLoading={isLoading}
                                isDisabled={isDisabledOk}
                            >
                                <Text
                                    color='white'
                                    fontSize='md'
                                    fontWeight='500'
                                    fontFamily={isAdmin ? 'Poppins' : 'Kanit'}
                                >
                                    {textOK}
                                </Text>
                            </FilledButton>
                        )}
                    </Box>
                </HStack>
            }
            isLoading={isLoading}
            bodyStyle={bodyStyle}
            padding={padding}
        ></CModal>
    )
}
