import { useRef, useState, useCallback, useMemo, memo } from 'react'
import { Box, HStack, Image, Text, VStack } from 'native-base'
import { CModal } from '../Modal'
import { toastError } from '../../utils/toast'

import Cropper from 'react-easy-crop'
import getCroppedImg from './util'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'

export const UploadAvatar = memo(
    ({
        onFileChange,
        imageAvatar,
        imageAvatarCrop,
        w = 100,
        h = 100,
        isUpload = true,
        isVerify = false,
        inputRef,
        maxSize = { size: 2097152, intSize: 2 },
    }) => {
        const defaultInputRef = useRef(null)

        const [showModal, setShowModal] = useState(false)
        const [crop, setCrop] = useState({ x: 0, y: 0 })
        const [rotation, setRotation] = useState(0)
        const [zoom, setZoom] = useState(1)
        const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

        const imageAvatarMemo = useMemo(() => {
            return imageAvatar ? imageAvatar : '/images/professions/health.png'
        }, [imageAvatar])

        const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
            setCroppedAreaPixels(croppedAreaPixels)
        }, [])

        const handleClickUpload = () => {
            defaultInputRef.current.click()
        }

        const onChange = async event => {
            if (event.target.files.length === 0) return

            const newErrors = []
            let newFile = event?.target?.files[0]
            if (newFile.size > maxSize.size) {
                newErrors.push({
                    property: 'file',
                    message: `ไฟล์มีขนาดเกิน ${maxSize.intSize} MB กรุณาอัปโหลดใหม่อีกครั้ง`,
                })
                toastError(
                    `ไฟล์มีขนาดเกิน ${maxSize.intSize} MB กรุณาอัปโหลดใหม่อีกครั้ง`
                )
            }
            if (
                !['image/png', 'image/gif', 'image/jpeg'].includes(newFile.type)
            ) {
                newErrors.push({
                    property: 'files',
                    message: 'ประเภทไฟล์ไม่ถูกต้อง กรุณาอัปโหลดใหม่อีกครั้ง',
                })
                toastError(`ประเภทไฟล์ไม่ถูกต้อง กรุณาอัปโหลดใหม่อีกครั้ง`)
            }
            if (newErrors.length) return

            onFileChange(event)
            handleVisibleModal()
        }

        const showCroppedImage = useCallback(async () => {
            try {
                const croppedImage = await getCroppedImg(
                    imageAvatarCrop,
                    croppedAreaPixels,
                    rotation
                )
                onFileChange({ target: { files: [croppedImage] } }, true)
                onClose()
            } catch (e) {
                console.error(e)
            }
        }, [croppedAreaPixels, rotation])

        const onClose = () => {
            onFileChange(undefined)
            setCrop({ x: 0, y: 0 })
            handleVisibleModal()
        }

        const handleVisibleModal = () => {
            setShowModal(!showModal)
        }

        return (
            <Box position='relative'>
                <CModal
                    showModal={showModal}
                    header='Crop Image'
                    onOk={showCroppedImage}
                    onCancel={onClose}
                    maxWidth={{ base: '350px', sm: '780px' }}
                >
                    <HStack>
                        <div style={{ width: 300, height: 300 }}>
                            <Cropper
                                image={imageAvatarCrop}
                                crop={crop}
                                rotation={rotation}
                                zoom={zoom}
                                aspect={1 / 1}
                                onCropChange={setCrop}
                                onRotationChange={setRotation}
                                onCropComplete={onCropComplete}
                                cropShape='round'
                            />
                        </div>
                    </HStack>
                    <HStack justifyContent='center'>
                        <VStack mt={2} width='50%'>
                            <Text>Zoom</Text>
                            <Box>
                                <Slider
                                    value={zoom}
                                    min={1}
                                    max={3}
                                    step={0.1}
                                    aria-labelledby='Zoom'
                                    onChange={zoom => setZoom(zoom)}
                                />
                            </Box>
                        </VStack>
                    </HStack>
                </CModal>

                <input
                    ref={inputRef ? inputRef : defaultInputRef}
                    id='upload-file'
                    name='filefield'
                    type='file'
                    accept='.jpeg,.jpg,.png'
                    onChange={onChange}
                    style={{ display: 'none' }}
                />
                <Image
                    source={{
                        uri: imageAvatarMemo,
                    }}
                    alt='avatar'
                    size='xl'
                    resizeMode='cover'
                    borderRadius={'50%'}
                    borderColor='white'
                    borderWidth={2}
                    w={w}
                    h={h}
                    bg='white'
                />

                {isVerify && (
                    <Image
                        source={{
                            uri: '/images/verify.svg',
                        }}
                        alt='verify'
                        size='lg'
                        maxW={5}
                        maxH={5}
                        position='absolute'
                        top={2}
                        right={0}
                    />
                )}
                {isUpload && !inputRef && (
                    <Box
                        onClick={handleClickUpload}
                        position='absolute'
                        bottom={0}
                        right={0}
                    >
                        <Box
                            borderColor='white'
                            borderWidth={3}
                            padding={1}
                            borderRadius='50%'
                            backgroundColor={'#F2F2F2'}
                        >
                            <Image
                                source={{
                                    uri: '/images/camera.svg',
                                }}
                                alt='camera'
                                size='lg'
                                maxW={3}
                                maxH={3}
                                style={{ cursor: 'pointer' }}
                            />
                        </Box>
                    </Box>
                )}
            </Box>
        )
    }
)
