import React, { Fragment, useState, useEffect, memo } from 'react'
import {
    Box,
    HStack,
    VStack,
    Pressable,
    Image,
    Text,
    Button,
    useMediaQuery,
} from 'native-base'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import CBadge from '../Badge'

import ConnectLineOAButton from '../LineOA'

import {
    dashboardRoute,
    staffMainMenus,
    adminMainMenus,
    staffRoutes,
    adminRoutes,
} from './route'
import { useDispatch } from 'react-redux'
import { logout } from '../../store/user'
import socket from '../../utils/socket'
import { fetchMessagesCount } from '../../store/messagesCount'

const SideMenu = () => {
    const user = useSelector(state => state.user)
    const [isSmallScreen] = useMediaQuery({
        maxWidth: 820,
    })
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const messagesCount = useSelector(state => state.messagesCount)

    const active = location.pathname
    const [mainMenuActive, setMainMenuActive] = useState(null)
    const [parentMenuActive, setParentMenuActive] = useState([])
    const [collapseActive, setCollapseActive] = useState(true)

    useEffect(() => {
        if (!_.isEmpty(user)) {
            dispatch(fetchMessagesCount(user))
        }
    }, [user])

    useEffect(() => {
        socket.off('messageNotification')
        socket.on('messageNotification', () => {
            dispatch(fetchMessagesCount(user))
        })
    }, [])

    const handleMainMenuPermission = () => {
        if (user?.role?.name === 'ADMIN') return adminMainMenus
        if (user?.role?.name === 'STAFF') return staffMainMenus
        return []
    }

    const handleRoutePermission = () => {
        if (user?.role?.name === 'ADMIN') return adminRoutes
        if (user?.role?.name === 'STAFF') return staffRoutes
        return []
    }

    const mainMenu = handleMainMenuPermission()
    const routes = handleRoutePermission()

    useEffect(() => {
        handleRoute()
    }, [active])

    const handleRoute = async () => {
        findMainMenuActiveKey()
    }

    const findMainMenuActiveKey = () => {
        mainMenu.forEach(item => {
            if (location.pathname.includes(item.pathname)) {
                setMainMenuActive(item.pathname)
            }
        })
        findParentMenuActiveKey(location.pathname, parentMenuActive)
    }

    const findParentMenuActiveKey = (pathname, oldParent = []) => {
        routes.forEach(item => {
            if (item.children) {
                const finded = item.children.find(
                    child => child.pathname === pathname
                )
                if (finded) {
                    setParentMenuActive([...oldParent, item.pathname])
                }
            }
        })
    }

    const filterRoute = () => {
        const newRoute = routes.filter(item =>
            item.pathname.includes(mainMenuActive)
        )
        return newRoute
    }

    const handleClickMainMenu = value => {
        setMainMenuActive(value)
        if (!isSmallScreen) {
            handleCollapse(mainMenuActive === value ? !collapseActive : true)
        }
    }

    const handleActiveParentMenu = newParentKey => {
        let result = []

        if (parentMenuActive.includes(newParentKey)) {
            result = parentMenuActive.filter(key => key !== newParentKey)
        } else {
            result = [...parentMenuActive, newParentKey]
        }
        setParentMenuActive([...result])
    }

    const handleClickMenu = pathname => {
        clearMenuActive(pathname)
        clearParentMenuActive(pathname)
        navigate(pathname)
    }

    const clearMenuActive = pathname => {
        if (dashboardRoute.pathname === pathname) {
            setMainMenuActive(null)
        }
    }

    const clearParentMenuActive = pathname => {
        let resultFiltered = []
        routes.forEach(item => {
            if (item.children) {
                const finded = item.children.find(
                    child => child.pathname === pathname
                )
                if (finded) {
                    resultFiltered = parentMenuActive.filter(
                        key => key === item.pathname
                    )
                    setParentMenuActive(resultFiltered)
                }
            }
        })
        if (resultFiltered.length === 0) {
            setParentMenuActive([])
        }
    }

    const handleCollapse = collapseControl => {
        setCollapseActive(collapseControl)
    }

    const handleLogout = () => {
        dispatch(logout())
    }

    return (
        <HStack>
            <VStack pt='142px' bg='gray27' minH={'100vh'} w='50px'>
                {mainMenu.map((item, ind) => {
                    return (
                        <Pressable
                            mb={5}
                            key={ind}
                            onPress={() => handleClickMainMenu(item.pathname)}
                            style={{
                                cursor: 'pointer',
                            }}
                            _hover={{ opacity: 0.7 }}
                            borderLeftColor='primaryBlue'
                            position='relative'
                        >
                            <HStack justifyContent={'center'}>
                                <CBadge
                                    badgeContent={messagesCount}
                                    hidden={item.pathname !== '/messages'}
                                >
                                    {mainMenuActive === item.pathname ? (
                                        <item.icon fill='primaryBlue' />
                                    ) : (
                                        <item.icon />
                                    )}
                                </CBadge>
                            </HStack>
                            <Box
                                borderLeftWidth={
                                    mainMenuActive === item.pathname
                                        ? '3px'
                                        : ''
                                }
                                borderColor='primaryBlue'
                                position='absolute'
                                height='100%'
                            >
                                {' '}
                            </Box>
                        </Pressable>
                    )
                })}
            </VStack>
            <Box w='250px' pl={1} pr={5} display={collapseActive || 'none'}>
                <VStack h='100px' justifyContent='center'>
                    <HStack px={5}>
                        <VStack pl={3}>
                            <Text color='heavyBlack' fontSize={'sm'}>
                                {user?.firstName} {user?.lastName}
                            </Text>

                            <Text
                                fontSize={'10px'}
                                fontWeight={100}
                                color='textDetail'
                                textTransform='capitalize'
                            >
                                {user?.role?.name?.toLowerCase()}
                            </Text>
                            <Button
                                h='10px'
                                w='60px'
                                mt={'3px'}
                                bg='gray23'
                                onPress={handleLogout}
                                _hover={{ bg: 'gray13' }}
                            >
                                <Text color='heavyBlack' fontSize={'xs'}>
                                    Logout
                                </Text>
                            </Button>
                        </VStack>
                    </HStack>
                </VStack>
                <HStack>
                    <VStack w={'100%'}>
                        <Pressable
                            py={3}
                            px={5}
                            bg={active === dashboardRoute.pathname && 'gray27'}
                            borderRadius={5}
                            onPress={() =>
                                handleClickMenu(dashboardRoute.pathname)
                            }
                        >
                            <Text
                                fontSize='sm'
                                color='heavyBlack'
                                fontWeight={500}
                            >
                                {dashboardRoute.name}
                            </Text>
                        </Pressable>
                        {filterRoute().map((item, ind) => {
                            if (item.children)
                                return (
                                    <Fragment key={ind}>
                                        <Pressable
                                            py={3}
                                            px={5}
                                            onPress={() =>
                                                handleActiveParentMenu(
                                                    item.pathname
                                                )
                                            }
                                        >
                                            <HStack justifyContent='space-between'>
                                                <Text
                                                    fontSize='sm'
                                                    color='heavyBlack'
                                                    fontWeight={500}
                                                >
                                                    {item.name}
                                                </Text>
                                                {parentMenuActive.includes(
                                                    item.pathname
                                                ) ? (
                                                    <img
                                                        src={
                                                            '/images/arrow-up.svg'
                                                        }
                                                        alt='arrow-up'
                                                    />
                                                ) : (
                                                    <img
                                                        src={
                                                            '/images/arrow-down.svg'
                                                        }
                                                        alt='arrow-down'
                                                    />
                                                )}
                                            </HStack>
                                        </Pressable>
                                        <Box
                                            display={
                                                parentMenuActive.includes(
                                                    item.pathname
                                                )
                                                    ? 'block'
                                                    : 'none'
                                            }
                                            pl={4}
                                        >
                                            {item.children.map(
                                                (child, childInd) => {
                                                    return (
                                                        <Pressable
                                                            py={3}
                                                            px={7}
                                                            key={childInd}
                                                            bg={
                                                                active.includes(
                                                                    child.pathname
                                                                ) && 'gray27'
                                                            }
                                                            borderRadius={5}
                                                            onPress={() =>
                                                                handleClickMenu(
                                                                    child.pathname
                                                                )
                                                            }
                                                        >
                                                            <HStack>
                                                                <Text
                                                                    color={
                                                                        active.includes(
                                                                            child.pathname
                                                                        )
                                                                            ? 'heavyBlack'
                                                                            : 'textDetail'
                                                                    }
                                                                    fontSize='sm'
                                                                    fontWeight={
                                                                        active.includes(
                                                                            child.pathname
                                                                        )
                                                                            ? 500
                                                                            : 400
                                                                    }
                                                                >
                                                                    {child.name}
                                                                </Text>
                                                            </HStack>
                                                        </Pressable>
                                                    )
                                                }
                                            )}
                                        </Box>
                                    </Fragment>
                                )
                            else
                                return (
                                    <Pressable
                                        key={ind}
                                        py={3}
                                        px={5}
                                        bg={
                                            active === item.pathname && 'gray27'
                                        }
                                        borderRadius={5}
                                        onPress={() =>
                                            handleClickMenu(item.pathname)
                                        }
                                    >
                                        <Text
                                            fontSize='sm'
                                            color='heavyBlack'
                                            fontWeight={500}
                                        >
                                            {item.name}
                                        </Text>
                                    </Pressable>
                                )
                        })}
                    </VStack>
                </HStack>
            </Box>
        </HStack>
    )
}

export default memo(SideMenu)
