import { useEffect, useState } from 'react'
import _ from 'lodash'

import { Text, VStack, Box, useMediaQuery } from 'native-base'

import { CModalConfirm } from '../index'

import {
    StyledTh,
    StyledTd,
    StyledUl,
    StyledOl,
    StyledLi,
    StyledLiNumber,
} from './style'

const PolicyModal = ({
    isOpen,
    onCancel,
    onOk,
    header,
    textCancel = 'ยกเลิก',
    textOK = 'ตกลง',
}) => {
    const [isSmallScreen] = useMediaQuery({
        maxWidth: 765,
    })

    const [isDisabledOk, setIsDisabledOk] = useState(true)

    useEffect(() => {
        if (isOpen) {
            setIsDisabledOk(true)
        }
    }, [isOpen])

    return (
        <CModalConfirm
            showModal={isOpen}
            header={
                <Text fontSize='2xl' fontWeight={500}>
                    {header}
                </Text>
            }
            textCancel={textCancel}
            textOK={textOK}
            onCancel={onCancel}
            onOk={onOk}
            isDisabledOk={isDisabledOk}
            bodyStyle={{ overflowY: 'auto', maxHeight: '520px' }}
            maxWidth={isSmallScreen ? '100%' : '733px'}
            children={
                <Box>
                    <VStack space={5}>
                        <VStack space={3} alignItems='center'>
                            <Text fontSize='2xl' fontWeight={500}>
                                บริษัท เมดวิลล์ จำกัด
                            </Text>
                            <Text fontSize='2xl' fontWeight={500}>
                                นโยบายคุ้มครองข้อมูลส่วนบุคคล
                            </Text>
                        </VStack>
                        <VStack space={3}>
                            <Text
                                fontSize='lg'
                                fontWeight={500}
                                color='heavyBlack'
                            >
                                1. บทนำ
                            </Text>
                            <VStack space={3}>
                                <Text fontSize='sm' color='heavyBlack'>
                                    บริษัท เมดวิลล์ จำกัด (&quot;บริษัท&quot;
                                    หรือ &quot; เรา&quot;)
                                    ได้จัดทำนโยบายคุ้มครองข้อมูลส่วนบุคคลฉบับนี้
                                    (&quot;นโยบาย&quot;)
                                    เพื่อสะท้อนถึงความมุ่งมั่นของเราในการปกป้องคุ้มครองข้อมูลส่วนบุคคลของบุคคลที่บริษัททำการประมวลผล
                                </Text>
                                <Text
                                    fontSize='lg'
                                    fontWeight={500}
                                    color='heavyBlack'
                                >
                                    ขอบเขตการใช้บังคับ
                                </Text>
                                <Text fontSize='sm' color='heavyBlack'>
                                    นโยบายฉบับนี้จัดทำขึ้นเพื่ออธิบายวิธีการ
                                    ขอบเขตและวัตถุประสงค์ของการรวบรวม
                                    การใช้และการเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้บริการ
                                    (ซึ่งรวมถึง ผู้หางาน ผู้ประกอบการ
                                    และลูกค้าอื่นๆ) ลูกจ้าง
                                    ผู้เยี่ยมชมเว็บไซต์หรือแอพพลิเคชั่นของเรา
                                    ซัพพลายเออร์ ผู้ให้บริการ คู่ค้าทางธุรกิจ
                                    ผู้ถือหุ้น ตัวแทนของบุคคลดังกล่าว
                                    และบุคคล/องค์กรอื่น ๆ
                                    ที่บริษัทได้รับมาในระหว่างการดำเนินธุรกิจและจากเว็บไซต์หรือแอพพลิเคชั่นของบริษัท
                                    รวมถึงทางอิเล็กทรอนิกส์
                                    นโยบายฉบับนี้ใช้บังคับกับการประมวลผลข้อมูลส่วนบุคคลใน/โดยบริษัท
                                    และบริษัทจะต้องกำกับดูแลให้การประมวลผลข้อมูลส่วนบุคคลที่ดำเนินการโดยบุคคลอื่น
                                    (ผู้ประมวลผลข้อมูลส่วนบุคคล)
                                    ที่กระทำในนามของบริษัทให้ดำเนินการโดยเป็นไปตามนโยบายฉบับนี้และตามกฎหมายด้วย
                                </Text>
                                <Text fontSize='sm' color='heavyBlack'>
                                    นโยบายฉบับนี้ใช้บังคับกับการประมวลผลข้อมูลส่วนบุคคลและข้อมูลส่วนบุคคลที่ละเอียดอ่อนที่กระทำโดยบริษัทในฐานะผู้ควบคุมข้อมูลส่วนบุคคลหรือในฐานะผู้ประมวลผลข้อมูลส่วนบุคคลด้วยแล้วแต่กรณี
                                </Text>
                                <Text fontSize='sm' color='heavyBlack'>
                                    อย่างไรก็ตาม
                                    เนื่องจากบริษัทเป็นวิสาหกิจขนาดย่อมหรือขนาดกลางตามกฎหมายว่าด้วยการส่งเสริมวิสาหกิจขนาดกลางละขนาดย่อม
                                    จึงได้รับการยกเว้นหน้าที่การบันทึกรายการของผู้ควบคุมข้อมูลส่วนบุคคลตามประกาศของคณะกรรมการคุ้มครองข้อมูลส่วนบุคคลที่จัดตั้งโดยพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
                                    พ.ศ.2562
                                    (&quot;คณะกรรมการคุ้มครองข้อมูลส่วนบุคคล&quot;)
                                </Text>
                            </VStack>
                        </VStack>

                        <Text fontSize='lg' fontWeight={500} color='heavyBlack'>
                            2. คำและคำจำกัดความ
                        </Text>

                        <table>
                            <thead>
                                <tr>
                                    <StyledTh style={{ width: '40px' }}>
                                        <Text color='heavyBlack'>เลขที่</Text>
                                    </StyledTh>
                                    <StyledTh>
                                        <Text color='heavyBlack'>คำสำคัญ</Text>
                                    </StyledTh>
                                    <StyledTh>
                                        <Text color='heavyBlack'>คำนิยาม</Text>
                                    </StyledTh>
                                </tr>
                            </thead>

                            <tbody>
                                {[
                                    {
                                        word: 'ข้อมูลส่วนบุคคล',
                                        definition:
                                            'ข้อมูลที่เกี่ยวกับบุคคลใด ๆ หรือที่สามารถใช้ระบุบุคคลใดๆได้โดยตรงหรือโดยอ้อม โดยเฉพาะอย่างยิ่งโดยการอ้างอิงถึงข้อมูลบ่งชี้ (เช่น ชื่อ หมายเลขประจำตัว ข้อมูลสถานที่ ข้อมูลบ่งชี้ออนไลน์หรือปัจจัยหนึ่งหรือหลายปัจจัยที่เกี่ยวข้องกับอัตลักษณ์ทางร่างกาย ทางสรีรวิทยา ทางพันธุกรรม ทางจิตใจความรู้สึก ทางเศรษฐกิจ ทางวัฒนธรรมหรือทางสังคม) ซึ่งถูกประมวลผลโดยบริษัทหรือโดยผู้ประมวลผลข้อมูลส่วนบุคคลที่กระทำในนามของบริษัท',
                                    },
                                    {
                                        word: 'ข้อมูลส่วนบุคคลที่ละเอียดอ่อน',
                                        definition:
                                            'ข้อมูลส่วนบุคคลที่ละเอียดอ่อนเป็นข้อมูลส่วนบุคคลประเภทหนึ่ง ข้อมูลส่วนบุคคลที่ละเอียดอ่อนเป็นข้อมูลส่วนบุคคลใด ๆ ที่เกี่ยวข้องกับเชื้อชาติ ต้นกำเนิด ความคิดเห็นทางการเมือง ศาสนา ความเชื่อทางศาสนาหรือทางปรัชญา พฤติกรรมทางเพศ ประวัติอาชญากรรม ข้อมูลสุขภาพหรือสภาพความพิการ ข้อมูลสหภาพการค้า ข้อมูลพันธุกรรม ข้อมูลไบโอเมตริกซ์และข้อมูลใด ๆ ในลักษณะเดียวกัน ตามที่กำหนดโดยคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล',
                                    },
                                    {
                                        word: 'การประมวลผล',
                                        definition:
                                            'กิจกรรมใด ๆ ที่มีการดำเนินการหรือลำดับการดำเนินการใด ๆ ที่ดำเนินการกับข้อมูลส่วนบุคคลไม่ว่าจะด้วยวิธีการอัตโนมัติหรือไม่ก็ตาม เช่น การรวบรวม การบันทึก การจัดเก็บ การเปลี่ยนแปลง การอ่านพิจารณา การใช้ การดึง การเปิดเผยโดยการส่ง การแชร์ การทำให้เข้าถึงได้ การจัดเรียงหรือการรวม การกำหนดข้อจำกัด การส่ง การเผยแพร่ การลบหรือการทำลายโดยวิธีการใด ๆ',
                                    },
                                    {
                                        word: 'เจ้าของข้อมูลส่วนบุคคลส่วนบุคคล',
                                        definition:
                                            'บุคคลธรรมดาที่เกี่ยวข้องกับข้อมูลส่วนบุคคลโดยบุคคลดังกล่าวได้รับการระบุตัวตนหรือสามารถระบุตัวตนได้ ทั้งนี้เจ้าของข้อมูลส่วนบุคคลไม่รวมถึงผู้เสียชีวิตและข้อมูลของนิติบุคคล (ที่จัดตั้งขึ้นภายใต้กฎหมาย เช่น บริษัท มูลนิธิ สมาคมหรือองค์กรอื่น ๆ)',
                                    },
                                    {
                                        word: 'ผู้ควบคุมข้อมูลส่วนบุคคล',
                                        definition:
                                            'บุคคลหรือนิติบุคคลที่มีอำนาจและหน้าที่ในการตัดสินใจเกี่ยวกับการรวบรวม การใช้หรือการเปิดเผยข้อมูลส่วนบุคคล',
                                    },
                                    {
                                        word: 'ผู้ประมวลผลข้อมูล',
                                        definition:
                                            'บุคคลหรือนิติบุคคลที่ประมวลผลข้อมูลส่วนบุคคลตามคำสั่งที่ได้รับจากหรือในนามของบริษัทที่ทำหน้าที่เป็นผู้ควบคุมข้อมูลส่วนบุคคล ',
                                    },
                                    {
                                        word: 'เจ้าหน้าที่คุ้มครองข้อมูล',
                                        definition:
                                            'เจ้าหน้าที่คุ้มครองข้อมูลที่ได้รับการแต่งตั้งจาก นางสาวปฐมา กิตติสุวรรณ์ และ นายภาคิน วชิรพรพงศา กรรมการบริษท โดยมีหน้าที่เกี่ยวกับข้อมูลส่วนบุคคลตามที่กฎหมายกำหนดรวมถึงหน้าที่ต่อไปนี้',
                                        definitionList: [
                                            'ให้คำแนะนำกับองค์กรรวมถึงผู้ควบคุมข้อมูลส่วนบุคคลหรือผู้ประมวลผลข้อมูลส่วนบุคคลส่วนบุคคลรวมถึงพนักงานหรือผู้ให้บริการของผู้ควบคุมข้อมูลส่วนบุคคลหรือผู้ประมวลผลข้อมูลส่วนบุคคลส่วนบุคคลและกำกับดูแลว่าปฏิบัติตามพระราชบัญญัติฯ',
                                            'เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลจะต้องได้รับแจ้งเมื่อเกิดการละเมิดข้อมูลส่วนบุคคลหรือการรั่วไหลของข้อมูลและจะต้องสอบสวนปัญหาทันทีพร้อมกับฟังก์ชั่นที่เกี่ยวข้องของบริษัท',
                                            'รวบรวมหลักฐานทั้งหมดที่เกี่ยวข้องกับการละเมิดข้อมูลส่วนบุคคลหรือการรั่วไหลของข้อมูลเพื่อให้องค์กรสามารถรายงานต่อเจ้าหน้าที่ได้',
                                            'ทบทวนผลการดำเนินงานของบริษัทหรือผู้ประมวลผลข้อมูลส่วนบุคคลส่วนบุคคล รวมถึงพนักงานหรือผู้ให้บริการของบริษัทหรือผู้ประมวลผลข้อมูลส่วนบุคคลส่วนบุคคลเกี่ยวกับการประมวลผลข้อมูลส่วนบุคคล',
                                            'ประสานงานกับคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล ในประเด็นที่เกี่ยวข้องกับการประมวลผลข้อมูลส่วนบุคคลที่ดำเนินการโดยบริษัท หรือผู้ประมวลผลข้อมูลส่วนบุคคลส่วนบุคคลรวมถึงการประมวลผลโดยพนักงานหรือผู้ให้บริการของบริษัทหรือของผู้ประมวลผลข้อมูลส่วนบุคคลส่วนบุคคล',
                                            'เก็บรักษาความลับของข้อมูลส่วนบุคคลที่ทราบหรือได้มาระหว่างการปฏิบัติหน้าที่',
                                            'หน้าที่อื่นใดตามที่พระราชบัญญัติฯ กำหนด',
                                        ],
                                    },
                                    {
                                        word: 'การละเมิดข้อมูลส่วนบุคคล',
                                        definition:
                                            'การประมวลผลข้อมูลส่วนบุคคลใด ๆ ที่ผิดกฎหมายไม่ว่าจะเป็นโดยอุบัติเหตุหรือโดยเจตนารวมถึงการสูญเสียข้อมูลส่วนบุคคลหรือการเข้าถึงข้อมูลโดยไม่ชอบด้วยกฎหมาย การทำลายโดยมิชอบด้วยกฎหมาย การดัดแปลงหรือการประมวลผลข้อมูลส่วนบุคคลโดยไม่ได้รับอนุญาต',
                                    },
                                ].map((item, index) => (
                                    <tr
                                        key={`wordsAndDefinitions-${index + 1}`}
                                    >
                                        <StyledTd>
                                            <Text color='heavyBlack'>
                                                {index + 1}.
                                            </Text>
                                        </StyledTd>
                                        <StyledTd>
                                            <Text color='heavyBlack'>
                                                {item.word}
                                            </Text>
                                        </StyledTd>
                                        <StyledTd>
                                            <Text color='heavyBlack'>
                                                {item.definition}
                                            </Text>

                                            {!_.isEmpty(
                                                item.definitionList
                                            ) && (
                                                <ul>
                                                    {item.definitionList.map(
                                                        (
                                                            detail,
                                                            detailIndex
                                                        ) => (
                                                            <StyledLi
                                                                key={`detailIndex-${detailIndex}`}
                                                                style={{
                                                                    listStyleType:
                                                                        'decimal',
                                                                }}
                                                            >
                                                                <Text
                                                                    fontSize='sm'
                                                                    color='heavyBlack'
                                                                >
                                                                    {detail}
                                                                </Text>
                                                            </StyledLi>
                                                        )
                                                    )}
                                                </ul>
                                            )}
                                        </StyledTd>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <VStack space={3}>
                            <Text
                                fontSize='lg'
                                fontWeight={500}
                                color='heavyBlack'
                            >
                                3. หลักการของเรา
                            </Text>
                            <Text fontSize='md' color='heavyBlack'>
                                3.1
                                บริษัทจะใช้มาตรการที่เหมาะสมเพื่อกำกับดูแลว่าการประมวลผลข้อมูลส่วนบุคคลโดยบริษัทและโดยผู้ประมวลผลข้อมูลส่วนบุคคลให้เป็นไปตามพระราชบัญญัติฯ
                                และกฎหมาย
                                โดยเฉพาะอย่างยิ่งบริษัทจะต้องใช้ความพยายามและดำเนินการตามขั้นตอนที่เหมาะสมในการปฏิบัติตามหลักการดังต่อไปนี้
                            </Text>

                            <ul>
                                {[
                                    'หลักการความชอบกฎหมาย หลักความเป็นธรรมและหลักความโปร่งใส: การประมวลผลข้อมูลส่วนบุคคลจะต้องชอบด้วยกฎหมายเป็นธรรมและโปร่งใส บริษัทจะต้องแจ้งเจ้าของข้อมูลส่วนบุคคลเกี่ยวกับวิธีที่บริษัทประมวลผลข้อมูลส่วนบุคคลให้เจ้าของข้อมูลส่วนบุคคลทราบตามพระราชบัญญัติฯเพื่อความโปร่งใสในการประมวลผล',
                                    'วัตถุประสงค์: ข้อมูลส่วนบุคคลจะต้องถูกรวบรวมเพื่อวัตถุประสงค์ที่ระบุไว้ชัดแจ้งและโดยชอบด้วยกฎหมายและบริษัทจะไม่ประมวลผลในลักษณะที่ไม่สอดคล้องกับวัตถุประสงค์เหล่านั้น',
                                    'การประมวลผลข้อมูลส่วนบุคคลให้น้อยที่สุด:  บริษัทจะประมวลผลข้อมูลส่วนบุคคลเฉพาะข้อมูลส่วนบุคคลที่เกี่ยวข้องกับวัตถุประสงค์ในการเก็บรวบรวมและเฉพาะข้อมูลส่วนบุคคลที่เหมาะสมจะนำมาประมวลเพื่อให้บรรลุวัตถุประสงค์ในการดำเนินการ และการประมวลผลต่าง ๆ จะต้องดำเนินการในลักษณะเท่าที่จำเป็นจริง ๆ  ตามวัตถุประสงค์ในการประมวลผลนั้นเท่านั้น',
                                    'ความถูกต้องของข้อมูลส่วนบุคคล: ข้อมูลส่วนบุคคลจะต้องมีความเหมาะสมและในกรณีที่จำเป็น บริษัทจะต้องปรับปรุงข้อมูลส่วนบุคคลให้เป็นปัจจุบัน และบริษัทจะต้องดำเนินการทุกขั้นตอนที่เหมาะสมเพื่อให้แน่ใจว่าข้อมูลส่วนบุคคลใด ๆ ก็ตามที่ไม่ถูกต้องเมื่อพิจารณาจากวัตถุประสงค์ของการประมวลผลข้อมูลนั้น ๆ ข้อมูลนั้นจะถูกลบหรือแก้ไขโดยไม่ชักช้า',
                                    'การเก็บรักษา: ข้อมูลส่วนบุคคลจะต้องถูกเก็บไว้ในรูปแบบที่มีการระบุตัวตนไม่เกินระยะเวลาที่จำเป็นเพื่อวัตถุประสงค์ในการประมวลผลข้อมูลส่วนบุคคล',
                                    'ความมั่งคงปลอดภัยและความลับ: ข้อมูลส่วนบุคคลจะต้องถูกประมวลผลในลักษณะที่มีความมั่นคงปลอดภัยที่เหมาะสมรวมถึงการป้องกันการประมวลผลและการสูญเสียข้อมูลส่วนบุคคลโดยไม่ได้รับอนุญาตอย่างถูกต้องหรือโดยไม่ชอบด้วยกฎหมาย;',
                                    'การถ่ายโอน/การแชร์/การเปิดเผยข้อมูลส่วนบุคคล: การถ่ายโอน การแชร์และการเปิดเผยทั้งหมดรวมถึงการถ่ายโอนข้ามประเทศหรือการแชร์ข้อมูลส่วนบุคคลจะต้องกระทำโดยอ้างอิงฐานทางกฎหมายได้ ทั้งนี้โดยเป็นไปตามที่กฎหมายกำหนด',
                                ].map((item, index) => (
                                    <StyledLi
                                        key={`principle-${index}`}
                                        style={{
                                            listStyleType: 'decimal',
                                        }}
                                    >
                                        <Text fontSize='sm' color='heavyBlack'>
                                            {item}
                                        </Text>
                                    </StyledLi>
                                ))}
                            </ul>

                            <Text fontSize='md' color='heavyBlack'>
                                3.2 ข้อมูลส่วนบุคคลที่ละเอียดอ่อน
                            </Text>

                            <ul>
                                {[
                                    {
                                        detail: 'บริษัทจะต้องกำกับดูแลว่าข้อมูลส่วนบุคคลนั้นได้รับการประมวลผลโดยชอบตามฐานทางกฎหมายที่ถูกต้อง ฐานทางกฎหมายสำหรับการประมวลผลมีดังนี้',
                                        detailList: [
                                            'บริษัทได้รับความยินยอมอย่างชัดแจ้งจากเจ้าของข้อมูลส่วนบุคคล',
                                            'มีความจำเป็นเพื่อการปฏิบัติตามสัญญาที่เจ้าของข้อมูลส่วนบุคคลเป็นคู่สัญญาหรือเพื่อดำเนินการตามคำร้องขอของเจ้าของข้อมูลส่วนบุคคลก่อนทำสัญญา',
                                            'มีความจำเป็นในการป้องกันหรือปราบปรามอันตรายต่อชีวิต ร่างกายหรือสุขภาพของบุคคล',
                                            'มีความจำเป็นต่อผลประโยชน์ที่ชอบด้วยกฎหมายของบริษัทซึ่งไม่ได้สำคัญน้อยกว่าสิทธิขั้นพื้นฐานของเจ้าของข้อมูลส่วนบุคคล หรือเพื่อปฏิบัติตามกฎหมายหรือเพื่อสาธารณะประโยชน์อื่น ๆ ตามที่กฎหมายอนุญาต',
                                            'เหตุผลที่ถูกกฎหมายอื่น ๆ เช่น เมื่อมีความจำเป็นที่จะต้องดำเนินงานที่เราได้รับมอบหมาย (ถ้ามี) จากหน่วยงานรัฐหรือเพื่อจัดทำเอกสารเชิงประวัติศาสตร์/จดหมายเหตุเพื่อสาธารณะประโยชน์หรือเพื่อวัตถุประสงค์ที่เกี่ยวข้องกับการวิจัยหรือสถิติที่มีการปกป้องสิทธิและเสรีภาพของเจ้าของข้อมูลส่วนบุคคลและเป็นไปตามกฎหมาย และหรือ',
                                            'มีความจำเป็นเพื่อการปฏิบัติตามกฎหมาย',
                                        ],
                                    },
                                    {
                                        detail: 'เราอาจประมวลผลข้อมูลส่วนบุคคลที่ละเอียดอ่อนเฉพาะเมื่อเรามีฐานทางกฎหมายอย่างใดอย่างหนึ่งดังต่อไปนี้',
                                        detailList: [
                                            'ได้รับความยินยอมอย่างชัดแจ้งจากเจ้าของข้อมูลส่วนบุคคล',
                                            'การประมวลผลมีความจำเป็นเพื่อการป้องกันหรือปราบปรามอันตรายต่อชีวิต ร่างกายหรือสุขภาพของบุคคลหากเจ้าของข้อมูลส่วนบุคคลไม่สามารถให้ความยินยอมด้วยเหตุผลใดก็ตาม',
                                            'ข้อมูลส่วนบุคคลที่ละเอียดอ่อนได้รับการเปิดเผยต่อสาธารณชนโดยความยินยอมอย่างชัดแจ้งจากเจ้าของข้อมูลส่วนบุคคลนั้นเอง',
                                            'มีความจำเป็นต้องมีการประมวลผลเพื่อก่อตั้งสิทธิเรียกร้องตามกฎหมายการปฏิบัติตามหรือการใข้สิทธิเรียกร้องตามกฎหมายหรือการยกขึ้นต่อสู้สิทธิเรียกร้องตามกฎหมาย ',
                                            'มีความจำเป็นต้องมีการประมวลผลเพื่อการปฏิบัติตามกฎหมายเพื่อวัตถุประสงค์ตามที่กฎหมายกำหนดไว้ และ/หรือ',
                                        ],
                                        afterDetail:
                                            'เราจะรวบรวมข้อมูลส่วนบุคคลโดยตรงจากเจ้าของข้อมูลส่วนบุคคล หากเราต้องได้รับข้อมูลจากแหล่งอื่น บริษัทจะต้องกำกับดูแลให้บริษัทต้องได้รับความยินยอมจากเจ้าของข้อมูลส่วนบุคคลและจะต้องแจ้งให้เจ้าของข้อมูลส่วนบุคคลทราบตามที่กฎหมายกำหนดไว้ต่อไป',
                                    },
                                    {
                                        detail: 'บริษัทจะต้องกำกับให้มีข้อตกลงที่เหมาะสมกับผู้ประมวลผลข้อมูลส่วนบุคคลเพื่อกำหนดมาตรการป้องกันและคุ้มครองข้อมูลส่วนบุคคลที่เพียงพอและเพื่อให้มีการปฏิบัติตามสิทธิของเจ้าของข้อมูลส่วนบุคคล บริษัทจะต้องมีมาตรการที่เหมาะสมเพื่อกำกับดูแลและเฝ้าติดตามการประมวลผลข้อมูลส่วนบุคคลของผู้ประมวลผลข้อมูลส่วนบุคคลดังกล่าวด้วย',
                                    },
                                ].map((item, index) => (
                                    <StyledLi
                                        key={`process-${index}`}
                                        style={{
                                            listStyleType: 'decimal',
                                        }}
                                    >
                                        <Text fontSize='sm' color='heavyBlack'>
                                            {item.detail}
                                        </Text>

                                        {!_.isEmpty(item.detailList) && (
                                            <StyledUl
                                                style={{ marginTop: '15px' }}
                                            >
                                                {item.detailList.map(
                                                    (list, listIndex) => (
                                                        <StyledLi
                                                            key={`process-list-${listIndex}`}
                                                            style={{
                                                                listStyleType:
                                                                    'disc',
                                                            }}
                                                        >
                                                            <Text
                                                                fontSize='sm'
                                                                color='heavyBlack'
                                                            >
                                                                {list}
                                                            </Text>
                                                        </StyledLi>
                                                    )
                                                )}
                                            </StyledUl>
                                        )}

                                        {item.afterDetail && (
                                            <Box mt={3} pl={6}>
                                                <Text
                                                    fontSize='sm'
                                                    color='heavyBlack'
                                                >
                                                    {item.afterDetail}
                                                </Text>
                                            </Box>
                                        )}
                                    </StyledLi>
                                ))}
                            </ul>
                        </VStack>
                        <VStack space={3}>
                            <Text
                                fontSize='lg'
                                fontWeight={500}
                                color='heavyBlack'
                            >
                                4. หน้าที่ที่ต้องปฏิบัติ
                            </Text>

                            <StyledOl>
                                {[
                                    'บริษัทจะต้องปฏิบัติตามนโยบายภายในที่เกี่ยวกับข้อมูลส่วนบุคคล และปฏิบัติตามพระราชบัญญัติฯ และกฎหมายที่บังคับใช้อย่างเคร่งครัด',
                                    'บริษัทจะต้องกำกับให้มีการรายงานการละเมิดต่อ เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลทันทีและจะต้องกำกับให้มีการแจ้งการละเมิดต่อหน่วยงานที่เกี่ยวข้องและ/หรือเจ้าของข้อมูลส่วนบุคคลตามที่กฎหมายกำหนด',
                                    'บริษัทจะต้องเคารพสิทธิของเจ้าของข้อมูลส่วนบุคคล',
                                    'บริษัทจะต้องจัดเก็บข้อมูลส่วนบุคคลอย่างเหมาะสมตามที่พระราชบัญญัติฯ และกฎหมายกำหนด',
                                    'บริษัทจะต้องไม่แชร์ข้อมูลส่วนบุคคลกับใครก็ตามที่ไม่มีสัญญากับบริษัท ทั้งนี้ บริษัทอาจแชร์หรือถ่ายโอนข้อมูลส่วนบุคคลไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่นหรือผู้ประมวลผลข้อมูลส่วนบุคคลเป็นครั้งคราว บริษัทจะต้องกำกับดูแลว่ามีบริษัทได้ทำสัญญาที่เหมาะสมกับบุคคลดังกล่าว',
                                    'บริษัทจะต้องปกป้องความลับและความปลอดภัยของข้อมูลส่วนบุคคล',
                                    'บริษัทจะต้องขอความยินยอมเพื่อใช้คุกกี้ที่เหมาะสมทุกครั้งก่อนที่จะดำเนินการเกี่ยวกับคุกกี้และจะต้องเปิดเผยนโยบายการใช้คุกกี้ของบริษัทต่อสาธารณะและง่ายต่อการเข้าถึง อย่างน้อยที่สุดจะต้องเผยแพร่นโยบายการใช้คุกกี้บนเว็บไซต์หรือแอพพลิเคชั่นของบริษัท',
                                ].map((item, index) => (
                                    <StyledLiNumber
                                        number='4'
                                        key={`mightShare-${index}`}
                                    >
                                        <Text fontSize='sm' color='heavyBlack'>
                                            {item}
                                        </Text>
                                    </StyledLiNumber>
                                ))}
                            </StyledOl>
                        </VStack>
                        <VStack space={3}>
                            <Text
                                fontSize='lg'
                                fontWeight={500}
                                color='heavyBlack'
                            >
                                5.
                                สิทธิของเจ้าของข้อมูลส่วนบุคคลภายใต้พระราชบัญญัติฯ
                            </Text>
                            <Text fontSize='md' color='heavyBlack'>
                                บริษัทเคารพสิทธิของเจ้าของข้อมูลส่วนบุคคลตามที่บัญญัติโดยพระราชบัญญัติฯ
                                และกฎหมายต่างๆ
                                บริษัทจะต้องกำกับดูแลเพื่อให้เจ้าของข้อมูลส่วนบุคคลสามารถใช้สิทธิดังต่อไปนี้ได้อย่างมีประสิทธิภาพตามกฎหมาย
                            </Text>

                            <StyledOl>
                                {[
                                    'สิทธิในการเข้าถึงข้อมูลส่วนบุคคล',
                                    'สิทธิในการแก้ไขข้อมูลส่วนบุคคล',
                                    'สิทธิในการลบข้อมูลส่วนบุคคล (สิทธิที่จะถูกลืม)',
                                    'สิทธิในการจำกัดการประมวลผลข้อมูลส่วนบุคคล',
                                    'สิทธิในการคัดค้านการประมวลผลข้อมูลส่วนบุคคล',
                                    'สิทธิในการโอนย้ายข้อมูลส่วนบุคคล',
                                    'สิทธิในการถอนความยินยอมสำหรับการประมวลผลข้อมูลส่วนบุคคล',
                                    'สิทธิในการยื่นเรื่องร้องเรียนต่อบริษัทหรือหน่วยงานที่เกี่ยวข้อง และ',
                                    'สิทธิอื่นใดของเจ้าของข้อมูลส่วนบุคคลตามที่กฎหมายกำหนด ',
                                ].map((item, index) => (
                                    <StyledLiNumber
                                        number='5'
                                        key={`mightShare-${index}`}
                                    >
                                        <Text fontSize='sm' color='heavyBlack'>
                                            {item}
                                        </Text>
                                    </StyledLiNumber>
                                ))}
                            </StyledOl>
                        </VStack>
                        <VStack space={3}>
                            <Text
                                fontSize='lg'
                                fontWeight={500}
                                color='heavyBlack'
                            >
                                6. การสื่อสารทางอิเล็กทรอนิกส์และข้อความ
                            </Text>
                            <Text fontSize='md' color='heavyBlack'>
                                การสื่อสารใด ๆ ที่เราสื่อสารกับผู้ใช้บริการ
                                (ซึ่งรวมถึง ผู้หางาน ผู้ประกอบการ
                                และลูกค้าอื่นๆ) ลูกจ้าง ซัพพลายเออร์
                                ผู้ให้บริการ ผู้ถือหุ้นและบุคคลอื่น ๆ
                                โดยทางอิเล็กทรอนิกส์และข้อความจะต้องเป็นไปตามหลักการดังต่อไปนี้
                            </Text>

                            <StyledOl>
                                {[
                                    'บุคคลที่เลือกที่จะไม่อยู่ในรายชื่อผู้รับข้อความของเราจะต้องไม่ได้รับข้อความ',
                                    'หากบุคคลใดแจ้งบริษัทว่าตนเองไม่ต้องการได้รับการติดต่อทางอิเล็กทรอนิกส์หรือข้อความอีกต่อไป ชื่อและรายละเอียดการติดต่อของบุคคลดังกล่าวจะต้องถูกลบออกจากรายชื่อ และต้องบันทึกว่าบุคคลดังกล่าวไม่ได้ให้ความยินยอมเพื่อรับข้อความ',
                                    'จะต้องมีตัวเลือกเพื่อยกเลิกการสื่อสารที่คล้ายกัน (เช่น มีการเพิ่มตัวเลือกในการยกเลิกการสมัครที่ด้านล่างของข้อความทุกครั้งที่มีการส่งข้อความ)',
                                ].map((item, index) => (
                                    <StyledLiNumber
                                        number='6'
                                        key={`mightShare-${index}`}
                                    >
                                        <Text fontSize='sm' color='heavyBlack'>
                                            {item}
                                        </Text>
                                    </StyledLiNumber>
                                ))}
                            </StyledOl>
                        </VStack>
                        <VStack space={3}>
                            <Text
                                fontSize='lg'
                                fontWeight={500}
                                color='heavyBlack'
                            >
                                7. การแชร์หรือถ่ายโอนไปยังประเทศอื่น ๆ
                            </Text>
                            <Text fontSize='md' color='heavyBlack'>
                                บริษัทอาจแชร์หรือถ่ายโอนข้อมูลส่วนบุคคลไปยังบุคคลหรือองค์กรในประเทศอื่น
                                ๆ ภายใต้กฎหมายโดยมีเงื่อนไขว่า
                                การแชร์หรือถ่ายโอนดังกล่าวต้องมีฐานทางกฎหมายอย่างน้อยหนึ่งข้อในข้อ
                                3.2
                                และประเทศที่ผู้รับข้อมูลส่วนบุคคลตั้งอยู่ต้องมีมาตรการคุ้มครองข้อมูลส่วนบุคคลอย่างเพียงพอตามที่คณะกรรมการคุ้มครองข้อมูลส่วนบุคคลกำหนดหรือได้วินิจฉัยไว้
                                อย่างไรก็ดี
                                ข้อกำหนดเกี่ยวกับมาตรฐานการคุ้มครองข้อมูลส่วนบุคคลที่พอเพียงนี้ไม่ใช้บังคับในกรณีที่
                            </Text>

                            <StyledOl>
                                {[
                                    'เจ้าของข้อมูลส่วนบุคคลได้ให้ความยินยอมอย่างชัดแจ้งและชัดเจนให้สามารถถ่ายโอน/แชร์ข้อมูลส่วนบุคคลได้โดยเจ้าของข้อมูลส่วนบุคคลได้รับแจ้งข้อมูลอย่างครบถ้วนแล้วถึงรายละเอียดของความไม่เพียงพอของการปกป้องคุ้มครองข้อมูลส่วนบุคคลของประเทศอื่นนั้น',
                                    'การถ่ายโอนหรือแชร์มีความจำเป็นเพื่อปฏิบัติตามหน้าที่ทางกฎหมาย',
                                    'มีความจำเป็นเพื่อการปฏิบัติตามสัญญาที่เจ้าของข้อมูลส่วนบุคคลเป็นคู่สัญญาหรือเพื่อดำเนินการตามคำขอของเจ้าของข้อมูลส่วนบุคคลก่อนที่จะมีการทำสัญญา',
                                    'มีความจำเป็นสำหรับการปฏิบัติตามสัญญาระหว่างบริษัทกับบุคคลหรือนิติบุคคลอื่นเพื่อผลประโยชน์ของเจ้าของข้อมูลส่วนบุคคล',
                                    'มีความจำเป็นในการป้องกันหรือระงับอันตรายต่อชีวิต ร่างกายหรือสุขภาพของบุคคล และเจ้าของข้อมูลส่วนบุคคลไม่สามารถให้ความยินยอมในเวลาดังกล่าวได้',
                                    'มีความจำเป็นเพื่อดำเนินกิจกรรมที่เกี่ยวข้องกับสาธารณะประโยชน์ที่สำคัญอย่างน้อยหนึ่งอย่างตามที่กฎหมายอนุญาต',
                                    'เมื่อกฎหมายได้บัญญัติให้ต้องมีการถ่ายโอนหรือการแชร์หรือเป็นกรณีที่กฎหมายอนุญาตให้สามารถดำเนินการดังกล่าวได้ ',
                                ].map((item, index) => (
                                    <StyledLiNumber
                                        number='7'
                                        key={`mightShare-${index}`}
                                    >
                                        <Text fontSize='sm' color='heavyBlack'>
                                            {item}
                                        </Text>
                                    </StyledLiNumber>
                                ))}
                            </StyledOl>
                        </VStack>

                        <VStack space={3}>
                            <Text
                                fontSize='lg'
                                fontWeight={500}
                                color='heavyBlack'
                            >
                                8. การจัดการการละเมิด
                            </Text>

                            <Text fontSize='md' color='heavyBlack'>
                                บริษัทจะต้องจัดให้มีมาตรการรักษาความปลอดภัยเพื่อบ่งชี้และตรวจจับเหตุการณ์ที่อาจทำให้เกิดการละเมิดข้อมูลส่วนบุคคล
                                บริษัทจะต้องทบทวนมาตรการที่ใช้ตรวจจับเหตุการณ์ดังกล่าวอย่างสม่ำเสมอ
                            </Text>
                            <Text fontSize='md' color='heavyBlack'>
                                เมื่อบริษัทจะต้องดำเนินการกับการละเมิดข้อมูลส่วนบุคคล
                                บริษัทและพนักงานทุกคนจะต้องมุ่งเน้นให้ความคุ้มครองแก่บุคคลและข้อมูลส่วนบุคคลของบุคคลเหล่านั้น
                                รวมทั้งจะต้องมุ่งลดการสูญเสียและลดผลกระทบที่จะเกิดขึ้นกับข้อมูลส่วนบุคคลเหล่านั้น
                            </Text>
                            <Text fontSize='md' color='heavyBlack'>
                                หากมีความเสี่ยงที่การละเมิดข้อมูลส่วนบุคคลอาจส่งผลกระทบต่อสิทธิและเสรีภาพของบุคคล
                                บริษัทจะต้องเร่งรายงานการละเมิดดังกล่าวต่อสำนักงานคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล
                                ภายใน 72
                                ชั่วโมงนับจากเวลาที่บริษัทตระหนักถึงการละเมิดดังกล่าว
                                หากมีความเสี่ยงสูงที่การละเมิดอาจก่อให้เกิดผลกระทบต่อสิทธิและเสรีภาพของบุคคล
                                บริษัทจะต้องแจ้งแผนการแก้ไขเยียวยาให้เจ้าของข้อมูลส่วนบุคคลทราบภายในระยะเวลาตามที่กฎหมายกำหนดไว้ด้วย
                            </Text>
                            <Text fontSize='md' color='heavyBlack'>
                                บริษัทจะต้องกำกับดูแลว่าพนักงานทุกคนเข้าใจถึงภาระหน้าที่ของบริษัทภายใต้กฎหมายและบริษัทจะต้องดำเนินการให้มีการตรวจพบและรายงานการละเมิดได้อย่างเหมาะสม
                                บริษัทจะต้องสอบสวนการละเมิดและดำเนินการแก้ไขหากจำเป็นและเก็บรักษาบันทึกการละเมิดไว้ต่อไป
                            </Text>
                        </VStack>
                        <VStack space={3}>
                            <Text
                                fontSize='lg'
                                fontWeight={500}
                                color='heavyBlack'
                            >
                                9. การทบทวนนโยบาย
                            </Text>

                            <Text fontSize='md' color='heavyBlack'>
                                เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลจะต้องพิจารณาเพื่อเสนอทบทวนนโยบายฉบับนี้
                                ตามความจำเป็นและเหมาะสาม
                            </Text>
                        </VStack>
                        <VStack space={3}>
                            <Text
                                fontSize='lg'
                                fontWeight={500}
                                color='heavyBlack'
                            >
                                10. ข้อมูลการติดต่อ
                            </Text>
                            <Text fontSize='sm' color='heavyBlack'>
                                หากต้องการข้อมูลเพิ่มเติมหรือความช่วยเหลือเกี่ยวกับการรวบรวม
                                การใช้ การเปิดเผย
                                การส่งต่อหรือการประมวลผลข้อมูลส่วนบุคคลใด ๆ
                                โปรดติดต่อ เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล
                                แอดมิน บริษัท เมดวิลล์ จำกัด หรือทางอีเมลที่
                                info@medimatch.app หรือทางไลน์แอดที่ @medimatch
                            </Text>
                        </VStack>
                    </VStack>
                </Box>
            }
        />
    )
}

export default PolicyModal
