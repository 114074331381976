import styled from 'styled-components'

import { commonColors } from '../../constant'

export const StyledLinearGradientTextContainer = styled.div`
    ${({ borderRadius, isDisabled, onlyShow, isHovered }) => `
    width: 100%;
    height: 100%;
    background: white;
    border-radius: ${borderRadius ? borderRadius : '10px'};

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background: ${isHovered ? commonColors.greenBlueLinear : 'white'};
    }


    & span {
        background:  ${
            isDisabled
                ? commonColors.greenBlueLinearDisabled
                : commonColors.greenBlueLinear
        };
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    &:hover span {
        background: ${isDisabled ? commonColors.greenBlueLinear : 'white'};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
    `}
`

export const StyledSelectedIndicator = styled.div`
    ${({ isActive }) => `
        background: ${isActive ? commonColors.greenBlueLinear : 'transparent'};

        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;

        width: 5px;

        border-radius: 0px 5px 5px 0px;
    `}
`

export const StyledLinearGradientOutlineButton2 = styled.div`
    ${({ width, height, minWidth, isDisabled }) => `
    
    width: ${width ? width : 'auto'};
    height: ${height ? height : '48px'};
    min-width: ${minWidth ? minWidth : 'none'};

    &:active {
        filter: ${isDisabled ? 'brightness(100%)' : 'brightness(80%)'} ;
    }
    `}
`
