import { useTheme } from 'native-base'

import { Text, Box } from 'native-base'

import { StyledLinearGradientTextContainer } from './style'

export const LinearGradientText = ({
    children,
    containerStyle,
    color,
    textAlign,
    ...rest
}) => {
    const { colors } = useTheme()

    return (
        <StyledLinearGradientTextContainer
            textAlign={textAlign}
            containerStyle={containerStyle}
            color={color ? colors[color] : colors.greenYellowBlueLinear}
        >
            <Text {...rest}>{children}</Text>
        </StyledLinearGradientTextContainer>
    )
}

export const LinearGradient = ({
    children,
    containerStyle,
    color,
    textAlign,
    ...rest
}) => {
    const { colors } = useTheme()

    return (
        <StyledLinearGradientTextContainer
            textAlign={textAlign}
            containerStyle={containerStyle}
            color={color ? colors[color] : colors.greenYellowBlueLinear}
        >
            <Box>{children}</Box>
        </StyledLinearGradientTextContainer>
    )
}

export const Span = ({
    color,
    fontSize,
    fontFamily,
    fontWeight,
    style,
    children,
    ...rest
}) => {
    const { colors, fontSizes } = useTheme()
    return (
        <span
            style={{
                color: colors[color],
                fontSize: fontSizes[fontSize],
                fontFamily: fontFamily,
                fontWeight: fontWeight,
                ...style,
            }}
            {...rest}
        >
            {children}
        </span>
    )
}
