import styled from 'styled-components'
import { ToastContainer } from 'react-toastify'

export const StyledToastContainer = styled(ToastContainer)`
    &&&.Toastify__toast-container {
        --toastify-toast-width: auto;
    }
    .Toastify__toast {
    }
    .Toastify__toast-body {
        .Toastify__toast-content {
            width: 100%;
            font-size: 16px;
        }
        @media only screen and (max-width: 820px) {
        .Toastify__toast-content {
            font-size: 14px;
        }
        }
    }
    .Toastify__progress-bar {
    }
`
