import { Center, Spinner, Box, useTheme } from 'native-base'

export const Progress = ({ containerStyle, zIndex = 1000, ...rest }) => {
    return (
        <Center
            minHeight='377px'
            position='absolute'
            top='0'
            left='0'
            right='0'
            bottom='0'
            zIndex={zIndex}
            style={{
                backgroundColor: 'rgba(255, 255, 255, 0.4)',
                WebkitBackdropFilter: 'blur(5px)',
                backdropFilter: 'blur(5px)',
                ...containerStyle,
            }}
        >
            <Spinner size='lg' {...rest} />
        </Center>
    )
}

export const ProgressBar = ({ value }) => {
    const { colors } = useTheme()

    return (
        <Box
            flexGrow='1'
            height='10px'
            bg='gray19'
            borderRadius='full'
            overflow='hidden'
        >
            <Box
                height='10px'
                borderRadius='full'
                width={`${value || 0}%`}
                style={{ background: colors.greenBlueLinear }}
            />
        </Box>
    )
}
