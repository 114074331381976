import { lazy, useEffect } from 'react'
import {
    Routes,
    Route,
    Outlet,
    useNavigate,
    useLocation,
} from 'react-router-dom'

import { Box } from 'native-base'

const AdminLogin = lazy(() => import('../containers/Admin/Login'))
const Forgot = lazy(() => import('../containers/Forgot'))
const Reset = lazy(() => import('../containers/Reset'))
const SetPassword = lazy(() => import('../containers/SetPassword'))

const GrayLaout = () => {
    return (
        <Box bg='gray15'>
            <Outlet />
        </Box>
    )
}

export const Redirect = () => {
    const navigate = useNavigate()

    useEffect(() => {
        navigate('/admin', true)
    }, [])

    return <Box></Box>
}

const PublicRoute = () => {
    return (
        <Routes>
            <Route element={<GrayLaout />}>
                <Route path='/admin' element={<AdminLogin />} />
                <Route path='/admin/forgot' element={<Forgot />} />
                <Route path='/admin/reset' element={<Reset />} />
                <Route path='/admin/set-password' element={<SetPassword />} />
            </Route>
            <Route path='*' element={<Redirect />} />
        </Routes>
    )
}

export default PublicRoute
