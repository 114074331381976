import { createSlice } from '@reduxjs/toolkit'

const initialState = false

const isHeaderExpanded = createSlice({
    name: 'messagesCount',
    initialState: initialState,
    reducers: {
        setIsHeaderExpanded(state, action) {
            return action.payload
        },
    },
})

export const { setIsHeaderExpanded } = isHeaderExpanded.actions

export default isHeaderExpanded.reducer
