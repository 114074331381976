import { useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import Switch from 'react-switch'
import { add, isFuture, isAfter, isEqual, isValid } from 'date-fns'

import { Box, HStack, Text, VStack, useTheme } from 'native-base'

import { FilledButton, LinearGradientOutlineButton, DateTimePicker } from '..'

import { formatDateTime, getGraphqlError } from '../../utils'
import { toastError, toastSuccess } from '../../utils/toast'
import { getErrorResponse, getSuccessResponse } from '../../utils/responseText'

export const SponsoredCard = ({ data, refetch, isDisabled, mutation }) => {
    const { colors } = useTheme()

    const [now] = useState(new Date())

    const [isDirty, setIsDirty] = useState(false)
    const [errors, setErrors] = useState([])
    const [inputValue, setInputValue] = useState({
        isSponsor: false,
        start: null,
        end: null,
    })

    const handleChange = (name, value) => {
        setIsDirty(true)
        setInputValue({ ...inputValue, [name]: value })

        setErrors(
            errors.filter(
                error => error.property !== 'start' && error.property !== 'end'
            )
        )
    }

    const onCancel = () => {
        try {
            setInputValue({
                isSponsor: data?.isSponsor || false,
                start: data?.sponsoredStart
                    ? new Date(data?.sponsoredStart)
                    : null,
                end: data?.sponsoredEnd ? new Date(data?.sponsoredEnd) : null,
            })

            setIsDirty(false)
        } catch (error) {
            console.log('onCancel', error)
        }
    }

    useEffect(() => {
        if (!data) return
        onCancel()
    }, [data])

    /* ---------- update Sponsor ---------- */
    const [updateSponsor, { loading: updateSponsorLoading }] = useMutation(
        mutation,
        {
            onCompleted: res => {
                setIsDirty(false)
                toastSuccess(getSuccessResponse('DEFAULT', 500))

                if (refetch) refetch()
            },
            onError: err => {
                const error = getGraphqlError(err)
                if (error) {
                    toastError(getErrorResponse('DEFAULT', 500))
                }
            },
        }
    )

    const handleSubmit = () => {
        const { isSponsor, start, end } = inputValue

        const newErrors = []

        if (isSponsor === true) {
            if (start === null) {
                newErrors.push({
                    property: `start`,
                    message: 'Please Provide start',
                })
            }
            if (end === null) {
                newErrors.push({
                    property: `end`,
                    message: 'Please Provide end',
                })
            }

            if (!isFuture(start)) {
                newErrors.push({
                    property: `start`,
                    message: 'Start must be in the future',
                })
            }
            if (!isFuture(end)) {
                newErrors.push({
                    property: `end`,
                    message: 'End must be in the future',
                })
            }
        }

        if (isValid(start) && isValid(end)) {
            if (isEqual(start, end) || isAfter(start, end)) {
                newErrors.push({
                    property: `end`,
                    message: 'End must be after start',
                })
            }
        }

        if (newErrors.length) return setErrors(newErrors)

        updateSponsor({
            variables: {
                id: data.id,
                isSponsor,
                sponsoredStart: start,
                sponsoredEnd: end,
            },
        })
    }

    return (
        <Box bg='white' borderRadius={8} px={7} py={'30px'} maxW='500px'>
            <Text fontWeight={600} fontSize='2xl' color='heavyBlack'>
                Sponsor Setting
            </Text>
            <VStack space={5}>
                <DateTimePicker
                    isRequired
                    placeholder={formatDateTime(now)}
                    name='start'
                    label='Start'
                    minDate={now}
                    onChange={handleChange}
                    value={inputValue.start}
                    error={
                        errors.find(value => value.property === 'start')
                            ?.message
                    }
                    isDisabled={!data || updateSponsorLoading || isDisabled}
                    formatFn={formatDateTime}
                />
                <DateTimePicker
                    isRequired
                    placeholder={formatDateTime(add(now, { days: 1 }))}
                    name='end'
                    label='End'
                    minDate={now}
                    onChange={handleChange}
                    value={inputValue.end}
                    error={
                        errors.find(value => value.property === 'end')?.message
                    }
                    isDisabled={!data || updateSponsorLoading || isDisabled}
                    formatFn={formatDateTime}
                />
                <VStack>
                    <Text color='heavyBlack' fontSize='md' mb={1}>
                        Public
                    </Text>
                    <Switch
                        onChange={checked => handleChange('isSponsor', checked)}
                        checked={inputValue.isSponsor}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        offColor={colors.gray19}
                        onColor={colors.primaryBlue}
                        handleDiameter={16}
                        height={20}
                        width={38}
                        disabled={!data || updateSponsorLoading || isDisabled}
                    />
                </VStack>
                <HStack alignItems='center' justifyContent='flex-end' space={5}>
                    <LinearGradientOutlineButton
                        width='155px'
                        height='48px'
                        onPress={onCancel}
                        isDisabled={
                            !data ||
                            !isDirty ||
                            updateSponsorLoading ||
                            isDisabled
                        }
                    >
                        Cancel
                    </LinearGradientOutlineButton>
                    <FilledButton
                        width='155px'
                        height='48px'
                        borderRadius='12'
                        onPress={handleSubmit}
                        isDisabled={!data || !isDirty || isDisabled}
                        isLoading={updateSponsorLoading}
                    >
                        <Text fontSize='md' color='white'>
                            Save
                        </Text>
                    </FilledButton>
                </HStack>
            </VStack>
        </Box>
    )
}
