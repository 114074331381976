import { useState, Fragment, useCallback } from 'react'
import _ from 'lodash'
import { HStack, VStack, Pressable, useMediaQuery } from 'native-base'
import { StarFullIcon, StarEmptyIcon } from '../index'

export const SelectStar = ({
    value,
    onChange,
    isDisabled,
    starSize = 25,
    showOnly,
}) => {
    const [isSmallScreen] = useMediaQuery({
        maxWidth: 820,
    })
    const [isHoveredContainer, setIsHoveredContainer] = useState(false)
    const onContainerHover = useCallback(
        _.debounce(value => {
            if (isDisabled) return
            setIsHoveredContainer(value)
        }, 50),
        []
    )

    const [hoverValue, setHoverValue] = useState(0)
    const onHoverStarDebounce = useCallback(
        _.debounce(value => {
            setHoverValue(value)
        }, 50),
        []
    )
    return (
        <div
            onMouseEnter={() =>
                !isDisabled && !isSmallScreen ? onContainerHover(true) : ''
            }
            onMouseLeave={() =>
                !isDisabled && !isSmallScreen ? onContainerHover(false) : ''
            }
        >
            <VStack>
                <HStack alignItems='center' space='2'>
                    <HStack alignItems='center' space='1'>
                        {Array.from(Array(5).keys()).map(item => (
                            <Pressable
                                onPress={() => onChange(item + 1)}
                                key={`start-${item}`}
                                onHoverOut={() => onHoverStarDebounce(0)}
                                isDisabled={isDisabled}
                                style={{
                                    cursor: showOnly
                                        ? ''
                                        : isDisabled
                                        ? 'not-allowed'
                                        : 'pointer',
                                }}
                            >
                                {({ isHovered }) => {
                                    if (isHovered) {
                                        onHoverStarDebounce(item + 1)
                                    }

                                    return (
                                        <Fragment>
                                            {(!isHoveredContainer &&
                                                value >= item + 1) ||
                                            hoverValue >= item + 1 ? (
                                                <StarFullIcon size={starSize} />
                                            ) : (
                                                <StarEmptyIcon
                                                    size={starSize}
                                                />
                                            )}
                                        </Fragment>
                                    )
                                }}
                            </Pressable>
                        ))}
                    </HStack>
                </HStack>
            </VStack>
        </div>
    )
}
