import { io } from 'socket.io-client'

const socket = io(process.env.REACT_APP_CMS_API_HOST, {
    path: '/socket.io/',
    withCredentials: true,
    extraHeaders: {
        'my-custom-header': 'abcd',
    },
})

export default socket
