import { Box, VStack, HStack, Text, Center, IconButton } from 'native-base'

import { format } from 'date-fns'

import { CrossIcon } from '../index'

const CVImage = '/images/cv_image1.svg'

export const Card = ({ children, shadow, style, ...rest }) => {
    return (
        <Box
            bg='white'
            borderRadius='18'
            padding='5'
            width='100%'
            style={{
                boxShadow: shadow
                    ? '8.66007px 3.60836px 48px rgba(0, 0, 0, 0.06)'
                    : 'none',
                ...style,
            }}
            {...rest}
        >
            {children}
        </Box>
    )
}

export const FeatureCVCard = () => {
    return (
        <Card maxWidth='313px' px='0'>
            <VStack space='3'>
                <Text fontSize='md' color='heavyBlack' px='5'>
                    ฟีเจอร์แนะนำสำหรับคุณ
                </Text>
                <img src={CVImage} alt='cvBanner' />
                <Text fontSize='sm' color='textDetail' px='5'>
                    คำแนะนำที่รวบรวมไว้สำหรับหลักสูตรโดยผู้เชี่ยวชาญ เช่น
                    วิธีปรับปรุง CV และขยายเครือข่าย
                    ของคุณเพื่อช่วยให้คุณเข้าถึงโอกาสได้มากขึ้น
                </Text>
                {/* <Button
                    variant='unstyled'
                    onPress={handleCreateCV}
                    width='max-content'
                    px='5'
                >
                    <HStack space='2' alignItems='center'>
                        <Text color='primaryBlue'>เริ่มสร้างเลย</Text>
                        <ArrowRightIcon />
                    </HStack>
                </Button> */}
            </VStack>
        </Card>
    )
}

export const FileCard = ({
    file,
    handleRemove,
    bgFile = 'gray19',
    borderRadius = 8,
    nameMaxWidth = '190px',
}) => {
    return (
        <Box
            borderWidth='1'
            borderColor='gray3'
            borderRadius={borderRadius}
            overflow='hidden'
            width='fit-content'
            bg='white'
        >
            <HStack height='73px'>
                <Center bg={bgFile} px={3} py={6}>
                    <Text
                        fontSize='lg'
                        color='white'
                        style={{ textTransform: 'uppercase' }}
                    >
                        {file?.name?.split('.').pop()}
                    </Text>
                </Center>
                <VStack align='center' justifyContent='center' pl={3} pr={5}>
                    <Text
                        fontSize='md'
                        color='heavyBlack'
                        maxWidth={nameMaxWidth}
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {file?.name}
                    </Text>
                    <Text fontSize='xs' fontWeight='300' color='textDetail'>
                        {file?.lastModified &&
                            format(new Date(file.lastModified), 'd/M/yyyy')}
                        {file?.createdAt &&
                            format(new Date(file.createdAt), 'd/M/yyyy')}
                    </Text>
                </VStack>
                {handleRemove && (
                    <Center mr='2'>
                        <IconButton
                            icon={<CrossIcon width='9' height='9' />}
                            onPress={handleRemove}
                            borderRadius='full'
                        />
                    </Center>
                )}
            </HStack>
        </Box>
    )
}

export const FileCardTemplate = ({ file, handleRemove, donwloadCV }) => {
    return donwloadCV ? (
        <a
            href={file?.url}
            target='_blank'
            style={{ textDecoration: 'none' }}
            rel='noreferrer'
        >
            <FileCard file={file} />
        </a>
    ) : (
        <FileCard file={file} handleRemove={handleRemove} />
    )
}
