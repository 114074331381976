import styled from 'styled-components'

import { commonColors } from '../../constant'

export const SPagination = styled.div`
ul{
  display: flex;
  list-style-type:none;
  margin: 0px;
}
li a {
  cursor: pointer ;
  color: ${commonColors.textDetail};
  float: left;
  padding: 5px 14px;
  text-decoration: none;
  font-size: 14px;
  margin : 0px 1px;
}
li.selected a{
  background-color: ${commonColors.gray8};
  color: ${commonColors.heavyBlack};
  border-radius: 8px;
}
li.previous a{
  cursor: pointer ;
  color: ${commonColors.heavyBlack};
}
li.next a{
  cursor: pointer ;
  color: ${commonColors.heavyBlack};
}

li.disabled a{
    cursor: not-allowed !important;
    color:${commonColors.gray41}
  }
`
