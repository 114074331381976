const CMS_API_HOST = process.env.REACT_APP_CMS_API_HOST
const PAYMENT_API_HOST = process.env.REACT_APP_PAYMENT_API_HOST
const WS_PAYMENT_API_HOST = process.env.REACT_APP_WS_PAYMENT_API_HOST

export function getCMSAPIUrl() {
    return `${CMS_API_HOST}/api`
}

export function getPaymentAPIUrl() {
    return PAYMENT_API_HOST
}

export function getWsPaymentAPIUrl() {
    return WS_PAYMENT_API_HOST
}

export function getAdminAPIUrl() {
    return `${CMS_API_HOST}/admin-api`
}
