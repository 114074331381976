import {
    forwardRef,
    useState,
    useEffect,
    useRef,
    useImperativeHandle,
} from 'react'
import { default as ReactDatePicker, registerLocale } from 'react-datepicker'
import { getYear, getMonth, addYears } from 'date-fns'
import { th } from 'date-fns/locale'

import moment from 'moment'
// moment came with rc-time-picker
import 'rc-time-picker/assets/index.css'

import { default as ReactSelect } from 'react-select'
import ReactSwitch from 'react-switch'

import {
    Input,
    Box,
    FormControl,
    Text,
    Pressable,
    Icon,
    Button,
    TextArea as NativeBaseTextArea,
    useTheme,
    IconButton,
    HStack,
    VStack,
    WarningOutlineIcon,
} from 'native-base'

import { Portal, Overlay } from 'react-overlays'

import { Editor } from 'react-draft-wysiwyg'
// import draftToHtml from 'draftjs-to-html'
// import draftToMarkdown from 'draftjs-to-markdown'
// import Markdown from 'markdown-to-jsx'

import {
    CalendarIcon,
    EyeIcon,
    EyeCloseIcon,
    ClockIcon,
    ArrowDownIcon,
    Card,
    TickCircleChecked,
    TickCircleUnCheck,
} from '../index'

import { MONTHS_TH, commonColors } from '../../constant'
import { getRange, formatSlashNumberThaiDate } from '../../utils'

import {
    StyledDatePickerContainer,
    StyledCustomDatePickerInputContainer,
    StyledDatePickerIconContainer,
    StyledNumberFormatInput,
    StyledNumberFormatFilledInput,
    StyledSelectButton,
    StyledDropDownOption,
    StyledRCTimePicker,
} from './style'

registerLocale('th', th)

const disabledAutocomplete = () => {
    let tagArr = document.getElementsByTagName('input')
    for (let i = 0; i < tagArr.length; i++) {
        tagArr[i].autocomplete = 'off'
    }
}

export const ErrorMessage = ({ children, preserveError, style }) => {
    return (
        <FormControl.ErrorMessage
            mt='5px'
            leftIcon={children && <WarningOutlineIcon size='xs' />}
            _text={{ color: 'primaryYellow' }}
            style={style}
        >
            {children ? children : preserveError ? ' ' : null}
        </FormControl.ErrorMessage>
    )
}

export const FilledInput = forwardRef(
    (
        {
            InputLeftElement,
            InputRightElement,
            placeholder,
            onChange,
            name,
            error,
            preserveError,
            label,
            isRequired,
            value,
            containerStyle,
            disableAutoComplete,
            afterLabel,
            isDisabled,
            style,
            ...rest
        },
        ref
    ) => {
        const inputEl = useRef(null)
        useEffect(() => {
            if (inputEl.current && disableAutoComplete) {
                inputEl.current.autocomplete = 'off'
            }
        }, [])

        return (
            <FormControl
                isInvalid={error || preserveError}
                width='auto'
                flexGrow='1'
                _invalid={{
                    borderColor: 'primaryYellow',
                }}
                style={{ ...containerStyle }}
            >
                {label && (
                    <FormControl.Label>
                        <Text
                            color='heavyBlack'
                            fontSize='md'
                            fontWeight={400}
                        >{`${label}${isRequired ? ' *' : ''}`}</Text>
                        <Text color='textDetail' fontSize='md' fontWeight={300}>
                            {afterLabel}
                        </Text>
                    </FormControl.Label>
                )}
                <Input
                    ref={node => {
                        inputEl.current = node
                        if (ref) {
                            ref.current = node
                        }
                    }}
                    width={{
                        base: '100%',
                        md: '100%',
                    }}
                    height={{ base: '58px' }}
                    borderRadius={12}
                    borderStyle='solid'
                    borderColor={
                        error
                            ? 'primaryYellow'
                            : isDisabled
                            ? 'inputFilledDisabled'
                            : 'inputFilled'
                    }
                    _invalid={{
                        borderColor: commonColors.nativeBassInputError,
                        _hover: {
                            borderColor: commonColors.nativeBassInputError,
                        },
                        _stack: {
                            style: {
                                outlineWidth: '0',
                                boxShadow: `0 0 0 1px ${commonColors.nativeBassInputError}`,
                            },
                        },
                    }}
                    _focus={{
                        borderColor: error ? 'primaryYellow' : 'primary.400',
                    }}
                    bgColor={isDisabled ? 'inputFilledDisabled' : 'inputFilled'}
                    InputLeftElement={
                        InputLeftElement && (
                            <Box paddingLeft='15px'>{InputLeftElement}</Box>
                        )
                    }
                    InputRightElement={
                        InputRightElement && (
                            <Box paddingRight='15px'>{InputRightElement}</Box>
                        )
                    }
                    placeholder={placeholder}
                    onChangeText={text => {
                        if (onChange) {
                            onChange(name, text)
                        }
                    }}
                    value={value}
                    isDisabled={isDisabled}
                    fontSize='16px'
                    // style={{
                    //     caretColor: colors.primaryBlue,
                    //     ...style,
                    // }}
                    {...rest}
                />
                {(error?.trim() || preserveError) && (
                    <ErrorMessage preserveError={preserveError}>
                        {error}
                    </ErrorMessage>
                )}
            </FormControl>
        )
    }
)

export const FilledPasswordInput = ({
    label,
    placeholder,
    onChange,
    name,
    value,
    InputLeftElement,
    error,
    preserveError,
    ...rest
}) => {
    const [show, setShow] = useState(false)

    return (
        <FilledInput
            type={show ? 'text' : 'password'}
            label={label}
            value={value}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            InputLeftElement={InputLeftElement}
            InputRightElement={
                <Pressable onPress={() => setShow(!show)}>
                    <Icon as={show ? <EyeIcon /> : <EyeCloseIcon />} />
                </Pressable>
            }
            error={error}
            preserveError={preserveError}
            disableAutoComplete
            {...rest}
        />
    )
}

export const OutlineInput = forwardRef(
    (
        {
            InputLeftElement,
            InputRightElement,
            placeholder,
            onChange,
            name,
            label,
            value,
            isRequired,
            error,
            preserveError,
            errorStyle,
            containerStyle,
            disableAutoComplete,
            afterLabel,
            isDisabled,
            bgColor,
            borderColor,
            borderRadius = 9,
            height = '48px',
            ...rest
        },
        ref
    ) => {
        const inputEl = useRef(null)
        useEffect(() => {
            if (inputEl.current && disableAutoComplete) {
                inputEl.current.autocomplete = 'off'
            }
        }, [])

        return (
            <FormControl
                isInvalid={error || preserveError}
                width='auto'
                flexGrow='1'
                style={{ ...containerStyle }}
            >
                {label && (
                    <FormControl.Label>
                        <Text color='heavyBlack' fontSize='md'>{`${label}${
                            isRequired ? ' *' : ''
                        } `}</Text>
                        <Text color='textDetail' fontSize='md' fontWeight={300}>
                            {afterLabel}
                        </Text>
                    </FormControl.Label>
                )}
                <Input
                    ref={node => {
                        inputEl.current = node
                        if (ref) {
                            ref.current = node
                        }
                    }}
                    _stack={() => <div></div>}
                    height={{ base: height }}
                    borderRadius={borderRadius}
                    borderColor={borderColor ? borderColor : 'gray1'}
                    _invalid={{
                        borderColor: commonColors.nativeBassInputError,
                        _hover: {
                            borderColor: commonColors.nativeBassInputError,
                        },
                        _stack: {
                            style: {
                                outlineWidth: '0',
                                boxShadow: `0 0 0 1px ${commonColors.nativeBassInputError}`,
                            },
                        },
                    }}
                    fontWeight='300'
                    fontSize='md'
                    opacity={isDisabled ? 0.4 : 1}
                    bgColor={
                        bgColor
                            ? bgColor
                            : isDisabled
                            ? 'inputFilledDisabled'
                            : 'white'
                    }
                    InputLeftElement={
                        InputLeftElement && (
                            <Box paddingLeft='15px'>{InputLeftElement}</Box>
                        )
                    }
                    InputRightElement={
                        InputRightElement && (
                            <Box paddingRight='15px'>{InputRightElement}</Box>
                        )
                    }
                    placeholder={placeholder}
                    onChangeText={text => {
                        onChange?.(name, text)
                    }}
                    value={value}
                    autoCorrect={false}
                    autoComplete={false}
                    isDisabled={isDisabled}
                    {...rest}
                />
                {(error || preserveError) && (
                    <ErrorMessage
                        preserveError={preserveError}
                        style={{ ...errorStyle }}
                    >
                        {error}
                    </ErrorMessage>
                )}
            </FormControl>
        )
    }
)

export const OutlinePasswordInput = ({
    label,
    placeholder,
    onChange,
    name,
    value,
    InputLeftElement,
    error,
    preserveError,
    disableAutoComplete,
    ...rest
}) => {
    const [show, setShow] = useState(false)

    useEffect(() => {
        disabledAutocomplete()
    }, [])

    return (
        <OutlineInput
            disableAutoComplete={disableAutoComplete}
            type={show ? 'text' : 'password'}
            label={label}
            value={value}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            InputLeftElement={InputLeftElement}
            InputRightElement={
                <Pressable onPress={() => setShow(!show)}>
                    <Icon as={show ? <EyeIcon /> : <EyeCloseIcon />} />
                </Pressable>
            }
            error={error}
            preserveError={preserveError}
            {...rest}
        />
    )
}

export const TextArea = ({
    placeholder,
    onChange,
    name,
    error,
    preserveError,
    label,
    isRequired,
    value,
    containerStyle,
    afterLabel,
    isDisabled,
    height,
    ...rest
}) => {
    return (
        <FormControl
            isInvalid={error || preserveError}
            width='auto'
            flexGrow='1'
            style={{ ...containerStyle }}
        >
            {label && (
                <FormControl.Label>
                    <Text
                        color='heavyBlack'
                        fontSize='md'
                        fontWeight={400}
                    >{`${label}${isRequired ? ' *' : ''} `}</Text>
                    <Text color='textDetail' fontSize='md' fontWeight={300}>
                        {afterLabel}
                    </Text>
                </FormControl.Label>
            )}
            <NativeBaseTextArea
                name={name}
                placeholder={placeholder}
                value={value}
                onChangeText={text => onChange(name, text)}
                fontSize='md'
                borderColor={
                    error
                        ? commonColors.nativeBassInputError
                        : commonColors.gray1
                }
                h={height}
                borderRadius={9}
                isDisabled={isDisabled}
                {...rest}
            />
            {(error || preserveError) && (
                <ErrorMessage preserveError={preserveError}>
                    {error}
                </ErrorMessage>
            )}
        </FormControl>
    )
}

const CalendarContainer = ({ children, ref }) => {
    const el = document.getElementById('calendar-portal')

    return (
        <Portal container={el}>
            <div ref={ref} id='calendar-container'>
                <div>{children}</div>
            </div>
        </Portal>
    )
}

const renderCustomHeader = ({
    years,
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
}) => (
    <div
        style={{
            margin: 10,
            display: 'flex',
            justifyContent: 'center',
        }}
    >
        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            {'<'}
        </button>
        <select
            value={getYear(date)}
            onChange={({ target: { value } }) => changeYear(value)}
        >
            {years.map(option => (
                <option key={option} value={option}>
                    {option + 543}
                </option>
            ))}
        </select>

        <select
            value={MONTHS_TH[getMonth(date)]}
            onChange={({ target: { value } }) =>
                changeMonth(MONTHS_TH.indexOf(value))
            }
        >
            {MONTHS_TH.map(option => (
                <option key={option} value={option}>
                    {option}
                </option>
            ))}
        </select>

        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            {'>'}
        </button>
    </div>
)

export const DatePicker = ({
    elRef,
    value,
    name,
    label,
    isRequired,
    error,
    preserveError,
    placeholder,
    onChange,
    minDate,
    maxDate,
    excludeDates,
    isDisabled,
    dateFormat,
    containerStyle,
    height = '48px',
    bgColor,
    borderColor,
    formatFn,
}) => {
    const CustomDatePickerInput = forwardRef((props, ref) => {
        const format =
            typeof formatFn === 'function'
                ? formatFn
                : formatSlashNumberThaiDate
        return (
            <StyledCustomDatePickerInputContainer
                error={error}
                onClick={props.onClick}
                disabled={isDisabled}
                height={height}
                bgColor={bgColor}
                borderColor={borderColor}
            >
                <Text
                    ref={ref}
                    color={props.value ? 'heavyBlack' : 'coolGray.400'}
                    fontWeight='300'
                    fontSize='md'
                    flexGrow='1'
                >
                    {format(props.value) || props.placeholder}
                </Text>
                <StyledDatePickerIconContainer>
                    <CalendarIcon />
                </StyledDatePickerIconContainer>
            </StyledCustomDatePickerInputContainer>
        )
    })

    const years = getRange(
        minDate ? getYear(minDate) : 1900,
        maxDate ? getYear(maxDate) : getYear(addYears(new Date(), 10))
    )

    return (
        <StyledDatePickerContainer style={containerStyle}>
            <FormControl
                isInvalid={error || preserveError}
                width='auto'
                flexGrow='1'
            >
                {label && (
                    <FormControl.Label>
                        <Text color='heavyBlack' fontSize='md'>{`${label}${
                            isRequired ? ' *' : ''
                        }`}</Text>
                    </FormControl.Label>
                )}
                <ReactDatePicker
                    locale='th'
                    popperContainer={({ children }) =>
                        CalendarContainer({ children, ref: elRef })
                    }
                    customInput={<CustomDatePickerInput />}
                    renderCustomHeader={props =>
                        renderCustomHeader({ ...props, years })
                    }
                    selected={value}
                    dateFormat={dateFormat}
                    onChange={date => onChange(name, date)}
                    placeholderText={placeholder}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode='select'
                    minDate={minDate}
                    maxDate={maxDate}
                    excludeDates={excludeDates}
                    disabled={isDisabled}
                />
                {(error || preserveError) && (
                    <ErrorMessage preserveError={preserveError}>
                        {error}
                    </ErrorMessage>
                )}
            </FormControl>
        </StyledDatePickerContainer>
    )
}

export const DateTimePicker = ({
    value,
    name,
    label,
    isRequired,
    error,
    preserveError,
    placeholder,
    onChange,
    minDate,
    maxDate,
    excludeDates,
    isDisabled,
    dateFormat,
    containerStyle,
    formatFn,
    height = '48px',
}) => {
    const CustomDatePickerInput = forwardRef((props, ref) => {
        const format =
            typeof formatFn === 'function'
                ? formatFn
                : formatSlashNumberThaiDate

        return (
            <StyledCustomDatePickerInputContainer
                error={error}
                onClick={props.onClick}
                disabled={isDisabled}
                height={height}
            >
                <Text
                    ref={ref}
                    color={props.dateTimeValue ? 'heavyBlack' : 'coolGray.400'}
                    fontWeight='300'
                    fontSize='md'
                    flexGrow='1'
                >
                    {format(props.dateTimeValue) || props.placeholder}
                </Text>
                <StyledDatePickerIconContainer>
                    <CalendarIcon />
                </StyledDatePickerIconContainer>
            </StyledCustomDatePickerInputContainer>
        )
    })

    const years = getRange(
        minDate ? getYear(minDate) : 1900,
        maxDate ? getYear(maxDate) : getYear(addYears(new Date(), 10))
    )

    return (
        <StyledDatePickerContainer style={containerStyle}>
            <FormControl
                isInvalid={error || preserveError}
                width='auto'
                flexGrow='1'
            >
                {label && (
                    <FormControl.Label>
                        <Text color='heavyBlack' fontSize='md'>{`${label}${
                            isRequired ? ' *' : ''
                        }`}</Text>
                    </FormControl.Label>
                )}
                <ReactDatePicker
                    locale='th'
                    popperContainer={CalendarContainer}
                    renderCustomHeader={props =>
                        renderCustomHeader({ ...props, years })
                    }
                    selected={value}
                    onChange={date => onChange(name, date)}
                    placeholderText={placeholder}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode='select'
                    minDate={minDate}
                    maxDate={maxDate}
                    excludeDates={excludeDates}
                    disabled={isDisabled}
                    showTimeInput
                    dateFormat={dateFormat}
                    customInput={
                        <CustomDatePickerInput dateTimeValue={value} />
                    }
                />
                {(error || preserveError) && (
                    <ErrorMessage preserveError={preserveError}>
                        {error}
                    </ErrorMessage>
                )}
            </FormControl>
        </StyledDatePickerContainer>
    )
}

export const NewTimePicker = ({
    name,
    value,
    label,
    isRequired,
    error,
    preserveError,
    placeholder,
    onChange,
    clockIcon,
    noClearButton,
    isDisabled,
    containerStyle,
    height = '48px',
    width = 'auto',
    ...rest
}) => {
    return (
        <StyledDatePickerContainer style={containerStyle}>
            <FormControl
                isInvalid={error || preserveError}
                width={width}
                flexGrow='1'
            >
                {label && (
                    <FormControl.Label>
                        <Text color='heavyBlack' fontSize='md'>{`${label}${
                            isRequired ? ' *' : ''
                        }`}</Text>
                    </FormControl.Label>
                )}
                <StyledRCTimePicker
                    // format={'HH:mm'}
                    value={value ? moment(value) : null}
                    showSecond={false}
                    onChange={time => {
                        if (!time?.isValid()) {
                            onChange(name, null)
                        } else {
                            onChange(name, time.toDate())
                        }
                    }}
                    error={error}
                    disabled={isDisabled}
                    height={height}
                    placeholder={placeholder}
                    // focusOnOpen
                    noClearButton={noClearButton}
                    inputIcon={
                        clockIcon && (
                            <StyledDatePickerIconContainer
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    right: '8px',
                                    transform: 'translate(-50%, -50%)',
                                }}
                            >
                                <ClockIcon fill='gray11' />
                            </StyledDatePickerIconContainer>
                        )
                    }
                    {...rest}
                />
                {(error || preserveError) && (
                    <ErrorMessage preserveError={preserveError}>
                        {error}
                    </ErrorMessage>
                )}
            </FormControl>
        </StyledDatePickerContainer>
    )
}

export const TimePicker = ({
    value,
    name,
    label,
    isRequired,
    error,
    preserveError,
    placeholder,
    onChange,
    isDisabled,
    containerStyle,
}) => {
    const CustomDatePickerInput = forwardRef((props, ref) => {
        return (
            <StyledCustomDatePickerInputContainer
                error={error}
                onClick={props.onClick}
                disabled={isDisabled}
            >
                <Text
                    ref={ref}
                    color={props.value ? 'heavyBlack' : 'coolGray.400'}
                    fontWeight='300'
                    fontSize='md'
                    flexGrow='1'
                >
                    {props.value || props.placeholder}
                </Text>
                <StyledDatePickerIconContainer>
                    <ClockIcon fill='gray11' />
                </StyledDatePickerIconContainer>
            </StyledCustomDatePickerInputContainer>
        )
    })

    return (
        <StyledDatePickerContainer style={containerStyle}>
            <FormControl
                isInvalid={error || preserveError}
                width='auto'
                flexGrow='1'
            >
                {label && (
                    <FormControl.Label>
                        <Text color='heavyBlack' fontSize='md'>{`${label}${
                            isRequired ? ' *' : ''
                        }`}</Text>
                    </FormControl.Label>
                )}
                <ReactDatePicker
                    popperContainer={CalendarContainer}
                    customInput={<CustomDatePickerInput />}
                    selected={value}
                    onChange={date => onChange(name, date)}
                    placeholderText={placeholder}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={1}
                    timeCaption='Time'
                    dateFormat='HH:mm'
                    timeFormat='HH:mm'
                />
                {(error || preserveError) && (
                    <ErrorMessage preserveError={preserveError}>
                        {error}
                    </ErrorMessage>
                )}
            </FormControl>
        </StyledDatePickerContainer>
    )
}

export const Select = forwardRef(
    (
        {
            placeholder,
            onChange,
            name,
            label,
            value,
            isRequired,
            options,
            error,
            preserveError,
            height,
            width = 'auto',
            maxWidth,
            optionMaxHeight,
            isDisabled,
            isLoading,
            containerStyle,
            bgColor,
            borderColor,
            optionsWidth = '100%',
            placement = 'bottom-end',
        },
        ref
    ) => {
        const { colors } = useTheme()

        const triggerRef = useRef(null)
        const containerRef = useRef(null)

        const [isOpen, setIsOpen] = useState(false)

        // hide picker if click somewhere else
        useEffect(() => {
            let elements = document.querySelectorAll("[role='button']")
            elements.forEach(item => {
                item.addEventListener('click', handleClickOutside, false)
            })

            document.addEventListener('click', handleClickOutside, false)
            return () => {
                document.removeEventListener('click', handleClickOutside, false)
                elements.forEach(item => {
                    item.removeEventListener('click', handleClickOutside, false)
                })
            }
        }, [])

        const handleClickOutside = event => {
            if (
                containerRef.current &&
                !containerRef.current.contains(event.target)
            ) {
                setIsOpen(false)
            }
        }

        useImperativeHandle(
            ref,
            () => ({
                closeSelect() {
                    setIsOpen(false)
                },
            }),
            []
        )

        const onPress = value => {
            setIsOpen(false)
            onChange(name, value)
        }

        return (
            <FormControl
                isInvalid={error || preserveError}
                width={width}
                flexGrow='1'
                style={{ ...containerStyle }}
                ref={containerRef}
            >
                {label && (
                    <FormControl.Label>
                        <Text color='heavyBlack' fontSize='md'>{`${label}${
                            isRequired ? ' *' : ''
                        }`}</Text>
                    </FormControl.Label>
                )}

                <StyledSelectButton
                    ref={triggerRef}
                    onClick={() => {
                        if (!isDisabled) setIsOpen(!isOpen)
                    }}
                    height={height}
                    isDisabled={isDisabled}
                    bgColor={bgColor}
                    borderColor={borderColor}
                    isInvalid={error}
                >
                    <HStack
                        alignItems='center'
                        justifyContent='space-between'
                        width='100%'
                        padding={3}
                    >
                        {value ? (
                            <Text fontSize='md'>
                                {options.find(i => i.value === value)?.label}
                            </Text>
                        ) : (
                            <Text
                                fontSize='md'
                                color='textDetail'
                                fontWeight={300}
                            >
                                {placeholder}
                            </Text>
                        )}
                        <ArrowDownIcon />
                    </HStack>
                </StyledSelectButton>

                <Overlay
                    show={isOpen}
                    rootClose
                    offset={[0, 10]}
                    onHide={() => setIsOpen(false)}
                    placement={placement}
                    container={containerRef}
                    target={triggerRef}
                >
                    {({ props, placement }) => (
                        <Box
                            {...props}
                            placement={placement}
                            zIndex={5}
                            width={optionsWidth}
                        >
                            <Card shadow padding={3}>
                                <VStack
                                    maxHeight={optionMaxHeight || '250px'}
                                    overflowY='auto'
                                >
                                    {options &&
                                        options.map(item => (
                                            <IconButton
                                                key={item.value}
                                                borderRadius='12'
                                                height='50px'
                                                icon={
                                                    <HStack width='100%'>
                                                        <span
                                                            style={{
                                                                color: colors.heavyBlack,
                                                            }}
                                                        >
                                                            {item.label}
                                                        </span>
                                                    </HStack>
                                                }
                                                isDisabled={item.isDisabled}
                                                onPress={() =>
                                                    onPress(item.value)
                                                }
                                                _hover={{ bg: 'blue4' }}
                                            />
                                        ))}
                                </VStack>
                            </Card>
                        </Box>
                    )}
                </Overlay>
                {(error || preserveError) && (
                    <ErrorMessage preserveError={preserveError}>
                        {error}
                    </ErrorMessage>
                )}
            </FormControl>
        )
    }
)

export const SelectSearch = ({
    placeholder,
    onChange,
    name,
    label,
    value,
    isRequired,
    options,
    error,
    preserveError,
    height = '48px',
    width = 'auto',
    isDisabled,
    isLoading,
    isClearable,
    isMulti,
    containerStyle,
    bgColor,
    borderColor,
    optionsWidth = '100%',
    flexGrow = 1,
    ...rest
}) => {
    const { colors } = useTheme()

    return (
        <FormControl
            isInvalid={error || preserveError}
            width={width}
            flexGrow={flexGrow}
            style={{ ...containerStyle }}
        >
            {label && (
                <FormControl.Label>
                    <Text color='heavyBlack' fontSize='md'>{`${label}${
                        isRequired ? ' *' : ''
                    }`}</Text>
                </FormControl.Label>
            )}

            <ReactSelect
                name={name}
                placeholder={placeholder}
                options={options}
                isOptionDisabled={option => option.disabled}
                value={value}
                onChange={e => onChange(name, e)}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isClearable={isClearable}
                isMulti={isMulti}
                components={{
                    IndicatorSeparator: () => null,
                }}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderRadius: 9,
                        minHeight: height,
                        borderColor: error
                            ? colors.nativeBassInputError
                            : state.isFocused
                            ? colors.nativeBaseBorderBlue
                            : borderColor
                            ? colors[borderColor]
                            : colors.gray1,
                        boxShadow: error
                            ? `0 0 0 1px ${colors.nativeBassInputError}`
                            : state.isFocused
                            ? `0 0 0 1px ${colors.nativeBaseBorderBlue}`
                            : 'none',
                        '&:hover': {
                            borderColor: error
                                ? colors.nativeBassInputError
                                : colors.nativeBaseBorderBlue,
                        },
                        backgroundColor: state.isDisabled
                            ? colors.inputFilledDisabled
                            : bgColor
                            ? colors[bgColor]
                            : colors.white,
                        opacity: state.isDisabled ? 0.4 : 1,
                    }),
                    singleValue: base => ({
                        ...base,
                        color: colors.heavyBlack,
                        fontFamily: 'Kanit',
                        fontSize: '16px',
                    }),
                    menu: base => ({
                        ...base,
                        borderRadius: 9,
                        color: colors.heavyBlack,
                        fontFamily: 'Kanit',
                        fontSize: '16px',
                    }),
                    placeholder: base => ({
                        ...base,
                        color: colors.textDetail,
                        fontFamily: 'Kanit',
                        fontSize: '16px',
                        fontWeight: '300',
                    }),
                }}
                {...rest}
            />
            {(error || preserveError) && (
                <ErrorMessage preserveError={preserveError}>
                    {error}
                </ErrorMessage>
            )}
        </FormControl>
    )
}

export const PhoneInput = ({
    value,
    name,
    label,
    isRequired,
    error,
    preserveError,

    placeholder,
    onChange,
    isDisabled,
    containerStyle,
    format,
    ...rest
}) => {
    return (
        //
        <FormControl
            isInvalid={error || preserveError}
            width='auto'
            flexGrow='1'
            style={{ ...containerStyle }}
        >
            {label && (
                <FormControl.Label>
                    <Text color='heavyBlack' fontSize='md'>{`${label}${
                        isRequired ? ' *' : ''
                    }`}</Text>
                </FormControl.Label>
            )}
            <StyledNumberFormatInput
                disabled={isDisabled}
                format={format ? format : '###-###-####'}
                placeholder={placeholder}
                value={value}
                onValueChange={values => onChange(name, values.value)}
                error={error}
                {...rest}
            />
            {(error || preserveError) && (
                <ErrorMessage preserveError={preserveError}>
                    {error}
                </ErrorMessage>
            )}
        </FormControl>
    )
}

export const PhoneFilledInput = ({
    value,
    name,
    label,
    isRequired,
    error,
    preserveError,

    placeholder,
    onChange,
    isDisabled,
    containerStyle,
    format,
    ...rest
}) => {
    return (
        <FormControl
            isInvalid={error || preserveError}
            width='auto'
            flexGrow='1'
            style={{ ...containerStyle }}
        >
            {label && (
                <FormControl.Label>
                    <Text color='heavyBlack' fontSize='md'>{`${label}${
                        isRequired ? ' *' : ''
                    }`}</Text>
                </FormControl.Label>
            )}
            <StyledNumberFormatFilledInput
                disabled={isDisabled}
                format={format ? format : '###-###-####'}
                placeholder={placeholder}
                value={value}
                onValueChange={values => onChange(name, values.value)}
                error={error}
                {...rest}
            />
            {(error || preserveError) && (
                <ErrorMessage preserveError={preserveError}>
                    {error}
                </ErrorMessage>
            )}
        </FormControl>
    )
}

export const NumberInput = ({
    value,
    name,
    label,
    isRequired,
    error,
    preserveError,
    placeholder,
    onChange,
    onBlur,
    isDisabled,
    afterLabel,
    SuffixElement,
    containerStyle,
    ...rest
}) => {
    return (
        <FormControl
            isInvalid={error || preserveError}
            width='auto'
            flexGrow='1'
            position='relative'
            style={{ ...containerStyle }}
        >
            {label && (
                <FormControl.Label>
                    <Text
                        color='heavyBlack'
                        fontSize='md'
                        fontWeight={400}
                    >{`${label}${isRequired ? ' *' : ''} `}</Text>
                    <Text color='textDetail' fontSize='md' fontWeight={300}>
                        {afterLabel}
                    </Text>
                </FormControl.Label>
            )}
            <StyledNumberFormatInput
                disabled={isDisabled}
                placeholder={placeholder}
                value={value}
                onValueChange={values => onChange(name, values.value)}
                onBlur={e =>
                    onBlur ? onBlur(name, e.target.value) : undefined
                }
                error={error}
                {...rest}
            />
            {SuffixElement && SuffixElement}
            {(error || preserveError) && (
                <ErrorMessage preserveError={preserveError}>
                    {error}
                </ErrorMessage>
            )}
        </FormControl>
    )
}

export const RichTextEditor = ({
    label,
    isRequired,
    error,
    preserveError,
    placeholder,
    afterLabel,
    onEditorStateChange,
    handleBeforeInput,
    handlePastedText,
    defaultState,
    editorState,
}) => {
    return (
        <FormControl
            isInvalid={error || preserveError}
            width='auto'
            flexGrow='1'
            position='relative'
        >
            {label && (
                <FormControl.Label>
                    <Text
                        color='heavyBlack'
                        fontSize='md'
                        fontWeight={400}
                    >{`${label}${isRequired ? ' *' : ''} `}</Text>
                    <Text color='textDetail' fontSize='md' fontWeight={300}>
                        {afterLabel}
                    </Text>
                </FormControl.Label>
            )}
            <Editor
                maxLength={10}
                placeholder={placeholder}
                editorState={editorState}
                defaultContentState={defaultState}
                wrapperStyle={{
                    border: `1px solid ${
                        error
                            ? commonColors.nativeBassInputError
                            : commonColors.gray1
                    }`,
                    borderRadius: '9px',
                }}
                toolbarStyle={{
                    border: 'none',
                    borderBottom: `1px solid ${commonColors.gray1}`,
                    background: 'transparent',
                }}
                editorStyle={{
                    fontFamily: 'Kanit',
                    fontWeight: 300,
                    padding: '5px 10px',
                    minHeight: '240px',
                }}
                customStyleMap={{ background: 'blue' }}
                onEditorStateChange={onEditorStateChange}
                handleBeforeInput={handleBeforeInput}
                handlePastedText={handlePastedText}
                toolbar={{
                    options: ['inline', 'link'],
                    inline: {
                        inDropdown: false,
                        options: ['bold', 'italic', 'underline'],
                    },
                    link: {
                        inDropdown: false,
                    },
                }}
            />
            {(error || preserveError) && (
                <ErrorMessage preserveError={preserveError}>
                    {error}
                </ErrorMessage>
            )}
            {/* <textarea
                disabled
                value={draftToHtml(
                    convertToRaw(editorState.getCurrentContent())
                )}
                style={{ height: '200px' }}
            />
            <textarea
                disabled
                value={draftToMarkdown(
                    convertToRaw(editorState.getCurrentContent())
                )}
                style={{ height: '200px' }}
            />
            <Markdown>
                {draftToHtml(convertToRaw(editorState.getCurrentContent()))}
            </Markdown> */}
        </FormControl>
    )
}

export const SelectionDropDown = ({ selectOption, onPress, isDisabled }) => {
    const containerRef = useRef(null)

    const [isFirst, setIsFirst] = useState(true)
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if (!isFirst && !selectOption) {
            setIsOpen(false)
        }
        setIsFirst(false)
    }, [selectOption])

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, false)
        return () => {
            document.removeEventListener('click', handleClickOutside, false)
        }
    }, [])

    const handleClickOutside = event => {
        if (
            containerRef.current &&
            !containerRef.current.contains(event.target)
        ) {
            setIsOpen(false)
        }
    }

    const onOpen = () => {
        setIsOpen(!isOpen)
    }

    const onClickMenu = () => {
        if (!isDisabled) {
            onOpen()
            onPress()
        }
    }
    return (
        <StyledDropDownOption isOpen={isOpen} isDisabled={isDisabled}>
            <Button
                variant='outline'
                borderColor={selectOption ? 'heavyBlack' : 'gray35'}
                height='35px'
                borderRadius='6px'
                isDisabled={!selectOption}
                onPress={onOpen}
            >
                {selectOption ? (
                    <img
                        src='/images/meatball_black.svg'
                        alt='meatball_black'
                    />
                ) : (
                    <img src='/images/meatball_gray.svg' alt='meatball_gray' />
                )}
            </Button>
            <div className='dropdown-content' ref={containerRef}>
                <div className='menu-item' onClick={onClickMenu}>
                    <img src='/images/export.svg' alt='export' />
                    <Text pl={'7px'} fontSize='xs' fontWeight={500}>
                        Export Data
                    </Text>
                </div>
            </div>
        </StyledDropDownOption>
    )
}

export const DropDownOption = ({
    isDisabled,
    children,
    buttonParent,
    isOpen,
    onClose,
    borderRadius,
    contentContainerStyle,
    rest,
    ignoreElementRefs = [],
}) => {
    const containerRef = useRef(null)

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, false)
        return () => {
            document.removeEventListener('click', handleClickOutside, false)
        }
    }, [])

    const handleClickOutside = event => {
        if (
            containerRef?.current &&
            !containerRef?.current.contains(event.target)
        ) {
            const isIn = ignoreElementRefs.some(
                ref =>
                    (ref?.current && ref?.current?.contains(event?.target)) ||
                    (ref?.current &&
                        event?.target?.className?.includes('react-datepicker'))
            )
            if (!isIn) {
                onClose && onClose()
            }
        }
    }

    return (
        <StyledDropDownOption
            isOpen={isOpen}
            isDisabled={isDisabled}
            borderRadius={borderRadius}
            {...rest}
        >
            {buttonParent}
            <div
                className='dropdown-content'
                ref={containerRef}
                style={contentContainerStyle}
            >
                {children}
            </div>
        </StyledDropDownOption>
    )
}

export const DropDownOption2 = ({
    isDisabled,
    children,
    buttonParent,
    borderRadius,
    contentContainerStyle,
    rest,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const containerRef = useRef(null)

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, false)
        return () => {
            document.removeEventListener('click', handleClickOutside, false)
        }
    }, [])

    const handleClose = () => {
        setIsOpen(false)
    }
    const handleOpen = () => {
        setIsOpen(true)
    }

    const handleClickOutside = event => {
        if (
            containerRef.current &&
            !containerRef.current.contains(event.target)
        ) {
            handleClose()
        }
    }

    return (
        <StyledDropDownOption
            isOpen={isOpen}
            isDisabled={isDisabled}
            borderRadius={borderRadius}
            top={'-60px'}
            {...rest}
        >
            <div onClick={() => handleOpen()} ref={containerRef}>
                {buttonParent}
            </div>
            <div
                className='dropdown-content'
                style={contentContainerStyle}
                onClick={() => handleClose()}
            >
                {children}
            </div>
        </StyledDropDownOption>
    )
}

export const CheckBox = ({
    name,
    value,
    onChange,
    children,
    containerStyle,
    contentContainerStyle,
    error,
    preserveError,
    isDisabled,
    space = 3,
}) => {
    return (
        <FormControl
            isInvalid={error || preserveError}
            width='auto'
            position='relative'
        >
            <Pressable
                onPress={() => onChange(name, !value)}
                isDisabled={isDisabled}
                style={containerStyle}
            >
                <HStack
                    alignItems='center'
                    space={space}
                    style={contentContainerStyle}
                >
                    {value ? <TickCircleChecked /> : <TickCircleUnCheck />}
                    {children}
                </HStack>
            </Pressable>
            {(error?.trim() || preserveError) && (
                <ErrorMessage preserveError={preserveError}>
                    {error}
                </ErrorMessage>
            )}
        </FormControl>
    )
}

export const Switch = ({ onChange, checked, disabled }) => {
    const { colors } = useTheme()

    return (
        <ReactSwitch
            onChange={onChange}
            checked={checked}
            uncheckedIcon={false}
            checkedIcon={false}
            offColor={colors.gray19}
            onColor={colors.primaryBlue}
            handleDiameter={16}
            height={20}
            width={38}
            disabled={disabled}
        />
    )
}
