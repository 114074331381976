import React, { useEffect } from 'react'
import { Box, HStack } from 'native-base'
import { StyledAdminContainer } from './style'
import { MobileHeader } from '../../components'
import AdminSideMenu from '../SideMenu/Admin'
import { Outlet, useLocation } from 'react-router-dom'

const AdminLayoutPart = () => {
    const location = useLocation()

    useEffect(() => {
        document.body.classList.add('admin-body')

        return () => {
            document.body.classList.remove('admin-body')
        }
    }, [])

    return (
        <Box>
            <MobileHeader noDivider height={{ base: 50, lg: 0 }} />
            <HStack>
                <Box>
                    <AdminSideMenu />
                </Box>
                <Box flex={7}>
                    <StyledAdminContainer>
                        <Outlet />
                    </StyledAdminContainer>
                </Box>
            </HStack>
        </Box>
    )
}

export default AdminLayoutPart
