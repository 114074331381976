import { useState, useEffect } from 'react'
import jsCookies from 'js-cookie'
import _ from 'lodash'

import { Box, Text, Stack, Pressable } from 'native-base'

import { FilledButton, Card } from '../index'

const Cookies = () => {
    const [accepted, setAccepted] = useState(true)

    const acceptCookieConsent = () => {
        jsCookies.remove('medi_cookie_consent')
        jsCookies.set('medi_cookie_consent', true, { expires: 30 })

        setAccepted(true)
    }

    useEffect(() => {
        let cookie_consent = jsCookies.get('medi_cookie_consent')

        if (_.isUndefined(cookie_consent) || JSON.parse(cookie_consent)) {
            setAccepted(true)
        } else {
            setAccepted(false)
        }
    }, [])

    return (
        !accepted && (
            <Box
                position='fixed'
                left='0'
                right='0'
                bottom='0'
                py='3'
                px='5'
                zIndex='6000'
            >
                <Card shadow>
                    <Stack
                        direction={{ sm: 'row' }}
                        space={5}
                        alignItems='center'
                        justifyContent='center'
                    >
                        <Text>
                            เราใช้คุกกี้ (Cookie)
                            เพื่อเพิ่มประสบการณ์และความพึงพอใจของท่านในการได้รับการเสนอข้อมูลและเนื้อหาต่างๆ
                            โดยจะทำให้เราเข้าใจลักษณะการใช้งาน
                            เว็บไซต์ของท่านได้เร็วและ
                            ทำให้เว็บไซต์ของเราเข้าถึงได้ง่าย
                            สะดวกยิ่งขึ้นโดยการเข้าใช้งานเว็นไซต์นี้ถือว่าท่านได้อนุญาตให้เราใช้คุกกี้ตาม{' '}
                            <Pressable
                                onPress={() => {
                                    window.open(
                                        `${window.location.origin}/cookies`,
                                        '_blank'
                                    )
                                }}
                            >
                                {() => {
                                    return (
                                        <Text color='primaryBlue' underline>
                                            นโยบายคุกกี้ของเรา
                                        </Text>
                                    )
                                }}
                            </Pressable>
                        </Text>
                        <FilledButton
                            borderRadius={9}
                            width='200px'
                            height='50px'
                            onPress={acceptCookieConsent}
                        >
                            <Text fontSize='md' color='white'>
                                ยอมรับ
                            </Text>
                        </FilledButton>
                    </Stack>
                </Card>
            </Box>
        )
    )
}

export default Cookies
