import React, { useState } from 'react'
import ImageViewer from 'react-simple-image-viewer'
import { Pressable, Stack } from 'native-base'

export const CImageViewer = ({
    imagesUrl = [],
    width,
    height,
    alt = '',
    direction = 'row',
    space = 3,
    isDisabled,
    styleImg,
}) => {
    const [isViewerOpen, setIsViewerOpen] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0)

    const onViewer = index => {
        setCurrentIndex(0)
        if (index) {
            setCurrentIndex(index)
        }
        setIsViewerOpen(!isViewerOpen)
    }
    return (
        <div>
            <Stack direction={direction} space={3}>
                {imagesUrl.map((url, ind) => {
                    return (
                        <Pressable
                            isDisabled={isDisabled}
                            onPress={() => onViewer(ind)}
                            key={ind}
                            style={{
                                cursor: isDisabled ? 'not-allowed' : 'pointer',
                            }}
                        >
                            {/* <Image
                                key={ind}
                                source={{
                                    uri: url,
                                }}
                                alt={alt + ind}
                                borderRadius={5}
                                w={width}
                                h={height}
                            /> */}
                            <img
                                src={url}
                                alt={alt + ind}
                                style={{
                                    borderRadius: '5px',
                                    objectFit: 'cover',
                                    width: width,
                                    height: height,
                                    ...styleImg,
                                }}
                            />
                        </Pressable>
                    )
                })}
            </Stack>

            {isViewerOpen && (
                <ImageViewer
                    src={imagesUrl}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={onViewer}
                    currentIndex={currentIndex}
                />
            )}
        </div>
    )
}
