import React, { useEffect } from 'react'
import { Button, HStack, Box, Text, useTheme } from 'native-base'
import { FilledButton } from '../../components'
import Modal from 'react-modal'

export const CModal = ({
    showModal,
    children,
    header,
    sideFooter,
    footer,
    onOk,
    onCancel,
    textCancel = 'ยกเลิก',
    textOK = 'บันทึก',
    isOkDisabled,
    isLoading,
    maxWidth = '780px',
    bodyStyle,
    padding = '20px',
    contentStyle,
}) => {
    const { breakpoints } = useTheme()

    const transformBreakpoint = objectWidth => {
        if (typeof objectWidth !== 'object') {
            return objectWidth
        }
        const screenWidth = screen.width
        const result = Object.keys(objectWidth)
            .map(key => {
                if (screenWidth >= parseInt(breakpoints[key])) {
                    return key
                }
                return undefined
            })
            .filter(item => item)
        return `${parseInt(objectWidth[result[result.length - 1]])}px`
    }

    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(59, 71, 89, 0.88)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '20px',
            padding: 'unset',
            width: transformBreakpoint(maxWidth),
            boreder: 'unset',
            overflow: 'unset',
            ...contentStyle,
        },
    }

    useEffect(() => {
        Modal.setAppElement('body')
    }, [])
    return (
        <Modal isOpen={showModal} style={customStyles}>
            {header && (
                <Box
                    p={5}
                    borderTopRadius={20}
                    borderBottomWidth={1}
                    borderColor='gray19'
                >
                    <Text color='heavyBlack' fontWeight={500} fontSize='2xl'>
                        {header}
                    </Text>
                </Box>
            )}
            <Box style={bodyStyle} padding={padding}>
                {children}
            </Box>
            {!footer && (onOk || onCancel) && (
                <Box
                    p={'20px'}
                    borderBottomRadius={20}
                    borderColor='gray19'
                    borderTopWidth={1}
                >
                    {!footer && (onOk || onCancel) && (
                        <Box width='100%'>
                            <HStack
                                justifyContent={
                                    sideFooter ? 'space-between' : 'end'
                                }
                            >
                                {sideFooter && (
                                    <Box maxWidth='50%'>{sideFooter}</Box>
                                )}
                                <Button.Group space={2}>
                                    <Button
                                        variant='outline'
                                        borderWidth={2}
                                        borderRadius={10}
                                        size='sm'
                                        height='43px'
                                        minWidth='133px'
                                        onPress={onCancel}
                                        isLoading={isLoading}
                                    >
                                        <Text
                                            fontSize='md'
                                            fontWeight='500'
                                            color='gray3'
                                        >
                                            {textCancel}
                                        </Text>
                                    </Button>
                                    <FilledButton
                                        minWidth='133px'
                                        height='43px'
                                        borderRadius='9'
                                        onPress={onOk}
                                        isLoading={isLoading}
                                        isDisabled={isOkDisabled}
                                    >
                                        <Text
                                            color='white'
                                            fontSize='md'
                                            fontWeight='500'
                                        >
                                            {textOK}
                                        </Text>
                                    </FilledButton>
                                </Button.Group>
                            </HStack>
                        </Box>
                    )}
                </Box>
            )}
            {footer}
        </Modal>
    )
}
