export * from './colors'

export const MONTHS_EN = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]

export const MONTHS_TH = [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม',
]
export const MONTHS_TH_SHORT = [
    'ม.ค.',
    'ก.พ.',
    'มี.ค.',
    'เม.ย.',
    'พ.ค.',
    'มิ.ย.',
    'ก.ค.',
    'ส.ค.',
    'ก.ย.',
    'ต.ค.',
    'พ.ย.',
    'ธ.ค.',
]

export const GENDERS_EN = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
    // { value: 'lgbtiqn', label: 'LGBTIQN+' },
    { value: 'none', label: 'Prefer not to say' },
]

export const GENDERS_TH = [
    { value: 'male', label: 'เพศชาย' },
    { value: 'female', label: 'เพศหญิง' },
    // { value: 'lgbtiqn', label: 'LGBTIQN+' },
    { value: 'none', label: 'ไม่ระบุเพศ' },
]
export const OCCUPATIONS = [
    { value: 'docter', label: 'หมอ' },
    { value: 'nurse', label: 'พยาบาล' },
    { value: 'practicalNurse', label: 'ผู้ช่วยพยาบาล' },
]

export const JOB_STATUS = [
    { value: '', label: 'สถานะงาน' },
    { value: 'CONFIRMED', label: 'ยืนยันรับงาน' },
    { value: 'WORKING', label: 'ทำงาน' },
    { value: 'COMPLETED', label: 'งานสำเร็จ' },
    { value: 'CANCELLED', label: 'ยกเลิก' },
]

export const JOB_TYPE = [
    { value: 'PART_TIME', label: 'Part-Time' },
    { value: 'FULL_TIME', label: 'Full-Time' },
]

export const JOB_TYPE_EN = [
    { value: 'ALL', label: 'งานทั้งหมด' },
    { value: 'PART_TIME', label: 'Part-Time' },
    { value: 'FULL_TIME', label: 'Full-Time' },
]

export const CONTACT_TYPE = [
    {
        label: 'อีเมล',
        value: 'EMAIL',
    },
    {
        label: 'Medimatch Messenger',
        value: 'MEDIMATCH',
    },
]
export const CONTACT_INFO_TYPE = [
    {
        label: 'LINE',
        value: 'LINE',
    },
    {
        label: 'เบอร์โทรศัพท์',
        value: 'เบอร์โทรศัพท์',
    },
    {
        label: 'Facebook',
        value: 'Facebook',
    },
    {
        label: 'อื่นๆ',
        value: 'อื่นๆ',
    },
]

export const SORT_ORDER = [
    {
        label: 'งานล่าสุด',
        value: 'createdAt:desc',
    },
    {
        label: 'งานเก่าสุด',
        value: 'createdAt:asc',
    },
]

export const SORT_ORDER_2 = [
    {
        label: 'งานล่าสุด',
        value: 'DESC',
    },
    {
        label: 'งานเก่าสุด',
        value: 'ASC',
    },
]

export const JOBS_QUERY_STATE = Object.freeze({
    TAGS: 'TAGS',
    PROFESSION: 'PROFESSION',
    ADDRESS: 'ADDRESS',
    FILTER: 'FILTER',
})

export const WORKHOUR = [
    {
        label: '00:00  - 02:00',
        value: { startTime: '00:00', endTime: '02:00' },
    },
    {
        label: '02:00  - 04:00',
        value: { startTime: '02:00', endTime: '04:00' },
    },
    {
        label: '04:00  - 06:00',
        value: { startTime: '04:00', endTime: '06:00' },
    },
    {
        label: '06:00  - 08:00',
        value: { startTime: '06:00', endTime: '08:00' },
    },
    {
        label: '08:00  - 10:00',
        value: { startTime: '08:00', endTime: '10:00' },
    },
    {
        label: '10:00  - 12:00',
        value: { startTime: '10:00', endTime: '12:00' },
    },
    {
        label: '12:00  - 14:00',
        value: { startTime: '12:00', endTime: '14:00' },
    },
    {
        label: '14:00  - 16:00',
        value: { startTime: '14:00', endTime: '16:00' },
    },
    {
        label: '16:00  - 18:00',
        value: { startTime: '16:00', endTime: '18:00' },
    },
    {
        label: '18:00  - 20:00',
        value: { startTime: '18:00', endTime: '20:00' },
    },
    {
        label: '20:00  - 22:00',
        value: { startTime: '20:00', endTime: '22:00' },
    },
    {
        label: '22:00  - 00:00',
        value: { startTime: '22:00', endTime: '23:59' },
    },
]

export const STATE = {
    APPLIED: 'APPLIED',
    ACCEPTED: 'ACCEPTED',
    CONFIRMED: 'CONFIRMED',
    COMPLETED: 'COMPLETED',
}

export const VERIFICATION_STATUS = [
    {
        label: 'Waiting Review',
        value: 'WAITING_REVIEW',
        color: 'primaryYellow',
    },
    {
        label: 'Waiting License Review',
        value: 'WAITING_LICENSE_REVIEW',
        color: 'primaryYellow',
    },
    {
        label: 'License Required',
        value: 'LICENSE_REQUIRED',
        color: 'yellow.400',
    },
    { label: 'Approved', value: 'APPROVED', color: 'green3' },
    { label: 'Approved', value: 'LICENSE_APPROVED', color: 'green3' },
    { label: 'Not Approved', value: 'NOT_APPROVED', color: 'red3' },
    {
        label: 'Lincese Not Approved',
        value: 'LICENSE_NOT_APPROVED',
        color: 'red3',
    },
]

export const VERIFICATION_EMAIL = [
    {
        label: 'Waiting Verify',
        value: false,
        color: 'primaryYellow',
    },
    {
        label: 'Verified',
        value: true,
        color: 'green3',
    },
]

export const ACCOUNT_TYPE = [
    { label: 'Employee', value: 'EMPLOYEE' },
    { label: 'Personal', value: 'EMPLOYER' },
    { label: 'Organization', value: 'COMPANY' },
]

export const SPONSOR_TYPE = [
    { label: 'All', value: null },
    { label: 'Sponsored', value: 'SPONSORED' },
    { label: 'Non Sponsored', value: 'NON_SPONSORED' },
]

export const ImageFilesType = ['image/jpeg', 'image/png', 'image/gif']

export const DocFilesType = [
    'text/csv',
    'application/pdf',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
]

export const NELLIKA_DEBUG_MODE = 'NELLIKA_DEBUG_MODE'
