import styled from 'styled-components'

import { commonColors } from '../../constant'

export const STable = styled.table`
border-collapse: collapse;
width: 100%;

`

export const STHead = styled.thead`
background-color: ${commonColors.gray29};
tr > th:first-child{
    padding-left: 40px;
}

`

export const STBody = styled.tbody`
  box-shadow: 0 0 0 1px ${commonColors.gray1};
  border-radius: 8px;

tr > td:first-child > div {
    margin-left: 20px;
}
tr:not(:last-child) > td:first-child > div{
    border-bottom: 1px solid ${commonColors.gray28};
}

tr:not(:last-child) > td > div{
    border-bottom: 1px solid ${commonColors.gray28};
}

tr > td:last-child > div {
    margin-right: 20px;   
}
`

export const STh = styled.th`
padding: 10px;
text-align: start;
:last-child{
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
:first-child{
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
`

export const STr = styled.tr`
cursor: pointer;
:hover{
    background-color: ${commonColors.gray27};
}
`

export const STd = styled.td`
> div{
    padding: 14px 10px;
}
`
