import { toast } from 'react-toastify'
import { Text } from 'native-base'

export const toastSuccess = text => {
    toast.success(
        <div className='Toastify__toast-content'>
            <Text fontSize='md'>{text}</Text>
        </div>
    )
}

export const toastError = text => {
    toast.error(
        <div className='Toastify__toast-content'>
            <Text fontSize='md'>{text}</Text>
        </div>
    )
}
