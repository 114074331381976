import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'

import jsCookies from 'js-cookie'

import { getMe } from '../services'
import { toastError } from '../utils/toast'
import { getErrorResponse } from '../utils/responseText'
import socket from '../utils/socket'

const initialUserState = {}

export const fetchUser = createAsyncThunk('fetchUser', async () => {
    const id = jsCookies.get('medi_id')

    if (!id) throw new Error('User ID not found')

    const res = await getMe(id)
    if (!res.data.user) throw new Error('User not found')
    if (res.data.user.isDeleted) userSlice.reducer.logout()
    return { ...res.data.user, packageHistories: res.data.packageHistories }
})

const userSlice = createSlice({
    name: 'user',
    initialState: initialUserState,
    reducers: {
        setUser(state, action) {
            return action.payload
        },
        logout(state, action) {
            jsCookies.remove('medi_to')
            jsCookies.remove('medi_id')

            const user = current(state)

            socket.emit('online', {
                id: user.id,
            })

            if (['STAFF', 'ADMIN'].includes(user?.role?.name)) {
                window.location.replace('/admin')
            } else {
                window.location.replace('/')
            }
            return {}
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchUser.pending, (state, action) => {
                // console.log('fetchUser loading', action)
            })
            .addCase(fetchUser.fulfilled, (state, action) => {
                // console.log('fetchUser succeeded', action)
                return action.payload
            })
            .addCase(fetchUser.rejected, (state, action) => {
                console.log('fetchUser failed', action)

                toastError(getErrorResponse('DEFAULT', 500))

                jsCookies.remove('medi_to')
                jsCookies.remove('medi_id')
                window.location.replace('/')
            })
    },
})

export const { setUser, logout } = userSlice.actions

export default userSlice.reducer
