import React from 'react'
import { HStack, VStack } from 'native-base'
import { EmptyBoxIcon } from '../../components/Icon'
import { Text } from 'native-base'

export const EmptyBox = ({ description }) => {
    return (
        <VStack alignItems='center'>
            <EmptyBoxIcon />
            <Text fontSize='lg' color='textDetail' mt={3}>
                {description ?? 'ไม่พบข้อมูล'}
            </Text>
        </VStack>
    )
}
