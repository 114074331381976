import {
    VERIFICATION_STATUS,
    ACCOUNT_TYPE,
    VERIFICATION_EMAIL,
} from '../constant'

import { formatPrice } from './addon'

export const transformVerificationStatus = (keyType, keyValue) => {
    if (keyType && keyValue) {
        const status = VERIFICATION_STATUS.find(item => item.value === keyType)
        return status[keyValue] || '-'
    }
    return '-'
}

export const transformAccountType = (keyType, keyValue) => {
    if (keyType && keyValue) {
        const accountType = ACCOUNT_TYPE.find(item => item.value === keyType)

        return accountType[keyValue] || '-'
    }
    return '-'
}

export const transformProfession = (data, key = 'title') => {
    if (data?.profession_three) return data?.profession_three?.[key]
    if (data?.profession_two) return data?.profession_two?.[key]
    if (data?.profession_one) return data?.profession_one?.[key]

    return '-'
}

export const transformToOption = (data, keys = {}) => {
    return data
        ? {
              label: data[keys.label],
              value: data[keys.value],
          }
        : null
}

export const transformName = (data, isOccupation) => {
    if (!data) return '-'
    if (typeof data?.isDeleted !== 'boolean') return 'ไม่มี isDeleted'
    if (data.isDeleted) return 'ไม่พบผู้ใช้งาน'
    if (!data.firstName) return '-'
    if (
        data?.role?.name &&
        ['COMPANY', 'STAFF', 'ADMIN'].includes(data?.role?.name)
    )
        return data?.firstName
    if (isOccupation)
        return `${data?.firstName} ${data?.lastName} ${data?.occupation}`
    return `${data?.firstName} ${data?.lastName}`
}

export const transformVerifyStatusWithin = user => {
    if (['APPROVED', 'LICENSE_APPROVED'].includes(user?.verificationStatus))
        return 'APPROVED'
    if (
        ['WAITING_REVIEW', 'WAITING_LICENSE_REVIEW'].includes(
            user?.verificationStatus
        )
    )
        return 'WAITING_REVIEW'
    if (
        ['LICENSE_REQUIRED', 'LICENSE_NOT_APPROVED'].includes(
            user?.verificationStatus
        )
    )
        return 'LICENSE_REQUIRED'
    if (
        ['NOT_APPROVED', 'LICENSE_NOT_APPROVED'].includes(
            user?.verificationStatus
        )
    )
        return 'NOT_APPROVED'
}

export const transformLocationStr = location => {
    let locationStr = ''
    if (location?.province) {
        locationStr = location?.province?.name_th
    }
    if (location?.district) {
        locationStr = locationStr + ', ' + location?.district?.name_th
    }
    if (location?.sub_district) {
        locationStr = locationStr + ', ' + location?.sub_district?.name_th
    }
    return locationStr
}

export const transformVerificationEmail = (keyType, keyValue) => {
    if ((keyType || typeof keyType === 'boolean') && keyValue) {
        const status = VERIFICATION_EMAIL.find(item => item.value === keyType)
        return status[keyValue] || '-'
    }
    return '-'
}

export const transformProfessionTag = (data, key = 'title') => {
    if (data?.profession_three) return data?.profession_three?.[key]
    if (data?.profession_two) return data?.profession_two?.[key]
    return '-'
}

export const transformPrice = (price, suffix) => {
    if (!price) return ''

    const number = +price

    if (Number.isNaN(number)) return price.slice(0, 50)

    let newPrice = formatPrice(number)

    if (price > 1000000) {
        newPrice = formatPrice(price.slice(0, 7))
    }

    return `${newPrice}${suffix ? ' THB/Month' : ''}`
}
