import {
    PersonIcon,
    JobIcon,
    CartIcon,
    MailIcon,
    CreditCard,
    VoucherIcon,
    TwoUserIcon,
    GearIcon2,
} from '../../components'

export const staffMainMenus = [
    {
        name: 'line-oa',
        pathname: '/admin/line-oa',
        icon: GearIcon2,
    },
    {
        name: 'user-management',
        pathname: '/admin/user',
        imgPathActive: '/images/user_active.svg',
        imgPathInActive: '/images/user_inactive.svg',
        icon: PersonIcon,
    },
    {
        name: 'job-management',
        pathname: '/admin/job',
        imgPathActive: '/images/job_active.svg',
        imgPathInActive: '/images/job_inactive.svg',
        icon: JobIcon,
    },
    {
        name: 'payment-history',
        pathname: '/admin/payment',
        imgPathActive: '/images/job_active.svg',
        imgPathInActive: '/images/job_inactive.svg',
        icon: CreditCard,
    },
    {
        name: 'messages',
        pathname: '/admin/messages',
        imgPathActive: '/images/messages_active.svg',
        imgPathInActive: '/images/messages_inactive.svg',
        icon: MailIcon,
    },
]

export const adminMainMenus = [
    {
        name: 'admin-management',
        pathname: '/admin/admin-management',
        icon: TwoUserIcon,
    },
    ...staffMainMenus,
    {
        name: 'package-management',
        pathname: '/admin/package',
        imgPathActive: '/images/job_active.svg',
        imgPathInActive: '/images/job_inactive.svg',
        icon: CartIcon,
    },
    {
        name: 'vouncher-management',
        pathname: '/admin/vouncher-management',
        icon: VoucherIcon,
    },
]

export const dashboardRoute = { name: 'Dashboard', pathname: '/admin' }

//routes

export const staffRoutes = [
    {
        name: 'User Registration',
        pathname: '/admin/user-registration',
        children: [
            {
                name: 'Employer',
                pathname: '/admin/user-registration/employer',
            },
            {
                name: 'Employee',
                pathname: '/admin/user-registration/employee',
            },
        ],
    },
    {
        name: 'User Management',
        pathname: '/admin/user-management',
        children: [
            {
                name: 'Employer',
                pathname: '/admin/user-management/employer',
            },
            {
                name: 'Employee',
                pathname: '/admin/user-management/employee',
            },
        ],
    },

    {
        name: 'Profession Management',
        pathname: '/admin/user/profession-management',
        children: [
            {
                name: 'Profession Layer One',
                pathname: '/admin/user/profession-management/profession-one',
            },
            {
                name: 'Profession Layer Two',
                pathname: '/admin/user/profession-management/profession-two',
            },
            {
                name: 'Profession Layer Three',
                pathname: '/admin/user/profession-management/profession-three',
            },
        ],
    },
    {
        name: 'Tag Management',
        pathname: '/admin/user/tag-management',
    },
    {
        name: 'Line OA',
        pathname: '/admin/line-oa',
    },
    {
        name: 'Job Management',
        pathname: '/admin/job-management',
        children: [
            {
                name: 'Job Dashboard',
                pathname: '/admin/job-management/job-dashboard',
            },
            {
                name: 'Part-time',
                pathname: '/admin/job-management/part-time',
            },
            {
                name: 'Full-time',
                pathname: '/admin/job-management/full-time',
            },
            {
                name: 'Hot Careers',
                pathname: '/admin/job-management/hot-careers',
            },
        ],
    },
    {
        name: 'Payment History',
        pathname: '/admin/payment-history',
    },
    {
        name: 'Ads Management',
        pathname: '/admin/package/ads-management',
    },
    {
        name: 'Messages',
        pathname: '/admin/messages',
    },
]

export const adminRoutes = [
    {
        name: 'Admin Management',
        pathname: '/admin/admin-management',
    },
    ...staffRoutes,
    {
        name: 'Package Management',
        pathname: '/admin/package/package-management',
    },
    {
        name: 'Voucher Management',
        pathname: '/admin/vouncher-management',
    },
]
