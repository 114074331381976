import React, { useState } from 'react'
import { HStack, Pressable, Text, Box } from 'native-base'
import { BackIcon, HamburgerMenuIcon } from '../../../components/Icon'
import { HamburgerMenu } from '../../../components'

import { useNavigate, useLocation } from 'react-router-dom'

export const MobileHeader = ({
    text,
    canBack,
    handleGoTo,
    option,
    footer,
    noDivider,
    height,
    bg,
    activeMenu,
    onMoving,
    zIndex = 99,
}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const [hamburgerActive, setHamburgerActive] = useState(false)

    const goBack = () => {
        if (location.key === 'default') {
            navigate('/')
        } else {
            navigate(-1)
        }
    }
    const onTriggerHamburger = action => {
        setHamburgerActive(action)
    }

    return (
        <Box display={{ lg: 'none' }}>
            <Box
                zIndex={zIndex}
                position='fixed'
                top={0}
                left={0}
                width='100%'
                px={4}
                bg={bg ? bg : text ? 'white' : ''}
                borderBottomColor={'divider'}
                borderBottomWidth={noDivider || 1}
            >
                <HStack
                    h={canBack || !text ? '55px' : '80px'}
                    width='100%'
                    alignItems='center'
                >
                    {canBack ? (
                        <HStack justifyContent='start' flex={1}>
                            <Pressable
                                _hover={{ opacity: 0.7 }}
                                onPress={handleGoTo ? handleGoTo : goBack}
                            >
                                <BackIcon />
                            </Pressable>
                        </HStack>
                    ) : (
                        <Pressable
                            onPress={() => onTriggerHamburger(true)}
                            bg='white'
                            p={2}
                            borderRadius={5}
                        >
                            <HamburgerMenuIcon size={18} />
                        </Pressable>
                    )}
                    {text && (
                        <HStack justifyContent={canBack ? 'center' : ''}>
                            <Text
                                fontSize={canBack ? 'lg' : '2xl'}
                                fontWeight={500}
                                color='heavyBlack'
                            >
                                {text}
                            </Text>
                        </HStack>
                    )}
                    <HStack flex={1}>{option}</HStack>
                </HStack>
                <Box>{footer}</Box>
            </Box>
            <Box height={height ? height : canBack ? '55px' : '80px'} />
            <HamburgerMenu
                hamburgerActive={hamburgerActive}
                onTriggerHamburger={onTriggerHamburger}
                onMoving={onMoving}
                activeMenu={activeMenu}
            />
        </Box>
    )
}
