import { useState, useEffect, Fragment } from 'react'
import _ from 'lodash'
import jsCookies from 'js-cookie'
import { isAfter } from 'date-fns'
import { useNavigate, useLocation } from 'react-router-dom'

import { Box, useColorMode } from 'native-base'

import './App.css'

import 'rc-collapse/assets/index.css'

/* ---------- Date Picker ---------- */
import 'react-datepicker/dist/react-datepicker.css'

/* ---------- RichText Editor ---------- */
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

/* ---------- Swiper ---------- */
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

/* ---------- Splide ---------- */
import '@splidejs/react-splide/css'

/* ---------- Route ---------- */
import PublicRoute from './routes/publicRoute'
import PrivateRoute from './routes/privateRoute'

/* ---------- Cookies ---------- */
import Cookies from './components/Cookies'

/* ---------- Redux ---------- */
import { useSelector, useDispatch } from 'react-redux'
import { fetchUser, logout } from './store/user'
import { fetchAds } from './store/ads'

/* ---------- Error Boundary ---------- */
import ErrorBoundary from './components/ErrorBoundary'

import axios, { tokenDecrypt } from './services'

import socket from './utils/socket'

const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID
const OMISE_PUBLIC_KEY = process.env.REACT_APP_OMISE_PUBLIC_KEY

const minutesToMilliseconds = minutes => {
    return minutes * 60 * 1000
}

const RouterContainer = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const [isInit, setIsInit] = useState(false)
    const [isLogin, setIsLogin] = useState(false)

    useEffect(() => {
        // wait for facebook sdk to initialize before starting the react app
        window.fbAsyncInit = function () {
            FB.init({
                appId: FACEBOOK_APP_ID,
                xfbml: true,
                version: 'v17.0',
            })
            FB.AppEvents.logPageView()
        }

        // load facebook sdk script
        ;(function (d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0]
            if (d.getElementById(id)) {
                return
            }
            js = d.createElement(s)
            js.id = id
            js.src = 'https://connect.facebook.net/en_US/sdk.js'
            fjs.parentNode.insertBefore(js, fjs)
        })(document, 'script', 'facebook-jssdk')
    }, [])

    useEffect(() => {
        dispatch(fetchAds())

        const verifyToken = async () => {
            const token = jsCookies.get('medi_to')

            if (token) {
                try {
                    const tokenRes = await tokenDecrypt(token)

                    axios.defaults.headers.common[
                        'Authorization'
                    ] = `Bearer ${token}`

                    jsCookies.set('medi_id', tokenRes.id)

                    if (isAfter(new Date(tokenRes.exp * 1000), new Date())) {
                        dispatch(fetchUser())
                    } else {
                        dispatch(logout())
                    }
                } catch (error) {
                    dispatch(logout())
                    setIsInit(true)
                }
            } else {
                setIsInit(true)
            }
        }

        verifyToken()
    }, [])

    useEffect(() => {
        if (!_.isEmpty(user)) {
            setIsLogin(true)
            setIsInit(true)

            if (user?.role?.name === 'EMPLOYEE' && _.isEmpty(user.tags)) {
                navigate('/tag')
            }

            socket.emit('online', {
                id: user.id,
            })

            if (['STAFF', 'ADMIN'].includes(user?.role?.name)) {
                setInterval(() => {
                    socket.emit('pingStaffAdmin', {
                        id: user.id,
                    })
                }, minutesToMilliseconds(3))
            }
        }
    }, [user])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
        })
    }, [location])

    /*----------- Setup Omise -----------*/
    const OmiseCard = window.OmiseCard

    useEffect(() => {
        console.log(OMISE_PUBLIC_KEY)
        OmiseCard.configure({
            publicKey: OMISE_PUBLIC_KEY,
            defaultPaymentMethod: 'credit_card',
            currency: 'THB',
            frameLabel: 'Medimatch Shop',
            submitLabel: 'Pay NOW',
            buttonLabel: 'Pay with Omise',
        })
    }, [])

    return (
        <Fragment>
            {!isInit && <div />}
            {isInit && !isLogin && <PublicRoute />}
            {isInit && isLogin && <PrivateRoute />}
        </Fragment>
    )
}

const App = () => {
    const { colorMode } = useColorMode()
    const dispatch = useDispatch()
    const user = useSelector(state => state.user)

    useEffect(() => {
        document.getElementById('root').firstChild.style.zIndex = 'auto'
    }, [])

    useEffect(() => {
        socket.on('connect', () => {
            console.log('connect')
        })
        socket.on('disconnect', () => {
            console.log('disconnect')
        })

        socket.on('connected', () => {
            console.log('connected')
        })

        socket.on('forceLogout', () => {
            console.log('logout now')
            dispatch(logout())
        })
    }, [])

    return (
        <ErrorBoundary name='App' user={user}>
            <Box
                id='app'
                bg={colorMode === 'light' ? 'white' : 'coolGray.900'}
                zIndex={0}
            >
                <RouterContainer />
                <Cookies />
            </Box>
        </ErrorBoundary>
    )
}

export default App
