import styled from 'styled-components'

import { commonColors } from '../../constant'

export const StyledTab = styled.div`
    &:hover {
        & span {
            background:  ${commonColors.greenBlueLinear};
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
        }
    }
    ${({ selected }) => `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & span {
        ${
            selected
                ? `
        background:  ${commonColors.greenBlueLinear};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    `
                : ''
        }
    }
    `}
`
export const StyledTabBorder = styled.div`
    height: 4px;
    width: 100%;
    border-radius: 50px;
    background:  ${commonColors.greenBlueLinear};
    ${({ hovered, selected }) => `
    background: ${
        hovered
            ? commonColors.greenBlueLinear
            : selected
            ? commonColors.greenBlueLinear
            : 'transparent'
    };
    `}
`

export const StyledTabOutLine = styled.div`
    ${({ selected, color }) => `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    :hover{
      filter: brightness(0.7);
    }
    & span {
        padding: 0px 20px;
        background: ${
            selected
                ? commonColors[color.selected]
                : commonColors[color.noSelected]
        };
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
    `}
`

export const StyledTabOutlineBorder = styled.div`
    ${({ selected, color }) => `
    height: 3px;
    width: 100%;
    border-radius: 50px;
    background: ${selected ? commonColors[color.selected] : 'transparent'};
    z-index: 99;
    
    `}
`
