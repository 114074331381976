import styled from 'styled-components'

import { commonColors } from '../../constant'

export const StyledTh = styled.th`
  border: 1px solid #dddddd;

`

export const StyledTd = styled.td`
  border: 1px solid #dddddd;
  vertical-align: top;
  padding: 5px;
  
`

export const StyledUl = styled.ul`
    margin: 0;
`

export const StyledLi = styled.li`
    color: ${commonColors.heavyBlack};

    &:not(:last-child){
        margin-bottom: 10px;  
    }
`

export const StyledOl = styled.ol`
    margin: 0;
    counter-reset: list;
`

export const StyledLiDecimal = styled(StyledLi)`
    list-style: none;
    counter-increment: list;

    &::marker {
        content: "(" counter(list, decimal) ") ";
        font-size: 14px;
    }
`

export const StyledLiNumber = styled(StyledLiDecimal)`
    ${({ number }) => `
        &::marker {
            content: "${number}."counter(list, decimal) " ";
        }
    `}
`

export const StyledLiAfterParentheses = styled(StyledLiDecimal)`
    &::marker {
        content: counter(list, decimal) ") ";
    }
`

export const StyledDt = styled.dt`
   &:not(:last-child){
        margin-bottom: 10px;  
    }
`

export const StyledDd = styled.dd`
   &:not(:last-child){
        margin-bottom: 10px;  
    }
`
