import {
    HomeGradientIcon,
    ProfileNavGrediantIcon,
    NotificationNavGradiantIcon,
    MesssageNavGrediantIcon,
    VolumeHighIcon,
    AdsIcon2,
} from '../../../components/Icon'

export const employerMenuItems = [
    {
        name: 'หน้าหลัก',
        pathName: '/',
        icon: HomeGradientIcon,
    },
    {
        name: 'ติดต่อลงโฆษณา',
        pathName: '/messages?room=admin',
        icon: AdsIcon2,
    },
    {
        name: 'งานประกาศ',
        pathName: '/myjob',
        icon: VolumeHighIcon,
    },
    {
        name: 'จัดการสถานะผู้สมัครงาน',
        pathName: '/job-status',
        icon: VolumeHighIcon,
    },
    {
        name: 'แชท',
        pathName: '/messages',
        icon: MesssageNavGrediantIcon,
    },
    {
        name: 'แจ้งเตือน',
        pathName: '/notifications',
        icon: NotificationNavGradiantIcon,
    },
    {
        name: 'โปรไฟล์',
        pathName: '/m-my-profile',
        icon: ProfileNavGrediantIcon,
    },
]
