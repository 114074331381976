import { extendTheme } from 'native-base'

import { commonColors } from './constant'

export default extendTheme({
    config: {
        initialColorMode: 'light',
    },
    fonts: {
        heading: 'Kanit',
        body: 'Kanit',
        mono: 'Kanit',
    },
    colors: commonColors,
    components: {
        Box: {
            baseStyle: {
                zIndex: 'auto',
            },
        },
    },
})
