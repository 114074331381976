import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { getNotificationsCount } from '../services'
import { toastError } from '../utils/toast'
import { getErrorResponse } from '../utils/responseText'

const initialCount = 0

export const fetchNotificationsCount = createAsyncThunk(
    'fetchNotificationsCount',
    async user => {
        const getNotificationsCountRes = await getNotificationsCount({
            id: user.id,
        })

        return (
            getNotificationsCountRes?.data?.notificationsConnection?.aggregate
                ?.count || 0
        )
    }
)

const notificationsCountSlice = createSlice({
    name: 'notificationsCount',
    initialState: initialCount,
    extraReducers(builder) {
        builder
            .addCase(fetchNotificationsCount.fulfilled, (state, action) => {
                return action.payload
            })
            .addCase(fetchNotificationsCount.rejected, (state, action) => {
                toastError(getErrorResponse('DEFAULT', 500))
            })
    },
})

export default notificationsCountSlice.reducer
