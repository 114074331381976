import styled from 'styled-components'

export const StyledLinearGradientTextContainer = styled.div`
    ${({ color, textAlign }) => `
    width: auto;
    text-align: ${textAlign};
    & div {
        background:  ${color};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

`}
`
