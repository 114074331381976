import {
    HomeGradientIcon,
    AboutUsGradientIcon,
    LoginGradientIcon,
    VolumeIcon,
    BagIcon,
    AdsIcon2,
} from '../../../components/Icon'

export const publishMenuItems = [
    {
        name: 'หน้าหลัก',
        pathName: '/',
        icon: HomeGradientIcon,
    },
    {
        name: 'เกี่ยวกับเรา',
        pathName: '/about-us',
        icon: AboutUsGradientIcon,
    },
    {
        name: 'ติดต่อลงโฆษณา',
        pathName: '/register/employer',
        redirect: '/messages?room=admin',
        icon: AdsIcon2,
    },
    {
        name: 'งานประกาศ',
        pathName: '/register/employer',
        redirect: '/post',
        icon: VolumeIcon,
    },
    {
        name: 'หางาน',
        pathName: '/register/employee',
        redirect: '/job',
        icon: BagIcon,
    },
    {
        name: 'เข้าสู่ระบบ / ลงทะเบียน',
        pathName: '/login',
        icon: LoginGradientIcon,
    },
]
