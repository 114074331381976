import { Box, Text, Pressable } from 'native-base'

import { commonColors } from '../../constant'

import {
    StyledTab,
    StyledTabBorder,
    StyledTabOutLine,
    StyledTabOutlineBorder,
} from './style'

export const Tabs = ({
    options,
    selected,
    setSelected,
    isDisabled,
    textOptionStyle,
    containerStyle,
    fontSize = 'lg',
}) => {
    return (
        <Box flexDirection='row' width='100%' style={containerStyle}>
            {options?.map(option => (
                <Pressable
                    key={`option-${option.value}`}
                    onPress={() => setSelected(option.value)}
                    flexGrow={1}
                    isDisabled={isDisabled}
                >
                    <StyledTab selected={selected === option.value}>
                        <Text
                            fontSize={fontSize}
                            fontWeight={500}
                            mb={2}
                            style={textOptionStyle}
                        >
                            <span>{option.label}</span>
                        </Text>
                        <StyledTabBorder selected={selected === option.value} />
                    </StyledTab>
                </Pressable>
            ))}
        </Box>
    )
}

export const TabsOutline = ({
    options,
    selected,
    setSelected,
    isDisabled,
    textOptionStyle,
    containerStyle,
    color = {
        selected: 'heavyBlack',
        noSelected: 'textDetail',
    },
    zIndex = 10,
}) => {
    return (
        <Box w='100%'>
            <Box flexDirection='row' width='100%' style={containerStyle}>
                {options?.map(option => (
                    <Box
                        key={`option-${option.value}`}
                        onClick={() => {
                            setSelected(option)
                        }}
                        flexGrow={1}
                        isDisabled={isDisabled}
                        zIndex={zIndex}
                    >
                        <StyledTabOutLine
                            selected={selected === option.value}
                            color={color}
                        >
                            <Text
                                fontSize='lg'
                                fontWeight={
                                    selected === option.value ? 600 : 500
                                }
                                style={textOptionStyle}
                                mb={2}
                            >
                                <span>{option.label}</span>
                            </Text>
                            <StyledTabOutlineBorder
                                color={color}
                                selected={selected === option.value}
                            />
                        </StyledTabOutLine>
                    </Box>
                ))}
            </Box>
            <div
                style={{
                    height: '2px',
                    width: '100%',
                    background: `${commonColors.gray31}`,
                    position: 'absolute',
                    bottom: 0.5,
                }}
            />
        </Box>
    )
}
