import styled from 'styled-components'

import NumberFormat from 'react-number-format'
import { default as RCTimePicker } from 'rc-time-picker'

import { commonColors } from '../../constant'

export const StyledDatePicker = styled.input`
    ${() => `
    border-radius: 9px;
    border: 1px solid ${commonColors.gray1};
    box-sizing: border-box;

    height: 48px;
    padding: 5px 10px;

    &:hover {
        border: 1px solid ${commonColors.nativeBaseBorderBlue};
    }
    &:focus {
        outline: 1px solid ${commonColors.nativeBaseBorderBlue};
    }

    &::-webkit-calendar-picker-indicator {
        color: rgba(0, 0, 0, 0);
        opacity: 1;
        display: block;
        background: url(${
            process.env.PUBLIC_URL + '/images/calendarIcon.svg'
        }) no-repeat;
        width: 20px;
        height: 20px;
        border-width: thin;
    }

    `}
`

export const StyledDatePickerContainer = styled.div`
    ${() => `
   flex-grow: 1;
   position: relative;
   `}
`

export const StyledRCTimePicker = styled(RCTimePicker)`
  ${({ error, disabled, height, bgColor, borderColor, noClearButton }) => `
          .rc-time-picker-clear {
            display: ${noClearButton ? 'none' : 'block'};
            .rc-time-picker-clear-icon::after {
                font-size: 20px;
                line-height: 20px;
            }
        }
        input {
            font-family: Kanit;
            font-weight: 300;
            font-size: 16px;
            color: ${commonColors.heavyBlack};
            background-color: white;
            border: 1px solid ${
                error
                    ? commonColors.nativeBassInputError
                    : borderColor
                    ? commonColors[borderColor]
                    : commonColors.gray1
            };
            border-radius: 8px;
            padding: 8px 12px;

            display: flex;
            align-items: center;
            justify-content: space-between;

            height: ${height};
            box-sizing: border-box;

            outline: ${
                error
                    ? `1px solid ${commonColors.nativeBassInputError}`
                    : 'none'
            };

            &:hover {
                border: 1px solid ${
                    error
                        ? commonColors.nativeBassInputError
                        : commonColors.nativeBaseBorderBlue
                };
            }
            &:focus {
                outline: 1px solid ${
                    error
                        ? commonColors.nativeBassInputError
                        : commonColors.nativeBaseBorderBlue
                };
            }
            background-color: ${
                disabled
                    ? commonColors.inputFilledDisabled
                    : bgColor
                    ? commonColors[bgColor]
                    : commonColors.white
            };
            opacity: ${disabled ? 0.4 : 1};
            pointer-events: ${disabled ? 'none' : 'auto'};
            cursor: ${disabled ? 'not-allowed' : 'default'};

            &::placeholder {
                font-family: Kanit;
                font-weight: 300;
                font-size: 16px;
                color: ${commonColors.textDetail};
            }
        }

        .rc-time-picker-clear {
            top: 50%;
            transform: translate(-50%, -50%);
        }
    `}
`

export const StyledCustomDatePickerInputContainer = styled.div`
    ${({ error, disabled, height, bgColor, borderColor }) => `
        background-color: white;
        border: 1px solid ${
            error
                ? commonColors.nativeBassInputError
                : borderColor
                ? commonColors[borderColor]
                : commonColors.gray1
        };
        border-radius: 8px;
        padding: 8px 12px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        height: ${height};
        box-sizing: border-box;

        outline: ${
            error ? `1px solid ${commonColors.nativeBassInputError}` : 'none'
        };

        &:hover {
            border: 1px solid ${
                error
                    ? commonColors.nativeBassInputError
                    : commonColors.nativeBaseBorderBlue
            };
        }
        &:focus {
            outline: 1px solid ${
                error
                    ? commonColors.nativeBassInputError
                    : commonColors.nativeBaseBorderBlue
            };
        }
        background-color: ${
            disabled
                ? commonColors.inputFilledDisabled
                : bgColor
                ? commonColors[bgColor]
                : commonColors.white
        };
        opacity: ${disabled ? 0.4 : 1};
        pointer-events: ${disabled ? 'none' : 'auto'};
        cursor: ${disabled ? 'not-allowed' : 'default'};
    `}
`

export const StyledDatePickerIconContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`

export const StyledNumberFormatInput = styled(NumberFormat)`
    ${({ error, disabled, height, bgcolor, bordercolor }) => `
    font-family: Kanit;
    font-weight: 300;
    font-size: 16px;

    border-radius: 9px;
    border: 1px solid ${
        error
            ? commonColors.nativeBassInputError
            : bordercolor
            ? commonColors[bordercolor]
            : commonColors.gray1
    };
        box-sizing: border-box;

    height: ${height ? height : '48px'};
    padding: 8px 12px;

    &::placeholder {
        font-family: Kanit;
        font-weight: 300;
        font-size: 16px;
        color: ${commonColors.textDetail};
    }

    outline: ${
        error ? `1px solid ${commonColors.nativeBassInputError}` : 'none'
    };

    &:hover {
        border: 1px solid ${
            error
                ? commonColors.nativeBassInputError
                : commonColors.nativeBaseBorderBlue
        };
    }


    background-color: ${
        disabled
            ? commonColors.inputFilledDisabled
            : bgcolor
            ? commonColors[bgcolor]
            : commonColors.white
    };
    opacity: ${disabled ? 0.4 : 1};
    pointer-events: ${disabled ? 'none' : 'auto'};
    cursor: ${disabled ? 'not-allowed' : 'default'};

    `}
`

export const StyledNumberFormatFilledInput = styled(NumberFormat)`
    ${({ error, disabled, height, width }) => `
    font-family: Kanit;
    font-weight: 400;
    font-size: 16px;

    border-radius: 9px;
    border: none;
    box-sizing: border-box;

    height: ${height ? height : '48px'};
    width: ${width ? width : 'auto'};
    padding: 8px 12px;

    &::placeholder{
        color: red;
    }

    outline: ${
        error ? `1px solid ${commonColors.nativeBassInputError}` : 'none'
    };


    &:focus {
        outline: 1px solid ${
            error
                ? commonColors.nativeBassInputError
                : commonColors.nativeBaseBorderBlue
        };
    }

    background-color: ${
        disabled ? commonColors.inputFilledDisabled : commonColors.inputFilled
    };

    pointer-events: ${disabled ? 'none' : 'auto'};
    cursor: ${disabled ? 'not-allowed' : 'default'};

    

    `}
`

export const StyledSelectButton = styled.div`
    ${({ height, isDisabled, bgColor, borderColor, isInvalid }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    

    height: ${height ? height : '48px'};
    border: 1px solid ${
        isInvalid
            ? commonColors.nativeBassInputError
            : commonColors[borderColor] || commonColors.gray1
    };
    border-radius: 9px;

    outline: ${
        isInvalid ? `1px solid ${commonColors.nativeBassInputError}` : 'none'
    };

    cursor: ${isDisabled ? 'not-allowed' : 'pointer'};

    background-color: ${
        isDisabled
            ? commonColors.inputFilledDisabled
            : bgColor
            ? commonColors[bgColor]
            : commonColors.white
    };
    opacity: ${isDisabled ? 0.4 : 1};
    &:hover {
        border: 1px solid ${
            isDisabled
                ? commonColors.gray1
                : isInvalid
                ? commonColors.nativeBassInputError
                : commonColors.nativeBaseBorderBlue
        };
    }

    -webkit-user-select: none;
    -ms-user-select: none; 
    user-select: none; 
    `}
`

export const StyledDropDownOption = styled.div`
  ${({ isOpen, isDisabled, borderRadius = '12px', top = 'auto' }) => ` 
//   z-index: 20;
  position: relative;
  display: inline-block;


  .dropdown-content {
  display: ${isOpen ? 'block' : 'none'};
  align-items: center;
  position: absolute;
  background-color: white;
  min-width: 155px;
  overflow: auto;
  box-shadow: 8.660066604614258px 3.608360528945923px 34px 0px #0000001A;
  z-index: 1;
  right: 0;
  top: ${top};
  margin-top: 10px;
  border-radius: ${borderRadius};
}
.dropdown-content > div:hover{
    background-color: ${commonColors.gray25};
    border-radius: ${borderRadius};
}

.dropdown-content .menu-item {
  display: flex;
  align-items: center;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
  opacity:${isDisabled ? 0.3 : 1};
}`}
`
