const ERROR_RESPONSE = {
    DEFAULT: [
        {
            statusCode: 500,
            message: 'ระบบขัดข้อง กรุณาลองใหม่ภายหลัง',
        },
        {
            statusCode: 501,
            message: 'ระบบเกิดข้อผิดพลาด กรุณาติดต่อทางแอดมิน',
        },
    ],
    LOGIN: [
        {
            statusCode: 401,
            message: 'โปรดตรวจสอบสิทธิ์การเข้าใช้งานของคุณสำหรับการเข้าสู่ระบบ',
        },
        {
            statusCode: 403,
            message:
                'โปรดตรวจสอบอีเมลของคุณและใช้ลิงก์ที่แนบมาเพื่อยืนยันการลงทะเบียน',
        },

        {
            statusCode: 428,
            message: 'ผู้ใช้งานให้สิทธิ์การเข้าถึงระบบไม่ครบถ้วน',
        },
        {
            statusCode: 500,
            message: 'ไม่สามารถเข้าสู่ระบบได้ กรุณาลองภายหลัง',
        },
    ],
    REGISTER: [
        {
            statusCode: 406,
            message: 'กรุณาใส่ข้อมูลให้ถูกต้อง',
        },
        {
            statusCode: 409,
            message: 'อีเมลนี้ถูกใช้งานแล้ว',
        },
        {
            statusCode: 422,
            message: 'กรุณาใส่ข้อมูลให้ครบถ้วน',
        },
        {
            statusCode: 424,
            message: 'ระบบขัดข้อง กรุณาลองใหม่ภายหลัง',
        },
    ],
    COMPLETE_REGISTER: [
        {
            statusCode: 404,
            message: 'ผู้ใช้งานไม่ถูกต้อง',
        },
        {
            statusCode: 406,
            message: 'โปรดระบบผู้ใช้งานให้ถูกต้อง',
        },
        {
            statusCode: 409,
            message: 'ผู้ใช้งานนี้ยืนยันการใช้งานเรียบร้อยแล้ว',
        },
    ],
    FORGOT_PASSWORD: [
        {
            statusCode: 404,
            message: 'ผู้ใช้งานไม่ถูกต้อง',
        },
        {
            statusCode: 406,
            message: 'กรุณาใส่ข้อมูลให้ถูกต้อง',
        },
        {
            statusCode: 405,
            message: 'ผู้ใช้งานยังไม่ได้ยืนยันการสมัครสมาชิก',
        },
        {
            statusCode: 409,
            message: 'ผู้ใช้งานเข้าสู่ระบบด้วยช่องทางอื่น',
        },
    ],
    RESET_PASSWORD: [
        {
            statusCode: 400,
            message: 'URL ไม่ถูกต้อง กรุณาทำรายการใหม่อีกครั้ง',
        },
        {
            statusCode: 406,
            message: 'รหัสผ่านใหม่ต้องไม่ซ้ำกับรหัสผ่านเดิมของคุณ',
        },
        {
            statusCode: 408,
            message: 'URL หมดอายุ กรุณาทำรายการใหม่อีกครั้ง',
        },
        {
            statusCode: 409,
            message: 'ผู้ใช้งานเข้าสู่ระบบด้วยช่องทางอื่น',
        },
        {
            statusCode: 404,
            message: 'ผู้ใช้งานไม่ถูกต้อง',
        },
        {
            statusCode: 422,
            message: 'กรุณาใส่ข้อมูลให้ครบถ้วน',
        },
        {
            statusCode: 429,
            message: 'มีคำร้องมาเกินไป กรุณาลองใหม่ภายหลัง',
        },
    ],
    CHANGE_PASSWORD: [
        {
            statusCode: 400,
            message: 'รหัสผ่านเก่าไม่ถูกต้อง',
        },
        {
            statusCode: 401,
            message: 'โปรดตรวจสอบสิทธิ์การเข้าใช้งานของคุณ',
        },
        {
            statusCode: 404,
            message: 'ผู้ใช้งานไม่ถูกต้อง',
        },
        {
            statusCode: 406,
            message: 'รหัสผ่านใหม่ต้องไม่ซ้ำกับรหัสผ่านเดิมของคุณ',
        },
        {
            statusCode: 409,
            message: 'ผู้ใช้งานเข้าสู่ระบบด้วยช่องทางอื่น',
        },
        {
            statusCode: 422,
            message: 'กรุณาระบุรหัสผ่านและยืนยันรหัสผ่านให้ตรงกัน',
        },
    ],
    CREATE_JOB: [
        {
            statusCode: 403,
            message: 'กรุณายืนยันตัวตนก่อนลงประกาศหางาน',
        },
        {
            statusCode: 405,
            message: 'กรุณาตรวจสอบแพ็กเกจของคุณ',
        },
        {
            statusCode: 406,
            message: 'กรุณาใส่ข้อมูลให้ถูกต้อง',
        },
        {
            statusCode: 422,
            message: 'กรุณาใส่ข้อมูลให้ครบถ้วน',
        },
    ],
    EDIT_JOB: [
        {
            statusCode: 401,
            message: 'ไม่มีสิทธิ์แก้ไขงานนี้',
        },
        {
            statusCode: 403,
            message: 'กรุณายืนยันตัวตนก่อนลงประกาศหางาน',
        },
        {
            statusCode: 404,
            message: 'การ์ดงานนี้ถูกลบแล้ว',
        },
        {
            statusCode: 405,
            message: 'กรุณาตรวจสอบแพ็กเกจของคุณ',
        },
        {
            statusCode: 406,
            message: 'กรุณาใส่ข้อมูลให้ถูกต้อง',
        },
        {
            statusCode: 422,
            message: 'กรุณาใส่ข้อมูลให้ครบถ้วน',
        },
        {
            statusCode: 423,
            message: 'สิทธิ์การแก้ไขครบแล้ว',
        },
        {
            statusCode: 500,
            message: 'ระบบขัดข้อง กรุณาลองใหม่ภายหลัง',
        },
    ],
    GET_JOB: [
        {
            statusCode: 500,
            // message: 'ขออภัยงานนี้ปิดรับสมัครแล้ว',
            message: 'ไม่สามารถดูงานนี้ได้',
        },
    ],
    APPLY_JOB: [
        {
            statusCode: 401,
            message: 'กรุณาใช้อีเมลที่สมัครในการหางาน',
        },
        {
            statusCode: 403,
            message: 'กรุณายืนยันตัวตนก่อนลงประกาศหางาน',
        },
        {
            statusCode: 404,
            message: 'การ์ดงานนี้ถูกลบแล้ว',
        },
        {
            statusCode: 422,
            message: 'กรุณาใส่ข้อมูลให้ครบถ้วน',
        },
        {
            statusCode: 424,
            message: 'ไม่สามารถสมัครงานผ่านอีเมลได้กรุณาติดต่อแอดมิน',
        },
    ],
    CONFIRM_ACCEPT_JOB: [
        {
            statusCode: 405,
            message: 'งานนี้หมดอายุแล้ว',
        },
        {
            statusCode: 409,
            message: 'งานนี้ไม่ได้อยู่ในสถานะ เผยแพร่',
        },
        {
            statusCode: 410,
            message: 'งานที่เลือกมีผู้สมัครครบตามจำนวนเรียบร้อยแล้ว',
        },
    ],
    CREATE_TAG: [
        {
            statusCode: 500,
            message: 'สร้างแท็กไม่สำเร็จ กรุณาลองใหม่ภายหลัง',
        },
        {
            statusCode: 409,
            message: 'ชื่อแท็กนี้มีอยู่ในระบบแล้ว',
        },

    ],
    UPDATE_TAG: [
        {
            statusCode: 500,
            message: 'แก้ไขแท็กไม่สำเร็จ กรุณาลองใหม่ภายหลัง',
        },
        {
            statusCode: 409,
            message: 'ชื่อแท็กนี้มีอยู่ในระบบแล้ว',
        },
    ],
    MESSAGES: [
        {
            statusCode: 404,
            message: 'ห้องแชทนี้ถูกลบแล้ว',
        },
    ],
    MESSAGES_SEND_FILE: [
        {
            statusCode: 422,
            message: 'สามารถอัปโหลดได้สูงสุด 5 ไฟล์ กรุณาลองใหม่ภายหลัง',
        },
        {
            statusCode: 429,
            message: 'สามารถอัปโหลดได้สูงสุด 5 ไฟล์ กรุณาลองใหม่ภายหลัง',
        },
    ],
    RATE_EMPLOYER: [
        {
            statusCode: 401,
            message: 'กรุณาตรวจสอบสิทธิ์การประเมินในงานนี้',
        },
        {
            statusCode: 406,
            message: 'กรุณาใส่ข้อมูลให้ถูกต้อง',
        },
        {
            statusCode: 404,
            message: 'ระบบขัดข้อง กรุณาลองใหม่ภายหลัง',
        },
        {
            statusCode: 428,
            message: 'คุณจะสามารถประเมินได้หลังงานจบแล้ว',
        },
        {
            statusCode: 422,
            message: 'กรุณาใส่ข้อมูลให้ครบถ้วน',
        },
    ],
    RATE_EMPLOYEE: [
        {
            statusCode: 401,
            message: 'กรุณาตรวจสอบสิทธิ์การประเมินในงานนี้',
        },
    ],
    PUBLISH_JOB: [
        {
            statusCode: 401,
            message: 'ไม่มีสิทธิ์แก้ไขงานนี้',
        },
        {
            statusCode: 403,
            message: 'กรุณายืนยันตัวตนก่อนลงประกาศหางาน',
        },
        {
            statusCode: 404,
            message: 'ระบบขัดข้อง กรุณาลองใหม่ภายหลัง',
        },
        {
            statusCode: 405,
            message: 'กรุณาตรวจสอบแพ็กเกจของคุณ',
        },
        {
            statusCode: 422,
            message: 'กรุณาใส่ข้อมูลให้ครบถ้วน',
        },
        {
            statusCode: 500,
            message: 'ระบบขัดข้อง กรุณาลองใหม่ภายหลัง',
        },
    ],
    CREATE_VOUCHER: [
        {
            statusCode: 406,
            message: 'ชื่อนี้ถูกใช้งานแล้ว กรุณาตรวจสอบชื่ออีกครั้ง',
        },
        {
            statusCode: 409,
            message: 'รหัสนี้ถูกใช้งานแล้ว กรุณาตรวจสอบรหัสอีกครั้ง',
        },
    ],
    VOUCHER: [
        {
            statusCode: 401,
            message: 'กรุณาตรวจสอบสิทธิ์การใช้ส่วนลด',
        },
        {
            statusCode: 400,
            message: 'ไม่สามารถใช่งานส่วนลดได้',
        },
        {
            statusCode: 404,
            message: 'ไม่พบส่วนลด',
        },
        {
            statusCode: 409,
            message: 'ส่วนลดถูกใช้งานครบแล้ว',
        },
        {
            statusCode: 423,
            message: 'ส่วนลดหมดอายุ',
        },
    ],
    CREATE_ORDER: [
        {
            statusCode: 404,
            message: 'ระบบขัดข้อง กรุณาลองใหม่ภายหลัง',
        },
        {
            statusCode: 409,
            message: 'ไม่สามารถใช่งานส่วนลดได้',
        },
    ],
    PURCHASE_DEFAULT_PACKAGE: [
        {
            statusCode: 401,
            message: 'ผู้ใช้งานนี้ไม่สามารถซื้อแพ็คเกจ',
        },
        {
            statusCode: 404,
            message: 'ไม่พบแพ็คเกจ',
        },
        {
            statusCode: 409,
            message: 'ผู้ใช้งานมีแพ็คเกจนี้แล้ว',
        },
    ],
    ORDER_CALLBACK: [
        {
            statusCode: 402,
            message: 'กรุณาชำระเงิน',
        },
        {
            statusCode: 404,
            message: 'ไม่พบคำสั่งซื้อ',
        },
        {
            statusCode: 409,
            message: 'คำสั่งซื้อนี้เสร็จสิ้นแล้ว',
        },
        {
            statusCode: 422,
            message: 'คำสั่งซื้อล้มเหลว',
        },
        {
            statusCode: 424,
            message: 'ระบบขัดข้อง กรุณาลองใหม่ภายหลัง',
        },
        {
            statusCode: 500,
            message: 'ระบบขัดข้อง กรุณาลองใหม่ภายหลัง',
        },
    ],
    EXPORT_DATA: [
        {
            status: 400,
            message: 'ระบบขัดข้อง กรุณาลองใหม่ภายหลัง',
        },
    ],
    APP_UPDATE: [
        {
            statusCode: 401,
            message: 'กรุณาตรวจสอบสิทธิ์การจัดการงานนี้',
        },
        {
            status: 404,
            message: 'การ์ดงานนี้ถูกลบแล้ว',
        },
        {
            statusCode: 409,
            message: 'ไม่สามารถแก้ไขสถานะได้ เนื่องจากงานจบลงแล้ว',
        },
    ],
    CREATE_ADMIN: [
        {
            statusCode: 409,
            message: 'มีอีเมล์นี้ในระบบแล้ว',
        },
    ],
    DELETE_ADMIN: [
        {
            statusCode: 401,
            message: 'ไม่สามารถลบแอดมินได้อีก',
        },
    ],
    CREATE_ADS: [
        {
            statusCode: 404,
            message: 'ไม่พบผู้จ้างงานนี้',
        },
        {
            statusCode: 409,
            message: 'มีชื่อนี้ในระบบแล้ว',
        },
        {
            statusCode: 422,
            message: 'กรุณาใส่ข้อมูลให้ครบถ้วน',
        },
    ],
    EDIT_ADS: [
        {
            statusCode: 404,
            message: 'ไม่พบโฆษณานี้',
        },
        {
            statusCode: 409,
            message: 'มีชื่อนี้ในระบบแล้ว',
        },
        {
            statusCode: 422,
            message: 'กรุณาใส่ข้อมูลให้ครบถ้วน',
        },
    ],
    SETUP_LINE_NOTIFICATION: [
        {
            statusCode: 428,
            message: 'ผู้ใช้งานให้สิทธิ์การเข้าถึงระบบไม่ครบถ้วน',
        },
        {
            statusCode: 409,
            message: 'มีบัญชี LINE นี้อยู่ในระบบแล้ว',
        },
    ],
    RESEND_EMAIL: [
        {
            statusCode: 404,
            message: 'ผู้ใช้งานไม่ถูกต้อง',
        },
        {
            statusCode: 406,
            message: 'กรุณาใส่อีเมลให้ถูกต้อง',
        },
        {
            statusCode: 409,
            message: 'ผู้ใช้งานนี้ยืนยันการใช้งานเรียบร้อยแล้ว',
        },
        {
            statusCode: 424,
            message: 'ไม่สามารถส่งอีเมลได้ กรุณาติดต่อแอดมิน',
        },
        {
            statusCode: 429,
            message: 'มีคำร้องมาเกินไป กรุณาลองใหม่ภายหลัง',
        },
    ],
    CREATE_PROFESSION_ONE: [
        {
            statusCode: 409,
            message: 'มีอาชีพนี้ในระบบแล้ว',
        },
    ],
    UPDATE_PROFESSION_ONE: [
        {
            statusCode: 409,
            message: 'มีอาชีพนี้ในระบบแล้ว',
        },
    ],
    CREATE_PROFESSION_TWO: [
        {
            statusCode: 409,
            message: 'มีสาขานี้ในระบบแล้ว',
        },
    ],
    UPDATE_PROFESSION_TWO: [
        {
            statusCode: 409,
            message: 'มีสาขานี้ในระบบแล้ว',
        },
    ],
    CREATE_PROFESSION_THREE: [
        {
            statusCode: 409,
            message: 'มีสาขาย่อยนี้ในระบบแล้ว',
        },
    ],
    UPDATE_PROFESSION_THREE: [
        {
            statusCode: 409,
            message: 'มีสาขาย่อยนี้ในระบบแล้ว',
        },
    ],
}

export const getErrorResponse = (type, statusCode) => {
    return (
        ERROR_RESPONSE[type]?.find(item => item.statusCode === statusCode)
            ?.message || 'ระบบขัดข้อง กรุณาลองใหม่ภายหลัง'
    )
}

export const SUCCESS_RESPONSE = {
    DEFAULT: 'ทำรายการสำเร็จ',
    REGISTER: 'ลงทะเบียนสำเร็จ',
    COMPLETE_REGISTER: 'ยืนยันการใช้งานเรียบร้อยแล้ว',
    RESET_PASSWORD: 'เปลี่ยนรหัสผ่านเรียบร้อยแล้ว',
    CHANGE_PASSWORD: 'เปลี่ยนรหัสผ่านเรียบร้อยแล้ว',
    CREATE_JOB: 'การ์ดงานถูกสร้างเรียบร้อยแล้ว',
    EDIT_JOB: 'แก้ไขการ์ดงานเรียบร้อยแล้ว',
    DELETE_JOB: 'ลบการ์ดงานสำเร็จ',
    PUBLISH_JOB: 'การ์ดงานถูกเผยแพร่แล้ว',
    CREATE_TAG: 'สร้างแท็กสำเร็จ',
    UPDATE_TAG: 'แก้ไขแท็กเรียบร้อยแล้ว',
    MESSAGES_SEND_FILE: 'ทำรายการสำเร็จ',
    PURCHASE_DEFAULT_PACKAGE: 'คำสั่งซื้อสำเร็จ',
    ORDER_CALLBACK: 'คำสั่งซื้อสำเร็จ',
    UPDATE_PROFILE: 'แก้ไขโปรไฟล์สำเร็จ',
    UPDATE_VERIFY: 'ตอบกลับคำขอยืนยันตัวตนสำเร็จ',
    VERIFY_SEND: 'ส่งคำขอยืนยันตัวตนเรียบร้อย',
    UPDATE_AVATAR: 'แก้ไขรูปโปรไฟล์สำเร็จ',
    UPDATE_BANNER: 'แก้ไขรูปแบนเนอร์สำเร็จ',
    UNSEND_MESSAGE: 'ยกเลิกการส่งข้อความแล้ว',
    CREATE_PROFESSION_ONE: 'สร้างอาชีพสำเร็จ',
    UPDATE_PROFESSION_ONE: 'แก้ไขอาชีพเรียบร้อยแล้ว',
    CREATE_PROFESSION_TWO: 'สร้างสาขาสำเร็จ',
    UPDATE_PROFESSION_TWO: 'แก้ไขสาขาเรียบร้อยแล้ว',
    CREATE_PROFESSION_THREE: 'สร้างสาขาย่อยสำเร็จ',
    UPDATE_PROFESSION_THREE: 'แก้ไขสาขาย่อยเรียบร้อยแล้ว',
}

export const getSuccessResponse = type => {
    return SUCCESS_RESPONSE[type] || 'ทำรายการสำเร็จ'
}
