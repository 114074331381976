import { useState, memo, useRef, useEffect } from 'react'
import _ from 'lodash'
import { Panel } from 'rc-collapse'

import { Text, Box, HStack, Input, IconButton, VStack } from 'native-base'

import {
    SearchIcon,
    LinearGradientText,
    Progress,
    CrossIcon,
    WhiteCrossIcon,
} from '../index'

import {
    StyledCollapse,
    StyledHorizontalRule,
    StyledLinearGradientTagButtonContainer,
    StyledSelectedContainer,
} from './style'

import { sortProfessionsOne } from '../../utils'
import { toastSuccess } from '../../utils/toast'
import { NELLIKA_DEBUG_MODE } from '../../constant/index'

const SparklesIcon = '/images/sparkles.svg'

const processSuggestTags = data => {
    return _.orderBy(data, ['count'], ['desc'])
}

export const TagSelect = memo(
    ({
        getTagsData,
        isLoading,
        professionOneId,
        professionTwoId,
        professionThreeId,
        selectTags,
        setSelectTags,
        isDebug,
        setIsDebug,
        isDisabled,
    }) => {
        const inputRef = useRef(null)
        const [searchValue, setSearchValue] = useState('')

        const debounceSetSearchValue = _.debounce(value => {
            setSearchValue(value)
        }, 1000)

        const onSearchChange = value => {
            debounceSetSearchValue(value)
        }

        const handleClearSearch = () => {
            setSearchValue('')
            inputRef.current.value = ''
        }

        const onEnter = () => {
            if (isDebug === undefined || setIsDebug === undefined) return

            if (searchValue === NELLIKA_DEBUG_MODE) {
                if (isDebug) {
                    toastSuccess('ปิด Debug Mode')
                } else {
                    toastSuccess('เปิด Debug Mode')
                }
                setIsDebug(!isDebug)
                handleClearSearch()
            }
        }

        const [suggestTags, setSuggestTags] = useState([])

        const [tagsOptions, setTagsOptions] = useState([
            {
                title: 'งานคลินิกความงาม',
                tags: [],
            },
            {
                title: 'แพทย์',
                tags: [],
            },
            {
                title: 'การพยาบาล',
                tags: [],
            },
            {
                title: 'เภสัชกรรม',
                tags: [],
            },
            {
                title: 'ทันตกรรม',
                tags: [],
            },
            {
                title: 'กลุ่มอาชีพสาธารณสุข',
                tags: [],
            },
        ])

        useEffect(() => {
            if (getTagsData && !_.isEmpty(getTagsData?.getTagsOptions?.data)) {
                const { groupTags, tagsCount } = getTagsData.getTagsOptions.data

                let newTagsOptions = sortProfessionsOne(groupTags)

                if (searchValue) {
                    newTagsOptions = newTagsOptions.map(item => ({
                        ...item,
                        tags: item.tags.filter(tag =>
                            tag.title
                                .toLowerCase()
                                .includes(searchValue.toLowerCase())
                        ),
                    }))
                }

                newTagsOptions = newTagsOptions.map(item => ({
                    ...item,
                    tags: item.tags.map(tag => ({
                        ...tag,
                        professionOneId: item.id,
                    })),
                }))
                setTagsOptions(newTagsOptions)

                let newAllTags = groupTags.reduce(
                    (prev, cur) => prev.concat(cur.tags.map(i => i)),
                    []
                )
                if (tagsCount.length !== 0) {
                    newAllTags = _.uniqBy(newAllTags.concat(tagsCount), 'id')
                }

                let newSuggestTags = []

                if (professionThreeId) {
                    const professionThreeTags = newAllTags.filter(
                        item => item.profession_three === professionThreeId
                    )
                    newSuggestTags = newSuggestTags.concat(
                        processSuggestTags(professionThreeTags)
                    )
                }

                if (professionTwoId) {
                    const professionTwoTags = newAllTags.filter(
                        item => item.profession_two === professionTwoId
                    )

                    newSuggestTags = newSuggestTags.concat(
                        processSuggestTags(professionTwoTags)
                    )
                }

                if (searchValue) {
                    newSuggestTags = newSuggestTags.filter(tag =>
                        tag.title
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())
                    )
                }

                if (newSuggestTags.length < 5) {
                    const groupOne = newTagsOptions.find(
                        one => one.id === professionOneId
                    )
                    if (groupOne) {
                        newSuggestTags = newSuggestTags.concat(groupOne.tags)
                    }
                }

                newSuggestTags = _.uniqBy(newSuggestTags, 'id').slice(0, 5)
                setSuggestTags(newSuggestTags)
            }
        }, [
            getTagsData,
            professionOneId,
            professionTwoId,
            professionThreeId,
            searchValue,
        ])

        const [suggestActive, setSuggestActive] = useState([0])

        const handleSuggestActive = key => {
            setSuggestActive(key)
        }

        const onCheckbox = (newTag, checked) => {
            if (checked) {
                setSelectTags(prev => [...prev, newTag])
            } else {
                setSelectTags(prev =>
                    JSON.parse(JSON.stringify(prev)).filter(
                        item => item.id !== newTag.id
                    )
                )
            }
        }

        const handleRemoveSelectTag = id => {
            setSelectTags(prev =>
                JSON.parse(JSON.stringify(prev)).filter(item => item.id !== id)
            )
        }

        return (
            <Box
                width='100%'
                maxWidth='680px'
                alignItems='center'
                borderColor='gray1'
                borderWidth='1px'
                borderRadius='9px'
                overflow='hidden'
            >
                <Box
                    width='100%'
                    px='22px'
                    borderBottomWidth='1px'
                    borderBottomColor='gray1'
                >
                    <Input
                        ref={inputRef}
                        variant='unstyled'
                        placeholder='พิมพ์แท็กที่เกี่ยวข้อง'
                        fontWeight='300'
                        fontSize='md'
                        onChangeText={onSearchChange}
                        onSubmitEditing={onEnter}
                        InputLeftElement={
                            <SearchIcon fill='gray19' size='18' />
                        }
                        InputRightElement={
                            searchValue !== '' && (
                                <IconButton
                                    borderRadius='full'
                                    icon={
                                        <CrossIcon
                                            fill='gray10'
                                            width='12'
                                            height='12'
                                        />
                                    }
                                    onPress={handleClearSearch}
                                />
                            )
                        }
                        autoCorrect={false}
                        autoComplete={false}
                        width='100%'
                        height='45px'
                        isDisabled={isLoading || isDisabled}
                    />
                </Box>

                <Box
                    py='11px'
                    width='100%'
                    height='400px'
                    overflowY='auto'
                    position='relative'
                >
                    {isLoading && <Progress />}

                    {!_.isEmpty(selectTags) && (
                        <StyledSelectedContainer>
                            {selectTags.map(item => (
                                <LinearGradientTagButton
                                    key={item.id}
                                    data={item}
                                    onPress={() =>
                                        handleRemoveSelectTag(item.id)
                                    }
                                    isDisabled={isLoading || isDisabled}
                                />
                            ))}
                        </StyledSelectedContainer>
                    )}

                    {!_.isEmpty(selectTags) && <StyledHorizontalRule />}

                    {!_.isEmpty(suggestTags) && (
                        <StyledCollapse
                            accordion={false}
                            expandIcon={() => {}}
                            defaultActiveKey={['0']}
                            onChange={key => handleSuggestActive(key)}
                        >
                            <Panel
                                className='suggest-tags'
                                header={
                                    <HStack space={2}>
                                        <LinearGradientText
                                            fontWeight='500'
                                            color='greenBlueLinear'
                                        >
                                            แท็กแนะนำ
                                        </LinearGradientText>
                                        <img
                                            src={SparklesIcon}
                                            alt='sparkles-icon'
                                            width='18px'
                                        />
                                    </HStack>
                                }
                            >
                                <VStack px='27px' space={3} py={3}>
                                    {suggestTags?.map(tag => (
                                        <TagCheckBox
                                            key={`suggest-${tag.id}`}
                                            data={tag}
                                            isChecked={selectTags.some(
                                                item => item.id === tag.id
                                            )}
                                            isDisabled={
                                                (selectTags.length >= 5 &&
                                                    !selectTags.some(
                                                        item =>
                                                            item.id === tag.id
                                                    )) ||
                                                isDisabled
                                            }
                                            onChange={value =>
                                                onCheckbox(tag, value)
                                            }
                                        />
                                    ))}
                                </VStack>
                            </Panel>
                        </StyledCollapse>
                    )}

                    {!_.isEmpty(suggestActive) && !_.isEmpty(suggestTags) && (
                        <StyledHorizontalRule />
                    )}

                    {tagsOptions.length > 0 && (
                        <StyledCollapse
                            accordion={false}
                            expandIcon={() => {}}
                            defaultActiveKey={tagsOptions.map(
                                item => item.title
                            )}
                        >
                            {tagsOptions?.map(
                                item =>
                                    !_.isEmpty(item?.tags) && (
                                        <Panel
                                            key={item.title}
                                            header={
                                                <Text
                                                    color='gray10'
                                                    fontWeight='500'
                                                >
                                                    {item.title}
                                                </Text>
                                            }
                                        >
                                            <VStack px='27px' space={3} py={3}>
                                                {item?.tags?.map(tag => (
                                                    <TagCheckBox
                                                        key={`tag-${tag.id}`}
                                                        data={tag}
                                                        isChecked={selectTags.some(
                                                            item =>
                                                                item.id ===
                                                                tag.id
                                                        )}
                                                        isDisabled={
                                                            (selectTags.length >=
                                                                5 &&
                                                                !selectTags.some(
                                                                    item =>
                                                                        item.id ===
                                                                        tag.id
                                                                )) ||
                                                            isDisabled
                                                        }
                                                        onChange={value =>
                                                            onCheckbox(
                                                                tag,
                                                                value
                                                            )
                                                        }
                                                    />
                                                ))}
                                            </VStack>
                                        </Panel>
                                    )
                            )}
                        </StyledCollapse>
                    )}
                </Box>
            </Box>
        )
    }
)
const TagCheckBox = ({ data, isDisabled, onChange, isChecked }) => {
    return (
        <HStack space={3}>
            <label class='container'>
                <input
                    type='checkbox'
                    checked={isChecked}
                    disabled={isDisabled}
                    onChange={e => onChange(e.target.checked)}
                />
                <span class='checkmark'></span>
            </label>
            <Text color={isChecked ? 'gray10' : 'textDetail'}>
                {data?.title}
            </Text>
        </HStack>
    )
}

const LinearGradientTagButton = ({ data, onPress, isDisabled }) => {
    return (
        <StyledLinearGradientTagButtonContainer>
            <Text color='white'>{data.title}</Text>
            <IconButton
                borderRadius='full'
                icon={<WhiteCrossIcon width='10' height='10' />}
                onPress={onPress}
                padding='5px'
                isDisabled={isDisabled}
            />
        </StyledLinearGradientTagButtonContainer>
    )
}
