import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'

import { getAds } from '../services'
import socket from '../utils/socket'

const initialAdsState = {
    extra: [],
    large: [],
    basic: [],
}

export const fetchAds = createAsyncThunk('fetchAds', async () => {
    const res = await getAds()

    const newAds = res.data.getAds

    const processAds = {
        extra: [],
        large: [],
        basic: [],
    }

    for (const key in newAds) {
        const row = newAds[key].map(item => ({
            ...item,
            viewed: false,
        }))
        processAds[key] = row
    }

    return processAds
})

const adsSlice = createSlice({
    name: 'ads',
    initialState: initialAdsState,
    reducers: {
        setView(state, action) {
            const { type, id, user } = action.payload

            const newAds = JSON.parse(JSON.stringify(current(state)))

            const newType = newAds[type]
            const foundIndex = newType.findIndex(item => item.id === id)

            if (foundIndex === -1 || newType[foundIndex].viewed) return

            newType[foundIndex].viewed = true
            newAds[type] = newType

            socket.emit('viewAds', {
                ads: id,
                user: user,
            })

            return newAds
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchAds.fulfilled, (state, action) => {
                return action.payload
            })
            .addCase(fetchAds.rejected, (state, action) => {
                // toastError('Cannot fetch ads')
            })
    },
})

export const { setView } = adsSlice.actions

export default adsSlice.reducer
