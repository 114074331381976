import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useSelector, useDispatch } from 'react-redux'
import * as queryString from 'query-string'
import cryptoRandomString from 'crypto-random-string'
// import liff from '@line/liff'

import { Text, Button, Pressable, Box } from 'native-base'

import { fetchUser } from '../../store/user'

import { toastError, toastSuccess } from '../../utils/toast'
import { getErrorResponse, getSuccessResponse } from '../../utils/responseText'
import { getGraphqlError } from '../../utils'

import { checkDuplicateSocialID, SET_LINE_NOTIFICATION } from '../../services'

const REACT_APP_FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL
const REACT_APP_LINE_OA_LIFF_ID = process.env.REACT_APP_LINE_OA_LIFF_ID
const REACT_APP_LINE_BOT_ID = process.env.REACT_APP_LINE_BOT_ID
const REACT_APP_LINE_CHANNEL_ID = process.env.REACT_APP_LINE_CHANNEL_ID

const lineButton = '/images/line-button.png'

const ConnectLineOAButton = ({ setIsLoading }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const user = useSelector(state => state.user)

    const [searchParams] = useSearchParams()
    const error = searchParams.get('error')

    /* ---------- LIFF ---------- */
    const logoutAndReplace = () => {
        liff.logout()
        navigate('/admin/line-oa', true)
    }

    const getUserProfileAndFriendship = async () => {
        try {
            console.log('getUserProfileAndFriendship')
            if (error) {
                console.log('error', error)
                toastError(getErrorResponse('SETUP_LINE_NOTIFICATION', 428))
                liff.logout()
                return window.location.replace('/admin/line-oa')
            }

            const profile = await liff.getProfile()

            if (!profile) {
                return toastError(
                    getErrorResponse('SETUP_LINE_NOTIFICATION', 428)
                )
            }

            // await checkDuplicateSocialID({
            //     userID: user.id,
            //     socialID: profile.userId,
            //     socialProvider: 'LINE',
            // })

            const friend = await liff.getFriendship()

            const variables = {
                userID: user.id,
                friendFlag: friend.friendFlag,
            }

            if (user.loginMethod === 'LINE') {
                variables.lineID = user.lineID
            } else {
                variables.lineID = friend.friendFlag ? profile.userId : null
            }

            updateUserLine({ variables })
            if (setIsLoading) {
                setIsLoading(true)
            }

            if (friend.friendFlag) return

            if (liff.isInClient()) {
                window.location.href = `https://line.me/R/ti/p/${REACT_APP_LINE_BOT_ID}`
            } else {
                const query = queryString.stringify({
                    response_type: 'code',
                    client_id: REACT_APP_LINE_CHANNEL_ID,
                    redirect_uri: `${REACT_APP_FRONTEND_URL}/admin/line-oa`,
                    state: cryptoRandomString({ length: 10 }).toLowerCase(),
                    scope: 'profile openid',
                    bot_prompt: 'aggressive',
                    prompt: 'consent',
                    friendship_status_changed: true,
                })

                const urlLine = `https://access.line.me/oauth2/v2.1/authorize/consent?${query}`

                window.location.href = urlLine
            }
        } catch (error) {
            console.log('getUserProfileAndFriendship error', error)
            toastError(
                getErrorResponse(
                    'SETUP_LINE_NOTIFICATION',
                    error?.response?.data?.statusCode
                )
            )
            logoutAndReplace()
        }
    }

    const initLiff = async () => {
        try {
            console.log('initLiff')
            await liff.init({ liffId: REACT_APP_LINE_OA_LIFF_ID })
        } catch (error) {
            console.log('initLiff error', error)
            toastError(getErrorResponse('SETUP_LINE_NOTIFICATION', 428))
            window.location.replace('/admin/line-oa')
        }
    }

    useEffect(() => {
        liff.ready.then(async () => {
            console.log('useEffect ready')
            if (liff.isLoggedIn()) {
                console.log('useEffect isLoggedIn')
                getUserProfileAndFriendship()
            }
        })

        initLiff()
    }, [])

    const onClickAddFriend = () => {
        console.log('onClickAddFriend')
        try {
            liff.ready.then(() => {
                console.log('onClickAddFriend ready')
                if (liff.isLoggedIn()) {
                    console.log('onClickAddFriend isLoggedIn')
                    getUserProfileAndFriendship()
                } else {
                    liff.login()
                }
            })
        } catch (error) {
            console.log('onClickAddFriend error', error)
        }
    }

    /* ---------- update LINE ID and friendFlag ---------- */
    const [updateUserLine] = useMutation(SET_LINE_NOTIFICATION, {
        onCompleted: res => {
            if (setIsLoading) {
                setIsLoading(false)
            }

            toastSuccess(getSuccessResponse('DEFAULT'))
            dispatch(fetchUser())
            logoutAndReplace()
        },
        onError: res => {
            if (setIsLoading) {
                setIsLoading(false)
            }

            const error = getGraphqlError(res)
            if (error) {
                toastError(
                    getErrorResponse(
                        'SETUP_LINE_NOTIFICATION',
                        error.statusCode
                    )
                )
            }

            logoutAndReplace()
        },
    })

    const handleCancelNoti = () => {
        try {
            updateUserLine({
                variables: {
                    userID: user.id,
                    lineID: user.loginMethod === 'LINE' ? user.lineID : null,
                    friendFlag: false,
                },
            })

            if (setIsLoading) {
                setIsLoading(true)
            }

            if (user.loginMethod === 'LINE') return

            liff.ready.then(async () => {
                logoutAndReplace()
            })
        } catch (error) {
            console.log('handleCancelNoti error', error)
        }
    }

    return (
        <Box alignItems='flex-start'>
            {!user.friendFlag ? (
                <Pressable onPress={onClickAddFriend}>
                    <img
                        src={lineButton}
                        alt='เพิ่มเพื่อน'
                        height='36'
                        border='0'
                        style={{ objectFit: 'contain' }}
                    />
                </Pressable>
            ) : (
                <Button
                    // borderRadius='8'
                    bg='red3'
                    p='2'
                    color='white'
                    onPress={handleCancelNoti}
                    height='36'
                >
                    <Text color='white'>ยกเลิกการแจ้งเตือนผ่าน LINE OA</Text>
                </Button>
            )}
        </Box>
    )
}

export default ConnectLineOAButton
