import { Box, Text } from 'native-base'
import { ToolText } from '../ToolText'

import { commonColors } from '../../constant'

export const FakeInput = ({
    label,
    value,
    height = '48px',
    width = '100%',
    borderRadius = 5,
    fontWeight = '300',
    style,
    ...rest
}) => {
    return (
        <Box>
            {label && (
                <Text color='heavyBlack' fontSize='md'>{`${label}`}</Text>
            )}
            <Box
                mt={2}
                width={width}
                height={height}
                borderRadius={borderRadius}
                fontWeight={fontWeight}
                fontSize='md'
                padding={'8px 10px'}
                style={{ ...style, border: `1px solid ${commonColors.gray1}` }}
                {...rest}
            >
                <ToolText text={value} showTooltip fontFamily='Poppins' />
            </Box>
        </Box>
    )
}
