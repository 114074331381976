import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import _ from 'lodash'

import { Text, VStack, Box, useMediaQuery } from 'native-base'

import { CModalConfirm } from '../index'

import {
    StyledTh,
    StyledTd,
    StyledUl,
    StyledOl,
    StyledLi,
    StyledLiDecimal,
    StyledLiNumber,
    StyledLiAfterParentheses,
} from './style'

const TermModal = ({
    isOpen,
    onCancel,
    onOk,
    header,
    textCancel = 'ยกเลิก',
    textOK = 'ตกลง',
}) => {
    const [isSmallScreen] = useMediaQuery({
        maxWidth: 765,
    })

    const [isDisabledOk, setIsDisabledOk] = useState(true)

    const { ref } = useInView({
        threshold: 0,
        onChange: inView => {
            if (inView) {
                setIsDisabledOk(false)
            }
        },
    })

    useEffect(() => {
        if (isOpen) {
            setIsDisabledOk(true)
        }
    }, [isOpen])

    return (
        <CModalConfirm
            showModal={isOpen}
            header={
                <Text fontSize='2xl' fontWeight={500}>
                    {header}
                </Text>
            }
            textCancel={textCancel}
            textOK={textOK}
            onCancel={onCancel}
            onOk={onOk}
            isDisabledOk={isDisabledOk}
            bodyStyle={{ overflowY: 'auto', maxHeight: '520px' }}
            maxWidth={isSmallScreen ? '100%' : '733px'}
            children={
                <Box>
                    <VStack space={5}>
                        <VStack space={3} alignItems='center'>
                            <Text fontSize='2xl' fontWeight={500}>
                                บริษัท เมดวิลล์ จำกัด
                            </Text>
                            <Text fontSize='2xl' fontWeight={500}>
                                ข้อปฏิบัติและกฎหมาย
                            </Text>
                        </VStack>
                        <VStack space={3}>
                            <Text
                                fontSize='lg'
                                fontWeight={500}
                                color='heavyBlack'
                            >
                                1. บทนำ
                            </Text>
                            <VStack space={3}>
                                <Text fontSize='sm' color='heavyBlack'>
                                    ประกาศนโยบายคุ้มครองข้อมูลส่วนบุคคลฉบับนี้จัดทำโดย
                                    บริษัท เมดวิลล์ จำกัด (&quot;บริษัท&quot;
                                    หรือ &quot; เรา&quot;)
                                    ซึ่งเป็นบริษัทจำกัดที่จดทะเบียนในประเทศไทย
                                </Text>
                                <Text fontSize='sm' color='heavyBlack'>
                                    บริษัทมุ่งมั่นที่จะปกป้องและเคารพความเป็นส่วนตัวของผู้ที่เรามีปฏิสัมพันธ์
                                    ประกาศนโยบายคุ้มครองข้อมูลส่วนบุคคลฉบับนี้จะอธิบายถึงข้อมูลส่วนบุคคลที่เรารวบรวม
                                    วิธีการใช้ข้อมูลส่วนบุคคลและสิทธิของคุณ
                                </Text>
                                <Text fontSize='sm' color='heavyBlack'>
                                    อย่างไรก็ตาม
                                    เนื่องจากบริษัทเป็นวิสาหกิจขนาดย่อมหรือขนาดกลางตามกฎหมายว่าด้วยการส่งเสริมวิสาหกิจขนาดกลางละขนาดย่อม
                                    จึงได้รับการยกเว้นหน้าที่การบันทึกรายการของผู้ควบคุมข้อมูลส่วนบุคคลตามประกาศของคณะกรรมการคุ้มครองข้อมูลส่วนบุคคลที่จัดตั้งโดยพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
                                    พ.ศ.2562
                                    (&quot;คณะกรรมการคุ้มครองข้อมูลส่วนบุคคล&quot;)
                                </Text>
                                <Text fontSize='sm' color='heavyBlack'>
                                    ข้อมูลส่วนบุคคลที่ระบุในประกาศฉบับนี้หมายถึงข้อมูลส่วนบุคคลของบุคคลทุกคนที่ติดต่อกับเรา
                                    ไม่ว่าจะเป็นผู้ใช้บริการ (ซึ่งรวมถึง
                                    ผู้หางาน ผู้ประกอบการ และลูกค้าอื่นๆ)
                                    ลูกจ้าง
                                    ผู้เยี่ยมชมเว็บไซต์หรือแอพพลิเคชั่นของเรา
                                    ซัพพลายเออร์ ผู้ให้บริการ คู่ค้าทางธุรกิจ
                                    ผู้ถือหุ้น ตัวแทนของบุคคลดังกล่าว
                                    และบุคคล/องค์กรอื่น ๆ
                                    ที่บริษัทได้รับมาในระหว่างการดำเนินธุรกิจและจากเว็บไซต์หรือแอพพลิเคชั่นของบริษัท
                                    รวมถึงในจดหมายทางอิเล็กทรอนิกส์
                                    (ต่อไปนี้จะเรียกว่า &quot;คุณ&quot;) อนึ่ง
                                    คุณสามารถดูคำนินมศัพท์ของคำต่างๆได้ในข้อ 2
                                    ของประกาศฉบับนี้
                                </Text>
                                <Text fontSize='sm' color='heavyBlack'>
                                    ประกาศฉบับนี้อาจได้รับการปรับปรุงเป็นครั้งคราวเพื่อบันทึกการเปลี่ยนแปลงต่าง
                                    ๆ
                                    ในการประมวลผลข้อมูลส่วนบุคคลของเราหรือการเปลี่ยนแปลงต่าง
                                    ๆ ในทางกฎหมาย
                                    เราขอแนะนำให้คุณอ่านประกาศฉบับนี้และตรวจสอบข้อมูลล่าสุดที่อาจมีการปรับปรุงเป็นประจำ
                                </Text>
                            </VStack>
                        </VStack>

                        <Text fontSize='lg' fontWeight={500} color='heavyBlack'>
                            2. คำและคำจำกัดความ
                        </Text>

                        <table>
                            <thead>
                                <tr>
                                    <StyledTh style={{ width: '40px' }}>
                                        <Text color='heavyBlack'>เลขที่</Text>
                                    </StyledTh>
                                    <StyledTh>
                                        <Text color='heavyBlack'>คำสำคัญ</Text>
                                    </StyledTh>
                                    <StyledTh>
                                        <Text color='heavyBlack'>คำนิยาม</Text>
                                    </StyledTh>
                                </tr>
                            </thead>

                            <tbody>
                                {[
                                    {
                                        word: 'ข้อมูลส่วนบุคคล',
                                        definition:
                                            'ข้อมูลที่เกี่ยวกับบุคคลใด ๆ หรือที่สามารถใช้ระบุบุคคลใดๆได้โดยตรงหรือโดยอ้อม โดยเฉพาะอย่างยิ่งโดยการอ้างอิงถึงข้อมูลบ่งชี้ (เช่น ชื่อ หมายเลขประจำตัว ข้อมูลสถานที่ ข้อมูลบ่งชี้ออนไลน์หรือปัจจัยหนึ่งหรือหลายปัจจัยที่เกี่ยวข้องกับอัตลักษณ์ทางร่างกาย ทางสรีรวิทยา ทางพันธุกรรม ทางจิตใจความรู้สึก ทางเศรษฐกิจ ทางวัฒนธรรมหรือทางสังคม) ซึ่งถูกประมวลผลโดยบริษัทหรือโดยผู้ประมวลผลข้อมูลส่วนบุคคลที่กระทำในนามของบริษัท',
                                    },
                                    {
                                        word: 'ข้อมูลส่วนบุคคลที่ละเอียดอ่อน',
                                        definition:
                                            'ข้อมูลส่วนบุคคลที่ละเอียดอ่อนเป็นข้อมูลส่วนบุคคลประเภทหนึ่ง ข้อมูลส่วนบุคคลที่ละเอียดอ่อนเป็นข้อมูลส่วนบุคคลใด ๆ ที่เกี่ยวข้องกับเชื้อชาติ ต้นกำเนิด ความคิดเห็นทางการเมือง ศาสนา ความเชื่อทางศาสนาหรือทางปรัชญา พฤติกรรมทางเพศ ประวัติอาชญากรรม ข้อมูลสุขภาพหรือสภาพความพิการ ข้อมูลสหภาพการค้า ข้อมูลพันธุกรรม ข้อมูลไบโอเมตริกซ์และข้อมูลใด ๆ ในลักษณะเดียวกัน ตามที่กำหนดโดยคณะกรรมการคุ้มครองข้อมูลส่วนบุคคลที่จัดตั้งโดยพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.2562 (“ คณะกรรมการคุ้มครองข้อมูลส่วนบุคคล”)',
                                    },
                                    {
                                        word: 'การประมวลผล',
                                        definition:
                                            'กิจกรรมใด ๆ ที่มีการดำเนินการหรือลำดับการดำเนินการใด ๆ ที่ดำเนินการกับข้อมูลส่วนบุคคลไม่ว่าจะด้วยวิธีการอัตโนมัติหรือไม่ก็ตาม เช่น การรวบรวม การบันทึก การจัดเก็บ การเปลี่ยนแปลง การอ่านพิจารณา การใช้ การดึง การเปิดเผยโดยการส่ง การแชร์ การทำให้เข้าถึงได้ การจัดเรียงหรือการรวม การกำหนดข้อจำกัด การส่ง การเผยแพร่ การลบหรือการทำลายโดยวิธีการใด ๆ',
                                    },
                                    {
                                        word: 'เจ้าของข้อมูลส่วนบุคคลส่วนบุคคล',
                                        definition:
                                            'บุคคลธรรมดาที่เกี่ยวข้องกับข้อมูลส่วนบุคคลโดยบุคคลดังกล่าวได้รับการระบุตัวตนหรือสามารถระบุตัวตนได้ ทั้งนี้เจ้าของข้อมูลส่วนบุคคลไม่รวมถึงผู้เสียชีวิตและข้อมูลของนิติบุคคล (ที่จัดตั้งขึ้นภายใต้กฎหมาย เช่น บริษัท มูลนิธิ สมาคมหรือองค์กรอื่น ๆ)',
                                    },
                                    {
                                        word: 'ผู้ควบคุมข้อมูลส่วนบุคคล',
                                        definition:
                                            'บุคคลหรือนิติบุคคลที่มีอำนาจและหน้าที่ในการตัดสินใจเกี่ยวกับการรวบรวม การใช้หรือการเปิดเผยข้อมูลส่วนบุคคล',
                                    },
                                    {
                                        word: 'ผู้ประมวลผลข้อมูล',
                                        definition:
                                            'บุคคลหรือนิติบุคคลที่ประมวลผลข้อมูลส่วนบุคคลตามคำสั่งที่ได้รับจากหรือในนามของบริษัทที่ทำหน้าที่เป็นผู้ควบคุมข้อมูลส่วนบุคคล',
                                    },
                                    {
                                        word: 'เจ้าหน้าที่คุ้มครองข้อมูล',
                                        definition:
                                            'เจ้าหน้าที่คุ้มครองข้อมูลที่ได้รับการแต่งตั้งจากบริษัท',
                                    },
                                    {
                                        word: 'การละเมิดข้อมูลส่วนบุคคล',
                                        definition:
                                            'การประมวลผลข้อมูลส่วนบุคคลใด ๆ โดยไม่ชอบด้วยกฎหมายหรือโดยไม่ได้รับอนุญาต',
                                    },
                                ].map((item, index) => (
                                    <tr
                                        key={`wordsAndDefinitions-${index + 1}`}
                                    >
                                        <StyledTd>
                                            <Text color='heavyBlack'>
                                                {index + 1}.
                                            </Text>
                                        </StyledTd>
                                        <StyledTd>
                                            <Text color='heavyBlack'>
                                                {item.word}
                                            </Text>
                                        </StyledTd>
                                        <StyledTd>
                                            <Text color='heavyBlack'>
                                                {item.definition}
                                            </Text>
                                        </StyledTd>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <VStack space={3}>
                            <Text
                                fontSize='lg'
                                fontWeight={500}
                                color='heavyBlack'
                            >
                                3. ข้อมูลส่วนบุคคลที่บริษัททำการประมวลผล
                            </Text>
                            <Text fontSize='md' color='heavyBlack'>
                                3.1 ข้อมูลส่วนบุคคล
                            </Text>
                            <Text fontSize='sm' color='heavyBlack'>
                                ในการดำเนินธุรกิจของเรา
                                เราอาจได้รับและประมวลผลข้อมูลส่วนบุคคลของคุณในฐานะผู้ใช้บริการ
                                (ซึ่งรวมถึง ผู้หางาน ผู้ประกอบการ
                                และลูกค้าอื่นๆ) ลูกจ้าง
                                ผู้เยี่ยมชมเว็บไซต์หรือแอพพลิเคชั่นของเรา
                                ซัพพลายเออร์ ผู้ให้บริการ คู่ค้าทางธุรกิจ
                                ผู้ถือหุ้น ตัวแทนของบุคคลดังกล่าว
                                และบุคคล/องค์กรอื่น ๆ หรือเมื่อคุณติดต่อกับเรา
                                เช่น เมื่อคุณเข้าร่วมกิจกรรมของบริษัท
                                กระบวนการหางาน
                                การให้ข้อมูลส่วนบุคคลเพื่อการเข้ารับบริการต่าง ๆ
                                การรับข่าวสาร
                                การสอบถามข้อมูลการนัดหมายผู้ให้บริการ
                                การรับบริการระบบให้คำปรึกษาทางไกล
                                (Tele-counseling) การชำระเงินออนไลน์
                                รวมถึงเลขบัญชีธนาคารต่าง ๆ ฯลฯ
                                ข้อมูลบางอย่างที่เราได้รับคือข้อมูลส่วนบุคคลเกี่ยวกับคุณ
                            </Text>
                            <Text fontSize='md' color='heavyBlack'>
                                ข้อมูลส่วนบุคคลที่เราประมวลผลมีดังต่อไปนี้
                            </Text>
                            <StyledUl>
                                {[
                                    'ข้อมูลที่สามารถระบุตัวบุคคลได้ เช่นชื่อ นามสกุล วันเดือนปีเกิด หมายเลขบัตรประจำตัวประชาชน',
                                    'ข้อมูลติดต่อเช่น ที่อยู่ อีเมล และหมายเลขโทรศัพท์',
                                    'ข้อมูลทางเทคนิค เช่น ชื่อผู้ใช้ รหัสผ่าน ความชื่นชอบสนใจ การตั้งค่าที่ต้องการ เลขหมายประจำเครื่อง (IP Address) ข้อมูลการเข้าสู่ระบบ ประเภทเบราว์เซอร์ รุ่นเบราว์เซอร์ การตั้งค่าเวลาและวันที่ การตั้งค่าการเชื่อมต่อ ระบบปฏิบัติการและแพลตฟอร์มและเทคโนโลยีอื่น ๆ ที่คุณใช้อุปกรณ์ของคุณเพื่อเข้าสู่ระบบของเรา',
                                    'ข้อมูลการตลาดและการสื่อสาร เช่น ความพึงพอใจของบริการทางการตลาดและช่องทางการสื่อสาร',
                                    'บันทึกการสนทนาหรือเข้าร่วมประชุม เช่น บันทึกการสนทนา ประชุมและกิจกรรมอื่น ๆ ที่จัดโดยบริษัทหรือในนามของบริษัท',
                                    'บันทึกความยินยอม (เช่น บันทึกความยินยอมใด ๆ ที่ให้ไว้กับเรา พร้อมวันที่และเวลา วิธีการยินยอมและข้อมูลอื่น ๆ)',
                                    'สำหรับผู้ใช้บริการจะรวมถึง ข้อมูลประวัติและใบสมัครงานของคุณ ข้อมูลเกี่ยวกับคุณ ตลอดจนข้อมูลการติดต่อกับคุณ เช่น ญาติ ชื่อและช่องทางการติดต่อของบุคคลอ้างอิง',
                                    'สำหรับผู้ประกอบการจะรวมถึง ข้อมูลส่วนบุคคลของคุณที่เกี่ยวกับบัญชีผู้ใช้งาน ข้อมูลการติดต่อกับคุณ และบุคคลที่คุณมอบหมายให้เป็นผู้ติดต่อ และข้อมูลที่ผู้ประกอบการส่งให้แก่บริษัท เพื่อประกอบการลงโฆษณาประกาศรับสมัครงาน หรือโฆษณาในรูปแบบอื่น',
                                    'มุมมองและความคิดเห็นที่คุณเลือกที่จะส่งถึงเราหรือโพสต์แบบสาธารณะเกี่ยวกับบริษัท ',
                                ].map((item, index) => (
                                    <StyledLi key={`privacyProcess-${index}`}>
                                        <Text fontSize='sm' color='heavyBlack'>
                                            {item}
                                        </Text>
                                    </StyledLi>
                                ))}
                            </StyledUl>

                            <Text fontSize='md' color='heavyBlack'>
                                ข้อมูลการตลาด
                            </Text>

                            <Text fontSize='sm' color='heavyBlack'>
                                บริษัทจะส่งข้อมูลเกี่ยวกับผลิตภัณฑ์หรือบริการของเราให้คุณเป็นครั้งคราวหากคุณสมัครสมาชิกเพื่อรับข้อมูลผ่านจดหมายข่าว
                                อีเมล หรือช่องทางการสื่อสารอื่น ๆ ของเรา
                                อย่างไรก็ตามคุณสามารถเปลี่ยนวิธีการรับข้อมูลหรือยกเลิกบริการดังกล่าวได้ตลอดเวลาโดยใช้วิธีการเดียวกับที่คุณสมัครรับข้อมูลดังกล่าวจากบริษัท
                                หรือคุณจะติดต่อบริษัทเพื่อจุดประสงค์นี้ก็ได้เช่นกัน
                            </Text>

                            <Text fontSize='md' color='heavyBlack'>
                                3.2 ข้อมูลส่วนบุคคลที่ละเอียดอ่อน
                            </Text>
                            <Text fontSize='sm' color='heavyBlack'>
                                ในการดำเนินธุรกิจตามปกติของเรา
                                เราอาจได้รับข้อมูลส่วนบุคคลที่ละเอียดอ่อนเกี่ยวกับคุณ
                                ยกตัวอย่างเช่น
                                บัตรประจำตัวประชาชนที่เรารวบรวมในการดำเนินธุรกิจตามปกติจะมีข้อมูลส่วนบุคคลที่ละเอียดอ่อน
                                (เช่น ศาสนา)
                                เมื่อมีความจำเป็นต้องประมวลผลข้อมูลส่วนบุคคลที่ละเอียดอ่อนเกี่ยวกับคุณ
                                เราจะดำเนินการเพื่อวัตถุประสงค์และฐานทางกฎหมายต่างๆดังต่อไปนี้:
                            </Text>

                            <StyledOl>
                                {[
                                    'เราอาจประมวลผลข้อมูลส่วนบุคคลที่ละเอียดอ่อนในกรณีที่การประมวลผลมีความจำเป็นสำหรับการก่อตั้งสิทธิเรียกร้องตามกฎหมาย การปฏิบัติตามหรือการใช้สิทธิเรียกร้องตามกฎหมาย (เช่น การดำเนินคดี) หรือการยกขึ้นต่อสู้สิทธิเรียกร้องตามกฎหมาย (เช่น เพื่อเตรียมความพร้อมในการต่อสู้คดีในศาลหรือกระบวนการใด ๆ ที่ดำเนินการโดยเจ้าหน้าที่รัฐ)',
                                    'เราอาจประมวลผลข้อมูลส่วนบุคคลที่ละเอียดอ่อนเกี่ยวกับคุณเพื่อป้องกันหรือระงับอันตรายที่จะเกิดขึ้นกับคุณหรือชีวิต ร่างกายหรือสุขภาพของบุคคลอื่นเมื่อคุณไม่สามารถให้ความยินยอมได้',
                                    'เราอาจประมวลผลข้อมูลส่วนบุคคลที่ละเอียดอ่อนเพื่อวัตถุประสงค์อื่นเฉพาะในกรณีที่คุณให้ความยินยอมกับเราอย่างชัดแจ้งในการประมวลผลดังกล่าวหรือเมื่อข้อมูลส่วนบุคคลที่ละเอียดอ่อนเกี่ยวกับคุณที่คุณยินยอมให้เปิดเผยต่อสาธารณะแล้วเท่านั้น (เช่น ในโซเชียลมีเดีย)',
                                    'เราอาจต้องประมวลผลข้อมูลส่วนบุคคลที่ละเอียดอ่อนเมื่อการประมวลผลมีความจำเป็นเพื่อปฏิบัติตามกฎหมายหรือเพื่อวัตถุประสงค์อื่นตามที่กฎหมายกำหนด และ',
                                    'ฐานทางกฎหมายอื่น ๆ ตามที่กฎหมายบัญญัติให้ทำได้',
                                ].map((item, index) => (
                                    <StyledLiDecimal
                                        key={`sensitivePersonalInformation-${index}`}
                                    >
                                        <Text fontSize='sm' color='heavyBlack'>
                                            {item}
                                        </Text>
                                    </StyledLiDecimal>
                                ))}
                            </StyledOl>
                        </VStack>
                        <VStack space={3}>
                            <Text
                                fontSize='lg'
                                fontWeight={500}
                                color='heavyBlack'
                            >
                                4. วิธีการ/แหล่งข้อมูลที่บริษัท
                                ใช้ในการรวบรวมข้อมูลส่วนบุคคลของคุณ
                            </Text>
                            <Text fontSize='md' color='heavyBlack'>
                                บริษัทเป็นเจ้าของเว็บไซต์และแอพพลิเคชั่นต่อไปนี้
                            </Text>

                            <StyledUl>
                                <StyledLi>
                                    <Text fontSize='sm' color='heavyBlack'>
                                        https://medimatch.app/
                                    </Text>
                                </StyledLi>
                                <StyledLi>
                                    <Text fontSize='sm' color='heavyBlack'>
                                        เว็บไซต์หรือแอพพลิเคชั่นอื่น ๆ
                                        ที่เราอาจเป็นเจ้าของในอนาคตและบริการออนไลน์ของบริษัท
                                    </Text>
                                </StyledLi>
                                <Text fontSize='sm' color='heavyBlack'>
                                    นอกเหนือจากข้างต้นคุณอาจติดต่อกับบริษัทผ่านช่องทางอื่น
                                    ๆ
                                    ซึ่งคุณอาจให้ข้อมูลส่วนบุคคลของคุณกับเราได้
                                    เช่น
                                </Text>
                                {[
                                    'คุณลงทะเบียนข้อมูลส่วนบุคคลของคุณเมื่อคุณเปิดบัญชีผู้ใช้บริการใหม่ หรือบัญชีผู้ประกอบการใหม่ หรือบัญชีคู่ค้าทางธุรกิจของคุณเพื่อรับบริการจากบริษัท',
                                    'คุณติดต่อกับบริษัท กับตัวแทนของเราหรือกับคู่ค้าทางธุรกิจของเราผ่านช่องทางออนไลน์หรือออฟไลน์',
                                    'คุณสมัครรับโฆษณาหรือข่าวการตลาดของเรา หรือเข้าร่วมในแคมเปญการตลาดใด ๆ',
                                    'คุณได้เข้าร่วมในกิจกรรมต่าง ๆ ของบริษัท เช่น การถ่ายภาพในระหว่างการสัมมนาหรือในกรณีที่คุณเข้าร่วมกิจกรรมประชาสัมพันธ์อื่น ๆ',
                                    'เราอาจรวบรวมหรือได้รับข้อมูลส่วนบุคคลของคุณที่คุณเผยแพร่สู่สาธารณะรวมถึงผ่านโซเชียลมีเดีย (เช่น โปรไฟล์โซเชียลมีเดียที่คุณโพสต์แบบสาธารณะ)',
                                    'คุณเยี่ยมชมเว็บไซต์หรือแอพพลิเคชั่น หรือโซเชียลมีเดียใด ๆ ของเรา',
                                    'เราอาจได้รับข้อมูลส่วนบุคคลเกี่ยวกับคุณจากบุคคลที่สามหากคุณเลือกที่จะดำเนินการกับคอนเทนต์หรือการโฆษณาของบุคคลที่สามใด ๆ บนเว็บไซต์หรือแอพพลิเคชั่น',
                                    'เราอาจได้รับข้อมูลส่วนบุคคลของคุณจากบุคคลที่สามที่มอบข้อมูลส่วนบุคคลให้เรา (เช่น หน่วยงานสินเชื่อ ศาลหรือหน่วยงานรัฐ)',
                                    'เราอาจสร้างข้อมูลส่วนบุคคลเกี่ยวกับคุณ เช่น บันทึกการสื่อสารระหว่างคุณกับเรา รวมถึงการเข้าร่วมกิจกรรมหรือการสัมภาษณ์ เราอาจบันทึกการสนทานาทางโทรศัพท์ การประชุมและการสนทนาอื่น ๆ ที่คุณสื่อสารกับเราตามกฎหมาย',
                                ].map((item, index) => (
                                    <StyledLi key={`otherContacts-${index}`}>
                                        <Text fontSize='sm' color='heavyBlack'>
                                            {item}
                                        </Text>
                                    </StyledLi>
                                ))}
                            </StyledUl>
                        </VStack>
                        <VStack space={3}>
                            <Text
                                fontSize='lg'
                                fontWeight={500}
                                color='heavyBlack'
                            >
                                5. วัตถุประสงค์ของการประมวลผลข้อมูลส่วนบุคคล
                            </Text>
                            <Text fontSize='md' color='heavyBlack'>
                                บริษัทอาจประมวลผลข้อมูลส่วนบุคคลของคุณเพื่อวัตถุประสงค์ต่าง
                                ๆ ดังต่อไปนี้
                            </Text>

                            <table>
                                <thead>
                                    <tr>
                                        <StyledTh style={{ width: '50%' }}>
                                            <Text color='heavyBlack'>
                                                วัตถุประสงค์
                                            </Text>
                                        </StyledTh>
                                        <StyledTh style={{ width: '50%' }}>
                                            <Text color='heavyBlack'>
                                                ฐานทางกฎหมาย
                                            </Text>
                                        </StyledTh>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <StyledTd>
                                            <StyledUl>
                                                <StyledLi>
                                                    <Text
                                                        fontSize='sm'
                                                        color='heavyBlack'
                                                    >
                                                        เพื่อการดำเนินการให้บริการสำหรับผู้ใช้บริการหรือผู้ประกอบการ
                                                        หรือการลงทะเบียนการเข้าร่วมกิจกรรมของบริษัท
                                                    </Text>
                                                </StyledLi>

                                                <StyledLi>
                                                    <Text
                                                        fontSize='sm'
                                                        color='heavyBlack'
                                                    >
                                                        เพื่อยืนยันและระบุตัวตนก่อนเข้าสู่ระบบการให้บริการของบริษัท
                                                    </Text>
                                                </StyledLi>

                                                <StyledLi>
                                                    <Text
                                                        fontSize='sm'
                                                        color='heavyBlack'
                                                    >
                                                        เพื่อการให้บริการค้นหางานและเป็นตัวกลางในการสมัครหรือรับสมัครงาน
                                                        ตลอดจนการลงโฆษณาประกาศรับสมัครงาน
                                                        หรือโฆษณาในรูปแบบอื่น
                                                    </Text>
                                                </StyledLi>

                                                <StyledLi>
                                                    <Text
                                                        fontSize='sm'
                                                        color='heavyBlack'
                                                    >
                                                        เพื่อใช้เป็นข้อมูลสำหรับตรวจสอบและบันทึกการผิดนัดสัมภาษณ์
                                                        หรือการกระทำที่ไม่เหมาะสมอื่น
                                                        ๆ
                                                        ของผู้ใช้บริการจากผู้ประกอบการ
                                                    </Text>
                                                </StyledLi>
                                            </StyledUl>
                                        </StyledTd>
                                        <StyledTd>
                                            <Text
                                                fontSize='sm'
                                                color='heavyBlack'
                                            >
                                                เราจะประมวลผลข้อมูลส่วนบุคคลเมื่อ
                                            </Text>
                                            <StyledUl>
                                                <StyledLi>
                                                    <Text
                                                        fontSize='sm'
                                                        color='heavyBlack'
                                                    >
                                                        เราได้รับความยินยอมจากคุณ
                                                        หรือ
                                                    </Text>
                                                </StyledLi>
                                                <StyledLi>
                                                    <Text
                                                        fontSize='sm'
                                                        color='heavyBlack'
                                                    >
                                                        มีความจำเป็นเพื่อการปฏิบัติตามสัญญาที่คุณทำไว้กับเราหรือเพื่อให้เราดำเนินการตามคำขอของคุณก่อนที่จะทำสัญญา{' '}
                                                    </Text>
                                                </StyledLi>
                                            </StyledUl>
                                        </StyledTd>
                                    </tr>

                                    <tr>
                                        <StyledTd>
                                            <Text
                                                fontSize='sm'
                                                color='heavyBlack'
                                            >
                                                สำหรับติดต่อสอบถามและแนะนำข้อมูลเพิ่มเติมเกี่ยวกับการให้บริการ
                                                การนำเสนอบริการใหม่
                                                การส่งข่าวสาร โฆษณาและข้อมูลอื่น
                                                ๆ
                                                ที่เกี่ยวข้องกับผลิตภัณฑ์และบริการของบริษัท
                                                บริษัทในเครือและหรือคู่ค้าทางธุรกิจ{' '}
                                            </Text>
                                        </StyledTd>

                                        <StyledTd>
                                            <Text
                                                fontSize='sm'
                                                color='heavyBlack'
                                            >
                                                เราจะประมวลผลข้อมูลส่วนบุคคลเมื่อ
                                            </Text>
                                            <StyledUl>
                                                <StyledLi>
                                                    <Text
                                                        fontSize='sm'
                                                        color='heavyBlack'
                                                    >
                                                        เราได้รับความยินยอมจากคุณ
                                                    </Text>
                                                </StyledLi>
                                                <StyledLi>
                                                    <Text
                                                        fontSize='sm'
                                                        color='heavyBlack'
                                                    >
                                                        มีความจำเป็นเพื่อการป้องกันหรือปราบปรามอันตรายต่อชีวิต
                                                        ร่างกายหรือสุขภาพ
                                                    </Text>
                                                </StyledLi>
                                                <StyledLi>
                                                    <Text
                                                        fontSize='sm'
                                                        color='heavyBlack'
                                                    >
                                                        มีความจำเป็นเพื่อการปฏิบัติตามสัญญาที่คุณทำไว้กับเราหรือเพื่อให้เราดำเนินการตามคำขอของคุณก่อนที่จะทำสัญญา
                                                        หรือ
                                                    </Text>
                                                </StyledLi>
                                                <StyledLi>
                                                    <Text
                                                        fontSize='sm'
                                                        color='heavyBlack'
                                                    >
                                                        มีความจำเป็นที่จะต้องดำเนินการเพื่อผลประโยชน์ที่ชอบด้วยกฎหมายของบริษัทซึ่งไม่ได้สำคัญน้อยกว่าสิทธิและเสรีภาพขั้นพื้นฐานของคุณ
                                                    </Text>
                                                </StyledLi>
                                            </StyledUl>
                                        </StyledTd>
                                    </tr>
                                    <tr>
                                        <StyledTd>
                                            <Text
                                                fontSize='sm'
                                                color='heavyBlack'
                                            >
                                                เพื่อการจัดการและการปรับปรุงคุณภาพเว็บไซต์หรือแอพพลิเคชั่น
                                                และบริการเพื่อให้บริการที่เหมาะสมและน่าพอใจ
                                                รวมถึงการจัดทำสถิติที่ไม่ระบุตัวตนเพื่อวัตถุประสงค์ทางการตลาด
                                            </Text>
                                        </StyledTd>

                                        <StyledTd>
                                            <Text
                                                fontSize='sm'
                                                color='heavyBlack'
                                            >
                                                เราจะประมวลผลข้อมูลส่วนบุคคลเมื่อ
                                            </Text>
                                            <StyledUl>
                                                <StyledLi>
                                                    <Text
                                                        fontSize='sm'
                                                        color='heavyBlack'
                                                    >
                                                        เราได้รับความยินยอมจากคุณ
                                                    </Text>
                                                </StyledLi>
                                                <StyledLi>
                                                    <Text
                                                        fontSize='sm'
                                                        color='heavyBlack'
                                                    >
                                                        มีความจำเป็นเพื่อการป้องกันหรือปราบปรามอันตรายต่อชีวิต
                                                        ร่างกายหรือสุขภาพ
                                                    </Text>
                                                </StyledLi>
                                                <StyledLi>
                                                    <Text
                                                        fontSize='sm'
                                                        color='heavyBlack'
                                                    >
                                                        มีความจำเป็นเพื่อการปฏิบัติตามสัญญาที่คุณทำไว้กับเราหรือเพื่อให้เราดำเนินการตามคำขอของคุณก่อนที่จะทำสัญญา
                                                        หรือ
                                                    </Text>
                                                </StyledLi>
                                                <StyledLi>
                                                    <Text
                                                        fontSize='sm'
                                                        color='heavyBlack'
                                                    >
                                                        มีความจำเป็นที่จะต้องดำเนินการเพื่อผลประโยชน์ที่ชอบด้วยกฎหมายของบริษัทซึ่งไม่ได้สำคัญน้อยกว่าสิทธิและเสรีภาพขั้นพื้นฐานของคุณ
                                                    </Text>
                                                </StyledLi>
                                            </StyledUl>
                                        </StyledTd>
                                    </tr>
                                    <tr>
                                        <StyledTd>
                                            <Text
                                                fontSize='sm'
                                                color='heavyBlack'
                                            >
                                                เพื่อการถ่ายโอนข้อมูลใด ๆ
                                                ในกรณีของการโอนธุรกิจ
                                            </Text>
                                        </StyledTd>

                                        <StyledTd>
                                            <Text
                                                fontSize='sm'
                                                color='heavyBlack'
                                            >
                                                เราจะประมวลผลข้อมูลส่วนบุคคลเมื่อ
                                            </Text>
                                            <StyledUl>
                                                <StyledLi>
                                                    <Text
                                                        fontSize='sm'
                                                        color='heavyBlack'
                                                    >
                                                        เราได้รับความยินยอมจากคุณ
                                                    </Text>
                                                </StyledLi>

                                                <StyledLi>
                                                    <Text
                                                        fontSize='sm'
                                                        color='heavyBlack'
                                                    >
                                                        มีความจำเป็นเพื่อการปฏิบัติตามสัญญาที่คุณทำไว้กับเราหรือเพื่อให้เราดำเนินการตามคำขอของคุณก่อนที่จะทำสัญญา
                                                        หรือ
                                                    </Text>
                                                </StyledLi>
                                                <StyledLi>
                                                    <Text
                                                        fontSize='sm'
                                                        color='heavyBlack'
                                                    >
                                                        มีความจำเป็นที่จะต้องดำเนินการเพื่อผลประโยชน์ที่ชอบด้วยกฎหมายของบริษัทซึ่งไม่ได้สำคัญน้อยกว่าสิทธิและเสรีภาพขั้นพื้นฐานของคุณ
                                                    </Text>
                                                </StyledLi>
                                            </StyledUl>
                                        </StyledTd>
                                    </tr>
                                    <tr>
                                        <StyledTd>
                                            <Text
                                                fontSize='sm'
                                                color='heavyBlack'
                                            >
                                                เพื่อตรวจสอบการประพฤติมิชอบหรือการฉ้อโกงและเพื่อมาตรการรักษาความปลอดภัย
                                            </Text>
                                        </StyledTd>

                                        <StyledTd>
                                            <Text
                                                fontSize='sm'
                                                color='heavyBlack'
                                            >
                                                เราจะประมวลผลข้อมูลส่วนบุคคลเมื่อ
                                            </Text>
                                            <StyledUl>
                                                <StyledLi>
                                                    <Text
                                                        fontSize='sm'
                                                        color='heavyBlack'
                                                    >
                                                        มีความจำเป็นเพื่อการปฏิบัติตามสัญญาที่คุณทำไว้กับเราหรือเพื่อให้เราดำเนินการตามคำขอของคุณก่อนที่จะทำสัญญา
                                                        หรือ
                                                    </Text>
                                                </StyledLi>
                                                <StyledLi>
                                                    <Text
                                                        fontSize='sm'
                                                        color='heavyBlack'
                                                    >
                                                        มีความจำเป็นที่จะต้องดำเนินการเพื่อผลประโยชน์ที่ชอบด้วยกฎหมายของบริษัทซึ่งไม่ได้สำคัญน้อยกว่าสิทธิและเสรีภาพขั้นพื้นฐานของคุณ
                                                    </Text>
                                                </StyledLi>
                                            </StyledUl>
                                        </StyledTd>
                                    </tr>
                                    <tr>
                                        <StyledTd>
                                            <Text
                                                fontSize='sm'
                                                color='heavyBlack'
                                            >
                                                เพื่อการเปิดเผยข้อมูลส่วนบุคคล
                                                ใด ๆ
                                                ที่เกี่ยวข้องกับกระบวนการทางกฎหมายหรือคำสั่งศาลหรือหน่วยงานใด
                                                ๆ ภายใต้กฎหมาย
                                            </Text>
                                        </StyledTd>

                                        <StyledTd>
                                            <Text
                                                fontSize='sm'
                                                color='heavyBlack'
                                            >
                                                เราจะประมวลผลข้อมูลส่วนบุคคลเมื่อ
                                            </Text>
                                            <StyledUl>
                                                <StyledLi>
                                                    <Text
                                                        fontSize='sm'
                                                        color='heavyBlack'
                                                    >
                                                        เราได้รับความยินยอมจากคุณ
                                                    </Text>
                                                </StyledLi>
                                                <StyledLi>
                                                    <Text
                                                        fontSize='sm'
                                                        color='heavyBlack'
                                                    >
                                                        มีความจำเป็นที่จะต้องดำเนินการเพื่อผลประโยชน์ที่ชอบด้วยกฎหมายของบริษัทซึ่งไม่ได้สำคัญน้อยกว่าสิทธิและเสรีภาพขั้นพื้นฐานของคุณ
                                                        หรือ
                                                    </Text>
                                                </StyledLi>
                                                <StyledLi>
                                                    <Text
                                                        fontSize='sm'
                                                        color='heavyBlack'
                                                    >
                                                        มีความจำเป็นต้องปฏิบัติตามกฎหมาย
                                                    </Text>
                                                </StyledLi>
                                            </StyledUl>
                                        </StyledTd>
                                    </tr>
                                    <tr>
                                        <StyledTd>
                                            <Text
                                                fontSize='sm'
                                                color='heavyBlack'
                                            >
                                                เพื่อปกป้องสิทธิทางกฎหมายของบริษัทและผู้ที่เกี่ยวข้อง
                                            </Text>
                                        </StyledTd>

                                        <StyledTd>
                                            <StyledUl>
                                                <StyledLi>
                                                    <Text
                                                        fontSize='sm'
                                                        color='heavyBlack'
                                                    >
                                                        มีความจำเป็นที่จะต้องดำเนินการเพื่อผลประโยชน์ที่ชอบด้วยกฎหมายของบริษัทซึ่งไม่ได้สำคัญน้อยกว่าสิทธิและเสรีภาพขั้นพื้นฐานของคุณ
                                                        หรือ
                                                    </Text>
                                                </StyledLi>
                                                <StyledLi>
                                                    <Text
                                                        fontSize='sm'
                                                        color='heavyBlack'
                                                    >
                                                        มีความจำเป็นต้องปฏิบัติตามกฎหมาย
                                                    </Text>
                                                </StyledLi>
                                            </StyledUl>
                                        </StyledTd>
                                    </tr>
                                    <tr>
                                        <StyledTd>
                                            <Text
                                                fontSize='sm'
                                                color='heavyBlack'
                                            >
                                                เพื่อวัตถุประสงค์ที่ถูกกฎหมายอื่น
                                                ๆ
                                            </Text>
                                        </StyledTd>

                                        <StyledTd>
                                            <StyledUl>
                                                <StyledLi>
                                                    <Text
                                                        fontSize='sm'
                                                        color='heavyBlack'
                                                    >
                                                        เราได้รับความยินยอมจากคุณ
                                                        หรือ
                                                    </Text>
                                                </StyledLi>
                                                <StyledLi>
                                                    <Text
                                                        fontSize='sm'
                                                        color='heavyBlack'
                                                    >
                                                        มีความจำเป็นที่จะต้องดำเนินการเพื่อผลประโยชน์ที่ชอบด้วยกฎหมายของบริษัทซึ่งไม่ได้สำคัญน้อยกว่าสิทธิและเสรีภาพขั้นพื้นฐานของคุณ
                                                    </Text>
                                                </StyledLi>
                                            </StyledUl>
                                        </StyledTd>
                                    </tr>
                                </tbody>
                            </table>

                            <Text fontSize='sm' color='heavyBlack'>
                                จากที่กล่าวข้างต้น
                                เราอาจประมวลผลข้อมูลส่วนบุคคลเกี่ยวกับคุณโดยไม่จำเป็นต้องได้รับความยินยอมอย่างชัดแจ้งจากคุณเมื่อมีความจำเป็นเพื่อการปฏิบัติตามสัญญาที่คุณทำไว้กับเรา
                                เพื่อให้เราดำเนินการตามคำขอของคุณก่อนทำสัญญา
                                เพื่อให้เราดำเนินการเพื่อผลประโยชน์ที่ชอบด้วยกฎหมายซึ่งไม่ได้สำคัญน้อยกว่าสิทธิขั้นพื้นฐานของคุณ
                                หรือเพื่อปฏิบัติตามกฎหมายหรือเพื่อประโยชน์สาธารณะอื่น
                                ๆ
                                ข้อมูลส่วนบุคคลที่เราประมวลผลเพื่อวัตถุประสงค์เหล่านี้รวมถึงข้อมูลที่เกี่ยวข้องกับการระบุตัวตน
                                (เช่น ชื่อ หมายเลขบัตรประจำตัวประชาชน สัญชาติ
                                วันเดือนปีเกิด ศาสนา กลุ่มเลือด ที่อยู่ เพศ
                                ความสูง และรูปถ่ายในสำเนาบัตรประจำตัวประชาชน
                                ที่อยู่ และเพศในสำเนาทะเบียนบ้าน บัญชีธนาคาร
                                สำเนาสมุดคู่ฝาก)
                                หากเราไม่ได้รับข้อมูลส่วนบุคคลข้างต้น
                                เราอาจไม่สามารถระบุตัวคุณได้และดังนั้นเราอาจไม่สามารถดำเนินการในเรื่องดังกล่าวก่อนที่จะทำสัญญาหรือเราอาจไม่สามารถทำสัญญากับคุณได้
                                นอกจากนี้เราอาจไม่สามารถปฏิบัติตามสัญญาที่เราได้ทำไว้กับคุณได้อีกด้วย
                            </Text>
                        </VStack>
                        <VStack space={3}>
                            <Text
                                fontSize='lg'
                                fontWeight={500}
                                color='heavyBlack'
                            >
                                6. การแชร์/การถ่ายโอนข้อมูลส่วนบุคคลภายในประเทศ
                            </Text>
                            <Text fontSize='md' color='heavyBlack'>
                                เราแชร์หรือถ่ายโอนข้อมูลส่วนบุคคลเมื่อ
                            </Text>

                            <StyledUl>
                                {[
                                    'เราได้รับความยินยอมอย่างชัดแจ้งจากคุณ',
                                    'มีความจำเป็นในการป้องกันหรือระงับอันตรายต่อชีวิต ร่างกายหรือสุขภาพของบุคคล',
                                    'มีความจำเป็นเพื่อการปฏิบัติตามสัญญาที่คุณได้ทำไว้หรือเพื่อให้เราดำเนินการตามคำขอของคุณก่อนที่จะทำสัญญา',
                                    'มีความจำเป็นเพื่อผลประโยชน์ที่ชอบด้วยกฎหมายของบริษัทซึ่งไม่ได้สำคัญน้อยกว่าสิทธิขั้นพื้นฐานของคุณ หรือเพื่อปฏิบัติตามกฎหมายหรือเพื่อประโยชน์สาธารณะอื่น ๆ',
                                    'วัตถุประสงค์อื่น ๆ ตามที่เรามีหน้าที่ตามกฎหมายต่อหน่วยงานรัฐหรือกรณีอื่นตามที่กฎหมายบัญญัติอนุญาตให้ทำได้',
                                ].map((item, index) => (
                                    <StyledLi key={`shareInfoWhen-${index}`}>
                                        <Text fontSize='sm' color='heavyBlack'>
                                            {item}
                                        </Text>
                                    </StyledLi>
                                ))}
                            </StyledUl>

                            <Text fontSize='md' color='heavyBlack'>
                                บริษัทจะทำตรวจสอบว่าองค์กรที่เราอาจแชร์ข้อมูลส่วนบุคคลเกี่ยวกับคุณนั้นมีมาตรฐานการคุ้มครองข้อมูลส่วนบุคคลที่เพียงพอ
                            </Text>
                            <Text fontSize='md' color='heavyBlack'>
                                เนื่องจากเหตุผลข้างต้น
                                บริษัทอาจเปิดเผยหรือแชร์ข้อมูลส่วนบุคคลที่เราเก็บรวบรวมให้กับบุคคลต่อไปนี้ในการดำเนินธุรกิจตามปกติของเรา
                            </Text>

                            <StyledOl>
                                {[
                                    'บริษัทอาจเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้บริการโดยการส่งประวัติ ใบสมัครงาน หรือข้อมูลของคุณไปยังผู้ประกอบการที่ลงประกาศตำแหน่งงานนั้น ๆ หรือไปยังบุคคลภายนอก ซึ่งมีส่วนเกี่ยวข้องกับการให้บริการแก่คุณ ได้แก่ บริษัทประกันภัย ซึ่งเป็นผู้ให้บริการคุ้มครองสิทธิค่าบริการรักษาพยาบาลของคุณ ธนาคารผู้ให้บริการรับชำระเงินค่าบริการผ่านทางออนไลน์ หรือบริษัทผู้สนับสนุนการให้บริการอื่น ๆ ที่จำเป็นสำหรับการให้บริการของบริษัท',
                                    'บริษัทอาจเปิดเผยข้อมูลติดต่อที่ระบุไว้ในระบบของบริษัท หรือข้อมูลอื่นที่ผู้ประกอบการให้ไว้ผ่านช่องทางต่าง ๆ ซึ่งอาจเป็นข้อมูลส่วนบุคคลของพนักงาน เจ้าหน้าที่ บุคลากรภายใน หรือบุคคลที่เกี่ยวข้องของผู้ประกอบการ ควบคู่กับรายละเอียดตำแหน่งงานที่ลงประกาศ เพื่อประโยชน์ในการรับสมัครงาน หากมี ชื่อ ภาพ หรือข้อมูลส่วนบุคคลของพนักงาน เจ้าหน้าที่ บุคลากรภายใน หรือบุคคลที่เกี่ยวข้องของผู้ประกอบการ ไม่ว่าส่วนใดส่วนหนึ่งหรือทั้งหมด',
                                    'บริษัทอาจแชร์ข้อมูลส่วนบุคคล เพื่อวัตถุประสงค์ในการสื่อสารผลิตภัณฑ์และบริการของเรา การดำเนินการตามคำขอของคุณเพื่อใช้ผลิตภัณฑ์และบริการของเราหรือการเข้าร่วมกิจกรรมของเรา การถ่ายโอนข้อมูลส่วนบุคคลใด ๆ ในกรณีของการโอนรวมธุรกิจ การตรวจสอบการประพฤติมิชอบหรือการฉ้อโกงและมาตรการรักษาความปลอดภัย การเปิดเผยข้อมูลส่วนบุคคลใด ๆ ที่เกี่ยวข้องกับกระบวนการทางกฎหมายหรือคำสั่งศาลหรือหน่วยงานใด ๆ ภายใต้กฎหมาย การปกป้องสิทธิทางกฎหมายของกลุ่มของเราและบุคคลที่เกี่ยวข้อง รวมถึงวัตถุประสงค์ที่ชอบด้วยกฎหมายอื่น ๆ',
                                    'ผู้ให้บริการด้านวิชาชีพ เช่น นักกฎหมาย นักบัญชีหรือผู้ตรวจสอบบัญชีที่มีหน้าที่ตามสัญญาในการรักษาความลับของบริษัท',
                                    'ผู้ประมวลผลที่เป็นบุคคลที่สาม เช่น การให้บริการคลาวด์ บริการโฮสต์ข้อมูลส่วนบุคคล และบริการระบบไอที',
                                    'บุคคลที่เกี่ยวข้อง ศาล หน่วยงานรัฐ รัฐวิสาหกิจ (เช่น การให้บริการไฟฟ้า/ประปา) และหน่วยงานบังคับใช้กฎหมาย',
                                    'ผู้ซื้อที่เป็นบุคคลที่สามที่เกี่ยวข้อง คู่ค้าในกรณีที่เราขายหรือโอนธุรกิจหรือสินทรัพย์ของเราทั้งหมดหรือส่วนหนึ่งส่วนใด (รวมถึงในกรณีของการปรับโครงสร้าง การเลิกกิจการหรือการชำระบัญชี)',
                                    'บุคคลที่สามที่เกี่ยวข้องซึ่งเป็นผู้ให้บริการ/ผลิตภัณฑ์ซึ่งเว็บไซต์หรือแอพปพลิเคชั่นของเราใช้ปลั๊กอินหรือเนื้อหาโฆษณาของบุคคลที่สาม ในกรณีดังกล่าว คุณควรรับทราบว่า แม้เราจะตรวจสอบว่าบุคคลที่สามนั้นมีมาตรฐานการคุ้มครองข้อมูลส่วนบุคคลที่เพียงพอหรือไม่ก็ตาม แต่หากคุณเลือกที่จะดำเนินการสื่อสารกับโฆษณา ปลั๊กอินหรือคอนเทนต์ดังกล่าว ข้อมูลส่วนบุคคลของคุณจะถูกประมวลผลโดยบุคคลที่สาม ดังนั้นเราขอแนะนำให้คุณอ่านประกาศนโยบายคุ้มครองข้อมูลส่วนบุคคลของบุคคลที่สามอย่างละเอียดก่อนที่จะดำเนินการสื่อสารหรือดำเนินการกับบุคคลที่สามดังกล่าวต่อไป',
                                ].map((item, index) => (
                                    <StyledLiNumber
                                        number='6'
                                        key={`mightShare-${index}`}
                                    >
                                        <Text fontSize='sm' color='heavyBlack'>
                                            {item}
                                        </Text>
                                    </StyledLiNumber>
                                ))}
                            </StyledOl>

                            <Text fontSize='md' color='heavyBlack'>
                                หากการโอนข้อมูลส่วนบุคคลใด ๆ
                                จำเป็นต้องได้รับความยินยอม
                                บริษัทจะดำเนินการขอความยินยอมดังกล่าวก่อนที่จะทำการโอนดังกล่าว
                            </Text>
                        </VStack>
                        <VStack space={3}>
                            <Text
                                fontSize='lg'
                                fontWeight={500}
                                color='heavyBlack'
                            >
                                7. การโอนข้อมูลส่วนบุคคลระหว่างประเทศ
                            </Text>
                            <Text fontSize='md' color='heavyBlack'>
                                เนื่องจากลักษณะการดำเนินธุรกิจของเราและเพื่อวัตถุประสงค์ที่ระบุไว้ในข้อ
                                5-6 เราอาจส่ง ถ่ายโอน
                                แชร์หรือส่งข้อมูลส่วนบุคคลเกี่ยวกับคุณให้กับบุคคลต่าง
                                ๆ ในประเทศอื่น ๆ เมื่อการถ่ายโอน การแชร์
                                หรือการส่งมีฐานทางกฎหมายที่ถูกต้องตามที่ระบุในข้อ
                                6
                                และหากผู้รับข้อมูลส่วนบุคคลมีการคุ้มครองข้อมูลส่วนบุคคลที่เพียงพอตามประกาศของคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล
                                เราอาจแชร์หรือถ่ายโอนข้อมูลส่วนบุคคลแม้ว่าประเทศดังกล่าวจะไม่มีมาตรการคุ้มครองข้อมูลส่วนบุคคลที่เพียงพอเฉพาะในกรณีดังต่อไปนี้
                            </Text>

                            <StyledUl>
                                {[
                                    'การโอนหรือแชร์มีความจำเป็นเพื่อปฏิบัติตามหน้าที่ทางกฎหมาย',
                                    'เราได้รับความยินยอมอย่างชัดแจ้งจากคุณในการโอน/การแชร์โดยคุณได้รับแจ้งอย่างครบถ้วนถึงรายละเอียดของมาตรการคุ้มครองข้อมูลส่วนบุคคลที่ไม่เพียงพอของประเทศอื่นนั้นแล้ว',
                                    'มีความจำเป็นเพื่อวัตถุประสงค์ในการทำสัญญาหรือดำเนินการกับเรื่องตามคำขอของคุณก่อนที่จะทำสัญญา',
                                    'มีความจำเป็นสำหรับการทำสัญญาระหว่างบริษัทกับบุคคลหรือนิติบุคคลอื่นเพื่อผลประโยชน์ของคุณ',
                                    'มีความจำเป็นเพื่อการป้องกันหรือระงับอันตรายต่อชีวิต ร่างกายหรือสุขภาพหรือผลประโยชน์สาธารณะอื่น ๆ',
                                    'มีความจำเป็นเพื่อการดำเนินกิจกรรมที่เกี่ยวข้องกับผลประโยชน์สาธารณะที่สำคัญอย่างน้อยหนึ่งอย่างตามที่กฎหมายอนุญาต',
                                    'เมื่อกฎหมายได้บัญญัติให้ต้องมีการถ่ายโอนหรือการแชร์หรือเป็นกรณีที่กฎหมายอนุญาตให้สามารถดำเนินการดังกล่าวได้ ',
                                ].map((item, index) => (
                                    <StyledLi
                                        key={`shareInterNational-${index}`}
                                    >
                                        <Text fontSize='sm' color='heavyBlack'>
                                            {item}
                                        </Text>
                                    </StyledLi>
                                ))}
                            </StyledUl>

                            <Text fontSize='md' color='heavyBlack'>
                                นอกจากนี้เรายังอาจโอนหรือแชร์ข้อมูลส่วนบุคคลไปยังบริษัทย่อยหรือบริษัทในเครือของเราในต่างประเทศตามกฎหมายเพื่อวัตถุประสงค์ข้างต้นตามที่ระบุไว้ในประกาศฉบับนี้
                                (ดูข้อ 5-6)้
                            </Text>
                        </VStack>

                        <VStack space={3}>
                            <Text
                                fontSize='lg'
                                fontWeight={500}
                                color='heavyBlack'
                            >
                                8. ระยะเวลาการเก็บรักษา
                            </Text>

                            <Text fontSize='md' color='heavyBlack'>
                                เกณฑ์ที่เราใช้ในการกำหนดระยะเวลาการเก็บรักษาข้อมูลมีดังนี้
                                เราจะเก็บข้อมูลส่วนบุคคลตราบเท่าที่จำเป็นในการให้บริการหรือมีปฏิสัมพันธ์กับคุณตามกฎหมายและตราบเท่าที่ถือว่ามีความสมเหตุสมผลตามจุดประสงค์ในการรวบรวมข้อมูลส่วนบุคคล
                                โดยเฉพาะอย่างยิ่งเราอาจเก็บรักษาข้อมูลส่วนบุคคลของคุณในช่วงระยะเวลาที่จำเป็นเพื่อกำหนด
                                ใช้ หรือปกป้องสิทธิทางกฎหมายใด ๆ
                            </Text>
                            <Text fontSize='md' color='heavyBlack'>
                                หากคุณต้องการที่จะลบข้อมูลส่วนบุคคลของคุณคุณสามารถขอให้เราลบข้อมูลส่วนบุคคลของคุณได้ผ่านช่องทางต่าง
                                ๆ เช่น ผ่านเว็บไซต์ของบริษัท หรือทางอีเมลที่
                                info@medimatch.app หรือทางไลน์แอดที่ @medimatch
                                วันจันทร์ถึงวันศุกร์เวลา 08.00 น. ถึง 17.00 น.
                                เราจะพิจารณาคำขอเป็นรายกรณี
                            </Text>
                        </VStack>
                        <VStack space={3}>
                            <Text
                                fontSize='lg'
                                fontWeight={500}
                                color='heavyBlack'
                            >
                                9. สิทธิของคุณ
                            </Text>

                            <Text fontSize='md' color='heavyBlack'>
                                คุณมีสิทธิต่อไปนี้ภายใต้ PDPA
                            </Text>

                            <table>
                                <thead>
                                    <tr>
                                        <StyledTh></StyledTh>
                                        <StyledTh>
                                            <Text color='heavyBlack'>
                                                สิทธิของเจ้าของข้อมูลส่วนบุคคล
                                                ส่วนบุคคล
                                            </Text>
                                        </StyledTh>
                                        <StyledTh>
                                            <Text color='heavyBlack'>
                                                รายละเอียด
                                            </Text>
                                        </StyledTh>
                                    </tr>
                                </thead>
                                <tbody>
                                    {[
                                        {
                                            right: 'สิทธิในการเข้าถึง',
                                            detail: 'คุณมีสิทธิในการเข้าถึงและรับสำเนาข้อมูลส่วนบุคคลที่เรามีเกี่ยวกับตัวคุณ หรือคุณอาจขอให้เราเปิดเผยแหล่งที่เราได้รับข้อมูลของคุณซึ่งคุณไม่ได้ให้ความยินยอม เราจะไม่สามารถให้การเข้าถึงดังกล่าวแก่คุณได้หากเราถูกห้ามโดยกฎหมายหรือคำสั่งศาลและหากการเข้าถึงดังกล่าวจะทำให้สิทธิและเสรีภาพของบุคคลอื่นได้รับผลกระทบ',
                                        },
                                        {
                                            right: 'สิทธิในการโอนย้ายข้อมูล',
                                            detail: 'คุณมีสิทธิที่จะขอให้เราถ่ายโอนข้อมูลส่วนบุคคลของคุณไปยังบุคคล/องค์กรอื่น หรือขอดูข้อมูลส่วนบุคคลที่เราถ่ายโอนไปยังบุคคล/องค์กรอื่น ๆ เว้นแต่จะเป็นไปไม่ได้ด้วยเหตุผลทางเทคนิค',
                                        },
                                        {
                                            right: 'สิทธิในการคัดค้านการประมวลผลข้อมูลของคุณ',
                                            detail: 'คุณมีสิทธิคัดค้านการประมวลผลข้อมูลส่วนบุคคลของคุณ บริษัทเคารพสิทธิของคุณและเราจะประเมินคำขอเป็นรายกรณีตามกฎหมาย',
                                        },
                                        {
                                            right: 'สิทธิในการลบ',
                                            detail: 'คุณมีสิทธิที่จะขอให้เราลบ ทำลายหรือลบชื่อในข้อมูลส่วนบุคคลของคุณในกรณีต่อไปนี้',
                                            detailList: [
                                                'ข้อมูลส่วนบุคคลนั้นไม่มีความจำเป็นอีกต่อไปเมื่อพิจารณาวัตถุประสงค์ที่ข้อมูลดังกล่าวถูกรวบรวม ใช้หรือเปิดเผย',
                                                'คุณได้ถอนความยินยอมของคุณที่ให้มีการรวบรวม ใช้หรือเปิดเผยแล้ว',
                                                'คุณได้คัดค้านการรวบรวม การใช้หรือการเปิดเผยข้อมูลส่วนบุคคลและบริษัทไม่มีเหตุผลที่จะปฏิเสธคำขอดังกล่าว',
                                                'เมื่อข้อมูลส่วนบุคคลถูกรวบรวม ใช้หรือเปิดเผยอย่างผิดกฎหมายภายใต้พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.2562',
                                                'กรณีอื่น ๆ ตามที่กฎหมายกำหนด',
                                            ],
                                            afterDetail:
                                                'บริษัทเคารพสิทธิของคุณและเราจะลบข้อมูลส่วนบุคคลของคุณเว้นแต่ว่าบริษัทจะเห็นว่าจำเป็นที่จะต้องเก็บรักษาข้อมูลส่วนบุคคลดังกล่าวไว้',
                                        },
                                        {
                                            right: 'สิทธิในการระงับการประมวลผลข้อมูลของคุณ',
                                            detail: 'คุณมีสิทธิที่จะขอให้เราระงับการประมวลผลข้อมูลส่วนบุคคลของคุณในกรณีต่อไปนี้',
                                            detailList: [
                                                'อยู่ภายใต้กระบวนการตรวจสอบที่รอการตรวจสอบว่าข้อมูลส่วนบุคคลนั้นถูกต้อง เป็นปัจจุบันและครบถ้วนหรือไม่',
                                                'เป็นข้อมูลส่วนบุคคลที่ควรถูกลบหรือทำลายเนื่องจากไม่เป็นไปตามกฎหมายและคุณขอระงับการประมวลผลแทน',
                                                'ข้อมูลส่วนบุคคลไม่จำเป็นต้องเก็บรักษาอีกต่อไปเพื่อจุดประสงค์ที่ข้อมูลถูกรวบรวม ใช้หรือเปิดเผย แต่คุณยังมีความจำเป็นที่จะต้องเก็บรักษาเพื่อวัตถุประสงค์ในการตั้งสิทธิหรือปฏิบัติตาม หรือเพื่อการใช้สิทธิทางกฎหมายหรือการปกป้องสิทธิในทางกฎหมาย',
                                                'บริษัทกำลังพิจารณาคำขอของคุณซึ่งคัดค้านการประมวลผลข้อมูลส่วนบุคคล',
                                            ],
                                        },
                                        {
                                            right: 'สิทธิในการถอนความยินยอม',
                                            detail: 'คุณสามารถถอนความยินยอมเมื่อใดก็ได้เว้นแต่จะเป็นการขัดต่อนโยบายของบริษัท หลังจากความยินยอมถูกเพิกถอนแล้ว เราจะหยุดการประมวลผลข้อมูลส่วนบุคคลเว้นแต่จะมีฐานทางกฎหมายอื่น ๆ ที่เราสามารถประมวลผลข้อมูลส่วนบุคคลได้ต่อไป',
                                        },
                                        {
                                            right: 'สิทธิในการแก้ไข',
                                            detail: 'คุณมีสิทธิในการแก้ไขข้อมูลส่วนบุคคลที่ไม่ถูกต้องเพื่อให้ถูกต้อง เป็นปัจจุบัน ครบถ้วนและไม่ทำให้เข้าใจผิด หากบริษัทปฏิเสธคำขอของคุณบริษัทจะบันทึกการปฏิเสธดังกล่าวพร้อมเหตุผล',
                                        },
                                        {
                                            right: 'สิทธิในการยื่นเรื่องร้องเรียน',
                                            detail: 'คุณจะมีสิทธิร้องเรียนในกรณีที่บริษัทหรือผู้ประมวลผลข้อมูล รวมถึงพนักงานของบริษัท ไม่ปฏิบัติตาม พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.2562 หรือประกาศอื่น ๆ ตามกฎหมายฉบับดังกล่าว',
                                        },
                                    ].map((item, index) => (
                                        <tr key={`pdpa-${index + 1}`}>
                                            <StyledTd>
                                                <Text color='heavyBlack'>
                                                    {index + 1}
                                                </Text>
                                            </StyledTd>
                                            <StyledTd>
                                                <Text color='heavyBlack'>
                                                    {item.right}
                                                </Text>
                                            </StyledTd>
                                            <StyledTd>
                                                <Text color='heavyBlack'>
                                                    {item.detail}
                                                </Text>
                                                {!_.isEmpty(
                                                    item.detailList
                                                ) && (
                                                    <StyledOl>
                                                        {item.detailList.map(
                                                            (
                                                                detail,
                                                                detailIndex
                                                            ) => (
                                                                <StyledLiAfterParentheses
                                                                    key={`detailIndex-${detailIndex}`}
                                                                >
                                                                    <Text
                                                                        fontSize='sm'
                                                                        color='heavyBlack'
                                                                    >
                                                                        {detail}
                                                                    </Text>
                                                                </StyledLiAfterParentheses>
                                                            )
                                                        )}
                                                    </StyledOl>
                                                )}

                                                {item?.afterDetail && (
                                                    <Text color='heavyBlack'>
                                                        {item.afterDetail}
                                                    </Text>
                                                )}
                                            </StyledTd>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <Text fontSize='sm' color='heavyBlack'>
                                หากต้องการใช้สิทธิของเจ้าของข้อมูลส่วนบุคคล ใด ๆ
                                ของคุณ
                                โปรดติดต่อเจ้าหน้าที่คุ้มครองข้อมูลของเราผ่าน
                                ผ่านเว็บไซต์ของบริษัท หรือทางอีเมลที่
                                info@medimatch.app หรือทางไลน์แอดที่ @medimatch
                                วันจันทร์ถึงวันศุกร์เวลา 08.00 น. ถึง 17.00 น.
                                ในกรณีที่มีการร้องขอสำเนาข้อมูลส่วนบุคคลของคุณเว้นแต่บริษัทจะมีเหตุผลที่จะปฏิเสธคำขอของคุณบริษัทจะส่งสำเนาดังกล่าวให้คุณภายใน
                                30 วันหลังจากได้รับคำขอของคุณ
                                ในบางกรณีบริษัทอาจร้องขอข้อมูลเพิ่มเติมเพื่อยืนยันตัวตนและสิทธิของคุณซึ่งเป็นส่วนหนึ่งของมาตรการรักษาความปลอดภัยของเรา
                                หากคุณมีข้อสงสัยหรือต้องการใช้สิทธิใด ๆ
                                ที่เกี่ยวข้องกับข้อมูลส่วนบุคคลของคุณโปรดติดต่อเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล
                                ของบริษัทตามรายละเอียดที่ให้ไว้
                            </Text>
                            <Text fontSize='sm' color='heavyBlack'>
                                บริษัทขอแจ้งให้คุณทราบว่าการถอนความยินยอมของคุณอาจส่งผลกระทบต่อบริการที่บริษัทจัดให้
                                เช่น
                                การให้ข้อมูลเกี่ยวกับผลิตภัณฑ์หรือบริการใหม่ของเรา
                                เนื่องจากตัวอย่างเช่น
                                หากยังคงที่เหลืออยู่หลังจากการถอนความยินยอมแล้วข้อมูลอาจไม่เพียงพอที่เราจะให้บริการที่ครบถ้วนตามที่คุณต้องการหรือเราอาจต้องใช้เวลาในการขอข้อมูลเพิ่มเติมจากคุณ
                            </Text>
                        </VStack>
                        <VStack space={3}>
                            <Text
                                fontSize='lg'
                                fontWeight={500}
                                color='heavyBlack'
                            >
                                10. การปรับปรุงข้อมูลส่วนบุคคลของคุณ
                            </Text>
                            <Text fontSize='sm' color='heavyBlack'>
                                ในกรณีที่ข้อมูลส่วนบุคคลที่คุณให้ไว้มีการเปลี่ยนแปลง
                                เราขอแนะนำให้คุณแจ้งให้บริษัททราบถึงการปรับปรุงหรือแก้ไขข้อมูลส่วนบุคคลที่ให้ไว้เพื่อให้ข้อมูลส่วนบุคคลของคุณถูกต้องและเป็นปัจจุบัน
                                หากข้อมูลส่วนบุคคลของคุณไม่ถูกต้องมันอาจส่งผลกระทบต่อบริการของบริษัท
                                และบริษัท
                                จะไม่รับผิดชอบต่อความสูญเสียหรือความเสียหายที่อาจเกิดขึ้นกับคุณหรือบุคคลที่สามอันเป็นผลมาจากการไม่แก้ไขหรือปรับปรุงข้อมูลส่วนบุคคลของคุณให้ถูกต้องแต่อย่างใด
                            </Text>
                        </VStack>
                        <VStack space={3}>
                            <Text
                                fontSize='lg'
                                fontWeight={500}
                                color='heavyBlack'
                            >
                                11. บันทึกคำขอ/การปฏิเสธของบริษัท
                            </Text>
                            <Text fontSize='sm' color='heavyBlack'>
                                เมื่อมีการร้องขอให้ใช้สิทธิเราจะรับทราบว่าได้รับคำขอและยืนยันว่าเรากำลังตรวจสอบคำขอและจะตอบกลับภายในกรอบเวลาตามกฎหมาย
                                เราจะประเมินข้อกำหนดทางกฎหมาย
                                ฐานทางกฎหมายของการประมวลผล
                                ผลกระทบที่อาจเกิดขึ้นกับคุณ
                                และเราจะตอบกลับคุณตามกำหนดเวลา
                                คำขอแต่ละครั้งจะได้รับการพิจารณาเกี่ยวกับข้อเท็จจริงและสถานการณ์และข้อกำหนดทางกฎหมายในขณะนั้น
                                เราจะติดตามและบันทึกคำขอเพื่อจุดประสงค์ด้านความรับผิดชอบ
                            </Text>
                        </VStack>
                        <VStack space={3}>
                            <Text
                                fontSize='lg'
                                fontWeight={500}
                                color='heavyBlack'
                            >
                                12.
                                สิทธิในการยื่นเรื่องร้องเรียนต่อสำนักงานคุ้มครองข้อมูลส่วนบุคคล
                            </Text>
                            <Text fontSize='sm' color='heavyBlack'>
                                คุณมีสิทธิที่จะร้องเรียนในกรณีที่บริษัท
                                ผู้ประมวลผลข้อมูล
                                รวมถึงพนักงานของบริษัทไม่ปฏิบัติตาม
                                พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.2562
                                หรือประกาศอื่นตามกฎหมายฉบับดังกล่าว
                            </Text>
                            <Text fontSize='sm' color='heavyBlack'>
                                หากคุณเห็นว่าเราได้ประมวลผลข้อมูลส่วนบุคคลของคุณโดยละเมิดกฎหมายที่บังคับใช้และไม่สามารถแก้ไขการละเมิดดังกล่าวเพื่อให้คุณพึงพอใจอย่างสมเหตุสมผลได้
                                โปรดติดต่อเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลของบริษัทผ่านทางเว็บไซต์ของบริษัท
                                หรือทางอีเมลที่ info@medimatch.app
                                หรือทางไลน์แอดที่ @medimatch
                            </Text>
                            <Text fontSize='sm' color='heavyBlack' mt={5}>
                                นอกจากนี้คุณยังมีสิทธิยื่นเรื่องร้องเรียนต่อคณะกรรมการคุ้มครองข้อมูลส่วนบุคคลหรือคณะกรรมการผู้เชี่ยวชาญที่ได้รับการแต่งตั้งตามกฎหมายได้อีกด้วย
                            </Text>
                        </VStack>
                        <VStack space={3}>
                            <Text
                                fontSize='lg'
                                fontWeight={500}
                                color='heavyBlack'
                            >
                                13. ความปลอดภัยของข้อมูลส่วนบุคคลของคุณ
                            </Text>
                            <Text fontSize='sm' color='heavyBlack'>
                                บริษัทขอรับรองว่าข้อมูลส่วนบุคคลทั้งหมดที่ถูกเก็บรวบรวมจะถูกเก็บไว้อย่างปลอดภัยและอย่างเคร่งครัดด้วยมาตรฐานความปลอดภัยที่เพียงพอ
                                หากคุณมีเหตุผลที่เชื่อได้ว่าข้อมูลส่วนบุคคลของคุณถูกละเมิดหรือหากคุณมีคำถามใด
                                ๆ
                                เกี่ยวกับประกาศฉบับนี้โปรดติดต่อเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลของบริษัท
                            </Text>
                            <Text fontSize='sm' color='heavyBlack'>
                                บริษัทจะดำเนินการตามขั้นตอนที่เหมาะสมเพื่อให้แน่ใจว่าข้อมูลส่วนบุคคลทั้งหมดที่ถูกรวบรวมและถูกประมวลผลได้รับการรักษาความปลอดภัยและป้องกันการประมวลผล
                                การใช้
                                การดัดแปลงหรือการเปิดเผยโดยไม่ได้รับอนุญาตหรือผิดกฎหมาย
                                การสูญหายหรือการทำลายโดยไม่ได้ตั้งใจ
                                เทคโนโลยีและซอฟต์แวร์ เช่น
                                การควบคุมการรับรองความถูกต้องของผู้ใช้
                                การควบคุมขอบเขตเครือข่ายภายนอกและภายในและการควบคุมโปรแกรม/ซอฟต์แวร์ที่เป็นอันตราย
                            </Text>
                        </VStack>
                        <VStack space={3}>
                            <Text
                                fontSize='lg'
                                fontWeight={500}
                                color='heavyBlack'
                            >
                                14. คุ้กกี้
                            </Text>
                            <Text fontSize='sm' color='heavyBlack'>
                                เราอาจวางคุกกี้ไว้บนเว็บเบราว์เซอร์ของคุณ
                                อุปกรณ์ของคุณหรืออ่านคุกกี้ที่อยู่บนอุปกรณ์ของคุณอยู่แล้วเมื่อคุณเยี่ยมชมเว็บไซต์หรือแอพพลิเคชั่นของเราหรือตรวจสอบข้อความโดยที่เราต้องได้รับความยินยอมจากคุณจากคุณก่อน
                            </Text>
                            <Text fontSize='sm' color='heavyBlack'>
                                เราอาจใช้คุกกี้เพื่อ
                            </Text>

                            <StyledUl>
                                {[
                                    'แยกความแตกต่างระหว่างผู้ใช้',
                                    'ปรับแต่งเว็บไซต์หรือแอพพลิเคชั่น และบริการของเราให้ตรงกับความจำเป็นและความต้องการของผู้เยี่ยมชม',
                                    'ปรับปรุงการใช้งานและฟังก์ชั่นการใช้งานของเว็บไซต์หรือแอพพลิเคชั่นของเรา และ',
                                    'วิเคราะห์ว่าเว็บไซต์หรือแอพพลิเคชั่นของเรามีการใช้งานอย่างไรและรวบรวมสถิติที่ไม่ระบุชื่อและสรุปรวม',
                                ].map((item, index) => (
                                    <StyledLi key={`privacyProcess-${index}`}>
                                        <Text fontSize='sm' color='heavyBlack'>
                                            {item}
                                        </Text>
                                    </StyledLi>
                                ))}
                            </StyledUl>

                            <Text fontSize='sm' color='heavyBlack'>
                                คุณอาจเลือกที่จะปิดการใช้งานคุกกี้ข้างต้นขณะที่เยี่ยมชมเว็บไซต์หรือแอพพลิเคชั่นของเราได้
                                อย่างไรก็ตามการปิดใช้งานคุกกี้ใด ๆ
                                อาจส่งผลกระทบต่อประสบการณ์ของคุณในเว็บไซต์หรือแอพพลิเคชั่นของเรา
                            </Text>
                            <Text fontSize='sm' color='heavyBlack'>
                                หากคุณใช้อุปกรณ์ต่าง ๆ
                                ในการเข้าถึงเว็บไซต์หรือแอพพลิเคชั่นของเรา
                                เราขอแนะนำให้คุณตรวจสอบให้แน่ใจว่าแต่ละเบราว์เซอร์ของแต่ละอุปกรณ์นั้นถูกตั้งค่าคุกกี้ตามที่คุณต้องการ
                            </Text>
                            <Text fontSize='sm' color='heavyBlack'>
                                สำหรับรายละเอียดเพิ่มเติมเกี่ยวกับการจัดการคุกกี้โปรดดูที่
                                &quot;นโยบายคุกกี้&quot; ของเรา
                            </Text>
                        </VStack>
                        <VStack space={3}>
                            <Text
                                fontSize='lg'
                                fontWeight={500}
                                color='heavyBlack'
                            >
                                15. การเปลี่ยนเส้นทางไปยังเว็บไซต์ของบุคคลอื่น
                            </Text>

                            <Text fontSize='sm' color='heavyBlack'>
                                เว็บไซต์หรือแอพพลิเคชั่นของบริษัทอาจถูกเปลี่ยนเส้นทางไปยังเว็บไซต์อื่น
                                ๆ
                                เพื่ออำนวยความสะดวกให้คุณเมื่อคุณเยี่ยมชมเว็บไซต์อื่น
                                ๆ เว็บไซต์เหล่านี้อาจรวบรวมข้อมูลส่วนบุคคลของคุณ
                                บริษัทจะไม่รับผิดชอบต่อการประมวลผลข้อมูลส่วนบุคคลของคุณโดยเว็บไซต์อื่นหรือบุคคลดังกล่าว
                                ด้วยเหตุผลนี้บริษัทแนะนำให้คุณตรวจสอบประกาศนโยบายการคุ้มครองข้อมูลส่วนบุคคลของเว็บไซต์เหล่านี้อย่างรอบคอบก่อนที่คุณจะใช้บริการบนเว็บไซต์เหล่านั้น
                            </Text>
                        </VStack>
                        <VStack space={3}>
                            <Text
                                fontSize='lg'
                                fontWeight={500}
                                color='heavyBlack'
                            >
                                16.
                                การเปลี่ยนแปลงประกาศนโยบายคุ้มครองข้อมูลส่วนบุคคล
                            </Text>

                            <Text fontSize='sm' color='heavyBlack'>
                                บริษัทขอสงวนสิทธิในการเปลี่ยนแปลง
                                แก้ไขหรือปรับปรุงประกาศนโยบายคุ้มครองข้อมูลส่วนบุคคลได้ตลอดเวลาตามที่เห็นสมควรโดยแจ้งให้คุณทราบถึงการเปลี่ยนแปลงดังกล่าว
                                บริษัทจะแจ้งการเปลี่ยนแปลงในเว็บไซต์หรือผ่าน
                                ซึ่งคุณสามารถตรวจสอบได้ตลอดเวลา
                            </Text>
                        </VStack>
                        <VStack space={3}>
                            <Text
                                fontSize='lg'
                                fontWeight={500}
                                color='heavyBlack'
                            >
                                17. ติดต่อเรา
                            </Text>

                            <div ref={ref}>
                                <Text fontSize='sm' color='heavyBlack'>
                                    หากคุณมีความคิดเห็น คำแนะนำ
                                    คำถามหรือต้องการร้องเรียนเกี่ยวกับข้อมูลส่วนบุคคลของคุณ
                                    โปรดติดต่อเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล
                                    ของบริษัทผ่านทางเว็บไซต์ของบริษัท
                                    หรือทางอีเมลที่ info@medimatch.app
                                    หรือทางไลน์แอดที่ @medimatch
                                </Text>
                            </div>
                        </VStack>
                    </VStack>
                </Box>
            }
        />
    )
}

export default TermModal
