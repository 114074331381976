import styled from 'styled-components'
import Collapse, { Panel } from 'rc-collapse'

import { commonColors } from '../../constant'

export const StyledCollapse = styled(Collapse)`
    ${({}) => `
      -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border: none;

        padding: 0 22px;

        background-color: white;

        & .rc-collapse-item {
            border-top: none;
        }

        & .rc-collapse-item .rc-collapse-header {
            padding: 2px 12px;
            background-color: ${commonColors.gray8};
        }
        & .rc-collapse-item.suggest-tags .rc-collapse-header {
            background-color: ${commonColors.blue1};
        }

        & .rc-collapse-content {
            padding: 0;
        }

        & .rc-collapse-content-box {
            margin: 0;
        }
    `}
`

export const StyledHorizontalRule = styled.div`
    ${({}) => `
        width: 100%;
        margin: 0 0 10px;
        border-bottom: 1px dashed ${commonColors.gray28};
    `}
`

export const StyledLinearGradientTagButtonContainer = styled.div`
    ${({}) => `
        background: ${commonColors.greenBlueLinear};
        column-gap: 10px;
        border-radius: 16px;
        height: 28px;
        display: flex;
        align-items: center;
        width: fit-content;
        padding: 0 10px;
    `}
`

export const StyledSelectedContainer = styled.div`
    ${({}) => `
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 12px;
        padding: 0 22px 10px;
    `}
`
