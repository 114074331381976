import React from 'react'
import DataTable from 'react-data-table-component'
import { Text, Skeleton } from 'native-base'
import { SContainer, SFooter } from './style'
import { Pagination, RowsPerPage } from '../../components'

export const CDataTable = ({
    className = '',
    customStyles,
    dataSource,
    columns,
    onChangePage,
    selectableRows,
    pagination = true,
    onRowClick,
    isLoading = false,
    colorRow,
    onSelectedRowsChange,
    selectableRowSelected,
    conditionalRowStyles,
    rowsPerPage,
    clearSelectedRows,
    keyField,
    noDataComponent,
    language = 'en',
    paginationServer,
    showLimit = true,
    ...restProps
}) => {
    const overrideClassName = `${className} ${selectableRows ? 'checkbox' : ''}`
    return (
        <SContainer colorRow={colorRow}>
            <DataTable
                className={overrideClassName}
                data={dataSource}
                columns={columns}
                selectableRows={selectableRows === undefined ? false : true}
                customStyles={customStyles}
                onRowClicked={onRowClick}
                progressPending={isLoading}
                onSelectedRowsChange={onSelectedRowsChange}
                selectableRowSelected={selectableRowSelected}
                paginationDefaultPage={pagination.currentPage}
                paginationRowsPerPageOptions={[pagination.pageSize]}
                pagination={pagination}
                clearSelectedRows={clearSelectedRows}
                conditionalRowStyles={conditionalRowStyles}
                paginationServer={paginationServer}
                keyField={keyField}
                persistTableHead={true}
                noDataComponent={
                    noDataComponent || (
                        <Text mt={3}>
                            {language === 'th'
                                ? 'ไม่มีข้อมูล'
                                : 'There are no records to display.'}
                        </Text>
                    )
                }
                progressComponent={
                    <ProgressComponent
                        pageSize={pagination.pageSize}
                        columns={columns}
                    />
                }
                {...restProps}
            />
            <SFooter>
                {showLimit && (
                    <RowsPerPage
                        callback={pagination.perPageCallback}
                        value={pagination.pageSize}
                        isDisabled={isLoading}
                    />
                )}
                {pagination.totalItem > pagination.pageSize && (
                    <Pagination
                        pageSize={pagination.pageSize}
                        totalItem={pagination.totalItem}
                        currentPage={pagination.currentPage}
                        onChangePage={onChangePage}
                    />
                )}
            </SFooter>
        </SContainer>
    )
}

const ProgressComponent = ({ pageSize, columns }) => {
    return (
        <div className='rdt_TableBody' style={{ width: '100%' }}>
            {Array.from({ length: pageSize }).map((_, index) => (
                <div
                    key={`skeleton_row_${index}`}
                    className='rdt_TableRow'
                    style={{
                        cursor: 'default',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <div
                        className='rdt_TableCell'
                        style={{ boxSizing: 'border-box' }}
                    >
                        <input />
                    </div>
                    {columns.map(item => (
                        <div
                            key={`skeleton_column_${item.name}`}
                            className='rdt_TableCell'
                            style={{
                                width: item.width,
                                minWidth: item.minWidth,
                                boxSizing: 'border-box',
                                flexGrow: item.width || item.minWidth ? 0 : 1,
                            }}
                        >
                            <Skeleton
                                flex='1'
                                h='36'
                                rounded='md'
                                startColor='coolGray.100'
                            />
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
}
