import { forwardRef } from 'react'

import {
    Button,
    Text,
    Pressable,
    Box,
    useTheme,
    Spinner,
    HStack,
    IconButton,
} from 'native-base'

import { DropdownIcon, XIcon } from '../index'

import {
    StyledLinearGradientTextContainer,
    StyledSelectedIndicator,
    StyledLinearGradientOutlineButton2,
} from './style'

import { StyledTabBorder } from '../Tabs/style'
import CBadge from '../Badge'

export const FilledButton = ({
    children,
    isDisabled,
    isLoading,
    background,
    disabledBackground,
    style,
    onPress,
    width,
    maxWidth,
    height,
    shadow,
    borderRadius,
    containerStyle,
    ...rest
}) => {
    const { colors } = useTheme()

    return (
        <Pressable
            onPress={onPress}
            width={width}
            maxWidth={maxWidth}
            isDisabled={isDisabled || isLoading}
            style={containerStyle}
        >
            {({ isHovered, isPressed }) => (
                <Box
                    alignItems='center'
                    justifyContent='center'
                    padding='10px'
                    borderRadius={borderRadius || 30}
                    height={height || '58px'}
                    position='relative'
                    style={{
                        background: isDisabled
                            ? disabledBackground
                                ? colors[disabledBackground]
                                : colors.greenBlueLinearDisabled
                            : background
                            ? colors[background]
                            : colors.greenBlueLinear,
                        filter: isPressed
                            ? 'brightness(65%)'
                            : isHovered
                            ? 'brightness(80%)'
                            : 'brightness(100%)',
                        cursor: isDisabled ? 'not-allowed' : 'pointer',
                        ...style,
                    }}
                    {...rest}
                >
                    {isLoading ? <Spinner color='white' /> : children}
                    {shadow && (
                        <Box
                            style={{
                                width: '80%',
                                height: height || '58px',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -25%)',
                                background: 'rgba(2, 120, 214, 0.3)',
                                filter: 'blur(34px)',
                                borderRadius: '24px',
                            }}
                        />
                    )}
                </Box>
            )}
        </Pressable>
    )
}

export const SoicialButton = ({ children, ...rest }) => {
    return (
        <Button
            borderRadius={12}
            borderWidth='1.5px'
            borderColor='gray1'
            bg='transparent'
            w='80px'
            h='62px'
            _hover={{
                bg: 'gray.50',
            }}
            _pressed={{
                bg: 'gray.200',
            }}
            {...rest}
        >
            {children}
        </Button>
    )
}

export const SelectButton = ({ selected, children, ...rest }) => {
    return (
        <Button
            height='32px'
            borderRadius={20}
            borderWidth={2}
            borderColor={selected ? 'primaryBlue' : 'gray4'}
            bg='transparent'
            _hover={{
                bg: selected ? 'gray4' : 'primaryBlue',
            }}
            {...rest}
        >
            <Text
                fontSize='md'
                fontWeight={500}
                color={selected ? 'primaryBlue' : 'gray4'}
            >
                {children}
            </Text>
        </Button>
    )
}
export const TagButton = ({ children, onPress, ...rest }) => {
    return (
        <Box
            height='32px'
            borderRadius={20}
            borderWidth={2}
            borderColor='primaryBlue'
            paddingLeft={2}
            {...rest}
        >
            <HStack alignItems='center' space='1'>
                <Text fontSize='md' fontWeight={500} color='primaryBlue'>
                    {children}
                </Text>
                <IconButton
                    padding='5px'
                    icon={<XIcon fill='primaryBlue' />}
                    onPress={onPress}
                    borderRadius='full'
                />
            </HStack>
        </Box>
    )
}

export const LinearGradientOutlineButton = forwardRef(
    (
        {
            children,
            isDisabled,
            isLoading,
            isNotText,
            onPress,
            width,
            maxWidth,
            height,
            style,
            textStyle,
            borderRadius,
            ...rest
        },
        ref
    ) => {
        const { colors } = useTheme()
        return (
            <Pressable
                ref={ref}
                onPress={onPress}
                width={width}
                maxWidth={maxWidth}
                height={height || '48px'}
                isDisabled={isDisabled || isLoading}
            >
                {({ isHovered, isPressed }) => {
                    return (
                        <Box
                            alignItems='center'
                            justifyContent='center'
                            padding='2px'
                            borderRadius={
                                borderRadius ? `${borderRadius + 2}px` : '12px'
                            }
                            height='100%'
                            style={{
                                background: isDisabled
                                    ? colors.greenBlueLinearDisabled
                                    : colors.greenBlueLinear,
                                filter:
                                    isDisabled || isPressed
                                        ? 'brightness(95%)'
                                        : 'brightness(100%)',
                                cursor: isDisabled ? 'not-allowed' : 'pointer',
                                ...style,
                            }}
                            {...rest}
                        >
                            <StyledLinearGradientTextContainer
                                isHovered={isHovered}
                                borderRadius={
                                    borderRadius ? `${borderRadius}px` : '10px'
                                }
                                isDisabled={isDisabled}
                            >
                                {isLoading ? (
                                    <Spinner
                                        color={
                                            isHovered ? 'white' : 'primaryBlue'
                                        }
                                    />
                                ) : isNotText ? (
                                    children
                                ) : (
                                    <Text
                                        fontSize='sm'
                                        fontWeight={400}
                                        style={textStyle}
                                    >
                                        <span>{children}</span>
                                    </Text>
                                )}
                            </StyledLinearGradientTextContainer>
                        </Box>
                    )
                }}
            </Pressable>
        )
    }
)

export const LinearGradientOutlineButton2 = forwardRef(
    (
        {
            children,
            isDisabled,
            isLoading,
            onPress,
            width,
            minWidth,
            height,
            style,
            textStyle,
            borderRadius,
            onlyShow,
            ...rest
        },
        ref
    ) => {
        const { colors } = useTheme()

        return (
            <StyledLinearGradientOutlineButton2
                ref={ref}
                onClick={isDisabled || isLoading ? () => {} : () => onPress()}
                isDisabled={isDisabled}
                width={width}
                height={height}
                minWidth={minWidth}
                {...rest}
            >
                <Box
                    alignItems='center'
                    justifyContent='center'
                    padding='2px'
                    borderRadius={
                        borderRadius ? `${borderRadius + 2}px` : '12px'
                    }
                    height='100%'
                    style={{
                        background: colors.greenBlueLinear,
                        cursor: onlyShow
                            ? ''
                            : isDisabled
                            ? 'not-allowed'
                            : 'pointer',
                        ...style,
                    }}
                >
                    <StyledLinearGradientTextContainer
                        isDisabled={isDisabled}
                        borderRadius={
                            borderRadius ? `${borderRadius}px` : '10px'
                        }
                    >
                        {isLoading ? (
                            <Spinner color='primaryBlue' />
                        ) : (
                            <Text
                                fontSize='sm'
                                fontWeight={400}
                                style={textStyle}
                            >
                                <span>{children}</span>
                            </Text>
                        )}
                    </StyledLinearGradientTextContainer>
                </Box>
            </StyledLinearGradientOutlineButton2>
        )
    }
)

export const WithIconButton = forwardRef(
    (
        {
            isDisabled,
            isLoading,
            onPress,
            width,
            maxWidth,
            height,
            Icon,
            text,
            isDropdown,
            badgeContent,
            selected,
        },
        ref
    ) => {
        return (
            <Pressable
                onPress={onPress}
                width={width}
                maxWidth={maxWidth}
                height={height}
                isDisabled={isDisabled || isLoading}
                ref={ref}
            >
                {({ isHovered, isPressed }) => {
                    return (
                        <Box
                            alignItems='center'
                            borderRadius={9}
                            style={{
                                transform: [
                                    {
                                        scale: isPressed ? 0.9 : 1,
                                    },
                                ],
                            }}
                        >
                            <CBadge badgeContent={badgeContent}>{Icon}</CBadge>
                            <HStack alignItems='center' space={1} mb='1'>
                                <Text fontSize='xs' fontWeight={400}>
                                    {text}
                                </Text>
                                {isDropdown && <DropdownIcon />}
                            </HStack>
                            <StyledTabBorder
                                hovered={isHovered}
                                selected={selected}
                            />
                        </Box>
                    )
                }}
            </Pressable>
        )
    }
)

export const TextButton = ({
    isDisabled,
    isLoading,
    onPress,
    width,
    maxWidth,
    height,
    children,
    containerStyle,
    style,
}) => {
    return (
        <Pressable
            onPress={onPress}
            width={width}
            maxWidth={maxWidth}
            height={height}
            isDisabled={isDisabled || isLoading}
            style={containerStyle}
        >
            {({ isHovered, isPressed }) => (
                <Box
                    alignItems='center'
                    borderRadius={9}
                    style={{
                        transform: [
                            {
                                scale: isPressed ? 0.9 : 1,
                            },
                        ],
                        ...style,
                    }}
                >
                    {children}
                </Box>
            )}
        </Pressable>
    )
}

export const EmployerJobTypeButton = ({
    onPress,
    isActive,
    text,
    inactiveIcon,
    activeIcon,
    ...rest
}) => {
    const { colors } = useTheme()

    return (
        <IconButton
            onPress={onPress}
            borderRadius={12}
            bg={isActive ? 'blue2' : 'white'}
            _hover={{
                bg: 'blue2',
            }}
            _pressed={{
                bg: 'blue3',
            }}
            icon={
                <HStack
                    width='100%'
                    alignItems='center'
                    justifyContent='flex-start'
                    space={3}
                >
                    {isActive ? activeIcon : inactiveIcon}
                    <span
                        style={{
                            fontSize: isActive ? 'md' : 'sm',
                            fontWeight: isActive ? 500 : 400,
                            color: isActive
                                ? colors.primaryBlue
                                : colors.heavyBlack,
                        }}
                    >
                        {text}
                    </span>
                </HStack>
            }
            {...rest}
        />
    )
}

export const EmployeeJobTypeButton = ({
    onPress,
    isActive,
    icon,
    text,
    ...rest
}) => {
    const { colors } = useTheme()

    return (
        <IconButton
            onPress={onPress}
            _hover={{
                bg: 'blue2',
            }}
            _pressed={{
                bg: 'blue3',
            }}
            pl='0'
            py='0'
            icon={
                <HStack
                    space='2'
                    width='100%'
                    alignItems='center'
                    justifyContent='flex-start'
                    position='relative'
                    pl='3'
                    height='38px'
                >
                    <StyledSelectedIndicator isActive={isActive} />
                    {icon}
                    <span
                        style={{
                            color: isActive
                                ? colors.heavyBlack
                                : colors.textDetail,
                        }}
                    >
                        {text}
                    </span>
                </HStack>
            }
            {...rest}
        />
    )
}
