import {
    MyWorkNavGrediantIcon,
    ProfileNavGrediantIcon,
    NotificationNavGradiantIcon,
    MesssageNavGrediantIcon,
    FindWorkNavGrediantIcon,
    HomeGradientIcon,
    HeartNavGrediantIcon,
} from '../../../components/Icon'

export const employeeMenuItems = [
    {
        name: 'หน้าหลัก',
        pathName: '/',
        icon: HomeGradientIcon,
    },
    {
        name: 'งานของฉัน',
        pathName: '/m-myjob',
        icon: MyWorkNavGrediantIcon,
    },
    {
        name: 'ตามหางาน',
        pathName: '/m-job',
        icon: FindWorkNavGrediantIcon,
    },
    {
        name: 'งานโปรด',
        pathName: '/m-favoritejob',
        icon: HeartNavGrediantIcon,
    },
    {
        name: 'แชท',
        pathName: '/messages',
        icon: MesssageNavGrediantIcon,
    },
    {
        name: 'แจ้งเตือน',
        pathName: '/notifications',
        icon: NotificationNavGradiantIcon,
    },
    {
        name: 'โปรไฟล์',
        pathName: '/m-my-profile',
        icon: ProfileNavGrediantIcon,
    },
]
